import LinkTool from "@editorjs/link"
import { IconLink } from "@codexteam/icons"

export function createWorkweekLinkTool(title: string) {
  return class WorkweekLinkTool extends LinkTool {
    static get toolbox() {
      return {
        icon: IconLink,
        title: title,
      }
    }
  }
}
