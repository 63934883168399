import { Check } from "lucide-react"
import { useCommunity } from "~/community/useCommunity"
import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { Callout } from "~/ui/Callout"
import { Token } from "~/ui/Token"
import { useWizard } from "~/ui/Wizard"
import BoardRoomBefore1 from "~/images/boardroom-before1.png"
import BoardRoomBefore2 from "~/images/boardroom-before2.png"
import BoardRoomBefore3 from "~/images/boardroom-before3.png"
import { useSubscription } from "../SubscriptionProvider"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useEffect, useRef } from "react"

export const BoardRoomBeforeYouMakeTheCallStep = () => {
  const community = useCommunity()
  const { back, goToStep, meta } = useWizard()
  const { sendMakeTheMostEmail } = useSubscription()
  const { logEvent } = useLogEvent()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.CommunitySummaryViewed,
      {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      },
      hasLoggedEvent
    )
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  const continueToCancel = () => {
    goToStep("LookupSaveBeforeLoseOffersStep", "forward", false)
  }

  const keepMembership = async () => {
    goToStep("LoadingStep", "forward", false)
    await sendMakeTheMostEmail()
    goToStep("SoGladYouStayedStep")
  }

  return (
    <div className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Before you make the call</DialogTitle>
        <div className="text-xs">
          We thought we'd share all the ways {community.name} can support you
        </div>
      </DialogHeader>

      <div className="flex flex-col gap-8 justify-start">
        <Callout
          medium={
            <img
              src={BoardRoomBefore1}
              alt="Real Discussions with Peers in Hospitalogy"
              className="max-w-[250px] sm:ml-16"
            />
          }
        >
          <div className="flex flex-col gap-2 p-8">
            <Token>1</Token>
            <div className="font-bold">
              Real Discussions with Peers in Hospitalogy
            </div>
          </div>
        </Callout>

        <Callout
          inverted
          medium={
            <div className="relative flex items-end justify-end">
              <img
                src={BoardRoomBefore2}
                alt="Resources as an extension of your team"
              />
              <div className="absolute w-16 top-0 bottom-0 right-0 bg-gradient-to-l from-card to-transparent" />
            </div>
          }
        >
          <div className="flex flex-col gap-2 p-8">
            <Token>2</Token>
            <div className="font-bold">
              Resources as an extension of your team
            </div>
          </div>
        </Callout>

        <Callout
          medium={
            <img
              src={BoardRoomBefore3}
              alt="Dashboards to unpack your org's data"
              className="max-w-[300px] sm:ml-8"
            />
          }
        >
          <div className="flex flex-col gap-2 p-8">
            <Token>3</Token>
            <div className="font-bold">
              Warm introductions to industry peers
            </div>
          </div>
        </Callout>
      </div>

      <Alert className="flex justify-between items-center">
        <ul className="flex flex-col gap-1 font-semibold text-xs">
          <li className="flex gap-2 items-center">
            <Check className="w-5 h-5" /> Exclusive invites to IRL events
          </li>
          <li className="flex gap-2 items-center">
            <Check className="w-5 h-5" /> Blake's financial analysis
          </li>
          <li className="flex gap-2 items-center">
            <Check className="w-5 h-5" /> Live Summit tickets
          </li>
          <li className="flex gap-2 items-center">
            <Check className="w-5 h-5" /> Bi-weekly Deep Dives
          </li>
        </ul>
        <div>
          <Button onClick={keepMembership}>Yes, I'll Keep My Membership</Button>
        </div>
      </Alert>

      <Separator />

      <DialogFooter>
        <Button type="button" variant="link" size="inline" onClick={back}>
          Back
        </Button>
        <Button
          type="button"
          variant="link"
          size="inline"
          onClick={continueToCancel}
        >
          Continue to Cancel
        </Button>
      </DialogFooter>
    </div>
  )
}

BoardRoomBeforeYouMakeTheCallStep.displayName =
  "BoardRoomBeforeYouMakeTheCallStep"
