import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { DotsVerticalIcon } from "@radix-ui/react-icons"
import { formatDate } from "date-fns"
import { EyeOffIcon, MessagesSquare, PlusIcon } from "lucide-react"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useDebounce } from "use-debounce"
import { z } from "zod"
import { gql } from "~/__generated__"
import { Channel } from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Badge } from "~/ui/badge"
import { Button } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import { Checkbox } from "~/ui/checkbox"
import { useConfirm } from "~/ui/Confirm"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { IconButton } from "~/ui/IconButton"
import { Input } from "~/ui/input"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { SearchInput } from "~/ui/SearchInput"

const channelFormSchema = z.object({
  name: z.string().min(1, {
    message: "Name is required",
  }),
  active: z.boolean(),
})

type ChannelFormValues = z.infer<typeof channelFormSchema>

export const AdminChannelsScreen = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300)
  const { data, loading } = useQuery(CHANNELS_QUERY_DOCUMENT, {
    variables: { searchTerm: debouncedSearchTerm },
  })
  const [runCreateChannel] = useSafeMutation(CREATE_CHANNEL_MUTATION)

  const [channels, setChannels] = useState<Partial<Channel>[]>([])

  useEffect(() => {
    if (data?.channels) {
      setChannels(data.channels.nodes)
    }
  }, [data])

  const form = useForm<ChannelFormValues>({
    resolver: zodResolver(channelFormSchema),
    defaultValues: {
      name: "",
      active: false,
    },
  })

  const onSubmit = useCallback(
    async (values: ChannelFormValues) => {
      const mutationInput = {
        name: values.name,
        active: values.active,
      }

      const { data, errors } = await runCreateChannel({
        variables: { input: { ...mutationInput } },
      })

      if (errors || !data?.channelCreate.channel) {
        toast.error("Failed to create channel")
        return
      }

      form.reset()
      setChannels([...channels, data.channelCreate.channel])
    },
    [runCreateChannel, form, channels]
  )

  return (
    <>
      <AdminHeader title="Channels" Icon={MessagesSquare} />
      <Card className="pt-4">
        <CardContent className="flex flex-col gap-4">
          <SearchInput
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder="Search channels"
          />

          {loading ? (
            <div className="h-64 flex items-center justify-center">
              <LoadingIndicator />
            </div>
          ) : (
            <div className="flex flex-col divide-y">
              <div className="p-4 grid grid-cols-3 items-center text-center font-bold">
                <div className="text-left">Name</div>
                <div>Created At</div>
                <div className="text-right">Actions</div>
              </div>

              {channels &&
                channels.map((channel) => (
                  <ChannelRow
                    key={channel.id}
                    channel={channel}
                    setChannels={setChannels}
                  />
                ))}
            </div>
          )}

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="gap-4 flex items-center"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <FormControl>
                      <Input placeholder="Name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="active"
                render={({ field }) => (
                  <FormItem className="flex gap-2 items-center space-y-0">
                    <FormControl>
                      <div>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </div>
                    </FormControl>
                    <FormLabel>Active</FormLabel>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="text-right">
                <Button
                  type="submit"
                  pre={<PlusIcon className="w-4 h-4" />}
                  className="w-fit"
                >
                  Add
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  )
}

const ChannelRow = ({
  channel,
  setChannels,
}: {
  channel: Partial<Channel>
  setChannels: Dispatch<SetStateAction<Partial<Channel>[]>>
}) => {
  const [runUpdateChannel] = useSafeMutation(UPDATE_CHANNEL_MUTATION)
  const contextMenuTriggerRef = useRef<HTMLDivElement>(null)
  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }
  const showConfirm = useConfirm()

  const toggleActive = () => {
    showConfirm({
      title: channel.active ? "Deactivate Channel" : "Activate Channel",
      body: channel.active
        ? "Deactivating a channel will hide it from community members and make it read-only. Are you sure you want to deactivate this channel?"
        : "Activating a channel will make it visible to community members. Are you sure you want to activate this channel?",
      onConfirm: async () => {
        const { data, errors } = await runUpdateChannel({
          variables: {
            input: {
              channelId: channel.id!,
              active: !channel.active,
            },
          },
        })

        if (errors) {
          toast.error("Failed to update channel")
          return
        }

        if (data?.channelUpdate.channel) {
          setChannels((channels) =>
            channels.map((c) =>
              c.id === channel.id ? data.channelUpdate.channel : c
            )
          )
        }
      },
    })
  }

  const editName = () => {
    showConfirm({
      title: "Edit Name",
      body: "Are you sure you want to edit the name of this channel?",
      prompt: {
        label: "Name",
        value: channel.name,
        required: true,
        placeholder: "Provide a name for your channel",
        type: "text",
      },
      onConfirm: async (name) => {
        const { data, errors } = await runUpdateChannel({
          variables: {
            input: {
              channelId: channel.id!,
              name,
            },
          },
        })

        if (errors) {
          toast.error("Failed to update channel")
          return
        }

        if (data?.channelUpdate.channel) {
          setChannels((channels) =>
            channels.map((c) =>
              c.id === channel.id ? data.channelUpdate.channel : c
            )
          )
        }
      },
    })
  }

  return (
    <ContextMenu>
      <ContextMenuContent>
        <ContextMenuItem onClick={editName} className="cursor-pointer">
          Edit Name
        </ContextMenuItem>
        <ContextMenuItem onClick={toggleActive} className="cursor-pointer">
          {channel.active ? "Deactivate" : "Activate"} Channel
        </ContextMenuItem>
      </ContextMenuContent>
      <ContextMenuTrigger asChild>
        <div
          className="p-4 grid grid-cols-3 items-center text-center"
          ref={contextMenuTriggerRef}
        >
          <div className="text-left">
            {channel.active ? (
              <Badge variant="highlightOutline">{channel.name}</Badge>
            ) : (
              <Badge variant="outline" className="opacity-80">
                <EyeOffIcon className="w-4 h-4 mr-2" />
                {channel.name}
              </Badge>
            )}
          </div>
          <div className="overflow-hidden text-ellipsis whitespace-nowrap">
            {formatDate(channel.createdAt, "MMM d, yyyy")}
          </div>

          <div className="text-right">
            <IconButton onClick={triggerContextMenu}>
              <DotsVerticalIcon />
            </IconButton>
          </div>
        </div>
      </ContextMenuTrigger>
    </ContextMenu>
  )
}

const CHANNELS_QUERY_DOCUMENT = gql(`
  query AdminChannels($searchTerm: String) {
    channels(search: $searchTerm) {
      nodes {
        id
        name
        slug
        active
        createdAt
      }
    }
  }
`)

const CREATE_CHANNEL_MUTATION = gql(`
  mutation CreateChannel($input: ChannelCreateInput!) {
    channelCreate(input: $input) {
      channel {
        id
        name
        slug
        active
        createdAt
      }
    }
  }
`)

const UPDATE_CHANNEL_MUTATION = gql(`
  mutation UpdateChannel($input: ChannelUpdateInput!) {
    channelUpdate(input: $input) {
      channel {
        id
        name
        slug
        active
        createdAt
      }
    }
  }
`)
