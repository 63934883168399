import { Button } from "~/ui/button"
import { feedPath } from "~/common/paths"
import Cup from "../images/icons/cup.svg?react"
import { Link } from "react-router-dom"

export const Syncing = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <div className="rounded-full p-4 bg-neutral-50 border border-mercury">
        <Cup />
      </div>
      <h2 className="font-serif text-2xl text-center">
        We are integrating your HRiS data
      </h2>
      <div className="text-center text-xs w-2/3">
        This process usually takes anywhere from 5 to 10 minutes. Feel free to
        explore the Community Feed while we load your content in the background.
      </div>

      <Button asChild className="py-2 px-4 my-4 rounded-full">
        <Link to={feedPath.pattern}>Community Feed</Link>
      </Button>
    </div>
  )
}
