import { useQuery } from "@apollo/client"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { ArticleSortTypeEnum } from "~/__generated__/graphql"
import { articlePath, libraryPath } from "~/common/paths"
import { HighlightContent } from "~/components/HighlightContent"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { DeepDiveSkeleton } from "./DeepDiveModule"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"
import { formatDate } from "~/common/formatDate"
import { UserName } from "~/directory/UserName"
import { useCommunity } from "~/community/useCommunity"
import { LIBRARY_QUERY_DOCUMENT } from "~/screens/LibraryScreen"

export const NewInLibraryModule = () => {
  const community = useCommunity()
  const { data, loading } = useQuery(LIBRARY_QUERY_DOCUMENT, {
    variables: {
      sort: ArticleSortTypeEnum.CreatedAtDesc,
      contentTypes: community.contentTypes,
      first: 1,
    },
  })
  const article = useMemo(() => data?.articles.edges[0]?.node, [data])
  const authorNames = article?.collaborators
    .map((c) => {
      return c.user ? `${c.user.firstName} ${c.user.lastName}` : ""
    })
    .join(", ")
  const formattedDate =
    article &&
    formatDate(article.markedLiveAt || article.createdAt, "MMM do, yyyy")

  return !loading && !article ? null : (
    <Card>
      <CardHeader>
        <CardTitle>New in Library</CardTitle>
      </CardHeader>
      <CardContent className="pt-4">
        {loading && <DeepDiveSkeleton />}
        {!loading && article && (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <StackedUserAvatars
                users={article.collaborators.map((c) => c.user)}
              />
              {authorNames && (
                <div className="text-2xs">
                  {article.collaborators.length === 1 ? (
                    <UserName user={article.collaborators[0].user} />
                  ) : (
                    authorNames
                  )}
                </div>
              )}
            </div>
            <div className="font-medium text-sm">
              <Link
                to={articlePath({ articleId: article.id })}
                className="hover:underline"
              >
                {article.approvedRevision?.title}
              </Link>
            </div>
            <div className="text-2xs text-pretext-light-gray">
              <HighlightContent
                content={article.approvedRevision?.description || ""}
                truncationLimit={150}
                lineLimit={3}
              />
            </div>
            <div className="text-2xs text-pretext-light-gray">
              {formattedDate}
            </div>
            <Link
              to={libraryPath.pattern}
              className="text-sm tracking-wide font-semibold hover:underline text-primary"
            >
              See More
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
