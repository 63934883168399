import { Link } from "react-router-dom"
import { CardHeader } from "~/ui/card"
import { bookmarksPath } from "~/common/paths"
import BookmarkFilled from "~/images/icons/bookmark-filled.svg?react"

export const JustBookmarkedCardHeader = () => {
  return (
    <CardHeader className="flex items-center">
      <div className="flex flex-row gap-2 items-start md:items-center">
        <BookmarkFilled className="text-highlight" />
        <div className="text-2xs flex flex-col md:flex-row gap-1">
          <b>Post has been bookmarked.</b>
          <span>You can access your bookmarks on your profile.</span>
          <Link
            to={bookmarksPath.pattern}
            className="z-[1] text-2xs text-primary underline"
          >
            Learn More
          </Link>
        </div>
      </div>
    </CardHeader>
  )
}
