import { useMemo } from "react"
import {
  Article,
  CommunitySlug,
  TierIntervalEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { Button } from "~/ui/button"
import ArticleStackBackground from "~/images/article-stack-bg.png"
import { marketinglandArticleStack } from "~/images/__generated__/marketingland-article-stack"
import { boardroomArticleStack } from "~/images/__generated__/boardroom-article-stack"
import { safespaceArticleStack } from "~/images/__generated__/safespace-article-stack"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"

export const SubscribeToReadArticleBanner = ({
  article,
}: {
  article: Pick<Article, "premium">
}) => {
  const { openSubscriptionWizard, redirectToStripe } = useSubscription()
  const community = useCommunity()
  const ccls = useCommunityClassname()

  const ctaClicked = () => {
    if (article.premium) {
      openSubscriptionWizard("PricingTableStep", {
        source: "SubscribeToReadArticleBanner",
        requiredFeature: "canViewPremiumArticles",
      })
    } else {
      redirectToStripe(TierLevelEnum.Free, TierIntervalEnum.Year)
    }
  }

  return (
    <>
      <div
        className={cn(
          "z-50 border-t fixed bottom-0 left-0 right-0 bg-background text-foreground py-8 px-4 lg:px-12",
          ccls({
            [CommunitySlug.Safespace]: "border-mercury",
            default: "border-white",
          })
        )}
      >
        <div className="container flex flex-col md:flex-row items-center mx-auto gap-4">
          <ArticleStack />
          <div className="flex-1">
            <div className="font-serif text-2xl mb-3">
              {article.premium ? (
                <>View full content with a {community.name} Membership</>
              ) : (
                <>
                  View full content and more with a {community.name} Membership
                </>
              )}
            </div>
            <div className="text-2xs">
              Pair your learning with discussions, events, and connections to
              the top leaders in the industry.
            </div>
          </div>

          <Button variant="default" className="ml-4" onClick={ctaClicked}>
            {article.premium ? <>Choose Membership</> : <>Join for Free!</>}
          </Button>
        </div>
      </div>
    </>
  )
}

const ArticleStack = () => {
  const community = useCommunity()
  const stackImage = useMemo(() => {
    switch (community.slug) {
      case CommunitySlug.Marketingland:
        return marketinglandArticleStack
      case CommunitySlug.Boardroom:
        return boardroomArticleStack
      case CommunitySlug.Safespace:
        return safespaceArticleStack
      default:
        return null
    }
  }, [community])

  return (
    <div className="relative top-3">
      {stackImage && (
        <img
          src={stackImage.src}
          alt="A stack of informative articles"
          className="w-[165px] h-[80px]"
        />
      )}
      <img
        src={ArticleStackBackground}
        alt=""
        className="w-[92px] h-[92px] absolute -top-4 -left-8 z-[-1]"
      />
    </div>
  )
}
