export const COMPANY_SIZES = [
  {
    value: "size_1",
    label: "1",
  },
  {
    value: "size_2_to_10",
    label: "2 to 10",
  },
  {
    value: "size_11_to_50",
    label: "11 to 50",
  },
  {
    value: "size_51_to_100",
    label: "51 to 100",
  },
  {
    value: "size_101_to_250",
    label: "101 to 250",
  },
  {
    value: "size_251_to_500",
    label: "251 to 500",
  },
  {
    value: "size_501_to_1000",
    label: "501 to 1000",
  },
  {
    value: "size_1001_to_5000",
    label: "1001 to 5000",
  },
  {
    value: "size_5000_plus",
    label: "5000+",
  },
]
