import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/ui/tabs"
import { ApplicationsTable } from "~/admin/users/ApplicationsTable"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import { adminApplicationsPath } from "~/common/paths"
import { UnfitApplicationsTable } from "~/admin/users/UnfitApplicationsTable"
import { Users } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { OnboardingStateEnum } from "~/__generated__/graphql"

export const AdminApplicationsScreen = () => {
  const { activeTab: activeTabFromParams } = useParams()
  const [activeTab, setActiveTab] = useState(activeTabFromParams || "completed")
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (activeTab !== activeTabFromParams) {
      setActiveTab(activeTabFromParams || "completed")
    }
  }, [activeTab, activeTabFromParams, navigate])

  const onTabChange = (tab: string) => {
    setSearchParams({})
    setActiveTab(tab)
    navigate(adminApplicationsPath({ "activeTab?": tab }))
  }

  const title = useMemo(() => {
    switch (activeTab) {
      case "unfit":
        return "Unfit Applications"
      case "applying":
        return "In Progress Applications"
      default:
        return "Completed Applications"
    }
  }, [activeTab])

  return (
    <>
      <AdminHeader title={title} Icon={Users} />
      <Tabs value={activeTab} onValueChange={onTabChange}>
        <TabsList className="justify-start mb-4 text-foreground">
          <TabsTrigger value="completed">Completed</TabsTrigger>
          <TabsTrigger value="applying">In Progress</TabsTrigger>
          <TabsTrigger value="unfit">Unfit</TabsTrigger>
        </TabsList>
        <TabsContent value="completed">
          <ApplicationsTable onboardingState={OnboardingStateEnum.FitWaiting} />
        </TabsContent>
        <TabsContent value="applying">
          <ApplicationsTable onboardingState={OnboardingStateEnum.InProgress} />
        </TabsContent>
        <TabsContent value="unfit">
          <UnfitApplicationsTable />
        </TabsContent>
      </Tabs>
    </>
  )
}
