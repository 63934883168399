import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const GENERATE_MERGE_LINK_TOKEN_QUERY_DOCUMENT = gql(`
  query GenerateMergeLinkToken($category: String!, $identifier: String!, $magicLink: Boolean) {
    generateMergeLinkToken(category: $category, identifier: $identifier, magicLink: $magicLink) {
      tempToken
      integrationName
      magicLinkUrl
      category
      identifier
    }
  }
`)
