import {
  API,
  BlockAPI,
  BlockToolConstructorOptions,
  ToolConfig,
} from "@editorjs/editorjs"
import { ReactPortal } from "react"
import invariant from "tiny-invariant"
import { AirTableFormEmbedEditor } from "../AirTableFormEmbedEditor"
import { Clipboard } from "lucide-static"

interface AirTableFormEmbedConfig extends ToolConfig {
  createPortal: (
    blockId: string,
    node: React.ReactNode,
    container: HTMLElement
  ) => ReactPortal
  removePortal: (blockId: string) => void
}

interface AirTableFormEmbedData {
  url: string | null
}

interface AirTableFormEmbedParams extends BlockToolConstructorOptions {
  data: AirTableFormEmbedData
  config?: AirTableFormEmbedConfig
  block: BlockAPI
}

export class AirTableFormEmbedTool {
  api: API
  block: BlockAPI
  url: string | null = null
  createPortal: AirTableFormEmbedConfig["createPortal"]
  removePortal: AirTableFormEmbedConfig["removePortal"]
  portal: ReactPortal | null = null

  constructor(config: AirTableFormEmbedParams) {
    invariant(config.config?.createPortal, "createPortal is required in config")

    this.block = config.block
    this.api = config.api
    this.url = config.data.url || null
    this.createPortal = config.config.createPortal
    this.removePortal = config.config.removePortal
    return this
  }

  static get toolbox() {
    return {
      title: "AirTable Embed",
      icon: Clipboard,
    }
  }

  render() {
    const rootEl = document.createElement("div")
    this.portal = this.createPortal(
      this.block.id,
      <AirTableFormEmbedEditor
        url={this.url || ""}
        onAirTableFormEmbedChange={(url) => this.onAirTableFormEmbedChange(url)}
        tool={this}
      />,
      rootEl
    )
    return rootEl
  }

  onAirTableFormEmbedChange(url: string) {
    this.url = url
  }

  remove() {
    const totalBlocks = this.api.blocks.getBlocksCount()
    for (let i = 0; i < totalBlocks; i++) {
      const block = this.api.blocks.getBlockByIndex(i)
      if (block && block.id === this.block.id) {
        this.api.blocks.delete(i)
        break
      }
    }
  }

  removed() {
    this.removePortal(this.block.id)
  }

  save() {
    return {
      url: this.url,
    }
  }
}
