import { FileManagerState, fileIsImage } from "./useFileManager"
import Attachment from "../images/icons/attachment.svg?react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { imgixResize } from "~/common/imgix"
import { X } from "lucide-react"

export const Attachments = ({
  attachedFiles,
  removeFile,
}: {
  attachedFiles: FileManagerState
  removeFile: (id: string) => void
}) => {
  const images = attachedFiles.filter(
    (file) => fileIsImage(file) && !file.isDeleted
  )
  const otherFiles = attachedFiles.filter(
    (file) => !fileIsImage(file) && !file.isDeleted
  )

  return (
    <>
      {images.length > 0 && (
        <div className="grid grid-cols-4 gap-4 mt-4">
          {images.map((image) => {
            const uploadPercentage = toPercentage(image.uploadProgress)

            return (
              <div
                key={image.id}
                className="aspect-square w-full border border-mercury relative"
              >
                <div
                  className="absolute top-[4px] right-[4px] w-[25px] h-[25px] bg-black text-white rounded-full flex items-center justify-center cursor-pointer"
                  onClick={() => removeFile(image.id)}
                >
                  <X size={18} />
                </div>
                <div className="absolute bottom-[4px] right-[4px] w-[25px] h-[25px]">
                  {uploadPercentage && (
                    <CircularProgressbar
                      value={uploadPercentage}
                      strokeWidth={50}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                      })}
                    />
                  )}
                </div>
                <img
                  src={imgixResize(image.previewUrl, {
                    width: 300,
                    height: 300,
                  })}
                  alt={image.filename}
                  className="aspect-square object-cover w-full"
                />
              </div>
            )
          })}
        </div>
      )}
      {otherFiles.length > 0 && (
        <div className="flex flex-col gap-2 mt-4">
          {otherFiles.map((file) => {
            const uploadPercentage = toPercentage(file.uploadProgress)

            return (
              <div className="flex items-center gap-3" key={file.id}>
                <Attachment />
                {file.filename}
                <div
                  className="w-[20px] h-[20px] font-xs bg-black text-white rounded-full flex items-center justify-center cursor-pointer"
                  onClick={() => removeFile(file.id)}
                >
                  <X size={16} />
                </div>
                {uploadPercentage && `[${uploadPercentage}%]`}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

const toPercentage = (value?: number | null) => {
  if (value === null || value === undefined) return null
  return Math.round(value * 100)
}
