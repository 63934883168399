import { gql } from "~/__generated__"
import { Post_PreviewFragment } from "~/__generated__/graphql"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { Card } from "~/ui/card"
import { cn } from "~/lib/utils"
import { parseISO, format } from "date-fns"
import { filterPostAttachments } from "~/common/postUtils"
import { imgixResize } from "~/common/imgix"
import { TagBubble } from "~/ui/TagBubble"

import Messages from "../images/icons/messages.svg?react"
import { FormattedContent } from "./FormattedContent"
import { UserName } from "~/directory/UserName"

export const PostPreview = ({
  post,
  className,
}: {
  post: Post_PreviewFragment
  className?: string
}) => {
  const [imageAttachments] = filterPostAttachments(post)

  return (
    <Card
      className={cn(
        "w-full !shadow-postPreview p-4 tracking-[0.5px] border-0",
        className
      )}
    >
      <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-4">
        <div>
          <div className="flex gap-2 items-center">
            <AvatarWithFallback user={post.user} size="preview" />
            <div>
              <div className="text-2xs leading-[12px]">
                <span className="font-semibold">
                  <UserName user={post.user} />
                </span>
                <span className="font-medium text-placeholder text-[10px] ml-2">
                  {format(parseISO(post.createdAt), "M/d/yyyy")}
                </span>
              </div>
              {post.channel && (
                <div className="flex items-center text-primary text-[10px] leading-[10px] mt-2 font-medium tracking-normal">
                  <div className="mr-1">
                    <Messages height="10px" width="10px" />
                  </div>
                  {post.channel.name}
                </div>
              )}
            </div>
          </div>
          <div className="hidden sm:block text-2xs leading-[16px] mt-2">
            <div className="line-clamp-3">
              <FormattedContent content={post.content || ""} />
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-start">
          <div className="block sm:hidden text-2xs leading-[16px] mt-2">
            <div className="line-clamp-3">
              <FormattedContent content={post.content || ""} />
            </div>
          </div>
          {!!imageAttachments.length && (
            <img
              src={imgixResize(imageAttachments[0].editorUrl, {
                width: 360,
                height: 240,
                fit: "clip",
              })}
              alt={imageAttachments[0].filename}
              className="max-w-[120px] max-h-[80px] rounded-md"
            />
          )}
        </div>
      </div>
      {post.tag && (
        <div className="mt-2">
          <TagBubble tagName={post.tag.name} />
        </div>
      )}
    </Card>
  )
}

gql(`
  fragment Post_Preview on Post {
    id
    content
    createdAt
    deleted
    channel {
      id
      name
    }
    tag {
      id
      name
    }
    user {
      ...User_Avatar
    }
    attachments {
      id
      editorUrl
      contentType
      byteSize
      filename
    }
  }
`)
