import { benchmarkPath } from "~/common/paths"
import { useNavigate } from "react-router-dom"
import { DialogHeader } from "~/ui/dialog"

import { useManualHrisDataContext } from "~/merge/manualData"

import { Button } from "~/ui/button"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useCommunityFeatures } from "~/community/useCommunity"

export const Success = () => {
  const { setDialogOpen, companySize, hrisBackend, industry } =
    useManualHrisDataContext()
  const navigate = useNavigate()
  const { logEvent } = useLogEvent()
  const features = useCommunityFeatures()

  if (!features.Benchmark) {
    throw new Error("Benchmark feature is not enabled")
  }

  return (
    <div>
      <div className="flex justify-between p-4 border-b border-mercury">
        <DialogHeader className="items-center text-sm tracking-wide font-medium">
          You're all set
        </DialogHeader>
      </div>
      <div className="p-6 flex flex-col items-center">
        <div className="text-center text-5xl mb-4">🎉</div>
        <div className="text-center font-serif font-normal text-2xl w-2/3 mb-1">
          Your metrics have been recorded and you've unlocked your Dashboard!
        </div>

        <div className="text-center text-2xs font-normal tracking-wider	mb-6">
          Data is our love language and we're glad it's yours too.
        </div>

        <div className="flex justify-center items-center">
          <Button
            onClick={() => {
              setDialogOpen(false)
              logEvent(AhoyEventTypeEnum.ManualDataSubmissionCompleted, {
                company_size: companySize,
                system: hrisBackend,
                industry: industry,
              })
              navigate(benchmarkPath.pattern)
            }}
          >
            View My Data
          </Button>
        </div>
      </div>
    </div>
  )
}
