export const formatArrayToSentence = (array: string[], conjunction = "and") => {
  if (array.length === 0) {
    return ""
  }

  if (array.length === 1) {
    return array[0]
  }

  if (array.length === 2) {
    return `${array[0]} ${conjunction} ${array[1]}`
  }

  const last = array.pop()
  return `${array.join(", ")}, ${conjunction} ${last}`
}
