import { useNavigate } from "react-router-dom"
import { Post_CardFragment, AhoyEventTypeEnum } from "~/__generated__/graphql"
import MessageSquare from "~/images/icons/message-square.svg?react"
import Smile from "~/images/icons/smile.svg?react"
import Repeat from "~/images/icons/repeat.svg?react"
import Pin from "~/images/icons/pin.svg?react"
import PinFilled from "~/images/icons/pin-filled.svg?react"
import Bookmark from "~/images/icons/bookmark.svg?react"
import BookmarkFilled from "~/images/icons/bookmark-filled.svg?react"
import { useComposerContext } from "~/post-composer/ComposerContext"
import EmojiPicker from "emoji-picker-react"
import { useState } from "react"
import ClickAwayListener from "react-click-away-listener"
import { useReactions } from "~/post-composer/useReactions"
import { useBookmarks } from "~/post-composer/useBookmarks"
import { feedPath, postPath } from "~/common/paths"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { usePostPinning } from "~/common/postUtils"
import { ClickableIconWrapper } from "~/ui/ClickableIconWrapper"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"
import { cn } from "~/lib/utils"
import { useCommunity } from "~/community/useCommunity"

export const PostActions = ({
  post,
  isReply,
  replyDisabled,
  replyToReply,
  onSinglePostPage,
  onBookmarkToggle,
}: {
  post: Post_CardFragment
  isReply: boolean
  replyDisabled: boolean
  replyToReply?: () => void
  onSinglePostPage?: boolean
  onBookmarkToggle?: (bookmarkExists: boolean) => void
}) => {
  const { logEvent } = useLogEvent()
  const { currentUser } = useCurrentUser()
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
  const { composerRef } = useComposerContext() || {}
  const { addReaction } = useReactions({ postId: post.id })
  const { bookmark, toggleBookmark } = useBookmarks({ post: post })
  const community = useCommunity()

  const navigate = useNavigate()
  const pinPost = usePostPinning()
  const navigateToReply = () =>
    navigate(`${postPath({ postId: post.id })}?reply=t`)

  return (
    <div className="flex flex-wrap items-center mt-1 gap-2 w-full self-start justify-between">
      <div className="flex flex-wrap items-center gap-2">
        <div className="relative">
          <ClickableIconWrapper
            border
            primary={emojiPickerOpen}
            onClick={() => {
              if (!emojiPickerOpen) {
                setEmojiPickerOpen(true)
              }
            }}
          >
            <Smile />
          </ClickableIconWrapper>
          {emojiPickerOpen && (
            <ClickAwayListener onClickAway={() => setEmojiPickerOpen(false)}>
              <div className="absolute top-[20px] left-[5px] z-[1000]">
                <EmojiPicker
                  reactionsDefaultOpen
                  allowExpandReactions
                  searchDisabled
                  skinTonesDisabled
                  lazyLoadEmojis
                  reactions={community.defaultReactions}
                  previewConfig={{ showPreview: false }}
                  onEmojiClick={(emoji) => {
                    addReaction(emoji.emoji)
                    logEvent(AhoyEventTypeEnum.PostReactedTo, {
                      emoji_name: emoji.emoji,
                      post_id: post.id,
                    })
                    setEmojiPickerOpen(false)
                  }}
                />
              </div>
            </ClickAwayListener>
          )}
        </div>

        {!isReply && (
          <>
            <ClickableIconWrapper border onClick={navigateToReply}>
              <MessageSquare />
              {post.repliesCount && post.repliesCount > 0 ? (
                <span className="ml-1">{post.repliesCount}</span>
              ) : null}
            </ClickableIconWrapper>
            <StackedUserAvatars
              users={post.orderedCommenters}
              totalCount={post.commentersCount}
              avatarSize="smaller"
              onClick={navigateToReply}
              showCount={4}
              className="card-clickable cursor-pointer"
            />
          </>
        )}
      </div>

      {!isReply && (
        <div className="flex items-center text-placeholder">
          <ClickableIconWrapper
            onClick={() => {
              if (onSinglePostPage) {
                navigate(
                  feedPath.pattern + `?post=focus&share_post_id=${post.id}`
                )
              } else {
                logEvent(AhoyEventTypeEnum.ContentShared, {
                  content_id: post.id,
                })
                composerRef?.current?.retweetPost(post.id)
              }
            }}
          >
            <Repeat />
          </ClickableIconWrapper>
          <ClickableIconWrapper
            onClick={async () => {
              const bookmarkExists = await toggleBookmark()
              if (onBookmarkToggle) {
                onBookmarkToggle(bookmarkExists)
              }
            }}
          >
            {bookmark ? (
              <BookmarkFilled className="text-highlight" />
            ) : (
              <Bookmark />
            )}
          </ClickableIconWrapper>
          {post.pinnable && post.currentUserCanPin.value && (
            <ClickableIconWrapper onClick={() => pinPost(post.id)}>
              {currentUser.pinnedPostId === post.id ? (
                <PinFilled className="text-highlight" />
              ) : (
                <Pin />
              )}
            </ClickableIconWrapper>
          )}
        </div>
      )}

      {isReply && replyToReply && !replyDisabled && (
        <div
          className={cn(
            "cursor-pointer p-2 text-2xs text-foreground tracking-wide card-clickable text-foreground"
          )}
          onClick={replyToReply}
        >
          Reply
        </div>
      )}
    </div>
  )
}
