import { useEffect, useState } from "react"
import { BarChart } from "./BarChart"
import { CardFeed } from "~/common/CardFeed"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { BENCHMARK_QUERY_DOCUMENT } from "./benchmarkQuery"
import { useCurrentUser } from "~/auth/CurrentUserContext"

import { SmallScreenCharts } from "~/benchmarks/SmallScreenCharts"

import { useQuery } from "@apollo/client"

import { useFilterContext } from "~/benchmarks/filterContext"

import { ManualTurnover } from "~/merge/steps/ManualTurnover"
import { ManualTenure } from "~/merge/steps/ManualTenure"
import { ManualRetention } from "~/merge/steps/ManualRetention"
import { ManualCostPerEmployee } from "~/merge/steps/ManualCostPerEmployee"
import { BenchmarksQuery } from "~/__generated__/graphql"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"

import { EditDialog } from "~/benchmarks/EditDialog"
import Info from "../images/icons/info.svg?react"

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"

const CardHeaderWithToolTip = ({
  title,
  content,
  editDialog,
  manualData = false,
  dialogOpen,
  setDialogOpen,
}: {
  title: string
  content: string
  manualData: boolean
  editDialog?: React.ReactNode
  dialogOpen: boolean
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <CardHeader>
      <div className="flex justify-between">
        <div className="flex justify-left gap-2 items-center">
          <CardTitle>{title}</CardTitle>
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <div className="items-center h-4 w-4 cursor-help">
                  <Info />
                </div>
              </TooltipTrigger>
              <TooltipContent
                className="TooltipContent"
                align="start"
                side="bottom"
              >
                {content}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        {manualData && (
          <EditDialog
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            editDialog={editDialog}
          />
        )}
      </div>
    </CardHeader>
  )
}

export const ChartFeed = ({
  data,
  manualData = false,
}: {
  data: BenchmarksQuery
  manualData: boolean
}) => {
  const [turnoverDialogOpen, setTurnoverDialogOpen] = useState(false)
  const [retentionDialogOpen, setRetentionDialogOpen] = useState(false)
  const [costPerEmployttDialogOpen, setCostPerEmployeeDialogOpen] =
    useState(false)
  const [tenureDialogOpen, setTenureDialogOpen] = useState(false)

  return (
    <>
      <Card>
        <CardHeaderWithToolTip
          manualData={manualData}
          setDialogOpen={setTurnoverDialogOpen}
          dialogOpen={turnoverDialogOpen}
          editDialog={
            <ManualTurnover
              editContext={true}
              closeDialog={() => setTurnoverDialogOpen(false)}
            />
          }
          title="Turnover"
          content="The percent of employees who have left your organization vs. average % of employees that have left peer Safe Space members’ organizations over the last six months."
        />
        <CardContent className="pt-10 pr-16">
          <BarChart
            chart={data.benchmarks.turnover}
            interactive={true}
            axisValueFormatter={(value: string) => `${value}%`}
          />
        </CardContent>
      </Card>

      <Card>
        <CardHeaderWithToolTip
          manualData={manualData}
          setDialogOpen={setCostPerEmployeeDialogOpen}
          dialogOpen={costPerEmployttDialogOpen}
          editDialog={
            <ManualCostPerEmployee
              editContext={true}
              closeDialog={() => setCostPerEmployeeDialogOpen(false)}
            />
          }
          title="Cost Per Employee"
          content="Your company’s average cost per employee inclusive of salary, taxes, and benefits vs. average cost of employee at peer Safe Space members’ organizations in the last six months. "
        />
        <CardContent className="pt-10 pr-16">
          <BarChart
            chart={data.benchmarks.costPerEmployee}
            interactive={true}
            axisValueFormatter={(value: string) => `$${value}k`}
          />
        </CardContent>
      </Card>

      <Card>
        <CardHeaderWithToolTip
          manualData={manualData}
          setDialogOpen={setTenureDialogOpen}
          dialogOpen={tenureDialogOpen}
          editDialog={
            <ManualTenure
              editContext={true}
              closeDialog={() => setTenureDialogOpen(false)}
            />
          }
          title="Tenure (in months)"
          content="The average number of months employees has been at your organization vs. average number of months employees have been at peer Safe Space members’ organizations in the last six months."
        />
        <CardContent className="pt-10 pr-16">
          <BarChart chart={data.benchmarks.tenure} interactive={true} />
        </CardContent>
      </Card>

      <Card>
        <CardHeaderWithToolTip
          manualData={manualData}
          setDialogOpen={setRetentionDialogOpen}
          dialogOpen={retentionDialogOpen}
          editDialog={
            <ManualRetention
              editContext={true}
              closeDialog={() => setRetentionDialogOpen(false)}
            />
          }
          title="Retention Rate"
          content="Your organization’s retention rate vs. the average retention rate of peer Safe Space members’ in the last six months"
        />
        <CardContent className="pt-10 pr-16">
          <BarChart
            chart={data.benchmarks.retention}
            interactive={true}
            axisValueFormatter={(value: string) => `${value}%`}
          />
        </CardContent>
      </Card>
    </>
  )
}

export const Charts = () => {
  const { filters } = useFilterContext()
  const { currentUser } = useCurrentUser()

  const { data, loading, error, refetch } = useQuery(BENCHMARK_QUERY_DOCUMENT, {
    variables: {
      locations: filters.locations,
      industries: filters.industries,
      companySizes: filters.companySizes,
    },
  })

  useEffect(() => {
    refetch()
  }, [currentUser, refetch])

  const manualData = !!currentUser.manualHrisData

  if (!data && loading)
    return (
      <div className="mt-8 flex items-center">
        <LoadingIndicator /> Loading benchmarks...
      </div>
    )
  if (error || !data) return <Error message="Error loading benchmarks." />

  return (
    <>
      <div className="md:hidden sm:block">
        <SmallScreenCharts data={data} manualData={manualData} />
      </div>
      <div className="hidden md:block">
        <CardFeed>
          <ChartFeed data={data} manualData={manualData} />
        </CardFeed>
      </div>
    </>
  )
}
