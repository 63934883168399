import { useApolloClient } from "@apollo/client"
import invariant from "tiny-invariant"
import { FEED_QUERY_DOCUMENT } from "~/screens/FeedScreen"
import {
  FeedScreenQueryVariables,
  Post_CardFragment,
} from "~/__generated__/graphql"
import { useCallback, useEffect, useMemo, useState } from "react"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"
import { ArrowUp } from "lucide-react"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { usePostInViewport } from "./PostInViewportContext"
import { NewFeedPostCallback, useNewFeedPost } from "./NewFeedPostContext"

interface RecordWithId {
  id: string
}

const hasOverlappingId = (array1: RecordWithId[], array2: RecordWithId[]) => {
  return array1
    .map((p) => p.id)
    .some((id) => array2.map((p) => p.id).includes(id))
}

export const NewPostsIndicator = ({
  channelId,
  queryVariables,
  onClick,
}: {
  channelId?: string
  queryVariables: FeedScreenQueryVariables
  onClick: () => void
}) => {
  const [newPosts, setNewPosts] = useState<Post_CardFragment[]>([])
  const { currentUser } = useCurrentUser()
  const { postsInView } = usePostInViewport() || {}
  const [showNewPostsIndicator, setShowNewPostsIndicator] = useState(false)
  const { onNewFeedPost } = useNewFeedPost()
  const client = useApolloClient()

  const handleNewFeedPost: NewFeedPostCallback = useCallback(
    (newPost) => {
      console.log("got channel", channelId, newPost.channel?.id)
      if (
        newPost.user.id === currentUser.id ||
        (channelId && newPost.channel?.id !== channelId)
      )
        return

      setNewPosts((posts) => [...posts, newPost])

      const cache = client.cache

      // read the existing cache
      const existingMessages = cache.readQuery({
        query: FEED_QUERY_DOCUMENT,
        variables: queryVariables,
      })

      if (!existingMessages) return

      // create a new list of messages with the new message and old messages, unique by id
      const updatedMessages = [
        { node: newPost },
        ...existingMessages.posts.edges,
      ].filter(
        (m, i, self) => self.findIndex((t) => t.node.id === m.node.id) === i
      )

      // write the new list of messages to the cache
      cache.writeQuery({
        query: FEED_QUERY_DOCUMENT,
        variables: queryVariables,
        data: {
          posts: {
            edges: updatedMessages,
            pageInfo: existingMessages.posts.pageInfo,
          },
          channels: existingMessages.channels,
          tags: existingMessages.tags,
        },
      })
    },
    [client, channelId, currentUser.id, queryVariables]
  )

  useEffect(() => {
    console.log("subscribing to onNewFeedPost")
    const unsubscribe = onNewFeedPost(handleNewFeedPost)
    return () => {
      console.log("unsubscribing from onNewFeedPost")
      unsubscribe()
    }
  }, [handleNewFeedPost, onNewFeedPost])

  invariant(postsInView, "PostInViewport context must be provided")

  useEffect(() => {
    if (
      hasOverlappingId(postsInView, newPosts) ||
      (newPosts.length > 0 && postsInView.length === 0)
    ) {
      setNewPosts([])
    }
  }, [postsInView, newPosts])

  // This delay is necessary to determine if the new post is in the viewport
  // after the post is added to the feed.
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null

    if (newPosts.length > 0) {
      timeoutId = setTimeout(() => setShowNewPostsIndicator(true), 200)
    } else {
      setShowNewPostsIndicator(false)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [newPosts.length])

  const userAvatars = useMemo(() => {
    return newPosts
      .map((p) => p.user)
      .filter((u, i, self) => self.findIndex((t) => t.id === u.id) === i)
  }, [newPosts])

  if (newPosts.length === 0 || !showNewPostsIndicator) return null

  return (
    <div
      className="flex justify-center fixed top-4 left-0 right-0 z-50 cursor-pointer"
      onClick={onClick}
    >
      <div className="bg-highlight text-white flex items-center gap-4 px-6 py-2 rounded-full">
        <ArrowUp size={20} />
        <StackedUserAvatars users={userAvatars} avatarSize="smaller" />
        <div className="text-xs font-medium tracking-wide">New Posts</div>
      </div>
    </div>
  )
}
