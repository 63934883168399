import { useState, createContext, useContext } from "react"
import { IndustryEnum } from "~/__generated__/graphql"

interface FilterContextType {
  locations: string[]
  setLocations: React.Dispatch<React.SetStateAction<string[]>>
  industries: IndustryEnum[]
  setIndustries: React.Dispatch<React.SetStateAction<IndustryEnum[]>>
  companySizes: string[]
  setCompanySizes: React.Dispatch<React.SetStateAction<string[]>>
  filters: any
  setFilters: React.Dispatch<React.SetStateAction<any>>
  updateFilters: () => void
  resetFilters: () => void
  filtersExpanded: boolean
  setFiltersExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

export const FilterContext = createContext<FilterContextType | null>(null)

export interface Locations {
  [key: string]: string
}
export const LOCATIONS: Locations = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
}

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [locations, setLocations] = useState<string[]>([])
  const [industries, setIndustries] = useState<IndustryEnum[]>([])
  const [companySizes, setCompanySizes] = useState<string[]>([])
  const [filters, setFilters] = useState<any>({ locations: [], industries: [] })
  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

  const resetFilters = () => {
    setLocations([])
    setIndustries([])
    setCompanySizes([])

    setFilters({
      industries: [],
      locations: [],
      companySizes: [],
    })
  }

  const updateFilters = () => {
    setFilters({
      industries: industries,
      locations: locations,
      companySizes: companySizes,
    })
  }

  return (
    <FilterContext.Provider
      value={{
        locations,
        setLocations,
        industries,
        setIndustries,
        companySizes,
        setCompanySizes,
        filters,
        setFilters,
        updateFilters,
        resetFilters,
        filtersExpanded,
        setFiltersExpanded,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterContext = () => {
  const filterContext = useContext(FilterContext)

  if (!filterContext) {
    throw new Error(
      "No FilterContext.Provider found when calling useFiltersContext."
    )
  }

  return filterContext
}
