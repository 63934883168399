import { useEffect, useState } from "react"
import { CurrentUserProviderQuery } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { PageWithRightSidebar } from "~/layouts/PageWithRightSidebar"

import { BenchmarkSidebar } from "~/benchmarks/BenchmarkSidebar"
import { Charts } from "~/benchmarks/Charts"
import { EmptyState } from "~/benchmarks/EmptyState"
import { Syncing } from "~/benchmarks/Syncing"

import { useNavigate } from "react-router-dom"
import { FilterProvider } from "~/benchmarks/filterContext"
import { feedPath } from "~/common/paths"
import { useCommunityFeatures } from "~/community/useCommunity"
import { ManualHrisDataProvider } from "~/merge/manualData"

enum PageState {
  NotConnected = "NOT_CONNECTED",
  Syncing = "SYNCING",
  Synced = "SYNCED",
  Loading = "LOADING",
  ManualHrisData = "MANUAL_HRIS_DATA",
}

const stateFromUser = (user: CurrentUserProviderQuery["user"]) => {
  if (!user) {
    return PageState.Loading
  }

  if (user.manualHrisData) {
    return PageState.ManualHrisData
  }

  if (user.hrisIntegration) {
    if (!user.hrisIntegration.initialSyncComplete) {
      return PageState.Syncing
    } else {
      return PageState.Synced
    }
  } else {
    return PageState.NotConnected
  }
}

const getPage = (state: PageState) => {
  switch (state) {
    case PageState.Loading:
      return <></>
    case PageState.NotConnected:
      return <EmptyState />
    case PageState.Syncing:
      return <Syncing />
    case PageState.Synced:
      return <Charts />
    case PageState.ManualHrisData:
      return <Charts />
  }
}

export const BenchmarkScreen = () => {
  const { currentUser, startPolling, stopPolling } = useCurrentUser()
  const [pageState, setPageState] = useState(stateFromUser(currentUser))
  const features = useCommunityFeatures()
  const navigate = useNavigate()

  useEffect(() => {
    setPageState(stateFromUser(currentUser))
  }, [currentUser])

  useEffect(() => {
    if (pageState === PageState.Syncing) {
      startPolling(30000)
    } else {
      stopPolling()
    }
  }, [pageState, startPolling, stopPolling])

  useEffect(() => {
    if (!features.Benchmark) {
      navigate(feedPath({}))
    }
  }, [features.Benchmark, navigate])

  if (!features.Benchmark) {
    return null
  }

  return (
    <ManualHrisDataProvider>
      <FilterProvider>
        {pageState === PageState.Syncing ? (
          <div className="flex w-full">{getPage(pageState)}</div>
        ) : (
          <PageWithRightSidebar sidebar={<BenchmarkSidebar />}>
            {getPage(pageState)}
          </PageWithRightSidebar>
        )}
      </FilterProvider>
    </ManualHrisDataProvider>
  )
}
