import invariant from "tiny-invariant"
import { CommunityQuery } from "~/__generated__/graphql"

const communityMetaContent = document
  .querySelector("meta[name=community]")
  ?.getAttribute("content")
invariant(communityMetaContent, "Community slug meta tag missing")
export const communityData = JSON.parse(communityMetaContent) as {
  data: CommunityQuery
}
invariant(communityData.data)
export const community = communityData.data.community
