import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { useEffect } from "react"
import { aboutYouPath } from "~/common/paths"

export const RequireUserOnboarded = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser && !currentUser.onboarded) {
      navigate(aboutYouPath.pattern)
    }
  }, [currentUser, navigate])

  if (currentUser && !currentUser.onboarded) {
    return null
  }

  return <Outlet />
}
