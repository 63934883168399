import ReactPlayer from "react-player"
import { useMemo, useRef, useState } from "react"
import useResizeObserver from "@react-hook/resize-observer"

const PLAYER_RATIO = 360 / 640

export const VideoSection = ({ videoUrl }: { videoUrl?: string | null }) => {
  const playerContainerRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>(0)
  const height = useMemo(() => PLAYER_RATIO * width, [width])

  useResizeObserver(playerContainerRef, (entry) => {
    setWidth(entry.contentRect.width)
  })

  if (!videoUrl || videoUrl.length === 0) return null

  return (
    <div ref={playerContainerRef} className="card-clickable">
      <ReactPlayer
        url={videoUrl}
        wrapper={({ children }) => {
          return <div style={{ height }}>{children}</div>
        }}
        controls
      />
    </div>
  )
}
