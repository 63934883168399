import { useCallback } from "react"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { Article, ArticleVisibilityEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"

export type ChangeVisibilityConfirmProps = {
  article: Pick<Article, "id" | "visibility">
  onSuccess?: () => void
} & Partial<ConfirmContextProviderProps>

export const useChangeVisibilityConfirm = ({
  article,
  onSuccess,
  ...confirmProps
}: ChangeVisibilityConfirmProps) => {
  const showConfirm = useConfirm()
  const [runChangeVisibility] = useSafeMutation(ARTICLE_VISIBILITY_MUTATION)

  const isHidden = article.visibility === ArticleVisibilityEnum.Hidden

  const changeVisibility = useCallback(() => {
    showConfirm({
      title: "Change Visibility",
      body: isHidden
        ? "Do you want to make this content LIVE? This will make the most recently approved version of the content visible to end users."
        : "Do you want to make this content HIDDEN? This will hide the content from end users in the library.",
      confirmText: isHidden ? "Make Live" : "Hide Content",
      onConfirm: async () => {
        const { errors } = await runChangeVisibility({
          variables: {
            input: {
              articleId: article.id,
              visibility: isHidden
                ? ArticleVisibilityEnum.Live
                : ArticleVisibilityEnum.Hidden,
            },
          },
        })

        if (errors) {
          displayErrors(errors)
          console.log(errors)
        } else {
          toast.success("Updated content visibility")
          onSuccess?.()
        }
      },
      ...confirmProps,
    })
  }, [
    article.id,
    confirmProps,
    runChangeVisibility,
    showConfirm,
    isHidden,
    onSuccess,
  ])

  return changeVisibility
}

export const ARTICLE_VISIBILITY_MUTATION = gql(`
  mutation ArticleChangeVisibility($input: ArticleUpdateInput!) {
    articleUpdate(input: $input) {
      article {
        ...Article_EditorContent
      }
    }
  }
`)
