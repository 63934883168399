type Option = {
  value: string
  label: string
}

export const sortOptions = (a: Option, b: Option) => {
  const labelA = a.label.toUpperCase()
  const labelB = b.label.toUpperCase()

  if (labelA < labelB) {
    return -1
  }

  if (labelA > labelB) {
    return 1
  }

  return 0
}
