import { useCallback } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { Article } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"

export type SubmitForReviewConfirmProps = {
  article: Pick<Article, "id">
} & Partial<ConfirmContextProviderProps>

export const useSubmitForReviewConfirm = ({
  article,
  ...confirmProps
}: SubmitForReviewConfirmProps) => {
  const showConfirm = useConfirm()
  const [runSubmitForReview] = useSafeMutation(SUBMIT_FOR_REVIEW_MUTATION)

  const submitForReview = useCallback(() => {
    showConfirm({
      title: "Submit Content for Review",
      body: "Request admin review of your content.",
      confirmText: "Submit",
      prompt: {
        label: "Note to admin",
        placeholder:
          "Describe the content in general or changes that you've made",
        required: true,
      },
      onConfirm: async (promptValue) => {
        invariant(promptValue)
        const { errors } = await runSubmitForReview({
          variables: {
            input: {
              articleId: article.id,
              message: promptValue,
            },
          },
        })

        if (errors) {
          console.log(errors)
        }
      },
      ...confirmProps,
    })
  }, [showConfirm, confirmProps, runSubmitForReview, article.id])

  return submitForReview
}

export const SUBMIT_FOR_REVIEW_MUTATION = gql(`
  mutation ArticleSubmitForReview($input: ArticleSubmitForReviewInput!) {
    articleSubmitForReview(input: $input) {
      article {
        ...Article_EditorContent
      }
    }
  }
`)
