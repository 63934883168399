import { Link2 } from "lucide-react"
import { Button } from "~/ui/button"
import { Dialog, DialogContent, DialogTitle } from "~/ui/dialog"
import { ArticleCard } from "~/ui/ArticleCard"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  XIcon,
} from "react-share"
import { articlePath, feedPath } from "~/common/paths"
import { useCommunity } from "~/community/useCommunity"
import { Article_LiveContentFragment } from "~/__generated__/graphql"
import { useCallback, useMemo } from "react"
import copy from "copy-to-clipboard"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"

interface ShareModalProps extends React.ComponentProps<typeof Dialog> {
  article: Article_LiveContentFragment
}

export const ShareModal = ({ article, ...props }: ShareModalProps) => {
  const community = useCommunity()
  const navigate = useNavigate()
  const url = useMemo(() => {
    const url = new URL(window.location.href)
    url.pathname = articlePath({ articleId: article.id })
    return url.toString()
  }, [article.id])

  const createPost = useCallback(() => {
    navigate(feedPath.pattern + `?post=focus&share_article_id=${article.id}`)
  }, [article.id, navigate])

  const copyToClipboard = useCallback(() => {
    copy(url)
    toast.success("Link copied to clipboard")
  }, [url])

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogTitle className="text-center">Share Article</DialogTitle>
        <div className="flex flex-col gap-4 justify-center">
          <ArticleCard article={article} wideVersion />
          <div className="flex justify-center mt-4">
            <Button onClick={createPost} className="text-xs">
              Create Post
            </Button>
          </div>
          <div className="flex justify-center">
            <Button
              variant="ghost"
              className="text-2xs flex gap-1 items-center"
              onClick={copyToClipboard}
            >
              Copy Link <Link2 className="w-3 h-3" />
            </Button>
          </div>
          <div className="text-center flex flex-col gap-2">
            <span className="text-xs">Share to</span>
            <div className="flex items-center justify-center gap-4">
              <LinkedinShareButton
                title={article.approvedRevision?.title || ""}
                summary={article.approvedRevision?.description || ""}
                source={community.name}
                url={url}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <FacebookShareButton url={url}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={url}
                title={article.approvedRevision?.title || ""}
              >
                <XIcon size={32} round />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
