import { ArrowLeft } from "lucide-react"
import { Link } from "react-router-dom"
import { myContentPath } from "~/common/paths"
import { Button } from "~/ui/button"
import {
  Article,
  Article_ReviewActionsFragment,
  ArticleRevision,
  ArticleStateEnum,
  Lesson,
} from "~/__generated__/graphql"
import {
  SubmitForReviewConfirmProps,
  useSubmitForReviewConfirm,
} from "~/content/useSubmitForReviewConfirm"
import {
  RequestEditsConfirmProps,
  useRequestEditsConfirm,
} from "~/content/useRequestEditsConfirm"
import { ArticlePublishModal } from "./ArticlePublishModal"
import { useState } from "react"

type RequiredArticleProps =
  | "id"
  | "state"
  | "currentUserCanSubmitForApproval"
  | "currentUserCanRequestEdits"
  | "currentUserCanApprove"

type ToolbarArticleBase = Pick<Article, RequiredArticleProps> & {
  approvedRevision?: Partial<ArticleRevision> | null
}

type LessonForToggleHidden = Pick<Lesson, "id" | "hidden"> & {
  section: Pick<Lesson["section"], "hidden">
}
type ToolbarArticleForToggleHidden = ToolbarArticleBase & {
  draftLesson: LessonForToggleHidden
}

type ToolbarOptions = "approve" | "submitForReview" | "requestEdits"

type BackLink = {
  label: string
  path: string
}

interface ArticleActionToolbarPropsBase {
  article: ToolbarArticleBase
  isLocked: boolean
  options?: Record<ToolbarOptions, boolean>
  submitForReviewConfirmOptions?: Partial<SubmitForReviewConfirmProps>
  requestEditsConfirmOptions?: Partial<RequestEditsConfirmProps>
  backLink?: BackLink
}

type ArticleActionToolbarPropsToggleHidden = Omit<
  ArticleActionToolbarPropsBase,
  "article"
> & { article: ToolbarArticleForToggleHidden }
export type ArticleActionToolbarProps =
  | ArticleActionToolbarPropsBase
  | ArticleActionToolbarPropsToggleHidden

const defaultOptions: Record<ToolbarOptions, boolean> = {
  approve: true,
  submitForReview: true,
  requestEdits: true,
}

const defaultBackLink: BackLink = {
  label: "Back to library",
  path: myContentPath.pattern,
}

export const shouldShowSubmitForReview = (
  article: Article_ReviewActionsFragment,
  enabled = true
) => {
  return (
    enabled &&
    article.currentUserCanSubmitForApproval.value &&
    article.state !== ArticleStateEnum.Approved &&
    article.state !== ArticleStateEnum.Submitted
  )
}

export const shouldShowRequestEdits = (
  article: Article_ReviewActionsFragment,
  enabled = true
) => {
  return (
    enabled &&
    article.currentUserCanRequestEdits.value &&
    article.state !== ArticleStateEnum.Approved &&
    article.state !== ArticleStateEnum.EditsRequested
  )
}
export const shouldShowApprove = (
  article: Article_ReviewActionsFragment,
  enabled = true
) => {
  return (
    enabled &&
    article.currentUserCanApprove.value &&
    article.state !== ArticleStateEnum.Approved
  )
}

export const ArticleTopActionToolbar = ({
  article,
  isLocked,
  options: optionProps,
  submitForReviewConfirmOptions = {},
  requestEditsConfirmOptions = {},
  backLink = defaultBackLink,
}: ArticleActionToolbarProps) => {
  const options = {
    ...defaultOptions,
    ...optionProps,
  }

  const [publishModalOpen, setPublishModalOpen] = useState(false)
  const submitForReview = useSubmitForReviewConfirm({
    article,
    ...submitForReviewConfirmOptions,
  })
  const requestEdits = useRequestEditsConfirm({
    article,
    ...requestEditsConfirmOptions,
  })

  const showSubmitForReview = shouldShowSubmitForReview(
    article,
    options.submitForReview
  )
  const showRequestEdits = shouldShowRequestEdits(article, options.requestEdits)
  const showApprove = shouldShowApprove(article, options.approve)

  return (
    <>
      <div className="flex py-8 items-center sticky top-0 left-0 w-100 z-[30] bg-background -ml-1 -mr-1 pl-1 pr-1">
        <Link
          to={backLink.path}
          className="mr-auto flex items-center font-bold tracking-wide text-foreground"
        >
          <ArrowLeft className="mr-4" />
          {backLink.label}
        </Link>

        {!isLocked && (
          <div className="flex gap-2 items-center">
            {showSubmitForReview && (
              <Button className="text-xs" onClick={submitForReview}>
                Submit for Review
              </Button>
            )}

            {showRequestEdits && (
              <Button className="text-xs" onClick={requestEdits}>
                Request Edits
              </Button>
            )}

            {showApprove && (
              <Button
                className="text-xs"
                onClick={() => setPublishModalOpen(true)}
              >
                {article.state === ArticleStateEnum.Submitted
                  ? "Approve"
                  : "Publish"}
              </Button>
            )}
          </div>
        )}
      </div>
      <ArticlePublishModal
        articleId={article.id}
        isOpen={publishModalOpen}
        setIsOpen={setPublishModalOpen}
      />
    </>
  )
}
