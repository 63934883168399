import { useQuery } from "@apollo/client"
import {
  RefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useLocation, useNavigate } from "react-router-dom"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { imgixResize } from "~/common/imgix"
import {
  editCelebrationsPath,
  editExpertisePath,
  editInterestsPath,
  editProfilePath,
  signOutPath,
} from "~/common/paths"
import { TAGS_QUERY_DOCUMENT } from "~/common/queries"
import { cn } from "~/lib/utils"
import { InstagramImg, isSocialMediaType } from "~/common/social-media"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import { OnboardingBioField } from "~/components/onboarding/OnboardingBioField"
import { OnboardingDetailsFields } from "~/components/onboarding/OnboardingDetailsFields"
import { OnboardingPhotoField } from "~/components/onboarding/OnboardingPhotoField"
import { Button, buttonVariants } from "~/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { Error } from "~/ui/Error"
import { AhoyEventTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import {
  NEXT_PROMPT_KEY,
  NOTIFICATIONS_DENY_COUNT_KEY,
} from "~/common/Notifications"
import { getMetaVar } from "~/common/getMetaVar"
import { useForceUpdate } from "~/common/useForceUpdate"
import LinkedinIcon from "~/images/icons/social/linkedin.svg?react"
import TwitterIcon from "~/images/icons/social/x.svg?react"
import { OnboardingFieldLabel, OnboardingInterestToggle } from "~/ui/Onboarding"
import ArrowLeft from "../images/icons/arrow-left.svg?react"
import SignOutLeft from "../images/icons/sign-out-left.svg?react"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { AddCelebration, ShowCelebration } from "~/celebrations"
import { useAuthenticatedLayout } from "~/layouts/AuthenticatedLayout"
import { Link } from "~/ui/Link"
import { ReferralCode } from "~/account-settings/ReferralCode"

interface IFormInput {
  firstName: string | null
  lastName: string | null
  companyName: string | null
  jobTitle: string | null
  placeId: string | null
  photo: string | null
  bio: string | null
  linkedin: string | null
  twitter: string | null
  instagram: string | null
}

interface NameMap {
  [key: string]: string
}

const qaToolsEnabled = getMetaVar("qa-tools-enabled") === "true"

const MAXIMUM_CELEBRATIONS = 3

export const EditProfileScreen = () => {
  const { logEvent } = useLogEvent()
  const forceUpdate = useForceUpdate()
  const { data, loading, error } = useQuery(TAGS_QUERY_DOCUMENT)
  const [runUserUpdate, { loading: updating }] =
    useSafeMutation(USER_UPDATE_MUTATION)

  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()

  const editProfileRef = useRef<HTMLDivElement>(null)
  const celebrationsRef = useRef<HTMLDivElement>(null)
  const interestsRef = useRef<HTMLDivElement>(null)
  const expertiseRef = useRef<HTMLDivElement>(null)

  const [selectedTags, setSelectedTags] = useState({
    interests: currentUser.interests.map((tag) => tag.id),
    expertise: currentUser.expertise.map((tag) => tag.id),
  })

  // periodically rerender so we pick up changes to notification permissions.
  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate()
    }, 400)
    return () => clearInterval(interval)
  }, [forceUpdate])

  const [imageURL, setImageURL] = useState<string | null>(
    currentUser.photoUrl
      ? imgixResize(currentUser.photoUrl, {
          width: 192,
          height: 192,
          fit: "crop",
        })
      : null
  )

  const form = useForm<IFormInput>()

  useEffect(
    () =>
      form.reset(
        {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          companyName: currentUser.companyName,
          jobTitle: currentUser.jobTitle,
          placeId: currentUser.place?.id,
          bio: currentUser.bio,
          linkedin: currentUser.linkedin,
          twitter: currentUser.twitter,
          instagram: currentUser.instagram,
        },
        { keepDirtyValues: true }
      ),
    [currentUser, form]
  )

  const dirtyFieldsCount = useMemo(
    () => Object.keys(form.formState.dirtyFields).length,
    [form.formState]
  )

  const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current)
      window.scrollTo({
        top: Math.max(0, ref.current.offsetTop - 30),
        behavior: "smooth",
      })
  }

  useEffect(() => {
    if (location.hash === "#celebrations") scrollToRef(celebrationsRef)
    if (location.hash === "#interests") scrollToRef(interestsRef)
    if (location.hash === "#expertise") scrollToRef(expertiseRef)
  }, [location])

  const onSocialBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!isSocialMediaType(e.target.name)) {
      return
    }

    let url = null
    try {
      url = new URL(e.target.value)
    } catch (TypeError) {}

    if (url) {
      form.setValue(
        e.target.name,
        url.pathname
          .split("/")
          .filter((s) => s)
          .slice(-1)[0]
      )
    } else if (e.target.value && e.target.value.startsWith("@")) {
      form.setValue(e.target.name, e.target.value.substr(1))
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    const oldCompany = currentUser.companyName
    const { errors } = await runUserUpdate({
      variables: {
        input: formData,
      },
    })

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      if (oldCompany !== formData.companyName) {
        logEvent(AhoyEventTypeEnum.CompanyChanged, {
          company: formData.companyName,
          prior_company_name: oldCompany,
        })
      }
      toast.success("Account settings updated!")
      form.reset()
    }
  }

  const toggleTag = (
    tagType: "interests" | "expertise",
    tagId: string,
    nameMap: NameMap
  ) => {
    const tags = structuredClone(selectedTags)
    const priorTags = structuredClone(tags)

    if (tags[tagType].includes(tagId) && tags[tagType].length > 2) {
      tags[tagType] = tags[tagType].filter((id) => id !== tagId)
    } else if (!tags[tagType].includes(tagId) && tags[tagType].length < 3) {
      tags[tagType].push(tagId)
    }

    setSelectedTags(tags)

    runUserUpdate({
      variables: {
        input: {
          interestIds: tags.interests,
          expertiseIds: tags.expertise,
        },
      },
    }).then(() => {
      // Detect if priorTags.interests and tags.interests are different
      const sortedInterestTags = (tags.interests || []).slice().sort()
      const sortedPriorInterestTags = (priorTags.interests || []).slice().sort()
      const interestsChanged = !(
        sortedPriorInterestTags.every((v, i) => v === sortedInterestTags[i]) &&
        sortedInterestTags.length === sortedPriorInterestTags.length
      )

      // Detect if priorTags.expertise and tags.expertise are different
      const sortedExpertiseTags = (tags.expertise || []).slice().sort()
      const sortedPriorExpertiseTags = (priorTags.expertise || [])
        .slice()
        .sort()
      const expertiseChanged = !(
        sortedPriorExpertiseTags.every(
          (v, i) => v === sortedExpertiseTags[i]
        ) && sortedExpertiseTags.length === sortedPriorExpertiseTags.length
      )

      if (interestsChanged) {
        logEvent(AhoyEventTypeEnum.InterestsChanged, {
          prior_interests: (priorTags.interests || [])
            .map((tag) => nameMap[tag])
            .join(", "),
          interests: (tags.interests || [])
            .map((tag) => nameMap[tag])
            .join(", "),
        })
      }

      if (expertiseChanged) {
        logEvent(AhoyEventTypeEnum.ExpertiseChanged, {
          prior_expertise: (priorTags.expertise || [])
            .map((tag) => nameMap[tag])
            .join(", "),
          expertise: (tags.expertise || [])
            .map((tag) => nameMap[tag])
            .join(", "),
        })
      }
    })
  }

  const TagSection = forwardRef(
    (
      {
        tagType,
        title,
      }: {
        tagType: "interests" | "expertise"
        title: string
      },
      ref: React.ForwardedRef<HTMLDivElement>
    ) => {
      const nameMap =
        error || !data
          ? {}
          : data.tags.nodes.reduce((acc, node) => {
              acc[node.id] = node.name
              return acc
            }, {} as NameMap)

      return (
        <Card ref={ref}>
          <CardHeader className="flex-row justify-between">
            <CardTitle>{title}</CardTitle>
            <div className="text-[15px] text-textMedium">
              Choose between 2-3 areas
            </div>
          </CardHeader>
          <CardContent>
            {!loading &&
              (error || !data ? (
                <Error message="Error loading tags." />
              ) : (
                <div className="max-w-[660px] m-auto mt-8 mb-4">
                  <div className="mr-[-8px]">
                    {data.tags.nodes.map((tag) => (
                      <OnboardingInterestToggle
                        key={tag.id}
                        className="mr-1 mb-1"
                        checked={selectedTags[tagType].includes(tag.id)}
                        onClick={() => {
                          toggleTag(tagType, tag.id, nameMap)
                        }}
                      >
                        {tag.name}
                      </OnboardingInterestToggle>
                    ))}
                  </div>
                </div>
              ))}
          </CardContent>
        </Card>
      )
    }
  )

  const ccls = useCommunityClassname()

  const { setLeftSidebar, resetLeftSidebar } = useAuthenticatedLayout()

  useEffect(() => {
    setLeftSidebar(<EditProfileNavigation />)

    return () => {
      resetLeftSidebar()
    }
  }, [location, setLeftSidebar, resetLeftSidebar])

  return (
    <div className="container mx-auto flex items-start tracking-[0.5px] mb-20 px-4 text-foreground">
      <div className="w-full flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <div
            className="flex items-center cursor-pointer font-bold"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft className="mr-3" /> Back
          </div>
        </div>
        <Card ref={editProfileRef}>
          <CardHeader>
            <CardTitle>Profile Details</CardTitle>
          </CardHeader>
          <CardContent>
            <FormProvider {...form}>
              <form
                className="max-w-[660px] m-auto"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <div className="my-8">
                  <OnboardingPhotoField
                    value={imageURL}
                    onChange={setImageURL}
                  />
                </div>
                <div className="font-bold mb-6">Account Details</div>
                <OnboardingDetailsFields displayPronouns />
                <div className="py-2"></div>
                <OnboardingFieldLabel className="col-span-2">
                  Bio
                </OnboardingFieldLabel>
                <OnboardingBioField />
                <OnboardingFieldLabel className="pt-6 col-span-2">
                  Social Media
                </OnboardingFieldLabel>
                <label className="onboarding-field col-span-2 flex">
                  <div className="onboarding-field__prefix">
                    <LinkedinIcon className="w-[17px] h-auto" />
                  </div>
                  <input
                    className="onboarding-field__input text-dark-gray"
                    placeholder="https://linkedin.com/in/..."
                    {...form.register("linkedin", {
                      onBlur: onSocialBlur,
                    })}
                  />
                </label>
                <label className="onboarding-field col-span-2 mt-2 flex">
                  <div className="onboarding-field__prefix">
                    <TwitterIcon className="w-[17px] h-auto" />
                  </div>
                  <input
                    className="onboarding-field__input text-dark-gray"
                    placeholder="@yourhandle"
                    {...form.register("twitter", {
                      onBlur: onSocialBlur,
                    })}
                  />
                </label>
                <label className="onboarding-field col-span-2 mt-2 flex">
                  <div className="onboarding-field__prefix">
                    <InstagramImg className="w-[17px] h-auto" />
                  </div>
                  <input
                    className="onboarding-field__input text-dark-gray"
                    placeholder="@yourhandle"
                    {...form.register("instagram", {
                      onBlur: onSocialBlur,
                    })}
                  />
                </label>
                <Button
                  type="submit"
                  className="mt-6 mb-2"
                  disabled={updating || dirtyFieldsCount === 0}
                >
                  Save Changes
                </Button>
              </form>
            </FormProvider>
          </CardContent>
        </Card>
        <Card ref={celebrationsRef}>
          <CardHeader>
            <CardTitle>What are you celebrating?</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="max-w-[660px] m-auto mt-6">
              {currentUser.celebrations.length < MAXIMUM_CELEBRATIONS && (
                <AddCelebration />
              )}
              {currentUser.celebrations.length > 0 && (
                <>
                  <div className="font-light text-xs text-pretext-light-gray mt-6 uppercase">
                    Celebrations
                  </div>
                  <div className="mt-4 divide-y">
                    {currentUser.celebrations.map((celebration) => (
                      <ShowCelebration
                        key={celebration.id}
                        celebration={celebration}
                        editable
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </Card>
        <TagSection
          tagType="interests"
          title="What you're most excited to learn about?"
          ref={interestsRef}
        />
        <TagSection
          tagType="expertise"
          title="What do you believe you're an expert in?"
          ref={expertiseRef}
        />

        <a
          className={cn(
            buttonVariants({ variant: "outline" }),
            "text-[12px] align-middle pt-[11px] pb-[9px] w-[112px] font-semibold",
            ccls({
              [CommunitySlug.Boardroom]: "border-white text-white",
              default: "border-highlight text-highlight",
            })
          )}
          href={signOutPath.pattern}
        >
          <SignOutLeft className="mr-2" /> Sign Out
        </a>

        {qaToolsEnabled && (
          <div>
            <div className="font-semibold underline mb-4">QA tools</div>
            <Button
              variant="outline"
              onClick={() => {
                localStorage.removeItem(NEXT_PROMPT_KEY)
                localStorage.removeItem(NOTIFICATIONS_DENY_COUNT_KEY)
                alert("cleared - refresh the page")
              }}
            >
              Clear Notification Vars
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export const EditProfileNavigation = () => {
  const community = useCommunity()

  return (
    <div className="flex flex-col gap-2 lg:mt-16 text-foreground">
      <Link variant="nav" to={editProfilePath.pattern}>
        Profile Details
      </Link>
      <Link variant="nav" to={editCelebrationsPath.pattern}>
        Celebrations
      </Link>
      <Link variant="nav" to={editInterestsPath.pattern}>
        Interests
      </Link>
      <Link variant="nav" to={editExpertisePath.pattern}>
        Expertise
      </Link>
      <Link
        className={cn(
          buttonVariants({ variant: "outline" }),
          "text-[12px] w-[143px] align-middle mr-2  pt-[11px] pb-[9px] font-semibold mt-10",
          {
            "border-white text-white hover:text-dark-gray":
              community.slug === CommunitySlug.Boardroom,
          }
        )}
        target="_blank"
        rel="noreferrer"
        href="mailto:memberships@workweek.com"
      >
        Contact Us
      </Link>
      <ReferralCode />
    </div>
  )
}
