import EditorJS from "@editorjs/editorjs"
import Header from "@editorjs/header"
import Quote from "@editorjs/quote"
import Table from "@editorjs/table"
import Underline from "@editorjs/underline"
import AttachesTool from "@editorjs/attaches"
import Paragraph from "@editorjs/paragraph"
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react"
import { getMetaVar } from "~/common/getMetaVar"
import { WorkweekImageTool } from "./editorTools/WorkweekImageTool"
import { useFileUpload } from "./useFileUpload"
import { ColorTool } from "./editorTools/ColorTool"
import { WorkweekCalloutTool } from "./editorTools/WorkweekCalloutTool"
import { createWorkweekLinkTool } from "./editorTools/WorkweekLinkTool"
import { WorkweekPdfEmbedTool } from "./editorTools/WorkweekPdfEmbedTool"
import Undo from "editorjs-undo"
import { WorkweekListTool } from "./editorTools/WorkweekListTool"
import { WorkweekDelimiterTool } from "./editorTools/WorkweekDelimiterTool"
import { createPortal } from "react-dom"
import { PollTool } from "./editorTools/PollTool"
import { AirTableFormEmbedTool } from "./editorTools/AirTableFormEmbedTool"

const linkMetadataPath = getMetaVar("link-metadata-path")

export type EditorHandle = {
  getContent: () => Promise<any | null>
}

type Props = {
  articleId: string
  initialData?: any
  onChange: () => void
}

// The editorjs onChange callback doesn't work well with hooks because
// it's not possible to update the onChange callback dynamically
let localOnChange = () => {}
const localOnChangeCaller = () => {
  localOnChange()
}

export const Editor = forwardRef<EditorHandle, Props>(
  ({ articleId, onChange, initialData = {} }, ref) => {
    const [pollPortals, setPollPortals] = React.useState<React.ReactPortal[]>(
      []
    )

    const createBlockPortal = useCallback(
      (blockId: string, node: React.ReactNode, container: HTMLElement) => {
        setPollPortals((portals) => {
          const portal = createPortal(
            node,
            container,
            `block-portal-${blockId}`
          )
          return [...portals, portal]
        })
      },
      []
    )
    const removeBlockPortal = useCallback(
      (blockId: string) => {
        setPollPortals((portals) => {
          return portals.filter(
            (portal) => portal.key !== `block-portal-${blockId}`
          )
        })
      },
      [setPollPortals]
    )

    const editorRef = useRef<EditorJS | null>(null)

    useImperativeHandle(
      ref,
      () => {
        return {
          getContent: async () => {
            if (!editorRef.current) return null
            return editorRef.current?.save()
          },
        }
      },
      []
    )

    const { uploadAndAttachFile, uploadAndAttachImage } = useFileUpload({
      articleId,
    })

    useEffect(() => {
      return () => {
        if (editorRef.current) {
          console.log("detaching editor")
          editorRef.current.destroy && editorRef.current.destroy()
        }
      }
    }, [])

    useEffect(() => {
      localOnChange = onChange
    }, [onChange])

    useEffect(() => {
      if (editorRef.current === null) {
        console.log("attaching editor")

        editorRef.current = new EditorJS({
          holder: "editorjs",
          autofocus: true,
          data: initialData,
          placeholder: "Start creating content here...",
          hideToolbar: false,
          inlineToolbar: true,
          onChange: localOnChangeCaller,
          onReady: () => {
            const undo = new Undo({
              editor: editorRef.current,
              config: {
                shortcuts: {
                  undo: "CMD+Z",
                  redo: "CMD+SHIFT+Z",
                },
              },
            })
            undo.initialize(initialData)
          },
          // onChange: async () => {
          //   const content = await editorRef.current?.saver.save()
          //   console.log(content)
          // },
          defaultBlock: "paragraph",
          tools: {
            paragraph: {
              class: Paragraph,
              inlineToolbar: true,
              config: {
                preserveBlank: true,
              },
            },
            color: {
              // @ts-ignore
              class: ColorTool,
              config: {
                colors: [
                  "var(--editor-text-color-1)",
                  "var(--editor-text-color-2)",
                  "var(--editor-text-color-3)",
                  "var(--editor-text-color-4)",
                  "var(--editor-text-color-5)",
                  "var(--editor-text-color-6)",
                ],
                defaultColor: "var(--editor-text-color-1)",
                type: "text",
              },
            },
            // marker: {
            //   class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
            //   config: {
            //     defaultColor: "#FFBF00",
            //     type: "marker",
            //   },
            // },
            header: {
              class: Header,
              inlineToolbar: true,
              config: {
                levels: [1, 2, 3],
                defaultLevel: 3,
              },
            },
            underline: Underline,
            list: {
              // @ts-ignore
              class: WorkweekListTool,
              inlineToolbar: true,
              config: {
                defaultStyle: "unordered",
              },
            },
            quote: Quote,
            delimiter: WorkweekDelimiterTool,
            linkTool: {
              class: createWorkweekLinkTool("Embed Link"),
              config: {
                endpoint: linkMetadataPath,
              },
            },
            // disabled due to security concerns
            // raw: RawTool,
            warning: {
              class: WorkweekCalloutTool,
              inlineToolbar: true,
              config: {
                titlePlaceholder: "💡️",
              },
            },
            table: {
              class: Table,
              inlineToolbar: true,
            },
            image: {
              // @ts-ignore
              class: WorkweekImageTool,
              config: {
                uploader: {
                  uploadByFile: uploadAndAttachImage,
                },
                types: "image/*",
                captionPlaceholder: "Caption (optional - leave blank for none)",
                altPlaceholder: "Alt text (for accessibility)",
              },
            },
            attaches: {
              class: AttachesTool,
              config: {
                uploader: {
                  uploadByFile: uploadAndAttachFile,
                },
              },
            },
            pdfEmbed: {
              // @ts-ignore
              class: WorkweekPdfEmbedTool,
              config: {
                uploader: {
                  uploadByFile: uploadAndAttachFile,
                },
                types: "application/pdf",
              },
            },
            poll: {
              class: PollTool as any, // There's a bug with editorjs's type definitions around class definitions
              config: {
                createPortal: createBlockPortal,
                removePortal: removeBlockPortal,
              },
            },
            airTableFormEmbed: {
              class: AirTableFormEmbedTool as any, // There's a bug with editorjs's type definitions around class definitions
              config: {
                createPortal: createBlockPortal,
                removePortal: removeBlockPortal,
              },
            },
          },
        })
      }
    }, [
      initialData,
      uploadAndAttachFile,
      uploadAndAttachImage,
      onChange,
      createBlockPortal,
      removeBlockPortal,
    ])

    return (
      <>
        {pollPortals.map((portal) => (
          <React.Fragment key={portal.key}>{portal}</React.Fragment>
        ))}
        <div id="editorjs" />
      </>
    )
  }
)
