import { useEffect, useRef } from "react"
import { Post_CardFragment } from "~/__generated__/graphql"
import { usePostRead } from "~/feed/PostReadContext"

export const useReadTracking = ({
  inView,
  post,
  enabled,
}: {
  inView: boolean
  post: Post_CardFragment
  enabled: boolean
}) => {
  const { markRead } = usePostRead()
  const readTrackedRef = useRef(false)

  useEffect(() => {
    if (!enabled) return
    if (post.currentUserHasRead) return
    if (readTrackedRef.current) return
    if (!inView) return

    readTrackedRef.current = true
    markRead(post.id)
  }, [inView, post.id, post.currentUserHasRead, markRead, enabled])
}
