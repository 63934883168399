import { cva, VariantProps } from "class-variance-authority"
import { capitalCase } from "change-case"
import { DotFilledIcon } from "@radix-ui/react-icons"
import { Badge } from "~/ui/badge"

const statusVariants = cva("pl-1 pr-2 py-0.5 align-middle", {
  variants: {
    status: {
      active: "text-success border-success",
      canceled: "text-error border-error",
      incomplete: "text-warning border-warning",
      incomplete_expired: "text-error border-error",
      past_due: "text-error border-error",
      trialing: "text-info border-info",
      unpaid: "text-error border-error",
      paused: "text-info border-info",
      unknown: "",
    },
  },
})

interface StatusProps extends VariantProps<typeof statusVariants> {
  className?: string
}

export const SubscriptionStatus = ({ status, className }: StatusProps) => {
  const statusText = status ? capitalCase(status) : "N/A"

  return !status ? (
    statusText
  ) : (
    <Badge
      variant="outline"
      className={statusVariants({ status: status, className })}
    >
      <DotFilledIcon className="w-6 h-6" />
      {statusText}
    </Badge>
  )
}
