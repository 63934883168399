import { RenderFn } from "editorjs-blocks-react-renderer"
import { AirTableFormEmbed } from "../AirTableFormEmbed"

export interface AirTableFormEmbedBlockData {
  url?: string
}

export const AirTableFormEmbedRenderer: RenderFn<
  AirTableFormEmbedBlockData
> = ({ data: { url } }) => {
  if (!url) return <></>
  return (
    <div className="flex flex-col gap-2 mb-6 text-xs">
      <AirTableFormEmbed url={url} />
    </div>
  )
}
