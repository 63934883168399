import { ActivitiesTable } from "~/admin/users/ActivitiesTable"
import { BarChart } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"

export const AdminHrisScreen = () => {
  return (
    <>
      <AdminHeader title="HRIS" Icon={BarChart} />
      <ActivitiesTable />
    </>
  )
}
