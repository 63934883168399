// @ts-nocheck

import ImageTool from "@editorjs/image"
import { IconAddBorder, IconAddBackground } from "@codexteam/icons"

export class WorkweekImageTool extends ImageTool {
  static get pasteConfig() {
    // Overrides https://github.com/editor-js/image/blob/master/src/index.js#L272
    // Disable converting pasted URLs to images
    return {}
  }

  static get tunes() {
    return [
      {
        name: "withBorder",
        icon: IconAddBorder,
        title: "With border",
        toggle: true,
      },
      // {
      //   name: "stretched",
      //   icon: IconStretch,
      //   title: "Stretch image2",
      //   toggle: true,
      // },
      {
        name: "withBackground",
        icon: IconAddBackground,
        title: "With background",
        toggle: true,
      },
    ]
  }

  renderSettings() {
    // Merge default tunes with the ones that might be added by user
    // @see https://github.com/editor-js/image/pull/49
    const tunes = WorkweekImageTool.tunes.concat(this.config.actions)

    return tunes.map((tune) => ({
      icon: tune.icon,
      label: this.api.i18n.t(tune.title),
      name: tune.name,
      toggle: tune.toggle,
      isActive: this.data[tune.name],
      onActivate: () => {
        /* If it'a user defined tune, execute it's callback stored in action property */
        if (typeof tune.action === "function") {
          tune.action(tune.name)

          return
        }
        this.tuneToggled(tune.name)
      },
    }))
  }
}
