import { useEffect, useMemo, useRef } from "react"
import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import {
  DialogFooter,
  DialogHeader,
  DialogSubTitle,
  DialogTitle,
} from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { formatCurrency } from "~/common/formatCurrency"
import {
  AhoyEventTypeEnum,
  Offer,
  OfferCategoryEnum,
  TierIntervalEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { useTiers } from "~/tiers/TiersProvider"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { PricingTier } from "./PricingTableStep"
import { CommunityName } from "~/community/CommunityName"

export const SpecialOfferStep = () => {
  const { goToStep, close, meta, addToMeta } = useWizard()
  const { subscription, acceptOffer } = useSubscription()
  const { logEvent } = useLogEvent()
  const { formatTierName } = useTiers()
  const { currentUser } = useCurrentUser()
  const { tiers, changeTierCta } = useTiers()
  const hasLoggedEvent = useRef(false)

  const offer = useMemo(() => {
    return meta.offer ? (meta.offer as Offer) : null
  }, [meta])

  useEffect(() => {
    if (!offer) return

    if (offer.category === OfferCategoryEnum.SaveBeforeLose) {
      logEvent(
        AhoyEventTypeEnum.OfferModalViewed,
        {
          source: meta.source,
          stripe_coupon_id: offer.stripeCoupon.id,
          cancellation_reason: meta.cancellationReason,
          other_cancellation_reason: meta.otherReason,
        },
        hasLoggedEvent
      )
    } else {
      logEvent(
        AhoyEventTypeEnum.UpgradeModalViewed,
        {
          source: meta.source || "unknown",
          stripe_coupon_ids: offer.stripeCoupon.id,
          content_id: meta.contentId,
        },
        hasLoggedEvent
      )
    }
  }, [
    logEvent,
    meta.cancellationReason,
    meta.otherReason,
    meta.source,
    offer,
    currentUser.tier,
    meta.contentId,
  ])

  const tier = useMemo(
    () => (offer ? tiers.find((t) => t.id === offer.tier.id)! : null),
    [tiers, offer]
  )

  const userIsAlreadyOnTierAndInterval = useMemo(() => {
    if (!offer) return true
    return (
      currentUser.tier?.level === offer.tier.level &&
      offer.tierInterval === currentUser.tierInterval
    )
  }, [currentUser.tier, offer, currentUser.tierInterval])

  const offerStripePrice = useMemo(() => {
    if (!offer) return null
    if (!tier) return null

    return offer.tierInterval === TierIntervalEnum.Quarter
      ? tier.quarterlyStripePrice
      : tier.yearlyStripePrice
  }, [offer, tier])

  const currentPrice = useMemo(() => {
    if (!offerStripePrice || !subscription) return null

    let totalDiscounts = 0
    if (subscription.discounts) {
      subscription.discounts.forEach((discount) => {
        if (discount.coupon.amountOff) {
          totalDiscounts += discount.coupon.amountOff
        }

        if (discount.coupon.percentOff) {
          totalDiscounts +=
            offerStripePrice.unitAmount * (discount.coupon.percentOff / 100)
        }
      })
    }

    return Math.max(0.0, offerStripePrice.unitAmount - totalDiscounts)
  }, [subscription, offerStripePrice])

  const discountedPrice = useMemo(() => {
    if (!subscription || !offer || !offerStripePrice) return null

    if (offer.stripeCoupon.amountOff) {
      return Math.max(
        0.0,
        offerStripePrice.unitAmount - offer.stripeCoupon.amountOff
      )
    }

    if (offer.stripeCoupon.percentOff) {
      return Math.max(
        0.0,
        offerStripePrice.unitAmount -
          offerStripePrice.unitAmount * (offer.stripeCoupon.percentOff / 100)
      )
    }

    return null
  }, [subscription, offer, offerStripePrice])

  const amountDifference = useMemo(() => {
    if (currentPrice === null || discountedPrice === null || currentPrice === 0)
      return null

    if (offer?.stripeCoupon.percentOff) {
      return `${offer.stripeCoupon.percentOff}%`
    } else if (offer?.stripeCoupon.amountOff) {
      return formatCurrency(offer.stripeCoupon.amountOff)
    }

    return `${Math.floor((1 - discountedPrice / currentPrice) * 100)}%`
  }, [currentPrice, discountedPrice, offer])

  const onDecline = () => {
    if (offer?.category === OfferCategoryEnum.SaveBeforeLose) {
      logEvent(AhoyEventTypeEnum.CancellationContinued, {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      })
      goToStep("ConfirmCancelStep")
    } else {
      close()
    }
  }

  const onAccept = async () => {
    if (!offer) return
    goToStep("LoadingStep", "forward", false)

    if (offer.category === OfferCategoryEnum.SaveBeforeLose) {
      logEvent(AhoyEventTypeEnum.OfferAccepted, {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
        stripe_coupon_id: offer.stripeCouponId,
        source: meta.source,
      })
    }

    const success = await acceptOffer(offer.id)
    addToMeta("offer", offer)
    addToMeta("selectedTier", tier)
    addToMeta("selectedInterval", offer.tierInterval)

    if (success) {
      goToStep("MigrateToTierStep", "forward", false)
    } else {
      close()
    }
  }

  const cta = useMemo(() => {
    return changeTierCta(currentUser, tier, offer?.tierInterval)
  }, [currentUser, tier, offer, changeTierCta])

  const body = useMemo(() => {
    if (!offer || !currentPrice || !discountedPrice) return null

    if (userIsAlreadyOnTierAndInterval) {
      return (
        <p>
          Keep your current membership benefits at a lower price. On your next
          billing cycle, you'll get a discount of {amountDifference} off your
          current membership price.
        </p>
      )
    }

    if (
      offer.category === OfferCategoryEnum.PaidMembershipUpsell ||
      offer.category === OfferCategoryEnum.ApplicationUpsell
    ) {
      return (
        <strong>
          You're here and we're happy! Take {amountDifference} off today!
        </strong>
      )
    }

    return (
      <strong>
        {currentUser.tier?.level !== offer.tier.level ? (
          <>
            Save {amountDifference} by switching to{" "}
            {formatTierName(offer.tier, offer.tierInterval)}.
          </>
        ) : (
          <>
            Switch to the{" "}
            {offer.tierInterval === TierIntervalEnum.Quarter
              ? "quarterly"
              : "annual"}{" "}
            membership and get {amountDifference} off{" "}
            {offer.tierInterval === TierIntervalEnum.Quarter
              ? "three more months"
              : "another year"}{" "}
            of {offer.tier.name} Membership.
          </>
        )}
      </strong>
    )
  }, [
    offer,
    currentPrice,
    discountedPrice,
    amountDifference,
    userIsAlreadyOnTierAndInterval,
    currentUser,
    formatTierName,
  ])

  if (!offer || !currentPrice || !discountedPrice) return null

  return (
    <div className="max-w-2xl flex flex-col gap-4">
      <DialogHeader>
        <DialogTitle>
          {offer.category === OfferCategoryEnum.SaveBeforeLose ? (
            <>
              We hate to hear it. Here's what we can do to help you keep your{" "}
              {currentUser.tier?.name} Membership
            </>
          ) : (
            <>
              Make the most of <CommunityName /> with {tier?.name}
            </>
          )}
        </DialogTitle>
        {offer.category !== OfferCategoryEnum.SaveBeforeLose &&
          offer.tier.level === TierLevelEnum.Plus && (
            <DialogSubTitle>
              Plus unlocks premium events, content, and intros.
            </DialogSubTitle>
          )}
      </DialogHeader>
      <Alert className="flex flex-col gap-4 text-center items-center justify-center">
        {body}
        <PricingTier
          className="mt-2"
          tier={offer.tier}
          quarterly={offer.tierInterval === TierIntervalEnum.Quarter}
          selected={false}
          selectable={false}
          showToggle={false}
          showCurrentBadge={false}
        />
      </Alert>

      <Separator />

      <DialogFooter className="flex-col gap-6 items-center">
        <Button onClick={onAccept}>
          {offer.category === OfferCategoryEnum.SaveBeforeLose ? (
            <>Yes, I'll Keep My Membership</>
          ) : (
            cta
          )}
        </Button>
        {offer.category === OfferCategoryEnum.SaveBeforeLose && (
          <Button
            type="button"
            variant="link"
            size="inline"
            className="text-2xs"
            onClick={onDecline}
          >
            Continue to cancel{" "}
            {formatTierName(currentUser.tier, currentUser.tierInterval)}
          </Button>
        )}
      </DialogFooter>
    </div>
  )
}

SpecialOfferStep.displayName = "SpecialOfferStep"
