import { Dialog, DialogContent } from "~/ui/dialog"
import { Button } from "~/ui/button"
import {
  ArticleContentTypeEnum,
  Article_LiveContentFragment,
} from "~/__generated__/graphql"
import { useBookmarks } from "~/post-composer/useBookmarks"
import BookmarkFilled from "~/images/icons/bookmark-filled.svg?react"
import { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"

export const BookmarkCourseModal = ({
  article,
}: {
  article: Article_LiveContentFragment
}) => {
  const logicCheckedRef = useRef(false)
  const [isOpen, setIsOpen] = useState(false)
  const { addBookmark } = useBookmarks({ article })

  useEffect(() => {
    if (logicCheckedRef.current) return
    logicCheckedRef.current = true

    const showModal =
      article.approvedRevision?.contentType === ArticleContentTypeEnum.Course &&
      !article.currentUserBookmark

    if (!showModal) return

    setTimeout(() => setIsOpen(true), 5000)
  }, [article])

  const bookmarkAndClose = async () => {
    await addBookmark({
      onSuccess: () => {
        toast.success("Course bookmarked!")
      },
    })

    setIsOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent
        className="w-2/3 max-w-xl gap-0 py-12"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <div className="flex justify-center mb-6">
          <div className="flex items-center justify-center border border-black w-[64px] h-[64px] rounded-full">
            {<BookmarkFilled height={30} width={30} />}
          </div>
        </div>
        <div className="mb-2 font-serif text-3xl text-center">
          Bookmark the Course!
        </div>
        <div className="mb-6 text-xs text-center">
          Bookmark the course for easy access.
        </div>

        <div className="flex justify-center">
          <Button
            type="button"
            className="px-10 py-3"
            onClick={bookmarkAndClose}
          >
            Bookmark & Start Course
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
