import { stripHtml } from "string-strip-html"
import HTMLReactParser from "html-react-parser"
import { RenderFn } from "editorjs-blocks-react-renderer"
import { useMemo } from "react"

export interface HeaderBlockData {
  text: string
  level: number
}

export const HeaderRenderer: RenderFn<HeaderBlockData> = ({
  data,
  className = "",
}) => {
  const props: {
    [s: string]: string
  } = {}

  const anchor = useMemo(() => {
    return stripHtml(data.text).result.replace(/\s+/g, "-")
  }, [data.text])

  if (className) {
    props.className = className
  }

  const Tag = `h${data?.level || 1}` as keyof JSX.IntrinsicElements
  return (
    <Tag {...props} id={anchor}>
      {data?.text && HTMLReactParser(data.text)}
    </Tag>
  )
}
