import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import {
  AdminUsersQueryVariables,
  IndustryEnum,
  JobFunctionEnum,
  Place_FilterFragment,
  StripeSubscriptionStatusEnum,
  Tag,
  Tier,
  UserSortEnum,
  User_AdminFragment,
  VerticalEnum,
} from "~/__generated__/graphql"
import { Button } from "~/ui/button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { USERS_QUERY_DOCUMENT } from "~/screens/admin/AdminMembersScreen"
import { useQuery } from "@apollo/client"
import { Error } from "~/ui/Error"
import { Skeleton } from "~/ui/skeleton"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { Card } from "~/ui/card"
import { UsersSearch } from "./UsersSearch"
import { UsersFilters } from "./UsersFilters"
import { cn } from "~/lib/utils"
import { FeatureFlag } from "~/common/FeatureFlag"
import invariant from "tiny-invariant"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"

const pinnedColumnStyles =
  "sticky left-0 bg-gradient-to-r from-white from-80% to-transparent group-hover:from-muted group-hover:from-80% group-hover:to-transparent"

export const TableSkeleton = ({ headers }: { headers: UserTableHeader[] }) => (
  <Table className="rounded-tl-2xl">
    <TableHeader>
      <TableRow className="group rounded-tl-2xl">
        {headers.map((header) => (
          <TableHead
            key={header.label}
            className={
              header.isPinned ? `${pinnedColumnStyles} rounded-tl-2xl` : ""
            }
          >
            {header.label}
          </TableHead>
        ))}
      </TableRow>
    </TableHeader>
    <TableBody>
      {Array.from({ length: 20 }).map((_, index) => (
        <TableRow key={index}>
          {headers.map((header) => (
            <TableCell key={[index, header].join("-")}>
              {header.label === "Name" && (
                <div className="flex gap-2">
                  <div>
                    <Skeleton className="rounded-full w-[24px] h-[24px]" />
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-2 items-center">
                      <Skeleton className="w-[80px] h-[16px]" />
                    </div>
                    <div className="text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis max-w-[140px] text-2xs">
                      <Skeleton className="w-[100px] h-[16px]" />
                    </div>
                  </div>
                </div>
              )}
              {header.label !== "Name" && (
                <Skeleton className="w-[80px] h-[16px]" />
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

const DEFAULT_SORT = UserSortEnum.Name
const PAGE_SIZE = 10

export type UserTableFilter =
  | "place"
  | "subscriptionStatus"
  | "expertises"
  | "interests"
  | "applicationState"
  | "tierId"
  | "companySize"
  | "industry"
  | "vertical"
  | "jobFunction"
  | "yearsOfExperience"

export type UserTableHeader = {
  label: string
  isPinned?: boolean
}

const UsersTableContext = createContext<{} | null>(null)

export const useUsersTable = () => {
  const context = useContext(UsersTableContext)
  invariant(context, "useUsersTable must be used within a UsersTableProvider")
  return context
}

export type UsersTableHandle = {
  refetch(): void
}

type UsersTableProps = {
  withFilters?: UserTableFilter[]
  withSearch?: boolean
  withSelectAll?: boolean
  onSelectAll?(users: User_AdminFragment[]): void
  onExport?(user_ids?: [string]): void
  withExport?: boolean
  withExportLoading?: boolean
  queryOptions?: AdminUsersQueryVariables
  defaultSort?: UserSortEnum
  pageSize?: number
  headers?: UserTableHeader[]
  skeleton?: React.ReactNode
  variant?: "table" | "card"
  children(user: User_AdminFragment): React.ReactNode
  className?: string
}

export const UsersTable = forwardRef<UsersTableHandle, UsersTableProps>(
  (
    {
      children,
      headers,
      skeleton,
      withFilters,
      withSearch,
      withSelectAll,
      withExport,
      withExportLoading,
      onExport,
      onSelectAll,
      queryOptions,
      className = "",
      variant = "table",
      defaultSort = DEFAULT_SORT,
      pageSize = PAGE_SIZE,
    },
    ref
  ) => {
    const usersExportFeature = FeatureFlag.get("usersExport")
    const [searchParams, setSearchParams] = useSearchParams()

    const [placeFilter, setPlaceFilter] = useState<
      Partial<Place_FilterFragment> | undefined
    >(
      searchParams.get("placeId")
        ? { id: searchParams.get("placeId")! }
        : undefined
    )
    const [subscriptionStatusFilter, setSubscriptionStatusFilter] = useState<
      StripeSubscriptionStatusEnum | undefined
    >(
      (searchParams.get(
        "subscriptionStatus"
      ) as StripeSubscriptionStatusEnum) || undefined
    )
    const [expertisesFilter, setExpertisesFilter] = useState<Partial<Tag>[]>(
      () => {
        const expertiseIds = searchParams.get("expertiseIds")
        return expertiseIds ? expertiseIds.split(",").map((id) => ({ id })) : []
      }
    )
    const [interestsFilter, setInterestsFilter] = useState<Partial<Tag>[]>(
      () => {
        const interestIds = searchParams.get("interestIds")
        return interestIds ? interestIds.split(",").map((id) => ({ id })) : []
      }
    )

    const [applicationStateFilter, setApplicationStateFilter] = useState<
      "completed" | "not_completed" | "not_started" | undefined
    >(() => {
      const applicationState = searchParams.get("applicationState")
      return applicationState ? (applicationState as any) : undefined
    })

    const [tierIdFilter, setTierIdFilter] = useState<Tier["id"] | undefined>(
      () => {
        const tierId = searchParams.get("tierId")
        return tierId ? (tierId as Tier["id"]) : undefined
      }
    )

    const [companySizeFilter, setCompanySizeFilter] = useState<
      string | undefined
    >(() => {
      const companySize = searchParams.get("companySize")
      return companySize ? companySize : undefined
    })

    const [industryFilter, setIndustryFilter] = useState<
      IndustryEnum | undefined
    >(() => {
      const industry = searchParams.get("industry")
      return industry ? (industry as IndustryEnum) : undefined
    })

    const [verticalFilter, setVerticalFilter] = useState<
      VerticalEnum | undefined
    >(() => {
      const vertical = searchParams.get("vertical")
      return vertical ? (vertical as VerticalEnum) : undefined
    })

    const [jobFunctionFilter, setJobFunctionFilter] = useState<
      JobFunctionEnum | undefined
    >(() => {
      const jobFunction = searchParams.get("jobFunction")
      return jobFunction ? (jobFunction as JobFunctionEnum) : undefined
    })

    const [yearsOfExperienceFilter, setYearsOfExperienceFilter] = useState<
      string | undefined
    >(() => {
      const yearsOfExperience = searchParams.get("yearsOfExperience")
      return yearsOfExperience ? yearsOfExperience : undefined
    })

    const [searchTerm, setSearchTerm] = useState<string>(
      searchParams.get("q") || ""
    )
    const [sort, setSort] = useState<UserSortEnum>(
      (searchParams.get("sort") as UserSortEnum) || defaultSort
    )

    const [debouncedSearchTerm] = useDebounce(searchTerm, 400)

    const useQueryOptions = useMemo(() => {
      const options: AdminUsersQueryVariables = { first: pageSize }
      if (Object.keys(queryOptions || {}).length > 0) {
        Object.assign(options, queryOptions)
      }
      if (sort) options.sort = sort
      if (debouncedSearchTerm) options.searchTerm = debouncedSearchTerm
      if (placeFilter?.id) options.placeId = placeFilter.id
      if (subscriptionStatusFilter)
        options.subscriptionStatus = subscriptionStatusFilter
      if (expertisesFilter.length > 0) {
        options.expertiseIds = expertisesFilter.map((tag) => tag.id!)
      }
      if (interestsFilter.length > 0) {
        options.interestIds = interestsFilter.map((tag) => tag.id!)
      }
      if (applicationStateFilter) {
        options.applicationState = applicationStateFilter
      }
      if (tierIdFilter) {
        options.tierId = tierIdFilter
      }
      if (companySizeFilter) {
        options.companySize = companySizeFilter
      }
      if (industryFilter) {
        options.industries = [industryFilter]
      }
      if (verticalFilter) {
        options.verticals = [verticalFilter]
      }
      if (jobFunctionFilter) {
        options.jobFunctions = [jobFunctionFilter]
      }
      if (yearsOfExperienceFilter) {
        options.yearsOfExperience = [yearsOfExperienceFilter]
      }

      if (Object.keys(options).length === 0) return {}
      return { variables: options }
    }, [
      applicationStateFilter,
      companySizeFilter,
      debouncedSearchTerm,
      expertisesFilter,
      industryFilter,
      interestsFilter,
      jobFunctionFilter,
      pageSize,
      placeFilter,
      queryOptions,
      sort,
      subscriptionStatusFilter,
      tierIdFilter,
      verticalFilter,
      yearsOfExperienceFilter,
    ])

    const {
      data: currentData,
      previousData,
      loading,
      error,
      fetchMore,
      refetch,
    } = useQuery(USERS_QUERY_DOCUMENT, {
      ...useQueryOptions,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    })

    const data = currentData || previousData

    const users = useMemo(() => {
      return (
        data?.users.edges
          .map((e) => e.node)
          .filter((user) => {
            return [
              queryOptions?.unreviewedOnly ? user.fit === null : true,
              queryOptions?.fitOnly ? user.fit === true : true,
              queryOptions?.unfitOnly ? user.fit === false : true,
              queryOptions?.activeOnly ? user.active : true,
              queryOptions?.inactiveOnly ? !user.active : true,
            ].every((condition) => condition)
          }) || []
      )
    }, [data, queryOptions])

    const hasNextPage = !!data?.users.pageInfo.hasNextPage
    const totalCount = useMemo(() => {
      return (
        (data?.users.totalCount || 0) -
        (data?.users.edges.length! - users.length)
      )
    }, [data, users])

    const memoizedSearchParams = useMemo(() => {
      const params = new URLSearchParams(searchParams.toString())
      if (debouncedSearchTerm !== params.get("q")) {
        if (debouncedSearchTerm === "") {
          params.delete("q")
        } else {
          params.set("q", debouncedSearchTerm)
        }
      }
      if (sort && sort !== defaultSort) {
        params.set("sort", sort)
      }
      if (placeFilter?.id) {
        params.set("placeId", placeFilter.id)
      } else {
        params.delete("placeId")
      }
      if (subscriptionStatusFilter) {
        params.set("subscriptionStatus", subscriptionStatusFilter)
      } else {
        params.delete("subscriptionStatus")
      }
      if (expertisesFilter.length > 0) {
        params.set(
          "expertiseIds",
          expertisesFilter.map((tag) => tag.id!).join(",")
        )
      } else {
        params.delete("expertiseIds")
      }
      if (interestsFilter.length > 0) {
        params.set(
          "interestIds",
          interestsFilter.map((tag) => tag.id!).join(",")
        )
      } else {
        params.delete("interestIds")
      }
      if (applicationStateFilter) {
        params.set("applicationState", applicationStateFilter)
      } else {
        params.delete("applicationState")
      }
      if (tierIdFilter) {
        params.set("tierId", tierIdFilter)
      } else {
        params.delete("tierId")
      }
      if (companySizeFilter) {
        params.set("companySize", companySizeFilter)
      } else {
        params.delete("companySize")
      }
      if (industryFilter) {
        params.set("industry", industryFilter)
      } else {
        params.delete("industry")
      }
      if (verticalFilter) {
        params.set("vertical", verticalFilter)
      } else {
        params.delete("vertical")
      }
      if (jobFunctionFilter) {
        params.set("jobFunction", jobFunctionFilter)
      } else {
        params.delete("jobFunction")
      }
      if (yearsOfExperienceFilter) {
        params.set("yearsOfExperience", yearsOfExperienceFilter)
      } else {
        params.delete("yearsOfExperience")
      }
      return params
    }, [
      applicationStateFilter,
      companySizeFilter,
      debouncedSearchTerm,
      defaultSort,
      expertisesFilter,
      industryFilter,
      interestsFilter,
      jobFunctionFilter,
      placeFilter,
      searchParams,
      sort,
      subscriptionStatusFilter,
      tierIdFilter,
      verticalFilter,
      yearsOfExperienceFilter,
    ])

    useEffect(() => {
      setSearchParams(memoizedSearchParams)
    }, [memoizedSearchParams, setSearchParams])

    useImperativeHandle(
      ref,
      () => ({
        refetch,
      }),
      [refetch]
    )

    return (
      <UsersTableContext.Provider value={{}}>
        {withSearch && (
          <div className="flex justify-center items-center gap-4 mb-4">
            {withSelectAll && onSelectAll && (
              <div>
                <Button
                  onClick={() => onSelectAll(users)}
                  size="lg"
                  className="text-2xs"
                >
                  Select All
                </Button>
              </div>
            )}

            {usersExportFeature && withExport && (
              <div>
                <Button
                  onClick={() => {
                    onExport && onExport()
                  }}
                  disabled={withExportLoading}
                  size="lg"
                  className="text-2xs"
                >
                  {withExportLoading ? "Loading... " : <span>Export</span>}
                </Button>
              </div>
            )}

            <div className="flex-grow">
              <UsersSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                sort={sort}
                setSort={setSort}
              />
            </div>
          </div>
        )}
        {withFilters && (
          <UsersFilters
            applicationStateFilter={applicationStateFilter}
            companySizeFilter={companySizeFilter}
            enabledFilters={withFilters}
            expertisesFilter={expertisesFilter}
            industryFilter={industryFilter}
            interestsFilter={interestsFilter}
            jobFunctionFilter={jobFunctionFilter}
            placeFilter={placeFilter}
            setApplicationStateFilter={setApplicationStateFilter}
            setCompanySizeFilter={setCompanySizeFilter}
            setExpertisesFilter={setExpertisesFilter}
            setIndustryFilter={setIndustryFilter}
            setInterestsFilter={setInterestsFilter}
            setJobFunctionFilter={setJobFunctionFilter}
            setPlaceFilter={setPlaceFilter}
            setSubscriptionStatusFilter={setSubscriptionStatusFilter}
            setTierIdFilter={setTierIdFilter}
            setVerticalFilter={setVerticalFilter}
            setYearsOfExperienceFilter={setYearsOfExperienceFilter}
            subscriptionStatusFilter={subscriptionStatusFilter}
            tierIdFilter={tierIdFilter}
            verticalFilter={verticalFilter}
            yearsOfExperienceFilter={yearsOfExperienceFilter}
          />
        )}

        {error ? (
          <Error message="Error loading members." />
        ) : (
          <>
            {users.length > 0 && (
              <div className="text-sm text-gray-500 mb-4">
                {totalCount} users found
              </div>
            )}
            {variant === "table" && (
              <UsersTableView
                users={users}
                loading={loading}
                headers={headers!}
                children={children}
                className={className}
              />
            )}
            {variant === "card" && (
              <UsersCardView
                users={users}
                loading={loading}
                children={children}
                skeleton={skeleton}
                className={className}
              />
            )}
          </>
        )}
        <div className="p-16 w-full flex items-center justify-center">
          <InfiniteLoadMore
            onEndReached={() =>
              fetchMore({
                variables: {
                  after: data?.users.pageInfo.endCursor,
                },
              })
            }
            canLoadMore={!loading && hasNextPage}
            loadingText="Loading more users..."
            loading={loading && users.length > 0 && hasNextPage}
          />
        </div>
      </UsersTableContext.Provider>
    )
  }
)

type UsersTableViewProps = {
  users: User_AdminFragment[]
  headers: UserTableHeader[]
  loading: boolean
  children(user: User_AdminFragment): React.ReactNode
  className: string
}
type UsersCardViewProps = Omit<UsersTableViewProps, "headers"> & {
  skeleton?: React.ReactNode
}

const UsersCardView = ({
  users,
  loading,
  children,
  skeleton,
  className,
}: UsersCardViewProps) => {
  return (
    <>
      {(users.length > 0 || loading) && (
        <div className={cn("flex flex-col gap-2", className)}>
          {users.length > 0 && users.map(children)}
          {loading && skeleton}
        </div>
      )}
      {!loading && users.length === 0 && (
        <div className="text-center text-gray-500">No users found.</div>
      )}
    </>
  )
}

const UsersTableView = ({
  users,
  headers,
  children,
  className,
  loading,
}: UsersTableViewProps) => {
  return (
    <>
      <Card>
        <Table className={cn("rounded-tl-2xl", className)}>
          {headers && (
            <TableHeader>
              <TableRow className="group rounded-tl-2xl">
                {headers.map((header) => (
                  <TableHead
                    key={header.label}
                    className={
                      header.isPinned
                        ? `${pinnedColumnStyles} rounded-tl-2xl`
                        : ""
                    }
                  >
                    {header.label}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {users.length > 0 && users.map(children)}
            {loading &&
              Array.from({ length: 10 }).map((_, index) => (
                <TableRow key={index}>
                  {headers.map((header) => (
                    <TableCell key={header.label}>
                      <Skeleton className="w-full h-[16px]" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Card>
    </>
  )
}
