import { ComposedChart, Bar } from "recharts"
import { Chart } from "~/__generated__/graphql"
import {
  BenchmarkChart,
  USER_DATA_KEY_FOR_DISPLAY,
  COMMUNITY_DATA_KEY_FOR_DISPLAY,
  USER_COLOR,
  COMMUNITY_COLOR,
  Margin,
} from "./BenchmarkChart"

export const BarChart = ({
  axisValueFormatter,
  chart,
  domain,
  height,
  interactive = true,
  legend = true,
  margin,
  tickCount,
  yAxisLine = true,
}: {
  axisValueFormatter?: (value: string) => string
  chart: Chart
  domain?: Array<number>
  height?: number
  interactive?: Boolean
  legend?: Boolean
  margin?: Margin
  tickCount?: number
  yAxisLine?: Boolean
}) => {
  return (
    <BenchmarkChart
      axisValueFormatter={axisValueFormatter}
      ChartComponent={ComposedChart}
      data={chart.data}
      height={height}
      legend={legend}
      margin={margin}
      tickCount={tickCount}
      tooltip={interactive}
      yAxisLine={yAxisLine}
    >
      <Bar dataKey={USER_DATA_KEY_FOR_DISPLAY} barSize={20} fill={USER_COLOR} />
      <Bar
        dataKey={COMMUNITY_DATA_KEY_FOR_DISPLAY}
        barSize={20}
        fill={COMMUNITY_COLOR}
      />
    </BenchmarkChart>
  )
}
