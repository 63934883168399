import { Transition, TransitionChild } from "@headlessui/react"
import copy from "copy-to-clipboard"
import { useCallback, useEffect, useState } from "react"
import { cn } from "~/lib/utils"
import { Button } from "~/ui/button"
import { SimpleTooltip } from "./SimpleTooltip"
import { Check, ClipboardCopy } from "lucide-react"

export const CopyToClipboard = ({
  text,
  children,
  onCopy,
  className,
}: {
  text: string
  children: React.ReactNode
  onCopy?: () => void
  className?: string
}) => {
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false)
      }, 2000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isCopied])

  const copyToClipboard = useCallback(() => {
    copy(text)
    setIsCopied(true)
    onCopy?.()
  }, [text, onCopy])

  return (
    <Button
      variant="subtle"
      size="tight"
      onClick={copyToClipboard}
      className={cn("cursor-pointer", className)}
    >
      <SimpleTooltip content="Copy to clipboard">
        <>
          <div className="relative">
            <Transition show={isCopied}>
              <TransitionChild>
                <div className="absolute left-0 top-0 transition-opacity duration-200 data-[closed]:opacity-0 flex items-center">
                  <Check className="w-3 h-3 mr-1" />
                  Copied
                </div>
              </TransitionChild>
            </Transition>
            <Transition show={!isCopied}>
              <TransitionChild>
                <div className="absolute left-0 top-0 transition-opacity duration-200 data-[closed]:opacity-0 flex items-center">
                  <ClipboardCopy className="w-3 h-3 mr-1" />
                  {children}
                </div>
              </TransitionChild>
            </Transition>
            <div className="invisible flex items-center">
              <ClipboardCopy className="w-3 h-3 mr-1" />
              {children}
            </div>
          </div>
        </>
      </SimpleTooltip>
    </Button>
  )
}
