import { useState, ChangeEvent, KeyboardEvent } from "react"
import { useManualHrisDataContext } from "~/merge/manualData"

import { Input } from "~/ui/input"

import { Base } from "./Base"
import { getMetaVarMaybe } from "~/common/getMetaVar"

const ARTICLE_ID = getMetaVarMaybe("hris-retention-article-id")

export const ManualRetention = ({
  editContext = false,
  closeDialog,
}: {
  editContext?: boolean
  closeDialog?: () => void
}) => {
  const { next, prev, retention, setRetention, onSuccessCallback } =
    useManualHrisDataContext()

  const [inputValue, setInputValue] = useState(
    retention ? `${retention?.toString()}%` : ""
  )

  const updateInputs = (input: string) => {
    let newValue

    if (input === "" || /^\d*\.?\d{0,2}$/.test(input)) {
      newValue = input === "" ? "" : `${input}%`

      setInputValue(newValue)
      setRetention(input === "" ? undefined : Number(input))
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Backspace") {
      return
    }

    const selectionStart = (e.target as HTMLInputElement).selectionStart ?? 0
    const selectionEnd = (e.target as HTMLInputElement).selectionEnd ?? 0

    let backspacedValue

    // If there is a current selection remove that portion of the input,
    // otherwise remove the last character ignoring the % unit.
    if (selectionStart !== selectionEnd) {
      backspacedValue = (
        inputValue.slice(0, selectionStart) + inputValue.slice(selectionEnd)
      ).replace("%", "")
    } else {
      backspacedValue = inputValue.replace("%", "").slice(0, -1)
    }

    updateInputs(backspacedValue)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace("%", "")
    updateInputs(value)
  }

  return (
    <Base
      header="Retention Rate"
      title="Retention Rate (percentage)"
      articleId={ARTICLE_ID && ARTICLE_ID.length > 0 ? ARTICLE_ID : null}
      editContext={editContext}
      submit={() => {
        onSuccessCallback()
        closeDialog && closeDialog()
      }}
      prev={prev}
      next={next}
      disabled={retention === undefined}
    >
      <div className="text-xs font-medium tracking-wide text-textLight mb-1">
        Over the past 6 months, what has your company's retention rate been?
        <span className="text-red-400">*</span>
      </div>
      <div className="mb-6">
        <Input
          type="text"
          value={inputValue}
          placeholder={"95%"}
          onKeyDown={handleKeyPress}
          onChange={handleChange}
        />
      </div>
    </Base>
  )
}
