import { ArrowLeft, ArrowRight, Check } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Article_LiveContentFragment,
  AhoyEventTypeEnum,
} from "~/__generated__/graphql"
import { Button } from "~/ui/button"
import {
  adjacentCourseArticleIds,
  lessonForArticleId,
  sectionForArticleId,
} from "./courseUtils"
import { articlePath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import { displayErrors } from "~/common/validations"
import invariant from "tiny-invariant"
import { ModuleCompletedModal } from "./ModuleCompletedModal"
import { CourseCompletedModal } from "./CourseCompletedModal"
import { useLogEvent } from "~/analytics/EventsContext"

export const LessonFooter = ({
  article,
}: {
  article: Article_LiveContentFragment
}) => {
  const navigate = useNavigate()
  const course = article.approvedLesson?.section.course
  const lesson = lessonForArticleId(course, article.id)
  const section = sectionForArticleId(course, article.id)
  const [moduleCompletedModalOpen, setModuleCompletedModalOpen] =
    useState(false)
  const [courseCompletedModalOpen, setCourseCompletedModalOpen] =
    useState(false)

  const { nextArticleId, previousArticleId } = useMemo(() => {
    return adjacentCourseArticleIds(course, article.id)
  }, [course, article.id])

  const goToNextLesson = useCallback(
    () => nextArticleId && navigate(articlePath({ articleId: nextArticleId })),
    [nextArticleId, navigate]
  )

  const [runMarkComplete, { loading }] = useSafeMutation(
    LESSON_MARK_COMPLETE_MUTATION
  )

  const { logEvent } = useLogEvent()

  const markComplete = useCallback(async () => {
    const lessonId = lesson?.id
    invariant(lessonId)

    const { data, errors } = await runMarkComplete({
      variables: {
        input: {
          lessonId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      const lesson = data?.lessonMarkComplete.lesson
      invariant(lesson)

      logEvent(AhoyEventTypeEnum.LessonCompleted)

      setTimeout(() => {
        if (lesson.section.course.currentUserCompleted) {
          logEvent(AhoyEventTypeEnum.CourseCompleted)

          setCourseCompletedModalOpen(true)
        } else if (lesson.section.currentUserCompleted) {
          logEvent(AhoyEventTypeEnum.LessonCompleted)

          setModuleCompletedModalOpen(true)
        } else {
          goToNextLesson()
        }
      }, 500)
    }
  }, [runMarkComplete, lesson?.id, goToNextLesson, logEvent])

  if (!lesson) return null

  invariant(course)
  invariant(section)
  invariant(lesson)

  return (
    <div className="flex justify-end items-center mx-6 md:mx-10 xl:mx-20 mb-8">
      <div>
        <Button
          onClick={markComplete}
          disabled={loading || lesson.currentUserCompleted || false}
          className="mb-4"
        >
          {lesson.currentUserCompleted ? (
            <>
              <div className="h-[20px] w-[20px] rounded-full bg-mantis text-white flex items-center justify-center mr-2">
                <Check size={12} />
              </div>
              Complete
            </>
          ) : (
            <>Mark Complete</>
          )}
        </Button>
        <div className="flex justify-between">
          <Button
            variant="ghost"
            disabled={!previousArticleId}
            onClick={() =>
              previousArticleId &&
              navigate(articlePath({ articleId: previousArticleId }))
            }
          >
            <ArrowLeft />
          </Button>
          <Button
            variant="ghost"
            disabled={!nextArticleId}
            onClick={() =>
              nextArticleId &&
              navigate(articlePath({ articleId: nextArticleId }))
            }
          >
            <ArrowRight />
          </Button>
        </div>
      </div>

      <ModuleCompletedModal
        isOpen={moduleCompletedModalOpen}
        setIsOpen={setModuleCompletedModalOpen}
        section={section}
        course={course}
        onContinue={goToNextLesson}
      />
      <CourseCompletedModal
        isOpen={courseCompletedModalOpen}
        setIsOpen={setCourseCompletedModalOpen}
        course={course}
      />
    </div>
  )
}

const LESSON_MARK_COMPLETE_MUTATION = gql(`
  mutation LessonMarkComplete($input: LessonMarkCompleteInput!) {
    lessonMarkComplete(input: $input) {
      lesson {
        id
        currentUserCompleted
        section {
          id
          currentUserCompleted
          course {
            id
            currentUserCompleted
            currentUserCompletionPercentage
          }
        }
      }
    }
  }
`)
