import { ApolloQueryResult, FetchResult, useQuery } from "@apollo/client"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  TierIntervalEnum,
  TierLevelEnum,
  UserOffer,
  UserOffer_DisplayFragment,
  UserOffersQueryQuery,
  UserOfferStatusEnum,
} from "~/__generated__/graphql"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useTiers } from "~/tiers/TiersProvider"

type UserOffersContextType = {
  userOffers: UserOffer_DisplayFragment[]
  acceptedUserOffers: UserOffer_DisplayFragment[]
  acknowledgedUserOffers: UserOffer_DisplayFragment[]
  bestUserOffers: UserOffer_DisplayFragment[]
  redeemableUserOffers: UserOffer_DisplayFragment[]
  redeemedUserOffers: UserOffer_DisplayFragment[]
  unacknowledgedUserOffers: UserOffer_DisplayFragment[]
  acknowledgeUserOffer: (
    userOffer: Pick<UserOffer, "id">
  ) => Promise<FetchResult<any>>
  getBestUserOffer: (
    tierLevel: TierLevelEnum,
    tierInterval: string
  ) => UserOffer_DisplayFragment | null
  refetchUserOffers: () => Promise<ApolloQueryResult<UserOffersQueryQuery>>
}

const UserOffersContext = createContext<UserOffersContextType | null>(null)

export const useUserOffers = () => {
  const context = useContext(UserOffersContext)
  invariant(context, "useUserOffers must be used within a UserOffersProvider")
  return context
}

interface UserOffersProviderProps {
  children: React.ReactNode
}

export const UserOffersProvider = ({ children }: UserOffersProviderProps) => {
  const { currentUser } = useCurrentUserMaybe()

  const { data, loading, refetch } = useQuery(USER_OFFERS_QUERY_DOCUMENT, {
    variables: {
      statuses: [
        UserOfferStatusEnum.Created,
        UserOfferStatusEnum.Redeemed,
        UserOfferStatusEnum.Acknowledged,
        UserOfferStatusEnum.Accepted,
      ],
    },
    pollInterval: 1000 * 60 * 5, // Every 5 minutes
  })
  const isInitialLoadRef = useRef(true)

  useEffect(() => {
    if (currentUser) {
      refetch()
    }
  }, [currentUser, refetch])

  const userOffers = useMemo(
    () => data?.userOffers?.edges.map((edge) => edge.node) || [],
    [data]
  )

  const unacknowledgedUserOffers = useMemo(
    () =>
      userOffers
        .filter((offer) => offer.status === UserOfferStatusEnum.Created)
        .sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        }),
    [userOffers]
  )
  const acknowledgedUserOffers = useMemo(
    () =>
      userOffers
        .filter((offer) => offer.status === UserOfferStatusEnum.Acknowledged)
        .sort((a, b) => {
          return (
            new Date(b.acknowledgedAt || 0).getTime() -
            new Date(a.acknowledgedAt || 0).getTime()
          )
        }),
    [userOffers]
  )
  const acceptedUserOffers = useMemo(
    () =>
      userOffers
        .filter((offer) => offer.status === UserOfferStatusEnum.Accepted)
        .sort((a, b) => {
          return (
            new Date(b.acceptedAt || 0).getTime() -
            new Date(a.acceptedAt || 0).getTime()
          )
        }),
    [userOffers]
  )
  const redeemedUserOffers = useMemo(
    () =>
      userOffers
        .filter((offer) => offer.status === UserOfferStatusEnum.Redeemed)
        .sort((a, b) => {
          return (
            new Date(b.redeemedAt || 0).getTime() -
            new Date(a.redeemedAt || 0).getTime()
          )
        }),
    [userOffers]
  )

  const redeemableUserOffers = useMemo(
    () =>
      userOffers.filter(
        (offer) =>
          offer.status !== UserOfferStatusEnum.Redeemed &&
          (!offer.expiredAt || new Date(offer.expiredAt) > new Date())
      ),
    [userOffers]
  )

  const [runUserOfferAcknowledge] = useSafeMutation(
    ACKNOWLEDGE_USER_OFFER_MUTATION
  )

  const acknowledgeUserOffer = useCallback(
    (userOffer: Pick<UserOffer, "id">) => {
      return runUserOfferAcknowledge({
        variables: {
          input: {
            userOfferId: userOffer.id,
          },
        },
      })
    },
    [runUserOfferAcknowledge]
  )

  const { tiers } = useTiers()
  const getBestUserOffer = useCallback(
    (tierLevel: TierLevelEnum, tierInterval: string) => {
      const tier = tiers.find((t) => t.level === tierLevel)
      const unitAmount =
        tierInterval === TierIntervalEnum.Year
          ? tier?.yearlyStripePrice?.unitAmount
          : tier?.quarterlyStripePrice?.unitAmount
      const userOffersForTier = redeemableUserOffers.filter(
        (userOffer) =>
          userOffer.offer.tier.id === tier?.id &&
          userOffer.offer.tierInterval === tierInterval &&
          (!userOffer.expiredAt || new Date(userOffer.expiredAt) > new Date())
      )

      if (!unitAmount || !userOffersForTier.length) {
        return null
      }

      return userOffersForTier.sort((offerA, offerB) => {
        const amountA =
          unitAmount -
          (offerA.offer.stripeCoupon?.amountOff
            ? offerA.offer.stripeCoupon.amountOff
            : offerA.offer.stripeCoupon?.percentOff
            ? unitAmount * (offerA.offer.stripeCoupon.percentOff / 100)
            : 0)
        const amountB =
          unitAmount -
          (offerB.offer.stripeCoupon?.amountOff
            ? offerB.offer.stripeCoupon.amountOff
            : offerB.offer.stripeCoupon?.percentOff
            ? unitAmount * (offerB.offer.stripeCoupon.percentOff / 100)
            : 0)
        return amountA - amountB
      })[0]
    },
    [redeemableUserOffers, tiers]
  )

  const bestUserOffers = useMemo(() => {
    const bestUserOffers: (UserOffer_DisplayFragment | null)[] = []
    tiers.forEach((tier) => {
      if (tier.quarterlyStripePrice)
        bestUserOffers.push(
          getBestUserOffer(tier.level, TierIntervalEnum.Quarter)
        )
      if (tier.yearlyStripePrice)
        bestUserOffers.push(getBestUserOffer(tier.level, TierIntervalEnum.Year))
    })
    return bestUserOffers.filter(
      (userOffer) => userOffer !== null
    ) as UserOffer_DisplayFragment[]
  }, [getBestUserOffer, tiers])

  // InitialLoadRef prevents refetchs and polling from returning null
  if (loading && isInitialLoadRef.current) {
    return null
  } else if (isInitialLoadRef.current) {
    isInitialLoadRef.current = false
  }

  return (
    <UserOffersContext.Provider
      value={{
        acceptedUserOffers,
        acknowledgedUserOffers,
        acknowledgeUserOffer,
        bestUserOffers,
        getBestUserOffer,
        redeemableUserOffers,
        redeemedUserOffers,
        refetchUserOffers: refetch,
        unacknowledgedUserOffers,
        userOffers,
      }}
    >
      {children}
    </UserOffersContext.Provider>
  )
}

gql(`
  fragment UserOffer_Display on UserOffer {
    id
    status
    createdAt
    acknowledgedAt
    acceptedAt
    redeemedAt
    expiredAt

    offer {
      ...Offer_Display
    }
  }
`)

gql(`
  fragment Offer_Display on Offer {
    id
    name
    category
    translationNamespace
    tierInterval
    tier {
      id
      level
    }
    stripeCoupon {
      id
      amountOff
      percentOff
    }
    effects {
      id
      type
      priority
      params
    }
  }
`)

const USER_OFFERS_QUERY_DOCUMENT = gql(`
  query UserOffersQuery(
    $first: Int,
    $after: String,
    $statuses: [UserOfferStatusEnum!],
  ) {
    userOffers(
      first: $first,
      after: $after,
      statuses: $statuses,
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...UserOffer_Display
        }
      }
    }
  }
`)

const ACKNOWLEDGE_USER_OFFER_MUTATION = gql(`
  mutation UserOfferAcknowledge($input: UserOfferAcknowledgeInput!) {
    userOfferAcknowledge(input: $input) {
      userOffer {
        ...UserOffer_Display
      }
    }
  }
`)
