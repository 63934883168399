import { useCallback, useMemo } from "react"
import toast from "react-hot-toast"
import { Article } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { UPDATE_ARTICLE_MUTATION } from "./ArticleEditor"

export type ToggleExternalShareProps = {
  article: Pick<Article, "id" | "allowExternalShare">
}

export const useToggleExternalShare = ({
  article,
}: ToggleExternalShareProps) => {
  const [runToggleExternalShare] = useSafeMutation(UPDATE_ARTICLE_MUTATION)

  const isAllowingExternalShare = useMemo(
    () => article.allowExternalShare,
    [article]
  )

  const toggleExternalShare = useCallback(async () => {
    const { errors } = await runToggleExternalShare({
      variables: {
        input: {
          articleId: article.id,
          allowExternalShare: !isAllowingExternalShare,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      toast.success(
        isAllowingExternalShare
          ? "External sharing disabled"
          : "External sharing enabled"
      )
    }
  }, [article, runToggleExternalShare, isAllowingExternalShare])

  return toggleExternalShare
}
