import { BarChart } from "./BarChart"

import { subMonths, format } from "date-fns"

import { CardFeed } from "~/common/CardFeed"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"

import {
  USER_DATA_KEY,
  COMMUNITY_DATA_KEY,
  BENCHMARK_DATA_KEY,
} from "~/benchmarks/BenchmarkChart"

const generateCostPerEmployee = () => {
  return Math.floor(Math.random() * (170 - 85) + 85)
}

const generateTurnOverData = () => {
  return Math.floor(Math.random() * (15 - 1) + 1)
}

const generateTenure = () => {
  return Math.floor(Math.random() * (55 - 10) + 10)
}

const generateRetention = () => {
  return Math.floor(Math.random() * (100 - 85) + 85)
}

const turnoverData = {
  data: [...Array(10)].map((_, i) => {
    return {
      name: format(subMonths(new Date(), i), "MMM"),
      [USER_DATA_KEY]: generateTurnOverData(),
      [COMMUNITY_DATA_KEY]: generateTurnOverData(),
      [BENCHMARK_DATA_KEY]: 17.3,
    }
  }),
}

export const costPerEmployeeData = {
  data: [...Array(10)].map((_, i) => {
    return {
      name: format(subMonths(new Date(), i), "MMM"),
      [USER_DATA_KEY]: generateCostPerEmployee(),
      [COMMUNITY_DATA_KEY]: generateCostPerEmployee(),
    }
  }),
}

const tenureData = {
  data: [...Array(10)].map((_, i) => {
    return {
      name: format(subMonths(new Date(), i), "MMM"),
      [USER_DATA_KEY]: generateTenure(),
      [COMMUNITY_DATA_KEY]: generateTenure(),
      [BENCHMARK_DATA_KEY]: 49,
    }
  }),
}

export const retentionData = {
  data: [...Array(10)].map((_, i) => {
    return {
      name: format(subMonths(new Date(), i), "MMM"),
      [USER_DATA_KEY]: generateRetention(),
      [COMMUNITY_DATA_KEY]: generateRetention(),
      [BENCHMARK_DATA_KEY]: 95,
    }
  }),
}

export const EmptyState = () => {
  return (
    <CardFeed>
      <Card>
        <CardHeader>
          <CardTitle>Turnover</CardTitle>
        </CardHeader>
        <CardContent className="pt-10 pr-16 blur-sm">
          <BarChart chart={turnoverData} interactive={false} />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Cost Per Employee</CardTitle>
        </CardHeader>
        <CardContent className="pt-10 pr-16 blur-sm">
          <BarChart chart={costPerEmployeeData} interactive={false} />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Tenure (in months)</CardTitle>
        </CardHeader>
        <CardContent className="pt-10 pr-16 blur-sm">
          <BarChart chart={tenureData} interactive={false} />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Retention Rate</CardTitle>
        </CardHeader>
        <CardContent className="pt-10 pr-16 blur-sm">
          <BarChart chart={retentionData} interactive={false} />
        </CardContent>
      </Card>
    </CardFeed>
  )
}
