import { ChannelConnection_ComposerFragment } from "~/__generated__/graphql"
import Select from "react-select"
import clsx from "clsx"
import { gql } from "~/__generated__"

export const ChannelSelector = ({
  channels,
  channelId,
  setChannelId,
  disabled = false,
}: {
  channels: ChannelConnection_ComposerFragment
  channelId: string | null
  setChannelId: (channelId: string | null) => void
  disabled?: boolean
}) => {
  const options = channels.nodes.map((c) => ({ value: c.id, label: c.name }))
  const selectedOption =
    options.find((option) => option.value === channelId) || null

  return (
    <div
      className={clsx(
        "text-xs font-medium items-center justify-between px-6 tracking-wide border-y border-mercury",
        { "cursor-not-allowed": disabled }
      )}
    >
      <Select
        classNamePrefix="select"
        // defaultValue={colourOptions[0]}
        isDisabled={disabled}
        isClearable
        isSearchable
        name="channel"
        placeholder="Select channel..."
        options={options}
        value={selectedOption}
        onChange={(channel) => {
          setChannelId(channel?.value || null)
        }}
        styles={{
          control: (baseStyles, _state) => ({
            ...baseStyles,
            border: 0,
            outline: "none !important",
            boxShadow: "none",
            cursor: "pointer !important",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            fontSize: 14,
            fontWeight: 500,
          }),
          valueContainer: (base, _props) => ({
            ...base,
            padding: 0,
          }),
          indicatorSeparator: (_base, _props) => ({
            display: "none",
          }),
          menu: (baseStyles, _state) => ({
            ...baseStyles,
            zIndex: 100,
          }),
          option: (baseStyles, _state) => ({
            ...baseStyles,
            cursor: "pointer !important",
          }),
        }}
      />
    </div>
  )
}

gql(`
  fragment Selector_Channel on Channel {
    id
    name
    slug
    channelOldestUnreadPostTime
  }
`)

gql(`
  fragment ChannelConnection_Composer on ChannelConnection {
    nodes {
      ...Selector_Channel
    }
  }
`)
