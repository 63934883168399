import {
  ArticleTopActionToolbar,
  ArticleActionToolbarProps,
} from "~/articles/ArticleTopActionToolbar"
import { useCourseEditorContext } from "./CourseEditor"

export const CourseActionToolbar = ({
  isLocked,
}: Pick<ArticleActionToolbarProps, "isLocked">) => {
  const { article } = useCourseEditorContext()

  return (
    <ArticleTopActionToolbar
      article={article}
      isLocked={isLocked}
      options={{
        approve: true,
        submitForReview: true,
        requestEdits: true,
      }}
    />
  )
}
