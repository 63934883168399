import { CommunitySlug } from "~/__generated__/graphql"
import ArrowRight from "../images/icons/arrow-right.svg?react"
import { useCommunityClassname } from "~/community/useCommunity"

export const DiveInModule = () => {
  const ccls = useCommunityClassname()

  return (
    <div className="rounded-2xl border border-mercury bg-white">
      <div className="font-bold flex items-center justify-between px-6 py-4 tracking-wide border-mercury">
        Dive In
      </div>

      <div className="px-6 py-4 border-mercury">
        <a
          href="https://hateithere.co/articles/"
          target="_blank"
          rel="noreferrer"
          className="font-semibold flex items-center justify-between tracking-wide mb-4"
        >
          💻 &nbsp;Read I Hate it Here
          <ArrowRight
            className={ccls({
              [CommunitySlug.Boardroom]: "text-primary",
              default: "text-blueCharcoal",
            })}
          />
        </a>
        <a
          href="https://hateithere.co/podcast/"
          target="_blank"
          rel="noreferrer"
          className="font-semibold flex items-center justify-between tracking-wide"
        >
          🎧 &nbsp;Listen to I Hate it Here
          <ArrowRight
            className={ccls({
              [CommunitySlug.Boardroom]: "text-primary",
              default: "text-blueCharcoal",
            })}
          />
        </a>
      </div>
    </div>
  )
}
