import { XCircle } from "lucide-react"
import ReactPlayer from "react-player"

export const DialogVideoPlayer = ({
  setShowVideo,
  showVideo,
  url,
}: {
  setShowVideo: React.Dispatch<React.SetStateAction<boolean>>
  showVideo: boolean
  url: string
}) => {
  return (
    <div className="fixed left-[50%] top-[50%] z-50 w-[90vw] max-w-[1200px] translate-x-[-50%] translate-y-[-50%] p-4 shadow-lg">
      <div className="relative w-full aspect-video">
        <div className="absolute top-3 right-3 z-50">
          <XCircle
            className="h-5 w-5 cursor-pointer"
            onClick={() => {
              setShowVideo(false)
            }}
          />
        </div>

        <div className="top-0 absolute z-40">
          <ReactPlayer
            height={"100%"}
            playing={showVideo}
            width={"100%"}
            controls={true}
            url={url}
          />
        </div>
      </div>
    </div>
  )
}
