import { useCallback, useEffect } from "react"
import { Button, LinkButton } from "~/ui/button"
import contribute from "../images/contribute.png"
import { editArticlePath, myContentPath } from "~/common/paths"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  ArticleCollaboratorsModal,
  useCreateCollaborator,
  useDestroyCollaborator,
} from "./ArticleCollaboratorsModal"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useTranslation } from "react-i18next"
import { CreateContentModal } from "~/content/CreateContentModal"
import { Article_EditorContentFragment, Course } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { Card } from "~/ui/card"
import { ExternalShareSettingsModal } from "./ExternalShareSettingModal"

export const ContributeToCommunityCallout = ({
  includeMyContentLink = false,
  startWritingWithCollaborators = [],
  containerClassName,
}: {
  includeMyContentLink?: boolean
  startWritingWithCollaborators?: string[]
  containerClassName?: string
}) => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUser()

  const [collaboratorsModalOpen, setCollaboratorsModalOpen] = useState(false)
  const [collaboratorsModalArticle, setCollaboratorsModalArticle] =
    useState<Article_EditorContentFragment | null>(null)
  const [initialCollaboratorsProcessed, setInitialCollaboratorsProcessed] =
    useState<boolean>(false)
  const [isCreateContentModalOpen, setIsCreateContentModalOpen] =
    useState(false)
  const [externalSharingModalOpen, setExternalSharingModalOpen] =
    useState(false)

  const onCreateArticle = useCallback(
    (article: Article_EditorContentFragment) => {
      setIsCreateContentModalOpen(false)
      setCollaboratorsModalOpen(true)
      setCollaboratorsModalArticle(article)
    },
    []
  )
  const onCreateCourse = useCallback(
    (
      course: Pick<Course, "id"> & { article: Article_EditorContentFragment }
    ) => {
      setIsCreateContentModalOpen(false)
      setCollaboratorsModalOpen(true)
      setCollaboratorsModalArticle(course.article)
    },
    []
  )

  const { t } = useTranslation("library")

  const contentString = currentUser.admin ? "Manage Content" : "My Content"

  useEffect(() => {
    if (
      !initialCollaboratorsProcessed &&
      startWritingWithCollaborators.length > 0
    ) {
      setIsCreateContentModalOpen(true)
      setInitialCollaboratorsProcessed(true)
    }
  }, [initialCollaboratorsProcessed, startWritingWithCollaborators])

  const { createCollaborator } = useCreateCollaborator()
  const { destroyCollaborator } = useDestroyCollaborator()

  return (
    <>
      <Card
        className={cn(
          "mb-4 border border-mercury rounded-2xl px-4 py-3",
          containerClassName
        )}
      >
        <div className="hidden md:flex flex-row items-center text-left space-y-0">
          <img
            src={contribute}
            className="w-[76px] h-[82px] mt-1 mr-4"
            alt="community"
          />
          <div className="flex-1 mr-8">
            <div className="text-2xl mb-2 font-serif">
              {t("contributeBannerTitle")}
            </div>
            <div className="text-2xs">{t("contributeBannerCopy")}</div>
          </div>
          <div>
            <Button
              className="mr-4 text-xs"
              onClick={() => setIsCreateContentModalOpen(true)}
            >
              Start Writing!
            </Button>
            {includeMyContentLink && (
              <LinkButton
                variant="outline"
                className="text-xs"
                to={myContentPath.pattern}
              >
                {contentString}
              </LinkButton>
            )}
          </div>
        </div>

        <div className="md:hidden flex flex-col text-left">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col text-2xl font-serif">
              <div>Contribute</div>
              <div>to the (private)</div>
              <div className="mb-2">community library!</div>
            </div>
            <img
              src={contribute}
              className="w-[76px] h-[82px] mt-1 mr-4"
              alt="community"
            />
          </div>

          <div className="text-2xs tracking-wide mb-2">
            Share a guide or resource with your peers! And don't worry, what's
            shared here, stays here.
          </div>
          <div className="flex flex-row items-center justify-center">
            <Button
              className="mr-4 text-xs"
              onClick={() => setIsCreateContentModalOpen(true)}
            >
              Start Writing!
            </Button>
            {includeMyContentLink && (
              <LinkButton
                variant="outline"
                className="text-xs"
                to={myContentPath.pattern}
              >
                {contentString}
              </LinkButton>
            )}
          </div>
        </div>
      </Card>

      <CreateContentModal
        open={isCreateContentModalOpen}
        onOpenChange={setIsCreateContentModalOpen}
        onCreateArticle={onCreateArticle}
        onCreateCourse={onCreateCourse}
      />
      {collaboratorsModalOpen && collaboratorsModalArticle && (
        <ArticleCollaboratorsModal
          setIsOpen={setCollaboratorsModalOpen}
          includeCollaborators={startWritingWithCollaborators}
          articleId={collaboratorsModalArticle.id}
          collaborators={collaboratorsModalArticle.collaborators}
          onAddCollaborator={createCollaborator}
          onRemoveCollaborator={destroyCollaborator}
          onSubmit={() => setExternalSharingModalOpen(true)}
        />
      )}
      <ExternalShareSettingsModal
        isOpen={externalSharingModalOpen}
        setIsOpen={setExternalSharingModalOpen}
        articleId={collaboratorsModalArticle?.id || ""}
        allowExternalShare={
          collaboratorsModalArticle
            ? collaboratorsModalArticle.allowExternalShare
            : true
        }
        onSubmit={() => {
          if (collaboratorsModalArticle?.id) {
            navigate(
              editArticlePath({ articleId: collaboratorsModalArticle?.id })
            )
          }
        }}
      />
    </>
  )
}
