import { snakeCase } from "change-case"

export const cancellationReasons = [
  "CannotExpense",
  "CannotAfford",
  "TooExpensive",
  "NotUsingEnough",
  "Other",
] as const
export type CancellationReason = (typeof cancellationReasons)[number]
export const CancellationReasonEnum: { [key in CancellationReason]: string } =
  cancellationReasons.reduce((acc, current) => {
    acc[current] = snakeCase(current)
    return acc
  }, {} as any)
