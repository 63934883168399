import { ChevronDown } from "lucide-react"
import { useManualHrisDataContext } from "~/merge/manualData"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { Base } from "./Base"
import { IndustryEnum } from "~/__generated__/graphql"
import { useTranslation } from "react-i18next"

const SelectIcon = () => {
  return <ChevronDown className="h-4 w-4" />
}

export const ManualIndustry = ({
  editContext = false,
  closeDialog,
}: {
  editContext?: boolean
  closeDialog?: () => void
}) => {
  const { t: industries } = useTranslation("industries")
  const { industry, setIndustry, onSuccessCallback } =
    useManualHrisDataContext()

  return (
    <Base
      header="Industry"
      title="What is your company's Industry"
      submit={() => {
        onSuccessCallback()
        closeDialog && closeDialog()
      }}
      editContext={editContext}
      disabled={industry === undefined}
    >
      <div className="text-xs font-medium tracking-wide text-textLight mb-1">
        Industry
        <span className="text-red-400">*</span>
      </div>
      <div className="mb-6">
        <Select
          onValueChange={(value: IndustryEnum) => setIndustry(value)}
          defaultValue={industry}
        >
          <SelectTrigger Icon={SelectIcon} className="focus:ring-offset-0">
            <SelectValue>
              {industry ? industries(industry) : "Select Industry"}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {Object.values(IndustryEnum).map((option) => (
                <SelectItem key={option} value={option}>
                  {industries(option)}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </Base>
  )
}
