import { useCallback } from "react"
import toast from "react-hot-toast"
import { Article } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"
import { ARCHIVE_ARTICLE_MUTATION } from "./ArticleEditor"

export type ArchiveArticleConfirmProps = {
  article: Pick<Article, "id" | "state">
} & Partial<ConfirmContextProviderProps>

export const useArchiveArticleConfirm = ({
  article,
  ...confirmProps
}: ArchiveArticleConfirmProps) => {
  const showConfirm = useConfirm()
  const [runArticleArchive] = useSafeMutation(ARCHIVE_ARTICLE_MUTATION)

  const feature = useCallback(() => {
    showConfirm({
      title: "Archive Content",
      body: "Are you sure you want to archive this content? This action cannot be undone.",
      confirmText: "Archive",
      onConfirm: async () => {
        const { errors } = await runArticleArchive({
          variables: {
            input: {
              articleId: article.id,
            },
          },
        })

        if (errors) {
          displayErrors(errors)
          console.log(errors)
        } else {
          toast.success("Content archived")
        }
      },
      ...confirmProps,
    })
  }, [article, confirmProps, showConfirm, runArticleArchive])

  return feature
}
