import { VariantProps, cva } from "class-variance-authority"
import {
  TagConnection_FilterFragment,
  AhoyEventTypeEnum,
} from "~/__generated__/graphql"
import Select from "react-select"
import clsx from "clsx"
import { gql } from "~/__generated__"
import { useLogEvent } from "~/analytics/EventsContext"

const tagFilterVariants = cva("", {
  variants: {
    variant: {
      default: "text-xs tracking-wide px-4 py-1 rounded-2xl",
      directory: "mt-4 p-1 rounded-lg text-2xs tracking-[0.5px]",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

const placeholderVariants = cva("!text-placeholder", {
  variants: {
    variant: {
      default: "[font-weight:initial]",
      directory: "!px-2",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

const controlVariants = cva("", {
  variants: {
    variant: {
      default: "",
      directory: "!min-h-[28px]",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

const valueContainerVariants = cva("", {
  variants: {
    variant: {
      default: "!py-0",
      directory: "!p-0",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

export const TagFilter = ({
  allTags,
  selectedTagIds,
  setTagIds,
  variant,
  title = "Filter by Topic:",
  placeholder = "Search for topics...",
}: {
  allTags: TagConnection_FilterFragment
  selectedTagIds: string[]
  setTagIds: (tagId: string[]) => void
  title?: string
  placeholder?: string
} & VariantProps<typeof tagFilterVariants>) => {
  const options = allTags.nodes.map((t) => ({ value: t.id, label: t.name }))
  const selectedOptions =
    options.filter((option) => selectedTagIds.includes(option.value)) || []

  const { logEvent } = useLogEvent()

  return (
    <div
      className={clsx(
        tagFilterVariants({ variant }),
        "flex border border-mercury bg-white font-medium items-center"
      )}
    >
      {title.length > 0 && title}
      <Select
        classNamePrefix="tag-filter"
        className="flex-1"
        // defaultValue={colourOptions[0]}
        isClearable
        isSearchable
        isMulti
        name="tag"
        placeholder={placeholder}
        options={options}
        value={selectedOptions}
        onChange={(tags) => {
          const tagIds = tags.map((t) => t.value)
          const tagNames = tags.map((t) => t.label)

          if (tagNames.length === 0) {
            logEvent(AhoyEventTypeEnum.TopicFilterCleared)
          } else {
            logEvent(AhoyEventTypeEnum.TopicFilterApplied, {
              topics: tagNames.join(", "),
            })
          }

          setTagIds(tagIds)
        }}
        classNames={{
          control: () =>
            clsx(
              controlVariants({ variant }),
              "!border-0 ![outline-none] ![box-shadow:none] !cursor-pointer py-0"
            ),
          placeholder: () => placeholderVariants({ variant }),
          valueContainer: () => valueContainerVariants({ variant }),
        }}
        styles={{
          multiValue: (baseStyles, _props) => ({
            ...baseStyles,
            backgroundColor: `hsl(var(--primary))`,
            color: "white",
            borderRadius: "1000px",
            alignItems: "center",
            paddingRight: "6px",
            paddingLeft: "4px",
          }),
          multiValueLabel: (baseStyles, _props) => ({
            ...baseStyles,
            color: "white",
          }),
          multiValueRemove: (baseStyles, _props) => ({
            ...baseStyles,
            color: "white",
            borderRadius: "1000px",
            border: "1px solid white",
            height: "16px",
            width: "16px",
            marginLeft: "4px",
            padding: 0,
            justifyContent: "center",
            ":hover": {
              backgroundColor: "white",
              color: "red",
            },
          }),
          input: (baseStyles, _props) => ({
            ...baseStyles,
            // margin: 0,
          }),
          indicatorsContainer: (baseStyles, _state) => ({
            ...baseStyles,
          }),
          clearIndicator: (baseStyles, _state) => ({
            ...baseStyles,
            padding: 4,
          }),
          indicatorSeparator: (_base, _props) => ({
            display: "none",
          }),
          dropdownIndicator: () => ({
            display: "none",
          }),
          menu: (baseStyles, _state) => ({
            ...baseStyles,
            zIndex: 100,
          }),
          option: (baseStyles, _state) => ({
            ...baseStyles,
            cursor: "pointer !important",
          }),
        }}
      />
    </div>
  )
}

gql(`
  fragment TagConnection_Filter on TagConnection {
    nodes {
      id
      name
    }
  }
`)
