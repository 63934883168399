import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/lib/utils"
import { Switch } from "~/ui/switch"

export const ToggleField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  required,
  label,
  labelClassName,
  inputClassName,
  containerClassName,
}: {
  label: string
  labelClassName?: string
  inputClassName?: string
  containerClassName?: string
  description?: string
  required?: boolean
  control: Control<TFieldValues> | undefined
  name: TName
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem variant="radio" className={cn("", containerClassName)}>
          <FormLabel className={labelClassName}>
            {label}
            {required && <span className="text-red-500 pl-1">*</span>}
          </FormLabel>
          <FormControl>
            <Switch
              checked={field.value}
              onCheckedChange={(val) => {
                field.onChange(val)
              }}
              className={inputClassName}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
