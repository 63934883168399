import { gql } from "~/__generated__"

export const USER_UPDATE_MUTATION = gql(`
  mutation UserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...User_CurrentUser
      }
    }
  }
`)

export const MARK_READ_MUTATION = gql(`
  mutation MarkRead($input: MarkReadInput!) {
    markRead(input: $input) {
      unviewedIds
      currentUser {
        id
        unreadNotificationCount
        unreadChannelPostsCount
        unviewedLibraryCount
        unviewedDirectoryCount
        unviewedEventsCount
      }
      notification {
        id
        unread
      }
      channels {
        id
        unreadFeedPostCount
        channelOldestUnreadPostTime
      }
    }
  }
`)
