import { useEffect } from "react"
import { getMetaVar } from "~/common/getMetaVar"
import { Base64 } from "js-base64"
import toast from "react-hot-toast"

type FlashMessage = [string, string]

let ranOnce = false

export const FlashMessageToasts = () => {
  useEffect(() => {
    if (ranOnce) {
      return
    } else {
      ranOnce = true
    }

    const encodedMessages = getMetaVar("flash-messages")
    const decodedMessages = Base64.decode(encodedMessages)
    const messages: FlashMessage[] = JSON.parse(decodedMessages)

    messages.forEach(([type, message]) => {
      if (type === "error" || type === "alert") {
        toast.error(message)
      } else {
        toast(message)
      }
    })
  }, [])

  return null
}
