import { Article_LiveContentFragment } from "~/__generated__/graphql"
import { useBookmarks } from "~/post-composer/useBookmarks"
import { IconButton } from "~/ui/IconButton"
import { Bookmark } from "lucide-react"

export const BookmarkIcon = ({
  article,
  onBookmarkToggle,
}: {
  article: Article_LiveContentFragment
  onBookmarkToggle: (bookmarkExists: boolean) => void
}) => {
  const { bookmark, toggleBookmark } = useBookmarks({ article: article })

  return (
    <IconButton
      className="hover:text-black text-foreground"
      aria-label="Bookmark article"
      onClick={async () => {
        const bookmarkExists = await toggleBookmark()
        if (onBookmarkToggle) {
          onBookmarkToggle(bookmarkExists)
        }
      }}
    >
      <Bookmark fill={bookmark ? "currentColor" : "none"} size={14} />
    </IconButton>
  )
}
