import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { CommunitySlug, TierLevelEnum } from "~/__generated__/graphql"
import boardroomLogoNavLeft from "~/images/boardroom-logo-nav-left@3x.png"
import safespaceLogoCard from "~/images/safespace-logo-card.png"
import marketinglandLogoCard from "~/images/marketingland-onboarding-logo@3x.png"
import Medal from "~/images/icons/medal.svg?react"
import { cn } from "~/lib/utils"

const TIERS: Record<TierLevelEnum, string> = {
  [TierLevelEnum.Free]: "Free Member",
  [TierLevelEnum.Plus]: "Plus Member",
  [TierLevelEnum.Pro]: "Pro Member",
}

const tierDisplay = (tier?: TierLevelEnum) => TIERS[tier || TierLevelEnum.Free]

type ActivationStatus = "Not Activated" | "Activating" | "Activated"

export const MembershipCard = ({
  containerClassName,
}: {
  containerClassName?: string
}) => {
  const { currentUser } = useCurrentUser()
  const community = useCommunity()
  const name = currentUser.name || "New Member"

  const ccls = useCommunityClassname()

  const logoMap: Record<
    CommunitySlug,
    { src: string; width?: number; height: number }
  > = {
    BOARDROOM: { src: boardroomLogoNavLeft, height: 48 },
    SAFESPACE: { src: safespaceLogoCard, height: 26 },
    MARKETINGLAND: { src: marketinglandLogoCard, height: 48 },
  }
  const logo = logoMap[community.slug]

  let status: ActivationStatus = "Not Activated"
  if (currentUser.fit) {
    status = "Activated"
  } else if (currentUser.fitWaiting) {
    status = "Activating"
  }

  return (
    <div
      className={cn(
        "p-6 rounded-xl w-full md:w-[415px] aspect-video flex-shrink-0 flex flex-col justify-between",
        ccls({
          [CommunitySlug.Safespace]: "safespace-membership-card-bg text-white",
          [CommunitySlug.Boardroom]: "boardroom-membership-card-bg text-white",
          [CommunitySlug.Marketingland]:
            "marketingland-membership-card-bg text-white",
        }),
        containerClassName
      )}
    >
      <div className="flex items-center justify-between">
        <img
          src={logo.src}
          style={{ height: logo.height, width: logo.width }}
          alt=""
        />
        <div
          className={cn(
            "px-3 py-2 text-black text-2xs rounded tracking-wide text-white uppercase",
            ccls({
              [CommunitySlug.Safespace]: "bg-subtlePink text-black",
              default: "bg-highlight",
            }),
            status === "Activating" && "bg-[#FFD829] text-black",
            status === "Activated" && "bg-seaGreen text-black"
          )}
        >
          {status}
        </div>
      </div>
      <div className="text-2xl">5892 7629 1003 2241</div>
      <div className="flex items-center justify-between">
        <div className="text-lg">{name}</div>
        <div className="flex items-center gap-1 text-2xs mt-1">
          <Medal
            className={cn(
              "mt-[2px]",
              ccls({
                [CommunitySlug.Safespace]: "text-primary",
                default: "text-highlight",
              })
            )}
          />
          {tierDisplay(currentUser.tier?.level)}
        </div>
      </div>
    </div>
  )
}
