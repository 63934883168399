import { ReactNode } from "react"
import {
  ArticleContentTypeEnum,
  Article_LiveContentFragment,
} from "~/__generated__/graphql"
import { CourseLessonLayout } from "./CourseLessonLayout"

export const ArticleScreenLayout = ({
  article,
  children,
}: {
  article: Article_LiveContentFragment
  children: ReactNode
}) =>
  article.approvedRevision?.contentType === ArticleContentTypeEnum.Lesson &&
  article.approvedLesson?.section.course ? (
    <CourseLessonLayout
      course={article.approvedLesson.section.course}
      articleId={article.id}
    >
      {children}
    </CourseLessonLayout>
  ) : (
    children
  )
