import { gql } from "~/__generated__"

gql(`
  fragment IntroductionCycle_Admin on IntroductionCycle {
    id
    name
    sendAt
    createdAt
    locked
  }
`)

gql(`
  fragment IntroductionMatch_Admin on IntroductionMatch {
    id
    matchValue
    accepted
    ignored
    user {
      ...IntroductionMatchUser_Admin
    }
    matchedWithUser {
      ...IntroductionMatchUser_Admin
    }
  }
`)

gql(`
  fragment IntroductionMatchUser_Admin on User {
    id
    name
    email
    creator
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_LIST_QUERY_DOCUMENT = gql(`
  query AdminIntroductionCyclesList($after: String) {
    introductionCycles(first: 20, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...IntroductionCycle_Admin
        }
      }
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_COUNT_QUERY_DOCUMENT = gql(`
  query AdminIntroductionCyclesCount {
    introductionCycles {
      totalCount
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_FIND_QUERY_DOCUMENT = gql(`
  query AdminIntroductionCycleFind($introductionCycleId: ID!) {
    introductionCycle(introductionCycleId: $introductionCycleId) {
      ...IntroductionCycle_Admin
      matches {
        ...IntroductionMatch_Admin
      }
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_POSSIBLE_MATCHES_QUERY_DOCUMENT = gql(`
  query IntroductionCyclePossibleMatches($introductionMatchId: ID!) {
    introductionMatchPossibleMatches(introductionMatchId: $introductionMatchId) {
      ...IntroductionMatchUser_Admin
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_CREATE_MUTATION = gql(`
  mutation IntroductionCycleAdminCreate($input: IntroductionCycleCreateInput!) {
    introductionCycleCreate(input: $input) {
      introductionCycle {
        ...IntroductionCycle_Admin
      }
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_UPDATE_MUTATION = gql(`
  mutation IntroductionCycleAdminUpdate($input: IntroductionCycleUpdateInput!) {
    introductionCycleUpdate(input: $input) {
      introductionCycle {
        ...IntroductionCycle_Admin
      }
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_SHUFFLE_MUTATION = gql(`
  mutation IntroductionCycleAdminShuffle($input: IntroductionCycleShuffleInput!) {
    introductionCycleShuffle(input: $input) {
      introductionCycle {
        ...IntroductionCycle_Admin
        matches {
          ...IntroductionMatch_Admin
        }
      }
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_TOGGLE_ACCEPTANCE_MUTATION = gql(`
  mutation IntroductionMatchToggleAcceptance($input: IntroductionMatchToggleAcceptanceInput!) {
    introductionMatchToggleAcceptance(input: $input) {
      introductionCycle {
        ...IntroductionCycle_Admin
        matches {
          ...IntroductionMatch_Admin
        }
      }
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_TOGGLE_IGNORANCE_MUTATION = gql(`
  mutation IntroductionMatchToggleIgnorance($input: IntroductionMatchToggleIgnoranceInput!) {
    introductionMatchToggleIgnorance(input: $input) {
      introductionCycle {
        ...IntroductionCycle_Admin
        matches {
          ...IntroductionMatch_Admin
        }
      }
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_OVERRIDE_MATCH_MUTATION = gql(`
  mutation IntroductionMatchOverride($input: IntroductionMatchOverrideInput!) {
    introductionMatchOverride(input: $input) {
      introductionCycle {
        ...IntroductionCycle_Admin
        matches {
          ...IntroductionMatch_Admin
        }
      }
    }
  }
`)

export const INTRODUCTION_CYCLES_ADMIN_ACCEPT_SELECTED_MUTATION = gql(`
  mutation IntroductionMatchAcceptSelected($input: IntroductionMatchAcceptSelectedInput!) {
    introductionMatchAcceptSelected(input: $input) {
      introductionCycle {
        ...IntroductionCycle_Admin
        matches {
          ...IntroductionMatch_Admin
        }
      }
    }
  }
`)
