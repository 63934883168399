import NestedList from "@editorjs/nested-list"
import { IconListBulleted, IconListNumbered } from "@codexteam/icons"

export class WorkweekListTool extends NestedList {
  static get toolbox() {
    return {
      icon: IconListBulleted,
      title: "List",
    }
  }

  renderSettings() {
    const tunes = [
      {
        name: "unordered",
        label: this.api.i18n.t("Unordered"),
        icon: IconListBulleted,
      },
      {
        name: "ordered",
        label: this.api.i18n.t("Ordered"),
        icon: IconListNumbered,
      },
    ]

    return tunes.map((tune) => ({
      name: tune.name,
      icon: tune.icon,
      label: tune.label,
      isActive: this.data.style === tune.name,
      closeOnActivate: true,
      onActivate: () => {
        this.data = {
          ...this.data,
          style: tune.name,
        }
        this.listStyle = tune.name
      },
    }))
  }
}
