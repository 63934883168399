import {
  CommunitySlug,
  TagConnection_ComposerFragment,
} from "~/__generated__/graphql"
import Select from "react-select"
import clsx from "clsx"
import { gql } from "~/__generated__"
import Tag from "../images/icons/tag.svg?react"
import { useCommunityClassname } from "~/community/useCommunity"

export const TagSelector = ({
  tags,
  tagId,
  setTagId,
  disabled = false,
}: {
  tags: TagConnection_ComposerFragment
  tagId: string | null
  setTagId: (tagId: string | null) => void
  disabled?: boolean
}) => {
  const ccls = useCommunityClassname()
  const options = tags.nodes.map((t) => ({ value: t.id, label: t.name }))
  const selectedOption =
    options.find((option) => option.value === tagId) || null

  return (
    <div
      className={clsx(
        "flex text-xs font-medium items-center tracking-wide mt-3",
        {
          "cursor-not-allowed": disabled,
        }
      )}
    >
      <Tag />
      <Select
        classNamePrefix="select"
        className="flex-1"
        // defaultValue={colourOptions[0]}
        isDisabled={disabled}
        isClearable
        isSearchable
        name="tag"
        placeholder="Add Topics"
        options={options}
        value={selectedOption}
        onChange={(tag) => {
          setTagId(tag?.value || null)
        }}
        styles={{
          placeholder: (baseStyles, _state) => ({
            ...baseStyles,
            color: "#999999",
          }),
          control: (baseStyles, _state) => ({
            ...baseStyles,
            justifyContent: "initial",
            border: 0,
            outline: "none !important",
            boxShadow: "none",
            cursor: "pointer !important",
            paddingTop: 0,
            paddingBottom: 0,
            fontSize: 12,
            fontWeight: 500,
            minHeight: 0,
          }),
          valueContainer: (baseStyles, _props) => ({
            ...baseStyles,
            flex: "initial",
            paddingTop: 0,
            paddingBottom: 0,
          }),
          singleValue: (baseStyles, _props) => ({
            ...baseStyles,
            color: "white",
            backgroundColor: ccls({
              [CommunitySlug.Safespace]: "#F14C76",
              default: `hsl(var(--highlight))`,
            }),
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: "11px",
            lineHeight: 1,
            borderRadius: 4,
          }),
          input: (baseStyles, _props) => ({
            ...baseStyles,
            margin: 0,
          }),
          indicatorsContainer: (baseStyles, _state) => ({
            ...baseStyles,
          }),
          clearIndicator: (baseStyles, _state) => ({
            ...baseStyles,
            padding: 0,
          }),
          indicatorSeparator: (_base, _props) => ({
            display: "none",
          }),
          dropdownIndicator: () => ({
            display: "none",
          }),
          menu: (baseStyles, _state) => ({
            ...baseStyles,
            zIndex: 100,
          }),
          option: (baseStyles, _state) => ({
            ...baseStyles,
            cursor: "pointer !important",
          }),
        }}
      />
    </div>
  )
}

gql(`
  fragment TagConnection_Composer on TagConnection {
    nodes {
      id
      name
    }
  }
`)
