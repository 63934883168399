import { gql } from "~/__generated__"

export const CELEBRATION_UPDATE_MUTATION = gql(`
  mutation CelebrationUpdate($input: CelebrationUpdateInput!) {
    subject: celebrationUpdate(input: $input) {
      currentUser {
        __typename
        id

        celebrations {
          id
          celebrationType
          customCelebrationType
          date
        }
      }
    }
  }
`)
