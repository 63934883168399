import { ProEventForm } from "~/pro-events/ProEventForm"
import { useNavigate } from "react-router-dom"
import { adminProEventsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { UseFormSetError } from "react-hook-form"
import {
  PRO_EVENT_ADMIN_UPDATE_MUTATION,
  PRO_EVENT_ADMIN_QUERY_DOCUMENT,
  utcDateToIntroductionsDateField,
  parseDateField,
} from "~/pro-events/pro-events"
import { ProEventFormValues } from "~/pro-events/ProEventForm"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { Error } from "~/ui/Error"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { CalendarDays } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { Card, CardContent } from "~/ui/card"

export const AdminProEventEditScreen = () => {
  const { proEventId } = useParams()
  invariant(proEventId)
  const navigate = useNavigate()

  const { data, loading } = useQuery(PRO_EVENT_ADMIN_QUERY_DOCUMENT, {
    variables: {
      proEventId: proEventId,
    },
  })

  const [proEventUpdate, proEventUpdateResult] = useSafeMutation(
    PRO_EVENT_ADMIN_UPDATE_MUTATION
  )

  const onSubmit = async (
    values: ProEventFormValues,
    setError: UseFormSetError<ProEventFormValues>
  ) => {
    const { errors } = await proEventUpdate({
      variables: {
        input: {
          proEventId: proEventId,
          title: values.title,
          description: values.description,
          date: parseDateField(values.date).toISOString(),
          hostId: values.hostId,
          ctaCopy: values.ctaCopy,
          ctaUrl: values.ctaUrl,
          published: values.published,
        },
      },
    })

    if (errors) {
      displayErrors(errors, setError)
      console.log(errors)
    } else {
      toast.success("Updated pro event")
      navigate(adminProEventsPath.pattern)
    }
  }

  return (
    <>
      <AdminHeader
        title="Events"
        subtitle={`Edit Event: ${data?.proEvent?.title}`}
        Icon={CalendarDays}
      />
      {loading ? (
        <LoadingIndicator />
      ) : data?.proEvent ? (
        <Card>
          <CardContent className="pt-4">
            <ProEventForm
              defaultValues={{
                title: data.proEvent.title,
                description: data.proEvent.description,
                date: utcDateToIntroductionsDateField(data.proEvent.date),
                hostId: data.proEvent.host.id,
                ctaCopy: data.proEvent.ctaCopy,
                ctaUrl: data.proEvent.ctaUrl || "",
                published: data.proEvent.published,
              }}
              onSubmit={onSubmit}
              loading={proEventUpdateResult.loading}
              host={{
                value: data.proEvent.host.id,
                label: data.proEvent.host.name || "Unknown",
              }}
            />
          </CardContent>
        </Card>
      ) : (
        <Error message="Error loading data." />
      )}
    </>
  )
}
