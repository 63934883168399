import { sortOptions } from "~/common/sortOptions"
export const HRIS_BACKENDS = [
  {
    value: "gusto",
    label: "Gusto",
  },
  {
    value: "rippling",
    label: "Rippling",
  },
  {
    value: "adp",
    label: "ADP",
  },
  {
    value: "bamboo_hr",
    label: "BambooHR",
  },
  {
    value: "hi_bob",
    label: "HiBob",
  },
  {
    value: "zenefits",
    label: "Zenefits",
  },
  {
    value: "workday",
    label: "Workday",
  },
  {
    value: "seven_shifts",
    label: "7shifts",
  },
  {
    value: "adp_force_now",
    label: "ADP Workforce Now",
  },
  {
    value: "alexis_hr",
    label: "AlexisHR",
  },
  {
    value: "alliance_hcm",
    label: "AllianceHCM",
  },
  {
    value: "altera_payroll",
    label: "Altera Payroll",
  },
  {
    value: "breathe",
    label: "Breathe",
  },
  {
    value: "ceridian_dayforce",
    label: "Ceridian Dayforce",
  },
  {
    value: "charlie",
    label: "Charlie",
  },
  {
    value: "chart_hop",
    label: "Chart Hop",
  },
  {
    value: "clay_hr",
    label: "ClayHR",
  },
  {
    value: "cyber_ark",
    label: "CyberArk",
  },
  {
    value: "deel",
    label: "Deel",
  },
  {
    value: "employment_hero",
    label: "Employment Hero",
  },
  {
    value: "factorial",
    label: "Factorial",
  },
  {
    value: "freshteam",
    label: "Freshteam",
  },
  {
    value: "google_workspace",
    label: "Google Workspace",
  },
  {
    value: "hr_cloud",
    label: "HR Cloud",
  },
  {
    value: "hr_partner",
    label: "HR Partner",
  },
  {
    value: "humaans",
    label: "humaans",
  },
  {
    value: "humi",
    label: "Humi",
  },
  {
    value: "insperity_premier",
    label: "Insperity Premier",
  },
  {
    value: "intelli_hr",
    label: "IntelliHR",
  },
  {
    value: "iris_cascade",
    label: "IRIS Cascade",
  },
  {
    value: "jump_cloud",
    label: "Jump Cloud",
  },
  {
    value: "justworks",
    label: "Justworks",
  },
  {
    value: "kallidus",
    label: "Kallidus",
  },
  {
    value: "keka",
    label: "Keka",
  },
  {
    value: "kenjo",
    label: "Kenjo",
  },
  {
    value: "lano",
    label: "Lano",
  },
  {
    value: "lucca",
    label: "Lucca",
  },
  {
    value: "microsoft_entra_id",
    label: "Microsoft Entra ID",
  },
  {
    value: "namely",
    label: "Namely",
  },
  {
    value: "nmbrs",
    label: "Nmbrs",
  },
  {
    value: "officient",
    label: "Officient",
  },
  {
    value: "okta",
    label: "Okta",
  },
  {
    value: "one_login",
    label: "OneLogin",
  },
  {
    value: "oyster_hr",
    label: "OysterHR",
  },
  {
    value: "pay_captain",
    label: "PayCaptain",
  },
  {
    value: "paychex",
    label: "Paychex",
  },
  {
    value: "paycor",
    label: "Paycor",
  },
  {
    value: "pay_fit",
    label: "PayFit",
  },
  {
    value: "paylocity",
    label: "Paylocity",
  },
  {
    value: "people_hr",
    label: "PeopleHR",
  },
  {
    value: "personio",
    label: "Personio",
  },
  {
    value: "ping_one",
    label: "PingOne",
  },
  {
    value: "proliant",
    label: "Proliant",
  },
  {
    value: "sage_hr",
    label: "Sage HR",
  },
  {
    value: "sap_success_factors",
    label: "SAP SuccessFactors",
  },
  {
    value: "sesame",
    label: "Sesame",
  },
  {
    value: "square_payroll",
    label: "Square Payroll",
  },
  {
    value: "tri_net",
    label: "TriNet",
  },
  {
    value: "ukg_pro",
    label: "UKG Pro",
  },
  {
    value: "ukg_pro_workforce_management",
    label: "UKG Pro Workforce management",
  },
  {
    value: "ukg_ready",
    label: "UKG Ready",
  },
  {
    value: "zoho_people",
    label: "Zoho People",
  },
  {
    value: "other",
    label: "Other",
  },
].sort(sortOptions)
