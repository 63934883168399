import { camelCase, capitalCase } from "change-case"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import {
  ApplicationFieldEnum,
  FitProfile,
  User_AdminFragment,
} from "~/__generated__/graphql"
import { useCommunity } from "~/community/useCommunity"
import { getURL, isLink } from "~/components/FormattedContent"

export const FitProfileDisplay = ({ user }: { user: User_AdminFragment }) => {
  const community = useCommunity()
  const fitProfile: Pick<
    FitProfile,
    "industry" | "jobFunction" | "vertical" | "linkedinProfileUrl"
  > | null = user.fitProfile || null

  const { t: translateIndustry } = useTranslation("industries")
  const { t: translateJobFunction } = useTranslation("job_functions")
  const { t: translateVertical } = useTranslation("verticals")

  const display = useCallback(
    (field: ApplicationFieldEnum, value?: string | null) => {
      if (!value) return "N/A"
      switch (field) {
        case ApplicationFieldEnum.Industry:
          return translateIndustry(value)
        case ApplicationFieldEnum.JobFunction:
          return translateJobFunction(value)
        case ApplicationFieldEnum.Vertical:
          return translateVertical(value)
        default:
          return value
      }
    },
    [translateIndustry, translateJobFunction, translateVertical]
  )

  return (
    <div className="text-2xs">
      {community.applicationFields.map((field) => {
        const key = camelCase(field) as keyof typeof fitProfile
        const value = fitProfile ? fitProfile[key] : null
        return (
          <div key={field}>
            {capitalCase(field)}:{" "}
            {field === ApplicationFieldEnum.LinkedinProfileUrl &&
            value &&
            isLink(value) ? (
              <a
                className="underline"
                href={getURL(value)?.toString() || "#"}
                target="_blank"
                rel="nofollow noreferrer"
              >
                {display(field, value)}
              </a>
            ) : (
              <strong>{display(field, value)}</strong>
            )}
          </div>
        )
      })}
    </div>
  )
}
