import { useQuery } from "@apollo/client"
import { format, parseISO } from "date-fns"
import { ReactNode, useState } from "react"
import { gql } from "~/__generated__"
import { UserName } from "~/directory/UserName"
import { Button } from "~/ui/button"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "~/ui/drawer"

export const RevisionHistoryDrawer = ({
  articleId,
  children,
  defaultOpen = false,
}: {
  articleId: string
  children: ReactNode
  defaultOpen?: boolean
}) => {
  const [open, setOpen] = useState(defaultOpen)
  const { data } = useQuery(REVISION_LOGS_QUERY_DOCUMENT, {
    variables: { articleId },
  })

  return (
    <Drawer
      direction="right"
      open={open}
      onOpenChange={(open) => setOpen(open)}
    >
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerContent className="bg-white flex flex-col h-full w-[400px] fixed bottom-0 right-0">
        <DrawerHeader>
          <DrawerTitle>Revision History</DrawerTitle>
        </DrawerHeader>
        <div className="px-4 text-xs flex flex-col gap-4">
          {(!data?.articleRevisionLogs.nodes ||
            data.articleRevisionLogs.nodes.length === 0) && (
            <div>No history yet!</div>
          )}
          {data?.articleRevisionLogs.nodes.map((revision) => (
            <div key={revision.id}>
              <div className="font-semibold">{revision.logType}</div>
              <div className="text-gray-600">
                <UserName user={revision.user} /> @{" "}
                {format(parseISO(revision.createdAt), "LLL d, y - h:mmaaa")}
              </div>
              {revision.message && <div>{revision.message}</div>}
            </div>
          ))}
        </div>
        <DrawerFooter>
          <DrawerClose asChild>
            <Button variant="outline">Close</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

const REVISION_LOGS_QUERY_DOCUMENT = gql(`
  query RevisionLogs($articleId: ID!) {
    articleRevisionLogs(articleId: $articleId) {
      nodes {
        id
        logType
        user {
          ...User_Avatar
        }
        message
        createdAt
      }
    }
  }
`)
