import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

export const useQaFitHelper = () => {
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)

  const qaMarkFit = async ({ fit }: { fit: boolean }) => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          fit,
        },
      },
    })
    if (errors) {
      displayErrors(errors)
    }
  }

  return { qaMarkFit }
}
