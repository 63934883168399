export const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className="w-full rounded-full border border-borderColor bg-white h-[20px] p-[2px]">
      <div
        className="bg-onboarding-primary h-full rounded-full"
        style={{ width: progress + "%" }}
      ></div>
    </div>
  )
}
