import { Post_PreviewFragment } from "~/__generated__/graphql"
import { IMAGE_MIME_TYPES } from "~/post-composer/useFileManager"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

const isImageType = (contentType: string) =>
  IMAGE_MIME_TYPES.includes(contentType)

export const filterPostAttachments = (post: Post_PreviewFragment) => {
  return [
    post.attachments.filter((a) => isImageType(a.contentType)),
    post.attachments.filter((a) => !isImageType(a.contentType)),
  ]
}

export const usePostPinning = () => {
  const { currentUser } = useCurrentUser()
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const { logEvent } = useLogEvent()

  return async (postId: string) => {
    const unpin = currentUser.pinnedPostId === postId
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          pinnedPostId: unpin ? null : postId,
        },
      },
    })

    if (errors) {
      toast.error(`Error ${unpin ? "unpinning" : "pinning"} post.`)
    } else {
      toast.success(`Post ${unpin ? "unpinned from" : "pinned to"} profile.`)

      if (unpin) {
        logEvent(AhoyEventTypeEnum.PostUnpinned, {
          post_id: postId,
        })
      } else {
        logEvent(AhoyEventTypeEnum.PostPinned, {
          post_id: postId,
        })
      }
    }
  }
}
