import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

const CONFIRMATION_MESSAGE =
  "By flagging this post, you will be unable to see the post and an administrator will be notified to review it. Are you sure you want to flag this post?"

export const usePostFlagging = ({ postId }: { postId: string }) => {
  const [runPostFlag] = useSafeMutation(POST_FLAG_MUTATION)

  const flagPostWithConfirmation = async () => {
    if (!window.confirm(CONFIRMATION_MESSAGE)) {
      return false
    }

    const { errors } = await runPostFlag({
      variables: {
        input: {
          postId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return false
    } else {
      toast.success("Post reported to administrators.")
      return true
    }
  }

  return { flagPostWithConfirmation }
}

const POST_FLAG_MUTATION = gql(`
  mutation PostFlag($input: PostFlagInput!) {
    postFlag(input: $input) {
      post {
        ...Post_Card
      }
    }
  }
`)
