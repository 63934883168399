import { useCommunity } from "~/community/useCommunity"
import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { formatDate } from "date-fns"
import { useLogEvent } from "~/analytics/EventsContext"
import { useEffect, useMemo, useRef } from "react"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useTiers } from "~/tiers/TiersProvider"

export const CancelledStep = () => {
  const community = useCommunity()
  const { close, meta } = useWizard()
  const { subscription } = useSubscription()
  const { logEvent } = useLogEvent()
  const { currentUser } = useCurrentUser()
  const { formatTierName } = useTiers()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.MembershipCanceled,
      {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      },
      hasLoggedEvent
    )
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  const tierName = useMemo(
    () => formatTierName(currentUser.tier, currentUser.tierInterval),
    [currentUser.tier, currentUser.tierInterval, formatTierName]
  )
  const upcomingTierName = useMemo(
    () =>
      formatTierName(
        currentUser.upcomingTier,
        currentUser.upcomingTierInterval
      ),
    [currentUser.upcomingTier, currentUser.upcomingTierInterval, formatTierName]
  )

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          You have canceled your {community.name} {tierName}
        </DialogTitle>
      </DialogHeader>

      <Alert className="flex flex-col gap-4 text-center justify-center">
        <p>
          Your {tierName} to {community.name} has been set to cancel at the end
          of the current billing period.
        </p>
        {upcomingTierName &&
          subscription?.subscriptionSchedule?.currentPhase?.endDate && (
            <p>
              Your membership will downgrade to a {upcomingTierName} on{" "}
              {formatDate(
                subscription.subscriptionSchedule?.currentPhase.endDate,
                "MMM d, yyyy"
              )}
              .
            </p>
          )}
      </Alert>

      <Separator />

      <DialogFooter className="justify-center">
        <Button onClick={close}>Back to Membership</Button>
      </DialogFooter>
    </>
  )
}

CancelledStep.displayName = "CancelledStep"
