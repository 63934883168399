import { Outlet, useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { useEffect, useState } from "react"
import { onboardingFitWaitingPath, unfitPath } from "~/common/paths"

export const FitRedirecting = () => {
  const navigate = useNavigate()
  const { currentUser } = useCurrentUserMaybe()
  const [allowRender, setAllowRender] = useState(false)

  useEffect(() => {
    if (!currentUser || (!currentUser?.onboarded && !currentUser?.fitWaiting)) {
      setAllowRender(true)
      return
    }

    if (currentUser.fit === false) {
      navigate(unfitPath.pattern)
    } else if (currentUser.fitWaiting || currentUser.fit === null) {
      navigate(onboardingFitWaitingPath.pattern)
    } else {
      setAllowRender(true)
    }
  }, [currentUser, navigate])

  if (!allowRender) return null

  return <Outlet />
}
