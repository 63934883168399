import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { CompanySize, HrisBackend, IndustryEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { CREATE_MANUAL_HRIS_DATA } from "~/merge/createManualHrisDataMutation"
import { Steps, STEPS } from "~/merge/MergeLinkButton"

interface ManualHrisDataContextType {
  industry: IndustryEnum | undefined
  setIndustry: React.Dispatch<React.SetStateAction<IndustryEnum | undefined>>
  hrisBackend: HrisBackend | undefined
  setHrisBackend: React.Dispatch<React.SetStateAction<HrisBackend | undefined>>
  companySize: CompanySize | undefined
  setCompanySize: React.Dispatch<React.SetStateAction<CompanySize | undefined>>
  turnover: number | undefined
  setTurnover: React.Dispatch<React.SetStateAction<number | undefined>>
  costPerEmployee: number | undefined
  setCostPerEmployee: React.Dispatch<React.SetStateAction<number | undefined>>
  retention: number | undefined
  setRetention: React.Dispatch<React.SetStateAction<number | undefined>>
  tenure: number | undefined
  setTenure: React.Dispatch<React.SetStateAction<number | undefined>>
  currentStep: Steps
  setCurrentStep: React.Dispatch<React.SetStateAction<Steps>>
  dialogOpen: boolean
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  onSuccessCallback: () => void
  resetFormToUserData: () => void
  prev: () => void
  next: () => void
}

export const ManualHrisDataContext =
  createContext<ManualHrisDataContextType | null>(null)

export const ManualHrisDataProvider = ({
  initialDialogOpen = false,
  children,
}: {
  initialDialogOpen?: boolean
  children: React.ReactNode
}) => {
  const { currentUser, refetch } = useCurrentUser()

  const [hrisBackend, setHrisBackend] = useState<undefined | HrisBackend>(
    currentUser.manualHrisData?.hrisBackend
  )

  const [companySize, setCompanySize] = useState<undefined | CompanySize>(
    currentUser.manualHrisData?.companySize
  )

  const [turnover, setTurnover] = useState<number | undefined>(
    currentUser.manualHrisData?.avgTurnoverPercent
  )

  const [costPerEmployee, setCostPerEmployee] = useState<number | undefined>(
    currentUser.manualHrisData?.avgCostPerEmployeeDollars
  )
  const [retention, setRetention] = useState<number | undefined>(
    currentUser.manualHrisData?.avgRetentionPercent
  )
  const [tenure, setTenure] = useState<number | undefined>(
    currentUser.manualHrisData?.avgTenureMonths
  )

  const [industry, setIndustry] = useState<undefined | IndustryEnum>(
    currentUser.manualHrisData?.industry
  )

  const resetFormToUserData = useCallback(() => {
    if (!currentUser.manualHrisData) {
      return
    }

    setHrisBackend(currentUser.manualHrisData.hrisBackend)
    setCompanySize(currentUser.manualHrisData.companySize)
    setTurnover(currentUser.manualHrisData.avgTurnoverPercent)
    setCostPerEmployee(currentUser.manualHrisData.avgCostPerEmployeeDollars)
    setRetention(currentUser.manualHrisData.avgRetentionPercent)
    setTenure(currentUser.manualHrisData.avgTenureMonths)
    setIndustry(currentUser.manualHrisData.industry)
  }, [
    currentUser.manualHrisData,
    setHrisBackend,
    setCompanySize,
    setTurnover,
    setTenure,
    setCostPerEmployee,
    setRetention,
    setIndustry,
  ])

  useEffect(() => {
    resetFormToUserData()
  }, [currentUser.manualHrisData, resetFormToUserData])

  const [currentStep, setCurrentStep] = useState<Steps>(STEPS[0])

  const [dialogOpen, setDialogOpen] = useState<boolean>(initialDialogOpen)

  const [runCreateManualHrisData] = useSafeMutation(CREATE_MANUAL_HRIS_DATA)

  const prev = () => {
    updateStep(-1)
  }

  const next = () => {
    updateStep(1)

    if (currentStep === STEPS.length - 2) {
      onSuccessCallback()
    }
  }

  const updateStep = (value: number) => {
    const newStep = currentStep + value
    if (newStep >= 0 && newStep < STEPS.length) {
      setCurrentStep(newStep)
    }
  }

  const onSuccessCallback = useCallback(() => {
    invariant(hrisBackend)
    invariant(companySize)
    invariant(industry)
    invariant(costPerEmployee)
    invariant(tenure)
    invariant(retention)
    invariant(turnover)

    runCreateManualHrisData({
      variables: {
        input: {
          industry: industry,
          hrisBackend: hrisBackend,
          companySize: companySize,
          avgCostPerEmployeeDollars: costPerEmployee,
          avgTenureMonths: tenure,
          avgRetentionPercent: retention,
          avgTurnoverPercent: turnover,
        },
      },
    }).then(
      (data) => {
        if (data.errors) {
          displayErrors(data.errors)
        } else {
          refetch()
          toast.success("Updated manual HRIS data")
        }
      },
      (_) => console.error("Failed to create manual hris data")
    )
  }, [
    hrisBackend,
    companySize,
    costPerEmployee,
    tenure,
    retention,
    turnover,
    runCreateManualHrisData,
    industry,
    refetch,
  ])

  return (
    <ManualHrisDataContext.Provider
      value={{
        industry,
        setIndustry,
        hrisBackend,
        setHrisBackend,
        companySize,
        setCompanySize,
        costPerEmployee,
        setCostPerEmployee,
        retention,
        setRetention,
        tenure,
        setTenure,
        turnover,
        setTurnover,
        currentStep,
        setCurrentStep,
        dialogOpen,
        setDialogOpen,
        prev,
        next,
        resetFormToUserData,
        onSuccessCallback,
      }}
    >
      {children}
    </ManualHrisDataContext.Provider>
  )
}

export const useManualHrisDataContext = () => {
  const manualHrisDataContext = useContext(ManualHrisDataContext)

  if (!manualHrisDataContext) {
    throw new Error(
      "No ManualHrisDataContext.Provider found when calling useManualHrisDataContext."
    )
  }

  return manualHrisDataContext
}
