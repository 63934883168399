import { QueryResult, useQuery } from "@apollo/client"
import { Link, useSearchParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { editArticlePath } from "~/common/paths"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import { ContributeToCommunityCallout } from "~/articles/ContributeToCommunityCallout"
import UserSquare from "../images/icons/user-square.svg?react"
import Bookmark from "../images/icons/bookmark.svg?react"
import DollarSquare from "../images/icons/dollar-square.svg?react"
import {
  ArticleSortTypeEnum,
  ArticleStateEnum,
  ArticleVisibilityEnum,
  Article_MyContentTableFragment,
  CommunitySlug,
  Exact,
  InputMaybe,
  MyContentQuery,
} from "~/__generated__/graphql"
import { ReactNode, useCallback, useMemo, useRef, useState } from "react"
import { cn } from "~/lib/utils"
import {
  ARTICLE_CONTENT_TYPE_LABELS,
  ARTICLE_STATE_LABELS,
} from "~/common/enumTranslations"
import { compareAsc, format, parseISO } from "date-fns"
import {
  ChevronLeft,
  ChevronRight,
  Megaphone,
  MoreHorizontal,
  Pencil,
  Trash,
} from "lucide-react"
import { Card } from "~/ui/card"
import Driver from "~/images/icons/driver.svg?react"
import Send2 from "~/images/icons/send-2.svg?react"
import ExternalLink from "~/images/icons/external-link.svg?react"
import ReactPaginate from "react-paginate"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"
import { CollaborationInvites } from "~/articles/CollaborationInvites"
import { ArticleCard } from "~/ui/ArticleCard"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import { useToggleFeaturedConfirm } from "~/content/useToggleFeaturedConfirm"
import { IconButton } from "~/ui/IconButton"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { useTogglePremiumConfirm } from "~/content/useTogglePremiumConfirm"
import { formatDate } from "~/common/formatDate"
import { ContentTypeIconWithToolTip, TagBubble } from "~/ui/TagBubble"
import { ButtonGroupToggle } from "~/ui/ButtonGroupToggle"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useChangeVisibilityConfirm } from "~/content/useChangeVisibilityConfirm"
import { useSubmitForReviewConfirm } from "~/content/useSubmitForReviewConfirm"
import { useRequestEditsConfirm } from "~/content/useRequestEditsConfirm"
import { ArticlePublishModal } from "~/articles/ArticlePublishModal"
import { SearchInput } from "~/ui/SearchInput"
import {
  BadgeOptionType,
  DropdownMenuBadges,
} from "~/common/DropdownMenuBadges"
import { DropdownMenuCheckboxes } from "~/common/DropdownMenuCheckboxes"
import {
  ContentOptionType,
  contentTypeOptions,
  SortOptionType,
} from "./LibraryScreen"
import { StateSetter } from "~/types"
import { useDebounce } from "use-debounce"
import {
  shouldShowApprove,
  shouldShowRequestEdits,
  shouldShowSubmitForReview,
} from "~/articles/ArticleTopActionToolbar"
import { Tabs, TabsList, TabsTrigger } from "~/ui/tabs"
import { Badge } from "~/ui/badge"
import { useArchiveArticleConfirm } from "~/content/useArchiveArticleConfirm"
import { useToggleExternalShare } from "~/content/useToggleExternalShare"
import { useResendPublishEventConfirm } from "~/content/useResendPublishEventConfirm"

const PER_PAGE = 25

type MyContentTabOptions =
  | ArticleVisibilityEnum.Hidden
  | ArticleVisibilityEnum.Live
  | "CONTENT_SUBMISSIONS"

export type AttributeOptionType = {
  label: ReactNode
  value: "PREMIUM" | "FEATURED" | "ADMIN_AUTHORED"
}

export const attributeOptions: AttributeOptionType[] = [
  {
    value: "PREMIUM",
    label: (
      <div className="flex flex-row justify-start items-center space-x-2">
        <DollarSquare className={cn("w-[18px] h-[18px]")} />
        <div>Premium</div>
      </div>
    ),
  },
  {
    value: "FEATURED",
    label: (
      <div className="flex flex-row justify-start items-center space-x-2">
        <Bookmark className={cn("w-[18px] h-[18px]")} />
        <div>Featured</div>
      </div>
    ),
  },
  {
    value: "ADMIN_AUTHORED",
    label: (
      <div className="flex flex-row justify-start items-center space-x-2">
        <UserSquare className={cn("w-[18px] h-[18px]")} />
        <div>Admin Authored</div>
      </div>
    ),
  },
]

export const sortOptions = [
  {
    value: ArticleSortTypeEnum.CreatedAtDesc,
    label: "Newest to Oldest",
  },
  {
    value: ArticleSortTypeEnum.CreatedAtAsc,
    label: "Oldest to Newest",
  },
  {
    value: ArticleSortTypeEnum.LastEditedAt,
    label: "Recently Edited",
  },
  {
    value: ArticleSortTypeEnum.Alpha,
    label: "Alphabetically",
  },
]

const resetPagination = <T extends any>(
  setter: StateSetter<T>,
  paginate: (page: number) => void
) => {
  return (value: T) => {
    paginate(1)
    setter(value)
  }
}

export const MyContentScreen = () => {
  const { currentUser } = useCurrentUser()
  const community = useCommunity()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get("page") || "1")
  const [tab, _setTab] = useState<MyContentTabOptions>(
    ArticleVisibilityEnum.Live
  )
  const [publishArticleId, setPublishArticleId] = useState<string | null>(null)
  const paginate = useCallback(
    (page: number) => {
      setSearchParams({ page: page.toString() })
    },
    [setSearchParams]
  )
  const [query, _setQuery] = useState("")
  const [topics, _setTopics] = useState<BadgeOptionType[]>([])
  const [contentTypes, _setContentTypes] = useState<ContentOptionType[]>([])
  const [sort, _setSort] = useState<SortOptionType[]>([sortOptions[0]])
  const [attributes, _setAttributes] = useState<AttributeOptionType[]>([])
  const [debouncedQuery] = useDebounce(query, 200)

  const setQuery = resetPagination(_setQuery, paginate)
  const setTopics = resetPagination(_setTopics, paginate)
  const setSort = resetPagination(_setSort, paginate)
  const setContentTypes = resetPagination(_setContentTypes, paginate)
  const setAttributes = resetPagination(_setAttributes, paginate)

  const setTab = (tab: MyContentTabOptions) => {
    paginate(1)
    _setTab(tab)
  }
  const myContentResult = useQuery(ARTICLES_QUERY_DOCUMENT, {
    variables: {
      first: PER_PAGE,
      after: btoa(((page - 1) * PER_PAGE).toString()),
      visibilities:
        tab === ArticleVisibilityEnum.Hidden ||
        tab === ArticleVisibilityEnum.Live
          ? [tab]
          : null,
      query: debouncedQuery,
      tagIds: topics.length > 0 ? topics.map((t) => t.value) : undefined,
      contentTypes:
        contentTypes.length > 0
          ? contentTypes.map((t) => t.value)
          : community.contentTypes,
      sort: sort[0] ? sort[0].value : undefined,
      premium:
        attributes.findIndex((v) => v.value === "PREMIUM") >= 0
          ? true
          : undefined,
      featured:
        attributes.findIndex((v) => v.value === "FEATURED") >= 0
          ? true
          : undefined,
      adminAuthoredOnly:
        attributes.findIndex((v) => v.value === "ADMIN_AUTHORED") >= 0
          ? true
          : undefined,
      memberSubmissions: tab === "CONTENT_SUBMISSIONS",
    },
  })

  const topicOptions = (
    myContentResult.data?.tags.nodes ||
    myContentResult.previousData?.tags.nodes ||
    []
  ).map((topic) => ({
    value: topic.id,
    label: topic.name,
  }))

  const pageCount = Math.ceil(
    (myContentResult.data?.myContent.totalCount || 0) / PER_PAGE
  )

  const afterInviteAccept = useCallback(() => {
    paginate(1)
    myContentResult.refetch()
  }, [paginate, myContentResult])

  return (
    <>
      <ContributeToCommunityCallout />

      <CollaborationInvites afterAccept={afterInviteAccept} />

      <MyContentFilters
        query={query}
        setQuery={setQuery}
        topics={topics}
        setTopics={setTopics}
        contentTypes={contentTypes}
        setContentTypes={setContentTypes}
        sort={sort}
        setSort={setSort}
        topicOptions={topicOptions}
        attributes={attributes}
        setAttributes={setAttributes}
      />

      <Tabs
        value={tab}
        onValueChange={(v) => {
          const value = v as MyContentTabOptions
          setTab(value)

          if (currentUser.admin) {
            const newAttributes = attributes.filter(
              (a) => a.value !== "ADMIN_AUTHORED"
            )
            if (value === ArticleVisibilityEnum.Hidden) {
              setAttributes([
                ...newAttributes,
                attributeOptions.find((a) => a.value === "ADMIN_AUTHORED")!,
              ])
            } else {
              setAttributes(newAttributes)
            }
          }

          if (
            value === "CONTENT_SUBMISSIONS" ||
            value === ArticleVisibilityEnum.Hidden
          ) {
            setSort(
              sortOptions.filter(
                (s) => s.value === ArticleSortTypeEnum.LastEditedAt
              )
            )
          } else {
            setSort(
              sortOptions.filter(
                (s) => s.value === ArticleSortTypeEnum.CreatedAtDesc
              )
            )
          }
        }}
      >
        <TabsList className="justify-start mb-4 text-foreground">
          <TabsTrigger value={ArticleVisibilityEnum.Live}>Live</TabsTrigger>
          <TabsTrigger value={ArticleVisibilityEnum.Hidden}>Drafts</TabsTrigger>
          {currentUser.admin && (
            <>
              <TabsTrigger value="CONTENT_SUBMISSIONS">
                Member Submissions
                {myContentResult.data?.memberSubmissionsCount.totalCount ? (
                  <Badge variant="counter" className="ml-2">
                    {myContentResult.data?.memberSubmissionsCount.totalCount}
                  </Badge>
                ) : null}
              </TabsTrigger>
            </>
          )}
        </TabsList>
      </Tabs>

      <Card className="mt-5 mb-12 flex-1">
        <MyContentTable
          result={myContentResult}
          tab={tab}
          setPublishArticleId={setPublishArticleId}
        />

        {!myContentResult.loading && pageCount > 1 && (
          <ReactPaginate
            breakLabel={<MoreHorizontal size={18} />}
            nextLabel={<ChevronRight size={18} />}
            previousLabel={<ChevronLeft size={18} />}
            forcePage={page - 1}
            onPageChange={({ selected }) => paginate(selected + 1)}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(
              (myContentResult.data?.myContent.totalCount || 0) / PER_PAGE
            )}
            renderOnZeroPageCount={null}
            containerClassName="flex items-center justify-center gap-4 my-6 text-sm"
            disabledLinkClassName="cursor-not-allowed text-gray-300"
            activeLinkClassName="text-blue-600 font-semibold"
          />
        )}
      </Card>
      {publishArticleId && (
        <ArticlePublishModal
          articleId={publishArticleId}
          isOpen={true}
          setIsOpen={(isOpen) => !isOpen && setPublishArticleId(null)}
          onSuccess={myContentResult.refetch}
        />
      )}
    </>
  )
}

const MyContentFilters = ({
  query,
  setQuery,
  topics,
  setTopics,
  topicOptions,
  contentTypes,
  setContentTypes,
  sort,
  setSort,
  attributes,
  setAttributes,
}: {
  query: string
  setQuery: (v: string) => void
  topics: BadgeOptionType[]
  setTopics: (v: BadgeOptionType[]) => void
  topicOptions: BadgeOptionType[]
  contentTypes: ContentOptionType[]
  setContentTypes: (v: ContentOptionType[]) => void
  sort: SortOptionType[]
  setSort: (v: SortOptionType[]) => void
  attributes: AttributeOptionType[]
  setAttributes: (v: AttributeOptionType[]) => void
}) => {
  const community = useCommunity()
  const ccls = useCommunityClassname()
  const { currentUser } = useCurrentUser()

  return (
    <div className="flex flex-col gap-2 mb-4">
      <div className="w-full text-sm relative">
        <SearchInput
          placeholder="Search"
          searchTerm={query}
          setSearchTerm={setQuery}
        />
      </div>
      <div className="flex flex-wrap md:flex-nowrap gap-2">
        <div className="md:w-1/4 w-full">
          <DropdownMenuCheckboxes
            title={"Sort"}
            options={sortOptions}
            selected={sort}
            onChange={setSort}
            exclusive={true}
          />
        </div>
        <div className="md:w-1/4 w-full">
          <DropdownMenuCheckboxes
            title={
              contentTypes.length > 0
                ? `${contentTypes.length} Selected`
                : "Type"
            }
            optionTitle="FILTER BY TYPE"
            options={contentTypeOptions(
              community.contentTypes,
              ccls({
                [CommunitySlug.Marketingland]: "text-primary",
                default: "text-library-dropdown-badges",
              })
            )}
            selected={contentTypes}
            onChange={(data) => setContentTypes(data)}
          />
        </div>
        <div className="md:w-1/4 w-full">
          <DropdownMenuBadges
            title={topics.length > 0 ? `${topics.length} Selected` : "Topic"}
            optionTitle="FILTER BY TOPIC"
            options={topicOptions}
            selected={topics}
            onChange={(data) => setTopics(data)}
          />
        </div>
        <div className="md:w-1/4 w-full">
          <DropdownMenuCheckboxes
            title={"Attributes"}
            options={attributeOptions.filter(
              (option) => currentUser.admin || option.value !== "ADMIN_AUTHORED"
            )}
            selected={attributes}
            onChange={setAttributes}
            // exclusive={true}
          />
        </div>
      </div>
    </div>
  )
}

const MyContentTable = ({
  result,
  tab,
  setPublishArticleId,
}: {
  result: QueryResult<
    MyContentQuery,
    Exact<{
      first?: InputMaybe<number> | undefined
      after?: InputMaybe<string> | undefined
    }>
  >
  tab: MyContentTabOptions
  setPublishArticleId: (id: string) => void
}) => {
  const { data, loading, error } = result

  if (loading)
    return (
      <div className="py-20">
        <LoadingIndicatorCentered />
      </div>
    )
  if (error || !data) return <Error message="Error loading articles." />

  const articles = data.myContent.nodes.filter((a) => {
    if (tab === ArticleVisibilityEnum.Live)
      return a.visibility === ArticleVisibilityEnum.Live
    if (tab === ArticleVisibilityEnum.Hidden)
      return a.visibility === ArticleVisibilityEnum.Hidden
    return true
  })

  if (articles.length === 0) {
    return (
      <div className="text-primary tracking-wide px-4 py-24 flex flex-col items-center">
        <div className="border-2 rounded-full border-primary w-[84px] h-[84px] flex items-center justify-center">
          <Driver width="28" height="28" />
        </div>

        <div className="my-4 text-center font-semibold text-2xl">
          No Content
        </div>

        <div className="text-center font-medium text-sm max-w-[350px]">
          You have not created any content yet. Use the buttons above to get
          started!
        </div>
      </div>
    )
  }

  return (
    <div>
      <MyContentCardView articles={articles} />

      <div className="hidden lg:block overflow-x-auto">
        <table className="w-full mb-5 [overflow-wrap:normal]">
          <tbody>
            {articles.map((article) => (
              <ArticleRow
                key={article.id}
                article={article}
                openPublish={() => setPublishArticleId(article.id)}
                refreshList={result.refetch}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const ArticleRow = ({
  article,
  openPublish,
  refreshList,
}: {
  article: Article_MyContentTableFragment
  openPublish: () => void
  refreshList: () => void
}) => {
  const { currentUser } = useCurrentUser()
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  const isFeatured = useMemo(
    () =>
      article.featuredUntil &&
      compareAsc(parseISO(article.featuredUntil), new Date()) === 1,
    [article]
  )

  const toggleExternalShare = useToggleExternalShare({ article })
  const toggleFeatured = useToggleFeaturedConfirm({ article })
  const togglePremium = useTogglePremiumConfirm({ article })
  const changeVisibility = useChangeVisibilityConfirm({
    article,
    onSuccess: refreshList,
  })
  const submitForReview = useSubmitForReviewConfirm({ article })
  const requestEdits = useRequestEditsConfirm({ article })
  const archive = useArchiveArticleConfirm({ article })
  const resendPublishEvent = useResendPublishEventConfirm({ article })

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  return (
    <>
      <ContextMenu>
        <ContextMenuContent>
          {shouldShowSubmitForReview(article) && (
            <ContextMenuItem
              className="cursor-pointer"
              onClick={submitForReview}
            >
              <MenuEntryWithIcon
                copy="Submit for Approval"
                icon={<Send2 className="w-[18px] h-[18px]" />}
              />
            </ContextMenuItem>
          )}

          {shouldShowApprove(article) &&
            article.state !== ArticleStateEnum.Approved && (
              <ContextMenuItem className="cursor-pointer" onClick={openPublish}>
                <MenuEntryWithIcon
                  copy={
                    article.state === ArticleStateEnum.Submitted
                      ? "Approve"
                      : "Ready to publish"
                  }
                  icon={<Send2 className="w-[18px] h-[18px]" />}
                />
              </ContextMenuItem>
            )}

          {shouldShowRequestEdits(article) && (
            <ContextMenuItem className="cursor-pointer" onClick={requestEdits}>
              <MenuEntryWithIcon
                copy="Request Edits"
                icon={<Pencil size={18} />}
              />
            </ContextMenuItem>
          )}

          {article.currentUserCanMarkAsFeatured.value && (
            <ContextMenuItem
              className="cursor-pointer"
              onClick={toggleFeatured}
            >
              <MenuEntryWithIcon
                copy="Feature"
                icon={
                  <Bookmark
                    className={cn(
                      "w-[18px] h-[18px]",
                      isFeatured && "text-seaGreen"
                    )}
                  />
                }
              />
            </ContextMenuItem>
          )}

          {article.currentUserCanMarkAsPremium.value && (
            <ContextMenuItem className="cursor-pointer" onClick={togglePremium}>
              <MenuEntryWithIcon
                copy="Premium"
                icon={
                  <DollarSquare
                    className={cn(
                      "w-[18px] h-[18px]",
                      article.premium && "text-seaGreen"
                    )}
                  />
                }
              />
            </ContextMenuItem>
          )}

          {currentUser.currentUserCanDebug.value &&
            article.visibility === ArticleVisibilityEnum.Live && (
              <ContextMenuItem
                className="cursor-pointer"
                onClick={resendPublishEvent}
              >
                <MenuEntryWithIcon
                  copy="Resend Publish Event"
                  icon={<Megaphone className="w-[18px] h-[18px]" />}
                />
              </ContextMenuItem>
            )}

          {article.currentUserCanArchive.value &&
            article.state !== ArticleStateEnum.Archived && (
              <ContextMenuItem className="cursor-pointer" onClick={archive}>
                <MenuEntryWithIcon
                  copy="Archive"
                  icon={<Trash className="w-4 h-4" />}
                />
              </ContextMenuItem>
            )}
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <tr key={article.id} ref={contextMenuTriggerRef}>
            <Cell>
              <div className="flex items-center pl-3 flex-wrap min-w-[100px]">
                <StackedUserAvatars
                  users={article.collaborators.map((c) => c.user)}
                />
              </div>
            </Cell>
            <Cell className="min-w-[250px]">
              <div className="flex items-center gap-1 mb-1">
                {isFeatured && (
                  <SimpleTooltip
                    content={`Featured until ${format(
                      article.featuredUntil,
                      "MMM d, yyyy 'at' h:mm bbb"
                    )}`}
                  >
                    <div className="text-seaGreen">
                      <Bookmark className="h-[20px] w-[20px]" />
                    </div>
                  </SimpleTooltip>
                )}
                {article.premium && (
                  <div className="text-seaGreen">
                    <DollarSquare className="h-[20px] w-[20px]" />
                  </div>
                )}
              </div>
              <Link to={editArticlePath({ articleId: article.id })}>
                <p className="text-3xs line-clamp-1">
                  {article.markedLiveAt ? (
                    <>Date Published - {formatDate(article.markedLiveAt)}</>
                  ) : (
                    <>Date Created - {formatDate(article.createdAt)}</>
                  )}
                </p>
                <p className="text-sm font-semibold line-clamp-2">
                  {article.draftRevision.title}
                </p>
              </Link>
            </Cell>
            <Cell>
              <div className="flex flex-col gap-2">
                <TagBubble
                  tagName={article.draftRevision.tag.name}
                  colorClass="text-black"
                  borderClass="border-black self-start"
                />
                {article.draftRevision.contentType && (
                  <div className="flex items-center gap-2 text-3xs text-textLight">
                    <ContentTypeIconWithToolTip
                      contentType={article.draftRevision.contentType}
                    />
                    {
                      ARTICLE_CONTENT_TYPE_LABELS[
                        article.draftRevision.contentType
                      ]
                    }
                  </div>
                )}
              </div>
            </Cell>
            <Cell>
              <div className="flex">
                <ButtonGroupToggle
                  leftButtonCopy="Don't Share"
                  leftButtonClassName="text-2xs"
                  leftButtonVariant="secondaryDark"
                  rightButtonCopy={
                    <ExternalLink className="w-[12px] h-[12px]" />
                  }
                  rightButtonVariant="green"
                  leftButtonOnClick={() =>
                    article.currentUserCanChangeExternalShareSetting.value &&
                    toggleExternalShare()
                  }
                  rightButtonOnClick={() =>
                    article.currentUserCanChangeExternalShareSetting.value &&
                    toggleExternalShare()
                  }
                  activeButton={article.allowExternalShare ? "right" : "left"}
                />
              </div>
            </Cell>
            {article.currentUserCanChangeVisibility.value && (
              <Cell>
                <ButtonGroupToggle
                  leftButtonCopy="Hide"
                  leftButtonClassName="text-2xs"
                  leftButtonVariant="secondaryDark"
                  rightButtonCopy="Live"
                  rightButtonClassName="text-2xs"
                  rightButtonVariant="green"
                  leftButtonOnClick={() => changeVisibility()}
                  rightButtonOnClick={() => changeVisibility()}
                  activeButton={
                    article.visibility === ArticleVisibilityEnum.Live
                      ? "right"
                      : "left"
                  }
                />
              </Cell>
            )}
            <Cell>{ARTICLE_STATE_LABELS[article.state]}</Cell>
            <Cell className="text-center">
              <IconButton onClick={triggerContextMenu}>
                <MoreHorizontal size={16} />
              </IconButton>
            </Cell>
          </tr>
        </ContextMenuTrigger>
      </ContextMenu>
    </>
  )
}

const MenuEntryWithIcon = ({
  icon,
  copy,
}: {
  icon: ReactNode
  copy: string
}) => (
  <div className="flex-1 flex items-center gap-2 text-pretext-light-gray hover:text-black">
    <div>{icon}</div>
    {copy}
  </div>
)

const Cell = ({
  children,
  className,
}: {
  children?: ReactNode
  className?: string
}) => (
  <td className={cn("border-b px-2 py-6 text-slate-600 text-2xs", className)}>
    {children}
  </td>
)

type MyContentCardViewProps = {
  articles: Article_MyContentTableFragment[]
}

const MyContentCardView = ({ articles }: MyContentCardViewProps) => {
  return (
    <div className="block lg:hidden flex flex-col gap-4">
      {articles.map((article) => (
        <ArticleCard key={article.id} article={article} editMode wideVersion />
      ))}
    </div>
  )
}

gql(`
  fragment Article_ReviewActions on Article {
    id
    state
    currentUserCanRequestEdits { value }
    currentUserCanApprove { value }
    currentUserCanSubmitForApproval { value }
  }
`)

gql(`
  fragment Article_MyContentTable on Article {
    id
    createdAt
    markedLiveAt
    state
    visibility
    featuredUntil
    premium
    allowExternalShare
    collaborators {
      id
      user {
        id
        ...User_Avatar
      }
    }
    draftRevision {
      id
      title
      description
      contentType
      contentVersion
      tag {
        id
        name
      }
    }
    createdAt

    currentUserCanMarkAsFeatured { value }
    currentUserCanMarkAsPremium { value }
    currentUserCanChangeExternalShareSetting { value }
    currentUserCanChangeVisibility { value }
    currentUserCanRequestEdits { value }
    currentUserCanApprove { value }
    currentUserCanSubmitForApproval { value }
    currentUserCanArchive { value }

    ...Article_ReviewActions
  }
`)

const ARTICLES_QUERY_DOCUMENT = gql(`
  query MyContent(
    $first: Int,
    $after: String,
    $contentTypes: [ArticleContentTypeEnum!],
    $visibilities: [ArticleVisibilityEnum!],
    $query: String
    $tagIds: [ID!]
    $sort: ArticleSortTypeEnum
    $featured: Boolean
    $premium: Boolean
    $adminAuthoredOnly: Boolean
    $memberSubmissions: Boolean
  ) {
    myContent: articles(
      first: $first,
      after: $after,
      revisionType: DRAFT,
      contentTypes: $contentTypes,
      visibilities: $visibilities,
      query: $query,
      tagIds: $tagIds
      sort: $sort
      featured: $featured
      premium: $premium
      adminAuthoredOnly: $adminAuthoredOnly,
      memberSubmissions: $memberSubmissions
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...Article_MyContentTable
      }
    }
    memberSubmissionsCount: articles(revisionType: DRAFT, contentTypes: $contentTypes, memberSubmissions: true) {
      totalCount
    }

    tags {
      nodes {
        id
        name
      }
    }
  }
`)
