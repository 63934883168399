import { useCallback } from "react"
import {
  Article,
  RulesEngineEventTypeEnum,
  User,
} from "~/__generated__/graphql"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"
import { useRulesEngine } from "~/rules-engine/RulesEngineContext"
import toast from "react-hot-toast"

export type ResendPublishEventConfirmProps = {
  article: Pick<Article, "id" | "state"> & { collaborators: Pick<User, "id">[] }
} & Partial<ConfirmContextProviderProps>

export const useResendPublishEventConfirm = ({
  article,
  ...confirmProps
}: ResendPublishEventConfirmProps) => {
  const showConfirm = useConfirm()
  const { createRulesEngineEvent } = useRulesEngine()

  const call = useCallback(() => {
    showConfirm({
      title: "Resend Publish Event",
      body: "Are you sure you want to resend the publish event for this article? This will cause a new in-app notification to be sent to all users.",
      confirmText: "Resend",
      onConfirm: async () => {
        await createRulesEngineEvent(
          RulesEngineEventTypeEnum.ArticleMarkedLive,
          {
            source_type: "Article",
            source_id: article.id,
            actor_id: article.collaborators[0].id,
          }
        )
        toast.success("Publish event resent")
      },
      ...confirmProps,
    })
  }, [article, confirmProps, showConfirm, createRulesEngineEvent])

  return call
}
