import { useSubscription } from "@apollo/client"
import { gql } from "~/__generated__"
import { useCurrentUser } from "~/auth/CurrentUserContext"

const USER_UPDATED_SUBSCRIPTION = gql(`
  subscription UserUpdated($userId: ID!) {
    userUpdated(userId: $userId) {
      currentUser {
        id
        unreadNotificationCount
        unviewedLibraryCount
        unviewedDirectoryCount
        unviewedEventsCount
      }
    }
  }
`)

export const useUserUpdatedSubscription = () => {
  const { currentUser } = useCurrentUser()

  const { data, loading, error } = useSubscription(USER_UPDATED_SUBSCRIPTION, {
    variables: { userId: currentUser.id },
  })

  return { data, loading, error }
}
