import { Post_CardFragment, AhoyEventTypeEnum } from "~/__generated__/graphql"
import { getMetaVar } from "~/common/getMetaVar"
import { useQuery } from "@apollo/client"
import { PostCard } from "./PostCard"
import { gql } from "~/__generated__"
import { LIVE_ARTICLE_QUERY_DOCUMENT } from "~/screens/ArticleScreen"
import { ArticleCard } from "~/ui/ArticleCard"
import { X } from "lucide-react"
import { useLogEvent } from "~/analytics/EventsContext"
import { Card, CardContent } from "~/ui/card"

const domain = getMetaVar("domain")
const contentRegex = new RegExp(
  `${domain}/(posts|library)/([a-zA-Z0-9]{8}(?:-[a-zA-Z0-9]{4}){3}-[a-zA-Z0-9]{12})`
)

export const DetectInternalContentShareSection = ({
  post,
}: {
  post: Post_CardFragment
}) => {
  if (!post.content) return null

  const match = post.content.match(contentRegex)
  if (!match || !(match[1] && match[2])) return null

  if (match[1] === "library") {
    return <RenderEmbeddedArticle articleId={match[2]} parentPost={post} />
  }

  if (match[1] === "posts") {
    return <RenderEmbeddedPost postId={match[2]} parentPost={post} />
  }
}

const EmbeddedContent = ({
  content,
  onClose,
  handleOnClick,
}: {
  content: React.ReactNode
  onClose?: () => void
  handleOnClick?: (e: React.MouseEvent) => void
}) => {
  return (
    <div
      className="mb-2 card-clickable"
      onClick={(e: React.MouseEvent) => {
        handleOnClick && handleOnClick(e)
      }}
    >
      {onClose && (
        <div
          className="absolute right-0 mr-2 mt-2 card-clickable cursor-pointer"
          onClick={onClose}
          aria-label="Remove embedded content"
        >
          <X size={16} />
        </div>
      )}
      {content}
    </div>
  )
}

export const RenderEmbeddedPost = ({
  postId,
  onClose,
  parentPost,
}: {
  postId: string
  onClose?: () => void
  parentPost?: Post_CardFragment
}) => {
  const { data: postData } = useQuery(POST_QUERY_DOCUMENT, {
    variables: { postId },
  })

  const { logEvent } = useLogEvent()

  if (!postData) return null

  return (
    <EmbeddedContent
      content={
        <PostCard
          post={postData.post}
          hidePostMenu
          hidePostActions
          cardClickable
        />
      }
      handleOnClick={(e: React.MouseEvent) => {
        if (e.target instanceof HTMLAnchorElement && parentPost) {
          logEvent(AhoyEventTypeEnum.EmbeddedLinkClicked, {
            url_clicked: e.target.href,
            location: parentPost.isReply ? "reply" : "post",
          })
        }
      }}
      onClose={onClose}
    />
  )
}

export const RenderEmbeddedArticle = ({
  articleId,
  onClose,
  parentPost,
}: {
  articleId: string
  onClose?: () => void
  parentPost?: Post_CardFragment
}) => {
  const { logEvent } = useLogEvent()

  const { data: articleData } = useQuery(LIVE_ARTICLE_QUERY_DOCUMENT, {
    variables: { articleId },
  })

  if (!articleData?.article) {
    return (
      <Card>
        <CardContent className="pt-4">
          <div className="text-center text-gray-600">
            This content has been deleted.
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <EmbeddedContent
      content={<ArticleCard article={articleData.article} wideVersion />}
      onClose={onClose}
      handleOnClick={(e: React.MouseEvent) => {
        if (e.target instanceof HTMLAnchorElement && parentPost) {
          logEvent(AhoyEventTypeEnum.EmbeddedLinkClicked, {
            url_clicked: e.target.href,
            location: parentPost.isReply ? "reply" : "post",
          })
        }
      }}
    />
  )
}

const POST_QUERY_DOCUMENT = gql(`
  query PostRetweetPost($postId: ID!) {
    post(postId: $postId) {
      ...Post_Card
    }
  }
`)
