import { Dialog, DialogContent } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { useCommunity } from "~/community/useCommunity"
import { Trans } from "react-i18next"
import Toggle from "react-toggle"
import { UPDATE_ARTICLE_MUTATION } from "~/content/ArticleEditor"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { useState } from "react"

export const ExternalShareSettingsModal = ({
  isOpen,
  setIsOpen,
  allowExternalShare: allowExternalShareDefault,
  articleId,
  onSubmit,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  allowExternalShare: boolean
  articleId: string
  onSubmit?: () => void
}) => {
  const community = useCommunity()
  const [runArticleUpdate, { loading }] = useSafeMutation(
    UPDATE_ARTICLE_MUTATION
  )
  const [allowExternalShare, setAllowExternalShare] = useState(
    allowExternalShareDefault
  )

  const saveSetting = async () => {
    const { errors } = await runArticleUpdate({
      variables: { input: { articleId, allowExternalShare } },
    })

    if (errors) {
      toast.error("Error updating share settings.")
    } else {
      setIsOpen(false)
      onSubmit && onSubmit()
    }
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="text-center mb-2 font-serif text-3xl">
          Allow External Sharing?
        </div>
        <div className="text-center mb-6 text-2xs">
          Allowing external share enables members of {community.name} to share a
          short preview of this content outside of the community via a link.
          Rest assured, non-members will not be able to read the full content
          until they have been vetted as an official member of {community.name}.
        </div>
        <div className="text-center mb-6 text-2xs">
          A good reason to enable preview share is to enable{" "}
          <Trans i18nKey="creatorFirstName" ns="community" /> and peers to
          highlight and celebrate your contributions across their socials and in
          their newsletter!
        </div>

        <label className="flex items-center mr-3">
          <Toggle
            icons={false}
            checked={allowExternalShare}
            onChange={(e) => setAllowExternalShare(!allowExternalShare)}
          />
          <span className="text-2xs ml-2">
            Sharing {allowExternalShare ? "On" : "Off"}
          </span>
        </label>

        <div className="h-[1px] bg-gray-300 my-8" />

        <div className="flex justify-end">
          <Button
            type="button"
            disabled={loading}
            onClick={saveSetting}
            className="px-10"
          >
            Save & Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
