import { CommunitySlug, Post_CardFragment } from "~/__generated__/graphql"
import { PostCard } from "./PostCard"
import Messages from "../images/icons/messages-thin.svg?react"
import House from "../images/icons/house.svg?react"
import { cn } from "~/lib/utils"
import { useCommunityClassname } from "~/community/useCommunity"

export const PostFeed = ({
  posts,
  isReplies = false,
  isChannel = false,
  isFiltered = false,
  trackReadStates = false,
}: {
  posts: Post_CardFragment[]
  isReplies?: boolean
  isChannel?: boolean
  isFiltered?: boolean
  trackReadStates?: boolean
}) => {
  const ccls = useCommunityClassname()

  return (
    <div className="flex flex-col gap-4">
      {!isReplies && posts.length === 0 && (
        <div
          className={cn(
            "mt-10 flex flex-col items-center text-center",
            ccls({
              [CommunitySlug.Safespace]: "text-primary",
              default: "text-foreground",
            })
          )}
        >
          <div
            className={cn(
              "w-[84px] h-[84px] border-2 rounded-full flex items-center justify-center",
              ccls({
                [CommunitySlug.Safespace]: "border-primary",
                default: "border-foreground",
              })
            )}
          >
            {isChannel ? (
              <Messages className="h-9 w-auto relative top-[2px]" />
            ) : (
              <House className="h-8 w-auto relative top-[-1px]" />
            )}
          </div>
          <div className="mt-4 font-semibold text-2xl">No Posts</div>
          <div className="mt-3 text-sm">
            {isFiltered ? (
              "No posts match the selected filters."
            ) : (
              <div>
                Be the first to share your thoughts
                {isChannel ? " in this channel." : "."}
                <br />
                Say something to the community above.
              </div>
            )}
          </div>
        </div>
      )}

      {posts
        .filter((post) => !post.currentUserFlagged && !post.deleted)
        .map((post) => (
          <PostCard
            key={post.id}
            post={post}
            isReply={isReplies}
            canReplyInLine={true}
            cardClickable={!isReplies}
            trackReadStates={trackReadStates}
          />
        ))}
    </div>
  )
}
