import { ProEventForm } from "~/pro-events/ProEventForm"
import { toDate } from "date-fns-tz"
import { PRO_EVENT_FIXED_TIMEZONE } from "~/pro-events/pro-events"
import { useNavigate } from "react-router-dom"
import { adminProEventsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { UseFormSetError } from "react-hook-form"
import { PRO_EVENT_ADMIN_CREATE_MUTATION } from "~/pro-events/pro-events"
import { ProEventFormValues } from "~/pro-events/ProEventForm"
import { CalendarDays } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { Card, CardContent } from "~/ui/card"

export const AdminProEventNewScreen = () => {
  const navigate = useNavigate()

  const [proEventCreate, proEventCreateResult] = useSafeMutation(
    PRO_EVENT_ADMIN_CREATE_MUTATION
  )

  const onSubmit = async (
    values: ProEventFormValues,
    setError: UseFormSetError<ProEventFormValues>
  ) => {
    const { errors } = await proEventCreate({
      variables: {
        input: {
          title: values.title,
          description: values.description,
          date: parseDateField(values.date).toISOString(),
          hostId: values.hostId,
          ctaCopy: values.ctaCopy,
          ctaUrl: values.ctaUrl,
          published: values.published,
        },
      },
    })

    if (errors) {
      displayErrors(errors, setError)
      console.log(errors)
    } else {
      toast.success("Created pro event")
      navigate(adminProEventsPath.pattern)
    }
  }

  return (
    <>
      <AdminHeader title="Events" subtitle="New Event" Icon={CalendarDays} />
      <Card>
        <CardContent className="pt-4">
          <ProEventForm
            defaultValues={{
              title: "",
              description: "",
              date: "",
              hostId: "",
              ctaCopy: "",
              ctaUrl: "",
              published: false,
            }}
            onSubmit={onSubmit}
            loading={proEventCreateResult.loading}
          />
        </CardContent>
      </Card>
    </>
  )
}

export const parseDateField = (dateString: string) => {
  return toDate(dateString, { timeZone: PRO_EVENT_FIXED_TIMEZONE })
}
