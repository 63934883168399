import { AhoyEventTypeEnum, Post_CardFragment } from "~/__generated__/graphql"
import { useCallback } from "react"
import { useLogEvent } from "~/analytics/EventsContext"
import { FormattedContent } from "~/components/FormattedContent"

export const PostContentSection = ({ post }: { post: Post_CardFragment }) => {
  const { logEvent } = useLogEvent()

  const handleLinkClick = useCallback(
    (url: string) => {
      logEvent(AhoyEventTypeEnum.DirectMessageLinkClicked, {
        postId: post.id,
        url_clicked: url,
      })
    },
    [logEvent, post.id]
  )

  if (!post.content) return null

  return (
    <div className="text-xs tracking-wide">
      <FormattedContent
        onLinkClicked={handleLinkClick}
        content={post.content}
      />
    </div>
  )
}
