import { createContext, useContext, useMemo } from "react"
import invariant from "tiny-invariant"
import {
  Community_DisplayFragment,
  CommunitySlug,
  DebugSettingKeyEnum,
  FeatureEnum,
} from "~/__generated__/graphql"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { COMMUNITY_QUERY_DOCUMENT } from "./communityQuery"
import { communityData } from "."

const CommunityContext = createContext<
  Community_DisplayFragment | undefined | null
>(null)

export const CommunityProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { data } = useQuery(COMMUNITY_QUERY_DOCUMENT, {
    variables: { id: communityData.data.community!.id },
    fetchPolicy: "cache-first", // Cache is preloaded in ~/app.tsx
    pollInterval: 1000 * 60 * 5, // Every 5 minutes
  })
  const community = data?.community

  if (!community) return null

  return (
    <CommunityContext.Provider value={community}>
      {children}
    </CommunityContext.Provider>
  )
}

export const useCommunity = () => {
  const ctx = useContext(CommunityContext)
  invariant(ctx, "useCommunity must be used within a CommunityProvider")
  return ctx
}

type DefaultRequired = { default: string; [key: string]: any }
type OptionsWithDefault = Partial<Record<CommunitySlug | "default", string>> &
  DefaultRequired
type OptionsWithoutDefault = Record<CommunitySlug, string>

const hasDefault = (
  opts: OptionsWithoutDefault | OptionsWithDefault
): opts is OptionsWithDefault => {
  const coerced = opts as OptionsWithDefault
  return coerced.default !== undefined
}

export const useCommunityClassname = () => {
  const ctx = useContext(CommunityContext)
  invariant(ctx, "useCommunity must be used within a CommunityProvider")

  return (entries: OptionsWithDefault | OptionsWithoutDefault) => {
    const matched = entries[ctx.slug]

    if (!matched && hasDefault(entries)) {
      return entries["default"]
    } else if (!matched) {
      return "no-community-match"
    }

    return matched
  }
}

export function useCommunityFeatures() {
  const { features } = useCommunity()
  let featureMap: Record<keyof typeof FeatureEnum, boolean> = {
    NewInLibrary: features.includes(FeatureEnum.NewInLibrary),
    Benchmark: features.includes(FeatureEnum.Benchmark),
    Referrals: features.includes(FeatureEnum.Referrals),
    DeepDive: features.includes(FeatureEnum.DeepDive),
    AskTheBot: features.includes(FeatureEnum.AskTheBot),
    DiscountCodeAfterPosting: features.includes(
      FeatureEnum.DiscountCodeAfterPosting
    ),
    FeedUpcomingEvents: features.includes(FeatureEnum.FeedUpcomingEvents),
    FeedDiveIn: features.includes(FeatureEnum.FeedDiveIn),
  }
  return featureMap
}

export function useDebugSettings() {
  const { data, loading } = useQuery(DEBUG_SETTINGS_QUERY_DOCUMENT)

  const debugSettings = useMemo(() => {
    if (loading || !data?.debugSettings) {
      return null
    }

    return data?.debugSettings.reduce(
      (
        acc: Record<DebugSettingKeyEnum, any>,
        { key, value }: { key: DebugSettingKeyEnum; value: any }
      ) => ({ ...acc, [key]: value }),
      {} as Record<DebugSettingKeyEnum, any>
    )
  }, [data, loading])

  return { debugSettings, loading }
}

const DEBUG_SETTINGS_QUERY_DOCUMENT = gql(`
  query DebugSettingsQuery {
    debugSettings {
      id
      key
      value
    }
  }
`)
