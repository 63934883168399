import { useCallback, useEffect, useMemo, useRef } from "react"
import { useThrottledCallback } from "use-debounce"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"

export const useArticleLocking = ({
  isDirty,
  articleId,
}: {
  isDirty: boolean
  articleId: string
}) => {
  const isDirtyWasRef = useRef(isDirty)

  const [runLockArticle] = useSafeMutation(LOCK_ARTICLE_MUTATION)
  const lockArticle = useCallback(async () => {
    console.log("locking article")
    const { errors } = await runLockArticle({
      variables: {
        input: {
          articleId,
        },
      },
    })

    if (errors) {
      console.log(errors)
    }
  }, [articleId, runLockArticle])

  const throttledLockArticle = useThrottledCallback(lockArticle, 15000, {
    trailing: false,
  })

  const onChange = useCallback(() => {
    if (!isDirty) {
      return
    }
    throttledLockArticle()
  }, [throttledLockArticle, isDirty])

  useEffect(() => {
    if (isDirty && !isDirtyWasRef.current) {
      onChange()
    }
    isDirtyWasRef.current = isDirty
  }, [isDirty, onChange])

  return useMemo(
    () => ({
      onChange,
    }),
    [onChange]
  )
}

const LOCK_ARTICLE_MUTATION = gql(`
  mutation ArticleLock($input: ArticleLockInput!) {
    articleLock(input: $input) {
      article {
        id
        isLockedBySomeoneElse
        lockedByUser {
          ...User_Avatar
        }
      }
    }
  }
`)
