import { RefObject, createContext, useContext, useMemo } from "react"
import { ComposerHandle } from "./PostComposer"

interface ComposerContextType {
  composerRef: RefObject<ComposerHandle>
}

const ComposerContext = createContext<ComposerContextType | null>(null)

export const ComposerContextProvider = ({
  children,
  composerRef,
}: {
  children: React.ReactNode
  composerRef: RefObject<ComposerHandle>
}) => {
  const value = useMemo(
    () => ({
      composerRef,
    }),
    [composerRef]
  )

  return (
    <ComposerContext.Provider value={value}>
      {children}
    </ComposerContext.Provider>
  )
}

export const useComposerContext = () => {
  const contextValue = useContext(ComposerContext)
  return contextValue
}
