import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import toast from "react-hot-toast"
import { formatDate } from "date-fns"
import { useEffect, useMemo, useRef } from "react"
import { AhoyEventTypeEnum, TierLevelEnum } from "~/__generated__/graphql"
import { PricingTableTier } from "./PricingTableStep"
import { TierFeature, useTiers } from "~/tiers/TiersProvider"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useLogEvent } from "~/analytics/EventsContext"

export const ConfirmCancelStep = () => {
  const { back, goToStep, meta, addToMeta } = useWizard()
  const { subscription } = useSubscription()
  const { tiers, getFeatureDifferences, formatTierName } = useTiers()
  const { currentUser } = useCurrentUser()
  const hasLoggedEvent = useRef(false)
  const { logEvent } = useLogEvent()

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.CancellationContinued,
      {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      },
      hasLoggedEvent
    )
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  const selectedTier = useMemo(() => {
    if (meta.selectedTier) {
      return meta.selectedTier as PricingTableTier
    }
    return tiers.find((tier) => tier.level === TierLevelEnum.Free)!
  }, [meta.selectedTier, tiers])

  const confirmCancel = async () => {
    if (!subscription) {
      toast.error("An unexpected error has occurred. Please try again later.")
      return
    }

    addToMeta("isCancellationConfirmed", true)
    goToStep("MigrateToTierStep", "forward", false)
  }

  const featureDifferences = useMemo(() => {
    if (!currentUser.tier || !selectedTier) return [] as TierFeature[]
    return getFeatureDifferences(currentUser.tier, selectedTier)
  }, [currentUser.tier, selectedTier, getFeatureDifferences])

  return (
    <div className="flex flex-col gap-4 max-w-2xl w-full">
      <DialogHeader>
        <DialogTitle>We're sorry to see you go</DialogTitle>
      </DialogHeader>
      <Alert className="flex flex-col gap-4">
        <div>
          Please confirm that you'd like to{" "}
          {selectedTier?.level === TierLevelEnum.Plus ? "downgrade" : "cancel"}{" "}
          your {formatTierName(currentUser.tier, currentUser.tierInterval)} when
          your current period ends on{" "}
          {formatDate(subscription!.currentPeriodEnd, "MMM d, yyyy")}. You will
          lose access to the following features:
        </div>
        <ul className="list-disc pl-6">
          {featureDifferences.includes("canViewProEvents") ? (
            <>
              <li>Members-Only Pro Events</li>
              <li>Ticket to the Annual Live Event</li>
            </>
          ) : (
            featureDifferences.includes("canAttendPrivateEvents") && (
              <li>Members-Only Virtual and Live Events</li>
            )
          )}
          {featureDifferences.includes("canViewPremiumArticles") && (
            <li>Premium Library Content</li>
          )}
          {featureDifferences.includes("canViewCourses") && <li>Courses</li>}
          {featureDifferences.includes("canUseIntroductions") && (
            <li>Curated Introductions</li>
          )}
          {featureDifferences.includes("canInitiateDms") && (
            <li>Initiating Direct Message</li>
          )}
        </ul>
      </Alert>

      <Separator />

      <DialogFooter>
        <Button type="button" variant="link" size="inline" onClick={back}>
          Back
        </Button>
        <Button onClick={confirmCancel}>Confirm Downgrade</Button>
      </DialogFooter>
    </div>
  )
}

ConfirmCancelStep.displayName = "ConfirmCancelStep"
