import { useQuery } from "@apollo/client"
import { HeartHandshake } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { AdminHeader } from "~/admin/AdminHeader"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  INTRODUCTION_CYCLES_ADMIN_ACCEPT_SELECTED_MUTATION,
  INTRODUCTION_CYCLES_ADMIN_FIND_QUERY_DOCUMENT,
  INTRODUCTION_CYCLES_ADMIN_OVERRIDE_MATCH_MUTATION,
  INTRODUCTION_CYCLES_ADMIN_TOGGLE_ACCEPTANCE_MUTATION,
  INTRODUCTION_CYCLES_ADMIN_TOGGLE_IGNORANCE_MUTATION,
} from "~/introduction-cycles/api"
import { IntroductionMatchRow } from "~/introduction-cycles/IntroductionMatchRow"
import { Button } from "~/ui/button"
import { Card } from "~/ui/card"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Table, TableBody, TableHead, TableHeader, TableRow } from "~/ui/table"

export const AdminIntroductionCycleScreen = () => {
  const { introductionCycleId } = useParams()
  invariant(introductionCycleId)
  const [overridingMatchId, setOverridingMatchId] = useState<string | null>(
    null
  )
  const [selectedMatchIds, setSelectedMatchIds] = useState<string[]>([])

  const {
    data,
    loading: fetchLoading,
    error: fetchError,
  } = useQuery(INTRODUCTION_CYCLES_ADMIN_FIND_QUERY_DOCUMENT, {
    variables: {
      introductionCycleId: introductionCycleId,
    },
  })
  const [
    runIntroductionMatchToggleAcceptance,
    introductionMatchToggleAcceptanceResult,
  ] = useSafeMutation(INTRODUCTION_CYCLES_ADMIN_TOGGLE_ACCEPTANCE_MUTATION)
  const [
    runIntroductionMatchToggleIgnorance,
    introductionMatchToggleIgnoranceResult,
  ] = useSafeMutation(INTRODUCTION_CYCLES_ADMIN_TOGGLE_IGNORANCE_MUTATION)
  const [
    runIntroductionMatchOverrideMatch,
    introductionMatchOverrideMatchResult,
  ] = useSafeMutation(INTRODUCTION_CYCLES_ADMIN_OVERRIDE_MATCH_MUTATION)
  const [
    runIntroductionMatchAcceptSelected,
    introductionMatchAcceptSelectedResult,
  ] = useSafeMutation(INTRODUCTION_CYCLES_ADMIN_ACCEPT_SELECTED_MUTATION)

  const matches = useMemo(() => data?.introductionCycle?.matches || [], [data])
  const locked = !!data?.introductionCycle?.locked
  const selectableMatches = useMemo(
    () => matches.filter((m) => !m.ignored && !m.accepted),
    [matches]
  )
  const allSelected = useMemo(
    () =>
      selectableMatches.length > 0 &&
      selectedMatchIds.length === selectableMatches.length,
    [selectableMatches, selectedMatchIds]
  )

  const onSelectAllToggle = useCallback(() => {
    if (allSelected) {
      setSelectedMatchIds([])
    } else {
      setSelectedMatchIds(selectableMatches.map((m) => m.id))
    }
  }, [allSelected, selectableMatches])

  const onAcceptSelectedMatches = () => {
    runIntroductionMatchAcceptSelected({
      variables: {
        input: {
          introductionMatchIds: selectedMatchIds,
        },
      },
    })
    setSelectedMatchIds([])
  }

  const matchesLoading =
    introductionMatchToggleAcceptanceResult.loading ||
    introductionMatchToggleIgnoranceResult.loading ||
    introductionMatchOverrideMatchResult.loading ||
    introductionMatchAcceptSelectedResult.loading
  const matchesError =
    introductionMatchToggleAcceptanceResult.error ||
    introductionMatchToggleIgnoranceResult.error ||
    introductionMatchOverrideMatchResult.error ||
    introductionMatchAcceptSelectedResult.error

  return (
    <>
      <AdminHeader
        title="Introductions"
        subtitle={`Introduction Cycle: ${data?.introductionCycle?.name}`}
        Icon={HeartHandshake}
      >
        {!fetchLoading && !fetchError && !locked && (
          <div className="flex gap-3">
            <Button
              onClick={onSelectAllToggle}
              disabled={matchesLoading}
              size="sm"
            >
              {allSelected ? "Unselect All" : "Select All"}
            </Button>
            <Button
              onClick={onAcceptSelectedMatches}
              disabled={matchesLoading || selectedMatchIds.length <= 0}
              size="sm"
            >
              Accept {selectedMatchIds.length} Selected Matches
            </Button>
          </div>
        )}
      </AdminHeader>
      {fetchLoading ? (
        <LoadingIndicatorCentered />
      ) : fetchError ? (
        <Error message="Error loading data." />
      ) : (
        <>
          {matchesError ? (
            <Error message="Error loading matches." />
          ) : (
            <Card>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Select</TableHead>
                    <TableHead>#</TableHead>
                    <TableHead>User</TableHead>
                    <TableHead>Match</TableHead>
                    <TableHead>Value</TableHead>
                    <TableHead>Accepted</TableHead>
                    <TableHead>Ignored</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {matches.map((match, index) => (
                    <IntroductionMatchRow
                      key={match.id}
                      index={index}
                      match={match}
                      locked={locked}
                      overriding={overridingMatchId === match.id}
                      loading={matchesLoading}
                      selected={selectedMatchIds.includes(match.id)}
                      onToggleAcceptanceClick={() =>
                        runIntroductionMatchToggleAcceptance({
                          variables: {
                            input: {
                              introductionMatchId: match.id,
                            },
                          },
                        })
                      }
                      onToggleIgnoranceClick={() =>
                        runIntroductionMatchToggleIgnorance({
                          variables: {
                            input: {
                              introductionMatchId: match.id,
                            },
                          },
                        })
                      }
                      onToggleOverrideClick={() => {
                        if (overridingMatchId === match.id) {
                          setOverridingMatchId(null)
                        } else {
                          setOverridingMatchId(match.id)
                        }
                      }}
                      onOverrideMatch={(userId: string) => {
                        runIntroductionMatchOverrideMatch({
                          variables: {
                            input: {
                              introductionMatchId: match.id,
                              matchedWithUserId: userId,
                            },
                          },
                        })
                        setOverridingMatchId(null)
                      }}
                      onToggleSelectClick={() => {
                        if (selectedMatchIds.includes(match.id)) {
                          setSelectedMatchIds(
                            selectedMatchIds.filter((id) => id !== match.id)
                          )
                        } else {
                          setSelectedMatchIds([...selectedMatchIds, match.id])
                        }
                      }}
                    />
                  ))}
                </TableBody>
              </Table>
            </Card>
          )}
        </>
      )}
    </>
  )
}
