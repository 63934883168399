import { useCallback, useMemo } from "react"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { PollDisplayFragment } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button, buttonVariants } from "~/ui/button"
import { Progress } from "~/ui/progress"
import { SimpleTooltip } from "~/ui/SimpleTooltip"

interface PollProps {
  className?: string | null
  poll: PollDisplayFragment
}

export const Poll = ({ className, poll }: PollProps) => {
  const showVotes = useMemo(
    () => poll.options.some((option) => option.currentUserHasVoted),
    [poll]
  )
  const totalVotes = useMemo(
    () => poll.options.reduce((acc, option) => acc + option.votesCount, 0),
    [poll]
  )
  const optionWithMostVotes = useMemo(
    () =>
      poll.options.reduce(
        (acc, option) => (option.votesCount > acc.votesCount ? option : acc),
        poll.options[0]
      ),
    [poll]
  )

  const [runVoteMutation] = useSafeMutation(POLL_VOTE_MUTATION)

  const selectOption = useCallback(
    async (optionId: string) => {
      const { data, errors } = await runVoteMutation({
        variables: { input: { pollOptionId: optionId } },
      })

      if (errors || !data?.pollVoteCreate) {
        toast.error("Vote failed")
        return
      }
    },
    [runVoteMutation]
  )

  if (!poll) return <></>
  return (
    <div className={cn("flex flex-col gap-2 mb-6 text-xs", className)}>
      {showVotes && (
        <>
          <div className="flex justify-between items-center">
            <div className="font-semibold">{poll.title}</div>
            <div>👍 Indicates Your Vote</div>
          </div>

          <div className="flex flex-col gap-2">
            {poll.options.map((option) => (
              <div
                className={cn(
                  buttonVariants({ variant: "input" }),
                  "relative overflow-hidden",
                  option.id === optionWithMostVotes?.id &&
                    "border-2 border-primary"
                )}
                key={option.id}
              >
                {totalVotes && (
                  <Progress
                    value={(option.votesCount / totalVotes!) * 100}
                    className={cn(
                      "h-10 absolute left-0 top-0 z-0 rounded-none bg-transparent"
                    )}
                  />
                )}
                <div className="flex w-full justify-between h-10 items-center relative z-10">
                  <div className="flex gap-2 items-center font-semibold">
                    <div>{option.title}</div>
                    {option.currentUserHasVoted && (
                      <SimpleTooltip content="You voted for this option">
                        <div>👍</div>
                      </SimpleTooltip>
                    )}
                  </div>
                  <div className="font-semibold">{option.votesCount}</div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <span className="font-semibold">Total votes:</span> {totalVotes}
          </div>
        </>
      )}

      {!showVotes && (
        <>
          <div className="font-semibold flex justify-between items-center">
            {poll.title}
          </div>
          {poll.options.map((option) => (
            <Button
              variant="input"
              modifiers="inputHover"
              className="justify-center"
              onClick={() => selectOption(option.id)}
              key={option.id}
            >
              {option.title}
            </Button>
          ))}
        </>
      )}
    </div>
  )
}

gql(`
  fragment PollDisplay on Poll {
    id
    title
    editable
    options {
      id
      title
      currentUserHasVoted
      votesCount
    }
  }
`)

export const POLL_QUERY = gql(`
  query PollQuery($pollId: ID!) {
    poll(id: $pollId) {
      ...PollDisplay
    }
  }
`)

const POLL_VOTE_MUTATION = gql(`
  mutation PollVoteMutation($input: PollVoteCreateInput!) {
    pollVoteCreate(input: $input) {
      pollVote {
        option {
          poll {
            ...PollDisplay
          }
        }
      }
    }
  }
`)
