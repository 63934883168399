import { formatCurrency } from "~/common/formatCurrency"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { Alert } from "~/ui/alert"
import { useCommunity } from "~/community/useCommunity"
import { formatDate } from "date-fns"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useTiers } from "~/tiers/TiersProvider"
import { useEffect, useRef } from "react"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

export const PostUpgradeStep = () => {
  const { close, meta } = useWizard()
  const { subscription } = useSubscription()
  const { currentUser } = useCurrentUser()
  const { formatTierName } = useTiers()
  const community = useCommunity()
  const hasLoggedEvent = useRef(false)
  const { logEvent } = useLogEvent()

  const tier = currentUser.tier

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.UpgradeModalConfirmed,
      {
        content_id: meta.contentId,
        source: meta.source || "unknown",
        user_previous_tier: currentUser.previousTier?.level || "unknown",
        stripe_coupon_id: meta.stripeCouponId,
      },
      hasLoggedEvent
    )
  }, [
    logEvent,
    meta.source,
    meta.previousTier,
    meta.contentId,
    meta.stripeCouponId,
    tier,
    currentUser.previousTier,
  ])

  if (!subscription || !tier) return null

  const invoice = subscription.latestInvoice
  const upcomingInvoice = subscription.upcomingInvoice

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          🎉 Welcome to {community.name}{" "}
          {formatTierName(currentUser.tier, currentUser.tierInterval)}
        </DialogTitle>
      </DialogHeader>

      <Alert variant="info" className="flex flex-col gap-4">
        <p>
          You've been upgraded to{" "}
          {formatTierName(currentUser.tier, currentUser.tierInterval)}.
          {invoice &&
            `
            We've charged a prorated amount
            of ${formatCurrency(invoice.amountPaid)} for the remainder of the
            billing period.
          `}
        </p>
        {upcomingInvoice && (
          <p>
            Your next payment of{" "}
            {formatCurrency(upcomingInvoice.amountRemaining)} will be processed
            on {formatDate(subscription.currentPeriodEnd, "MMMM d, yyyy")}.
          </p>
        )}
      </Alert>

      <DialogFooter className="sm:flex-col gap-6 items-center mt-6">
        <Button type="button" variant="link" size="inline" onClick={close}>
          Close
        </Button>
      </DialogFooter>
    </>
  )
}

PostUpgradeStep.displayName = "PostUpgradeStep"
