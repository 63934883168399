import { useCallback, useMemo } from "react"
import toast from "react-hot-toast"
import { Article } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"
import { UPDATE_ARTICLE_MUTATION } from "./ArticleEditor"
import { useTiers } from "~/tiers/TiersProvider"
import { formatArrayToSentence } from "~/common/formatArray"

export type TogglePremiumConfirmProps = {
  article: Pick<Article, "id" | "premium">
} & Partial<ConfirmContextProviderProps>

export const useTogglePremiumConfirm = ({
  article,
  ...confirmProps
}: TogglePremiumConfirmProps) => {
  const showConfirm = useConfirm()
  const [runTogglePremium] = useSafeMutation(UPDATE_ARTICLE_MUTATION)
  const { tiers } = useTiers()

  const isPremium = useMemo(() => article.premium, [article])
  const tiersThatCanViewPremiumContent = useMemo(() => {
    return formatArrayToSentence(
      tiers
        .filter((tier) => {
          return tier.permissions.canViewPremiumArticles
        })
        .map((tier) => tier.name)
    )
  }, [tiers])

  const togglePremium = useCallback(() => {
    showConfirm({
      title: isPremium
        ? "Remove 'Premium Content' Flag"
        : "Mark as Premium Content",
      body: isPremium
        ? "Would you like to remove the 'Premium Content' flag from this content? All members will be able to access this content."
        : `Are you sure you want to mark this content as Premium? This means only members on the ${tiersThatCanViewPremiumContent} plan(s) will be able to access it.`,
      confirmText: isPremium ? "Remove Premium" : "Mark as Premium",
      onConfirm: async () => {
        const { errors } = await runTogglePremium({
          variables: {
            input: {
              articleId: article.id,
              premium: !isPremium,
            },
          },
        })

        if (errors) {
          displayErrors(errors)
          console.log(errors)
        } else {
          toast.success(
            isPremium
              ? "Content no longer marked as premium"
              : "Content marked as premium"
          )
        }
      },
      ...confirmProps,
    })
  }, [
    article,
    confirmProps,
    runTogglePremium,
    showConfirm,
    isPremium,
    tiersThatCanViewPremiumContent,
  ])

  return togglePremium
}
