import invariant from "tiny-invariant"
import { Course_LiveContentFragment } from "~/__generated__/graphql"

export const adjacentCourseArticleIds = (
  course: Course_LiveContentFragment | undefined | null,
  currentArticleId: string
) => {
  if (!course) {
    return { nextArticleId: null, previousArticleId: null }
  }

  const allLessons = course.sections.map((s) => s.lessons).flat()
  const currentArticleIndex = allLessons.findIndex(
    (lesson) => lesson?.article && lesson.article.id === currentArticleId
  )
  invariant(currentArticleId, "expected current article to be in lessons")
  const nextArticleIndex =
    currentArticleIndex + 1 < allLessons.length ? currentArticleIndex + 1 : null
  const previousArticleIndex =
    currentArticleIndex - 1 < 0 ? null : currentArticleIndex - 1

  const nextArticleId =
    nextArticleIndex !== null ? allLessons[nextArticleIndex]!.article!.id : null
  const previousArticleId =
    previousArticleIndex !== null
      ? allLessons[previousArticleIndex]!.article!.id
      : null
  return { nextArticleId, previousArticleId }
}

export const lessonForArticleId = (
  course: Course_LiveContentFragment | undefined | null,
  articleId: string
) => {
  if (!course) return null

  return (
    course?.sections
      .map((s) => s.lessons)
      .flat()
      .find((lesson) => lesson?.article?.id === articleId) || null
  )
}

export const sectionForArticleId = (
  course: Course_LiveContentFragment | undefined | null,
  articleId: string
) => {
  if (!course) return null

  return course?.sections.find((section) =>
    section.lessons.some((lesson) => lesson.article.id === articleId)
  )
}
