import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { PRO_EVENT_ADMIN_UPDATE_MUTATION } from "~/pro-events/pro-events"
import { Switch } from "~/ui/switch"

export const PublishedToggle = ({
  proEventId,
  published,
}: {
  proEventId: string
  published: boolean
}) => {
  const [proEventUpdate, { loading }] = useSafeMutation(
    PRO_EVENT_ADMIN_UPDATE_MUTATION
  )

  const onChange = async (published: boolean) => {
    const { errors } = await proEventUpdate({
      variables: {
        input: {
          proEventId: proEventId,
          published: published,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    }
  }

  return (
    <div>
      <Switch
        checked={published}
        onCheckedChange={(val) => {
          onChange(val)
        }}
        disabled={loading}
      />
    </div>
  )
}
