import { VariantProps, cva } from "class-variance-authority"
import * as React from "react"
import { Link as RouterLink, LinkProps } from "react-router-dom"
import { getMetaVar } from "~/common/getMetaVar"
import { cn } from "~/lib/utils"
import { Button } from "~/ui/button"

const linkVariants = cva("cursor-pointer", {
  variants: {
    variant: {
      default: "underline font-medium text-blue-600 hover:text-blue-600/80",
      nav: "font-bold text-foreground hover:text-foreground/80",
      adminNav: "text-foreground hover:text-foreground/80",
    },
    size: {
      default: "",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
})

interface BaseAnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof linkVariants> {
  method?: "GET" | "POST" | "PUT" | "DELETE"
  requestBody?: Record<string, any>
}
type InternalAnchorProps = BaseAnchorProps & LinkProps

interface AnchorProps extends BaseAnchorProps, Omit<LinkProps, "to"> {
  to?: LinkProps["to"]
}

const isInternal = (props: AnchorProps): props is InternalAnchorProps => {
  return "to" in props
}

const Link = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    { variant, size, className, method = "GET", requestBody = {}, ...props },
    ref
  ) => {
    const csrfToken = getMetaVar("csrf-token")
    const onSubmit = React.useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        if (props.onClick) {
          props.onClick(
            event as unknown as React.MouseEvent<HTMLAnchorElement, MouseEvent>
          )
        }
      },
      [props]
    )

    if (isInternal(props)) {
      return (
        <RouterLink
          className={cn(linkVariants({ variant, size, className }))}
          ref={ref}
          {...props}
        />
      )
    }

    if (method !== "GET") {
      return (
        <form
          action={props.href}
          method={method}
          onSubmit={onSubmit}
          className="inline"
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <Button
            variant="link"
            className={cn(
              "inline p-0",
              linkVariants({ variant, size, className })
            )}
            type="submit"
          >
            {props.children}
          </Button>
          {Object.entries(requestBody).map(([key, value]) => (
            <input key={key} type="hidden" name={key} value={value} />
          ))}
        </form>
      )
    }

    return (
      <a
        className={cn(linkVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {props.children}
      </a>
    )
  }
)
Link.displayName = "Link"

export { Link }
