import { useLazyQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { User_AvatarFragment } from "~/__generated__/graphql"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { USER_DIALOG_QUERY_DOCUMENT } from "./UserDialogContext"
import { UserName } from "./UserName"

type UserNameAndAvatarProps = {
  user?: User_AvatarFragment | null
  userId?: string
}

export const UserNameAndAvatar = ({
  user: userFromProps,
  userId,
}: UserNameAndAvatarProps) => {
  const [user, setUser] = useState<User_AvatarFragment | null>(
    userFromProps || null
  )
  const [fetchUser] = useLazyQuery(USER_DIALOG_QUERY_DOCUMENT)

  useEffect(() => {
    ;(async () => {
      if (!userFromProps && userId) {
        const { data } = await fetchUser({ variables: { userId } })
        if (data?.user) {
          setUser(data.user)
        }
      }
    })()
  }, [userFromProps, userId, fetchUser])

  if (!userFromProps && !userId) {
    throw new Error("Must provide either a user or a userId")
  }

  if (!user) {
    return null
  }

  return (
    <div className="flex items-center gap-4">
      <AvatarWithFallback user={user} />
      <UserName user={user} />
    </div>
  )
}
