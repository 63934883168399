export const getCustomFingerprint = async () => {
  const screenResolution = `${window.screen.width}x${window.screen.height}`
  const devicePixelRatio = window.devicePixelRatio || 1
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const language =
    navigator.language ||
    (navigator as { userLanguage?: string }).userLanguage ||
    "en"

  // Enhanced Font Rendering Fingerprint (Canvas-based with additional fonts)
  const fontRendering = getEnhancedCanvasFontRenderingData()

  const cpuCores = navigator.hardwareConcurrency
  const deviceMemory = navigator.deviceMemory || "unknown"

  // Operating System Detection
  const osDetails = getOSDetails()

  // Storage Quotas (with timeout)
  const storageEstimate = await navigator.storage.estimate()
  const storageQuota = storageEstimate.quota || "unknown"
  const storageUsage = storageEstimate.usage || "unknown"

  const connection = navigator.connection || {}
  const networkType = connection.effectiveType || "unknown"
  const downlink = connection.downlink || "unknown"

  // WebGL Details
  const { webGLVendor, webGLRenderer } = getWebGLDetails()

  const doNotTrack = navigator.doNotTrack || "unspecified"

  const fingerprintString = [
    `screenResolution:${screenResolution}`,
    `devicePixelRatio:${devicePixelRatio}`,
    `timeZone:${timeZone}`,
    `language:${language}`,
    `fontRendering:${fontRendering}`,
    `cpuCores:${cpuCores}`,
    `deviceMemory:${deviceMemory}`,
    `osDetails:${osDetails}`,
    `storageQuota:${storageQuota}`,
    `storageUsage:${storageUsage}`,
    `networkType:${networkType}`,
    `downlink:${downlink}`,
    `webGLVendor:${webGLVendor}`,
    `webGLRenderer:${webGLRenderer}`,
    `doNotTrack:${doNotTrack}`,
  ].join("|")

  return hash(fingerprintString)
}

const hash = async (data: string) => {
  const hashBuffer = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(data)
  )

  const hashArray = Array.from(new Uint8Array(hashBuffer))

  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
}

// Enhanced Font Rendering Fingerprint using additional commonly found fonts
const getEnhancedCanvasFontRenderingData = () => {
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  const fonts = [
    "Comic Sans MS",
    "Arial Black",
    "Lucida Console",
    "Tahoma",
    "Impact",
    "Georgia",
    "Courier",
    "Palatino",
    "Bookman",
    "Trebuchet MS",
  ]

  if (ctx) {
    // Render text in each font and accumulate pixel data
    fonts.forEach((font) => {
      ctx.font = `20px ${font}`
      ctx.fillText("Font Test", 10, 30)
    })
  }

  return ctx ? ctx.getImageData(0, 0, canvas.width, canvas.height).data : ""
}

// OS detection using user agent
const getOSDetails = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.includes("win")) return "Windows"
  if (userAgent.includes("mac")) return "macOS"
  if (userAgent.includes("linux")) return "Linux"
  return "unknown"
}

// Quick WebGL vendor and renderer information
const getWebGLDetails = () => {
  const canvas = document.createElement("canvas")
  const gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl")

  if (!(gl instanceof WebGLRenderingContext)) {
    return { webGLVendor: "unavailable", webGLRenderer: "unavailable" }
  }

  const webGLVendor = gl.getParameter(gl.VENDOR)
  const webGLRenderer = gl.getParameter(gl.RENDERER)

  return { webGLVendor, webGLRenderer }
}
