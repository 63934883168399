import { QueryResult } from "@apollo/client"
import { RoomQueryQuery } from "~/__generated__/graphql"
import { MessageSectionHeader } from "./MessageSectionHeader"
import { Menu, MoreHorizontal } from "lucide-react"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import { LeaveRoomModal } from "./LeaveRoomModal"
import { useState } from "react"
import { StyledDropdown, StyledDropdownItem } from "~/ui/StyledDropdown"
import { AddMembersModal } from "./AddMembersModal"
import { RenameRoomModal } from "./RenameRoomModal"
import { roomDisplayName } from "./utils"
import { UserName } from "~/directory/UserName"

export const MessagesPaneHeader = ({
  roomResult,
  setMobileConversationsOpen,
}: {
  roomResult: QueryResult<RoomQueryQuery, any>
  setMobileConversationsOpen: (open: boolean) => void
}) => {
  const { data } = roomResult
  const otherUser = data?.room.dmOtherUser
  const room = data?.room
  const isRoom = !otherUser
  const { openUserDialog } = useUserDialogContext()

  const [leaveRoomModalOpen, setLeaveRoomModalOpen] = useState(false)
  const [addMembersModalOpen, setAddMembersModalOpen] = useState(false)
  const [renameRoomModalOpen, setRenameRoomModalOpen] = useState(false)

  if (!data) return null

  return (
    <>
      <MessageSectionHeader className="sticky top-0">
        <div
          onClick={() => setMobileConversationsOpen(true)}
          className="mr-4 xl:hidden cursor-pointer"
        >
          <Menu />
        </div>
        {otherUser ? (
          <div className="flex items-center flex-1">
            <AvatarWithFallback
              user={otherUser}
              size="header"
              className="mr-4"
            />
            <div className="text-xs font-semibold">
              <UserName user={otherUser} />
            </div>
          </div>
        ) : (
          <div className="flex-1">
            <div className="font-semibold">{roomDisplayName(data.room)}</div>
          </div>
        )}
        {isRoom && (
          <StyledDropdown
            trigger={
              <div className="cursor-pointer">
                <MoreHorizontal size={16} />
              </div>
            }
          >
            <StyledDropdownItem
              title="Add members"
              onClick={() => setAddMembersModalOpen(true)}
            />
            {room?.currentUserCanUpdate.value && (
              <StyledDropdownItem
                title="Rename Group"
                onClick={() => setRenameRoomModalOpen(true)}
              />
            )}
            <StyledDropdownItem
              title="Leave Group"
              onClick={() => setLeaveRoomModalOpen(true)}
            />
          </StyledDropdown>
        )}
      </MessageSectionHeader>
      {isRoom && (
        <div className="flex flex-wrap gap-1 px-4 py-2 border-b border-mercury">
          {data.room.users.map((user) => (
            <TooltipProvider key={user.id}>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <div
                    className="cursor-pointer"
                    onClick={() => openUserDialog(user.id)}
                  >
                    <AvatarWithFallback user={user} size="preview" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <UserName user={user} />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      )}
      {room && (
        <>
          <LeaveRoomModal
            roomId={room.id}
            isOpen={leaveRoomModalOpen}
            setIsOpen={setLeaveRoomModalOpen}
          />
          <AddMembersModal
            roomId={room.id}
            isOpen={addMembersModalOpen}
            setIsOpen={setAddMembersModalOpen}
            currentMembers={room.users}
          />
          <RenameRoomModal
            roomId={room.id}
            isOpen={renameRoomModalOpen}
            setIsOpen={setRenameRoomModalOpen}
            existingName={room.name}
          />
        </>
      )}
    </>
  )
}
