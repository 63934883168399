import instagram from "~/images/icons/social/instagram.png"

export type SocialMediaType = "linkedin" | "twitter" | "instagram"

export const isSocialMediaType = (name: string): name is SocialMediaType => {
  return ["linkedin", "twitter", "instagram"].includes(name)
}

export const socialMediaUrl = (
  socialMediaType: SocialMediaType,
  username: string
) => {
  if (socialMediaType === "linkedin") {
    if (
      username.startsWith("https://linkedin.com/") ||
      username.startsWith("https://www.linkedin.com/")
    ) {
      return username
    }
    return `https://linkedin.com/in/${username}`
  } else if (socialMediaType === "twitter") {
    return `https://twitter.com/${username}`
  } else if (socialMediaType === "instagram") {
    return `https://instagram.com/${username}`
  }
}

export const InstagramImg = (props: React.ComponentPropsWithoutRef<"img">) => {
  return <img src={instagram} alt="instagram logo" {...props} />
}
