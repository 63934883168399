import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
} from "react"

import * as paths from "~/common/paths"

import { useLocation } from "react-router-dom"
import { useCallback } from "react"
import gql from "graphql-tag"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  InputMaybe,
  Scalars,
  AhoyEventTypeEnum,
  EventPageTypeEnum,
} from "../__generated__/graphql"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import invariant from "tiny-invariant"
import { getMetaVar } from "../common/getMetaVar"
import useTimer from "easytimer-react-hook"
import { usePageVisibility } from "~/common/usePageVisibility"
import { generateUUID } from "../common/generateUUID"
import { useCommunity } from "~/community/useCommunity"
import { useLocalStorage } from "usehooks-ts"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { getCustomFingerprint } from "~/common/customFingerprint"

type LogEventFunction = (
  name: AhoyEventTypeEnum,
  properties?: InputMaybe<Scalars["EventProperties"]["input"]>,
  preventDuplicateRef?: React.MutableRefObject<boolean>
) => Promise<void>

interface EventsContextType {
  logEvent: LogEventFunction
  currentPageviewId: string | null
}

const VISIT_THRESHOLD = 1000 * 60 * 30 // 30 minutes

const qaToolsEnabled = getMetaVar("qa-tools-enabled") === "true"
const EventsContext = createContext<EventsContextType | null>(null)

const fpPromise = FingerprintJS.load()

const getDeviceId = async () => {
  const fp = await fpPromise
  const result = await fp.get()

  return result.visitorId
}

const getViewContext = () => {
  const isPWA = window.matchMedia("(display-mode: standalone)").matches
  return isPWA ? "pwa" : "web"
}

const INACTIVITY_THRESHOLD = 60

export const EventsProvider = ({ children }: { children: ReactNode }) => {
  const origin = window.location.origin
  const location = useLocation()
  const { slug, brand } = useCommunity()
  const [currentPageviewId, setCurrentPageviewId] = useState<string | null>(
    null
  )

  const [timer] = useTimer()
  const isVisible = usePageVisibility()
  const [currentPageTime, setCurrentPageTime] = useState(0)
  const currentPageTimeRef = useRef(currentPageTime)
  const lastTrackedPageviewIdRef = useRef<string | undefined>()

  const { currentUser } = useCurrentUserMaybe()

  useEffect(() => {
    currentPageTimeRef.current = currentPageTime
  }, [currentPageTime])

  useEffect(() => {
    if (!timer.isRunning()) {
      timer.start({
        precision: "seconds",
      })
    }
  }, [timer])

  useEffect(() => {
    const secondsElapsed = timer.getTimeValues().seconds

    if (isVisible === null) {
      if (qaToolsEnabled) {
        console.log(
          "[analytics] - Unable to detect browser visibility, aborting activity tracking"
        )
      }
    } else if (!isVisible || secondsElapsed > INACTIVITY_THRESHOLD) {
      // User has likely stopped interacting with the page
      timer.pause()
    } else {
      timer.start()
    }
  }, [isVisible, timer])

  const [currentPageType, setCurrentPageType] =
    useState<EventPageTypeEnum | null>(null)

  const [runLogAnalyticsEvent] = useSafeMutation(LOG_ANALYTICS_EVENT)

  const [visitHeaderValues, setVisitHeaderValues] = useLocalStorage<
    string | null
  >("visitHeaders", null)

  const visitHeaderValuesRef = useRef(visitHeaderValues)

  const logEvent: LogEventFunction = useCallback(
    async (
      name: AhoyEventTypeEnum,
      properties?: InputMaybe<Scalars["EventProperties"]["input"]>,
      preventDuplicateRef?: React.MutableRefObject<boolean>
    ) => {
      if (preventDuplicateRef) {
        if (preventDuplicateRef.current) {
          return
        }
        preventDuplicateRef.current = true
      }

      const activityProperties = {} as {
        time_on_page: number
        time_since_last_event_in_session: number
      }

      if (isVisible !== null) {
        const activeTime = timer.getTimeValues().seconds
        const sessionTimedOut = activeTime >= INACTIVITY_THRESHOLD
        const activeSecsSinceLastEvent = sessionTimedOut
          ? INACTIVITY_THRESHOLD / 2
          : activeTime

        const newCurrentPageTime =
          name === AhoyEventTypeEnum.PageViewed
            ? 0
            : activeSecsSinceLastEvent + currentPageTimeRef.current

        activityProperties["time_on_page"] = newCurrentPageTime
        activityProperties["time_since_last_event_in_session"] =
          activeSecsSinceLastEvent
      }

      const [deviceId, customFingerprint] = await Promise.all([
        getDeviceId(),
        getCustomFingerprint(),
      ])

      const eventProperties = {
        url: origin + location.pathname,
        device_id: deviceId,
        custom_fingerprint: `${customFingerprint}`,
        pageview_id: currentPageviewId,
        community_name: slug,
        community_brand: brand,
        ...activityProperties,
        ...properties,
      }

      const now = Date.now()
      const currentUserIdMaybe = currentUser ? currentUser.id : ""

      const parseVisitHeaderValues = (
        values: string | null
      ): [string | null, string | null, number | null] => {
        if (!values) return [null, null, null]

        const [visitId, visitorId, timestamp] = values.split(":")

        return [visitId, visitorId, Number(timestamp)]
      }

      let [visitId, visitorId, lastVisitTime] = parseVisitHeaderValues(
        visitHeaderValuesRef.current
      )

      const shouldInitializeNewVisit = () => {
        if (!visitHeaderValuesRef.current) return true

        if (currentUserIdMaybe && visitorId && currentUserIdMaybe !== visitorId)
          return true

        if (lastVisitTime && now - lastVisitTime > VISIT_THRESHOLD) return true

        return false
      }

      if (shouldInitializeNewVisit()) {
        if (qaToolsEnabled) {
          console.log("[analytics] initializing new visit")
        }

        visitId = generateUUID()
        lastVisitTime = now
      }

      const updatedVisitHeaderValues = `${visitId}:${currentUserIdMaybe}:${now}`

      setVisitHeaderValues(updatedVisitHeaderValues)
      visitHeaderValuesRef.current = updatedVisitHeaderValues

      if (qaToolsEnabled) {
        console.log(
          "[analytics]\n - visit_id: %s\n - visitor_id: %s\n - event_name: %s\n - page_type: %s",
          visitId,
          visitorId,
          name,
          currentPageType,
          eventProperties
        )
      }

      runLogAnalyticsEvent({
        variables: {
          input: {
            name,
            pageType: currentPageType,
            properties: eventProperties,
          },
        },
        context: {
          headers: {
            "Ahoy-Visit": visitId,
            "Ahoy-Visitor": visitorId,
          },
        },
      })
      setCurrentPageTime(activityProperties["time_on_page"])
      timer.reset()
    },
    [
      location.pathname,
      origin,
      currentPageviewId,
      currentPageType,
      runLogAnalyticsEvent,
      setCurrentPageTime,
      currentPageTimeRef,
      slug,
      brand,
      timer,
      isVisible,
      currentUser,
      setVisitHeaderValues,
    ]
  )

  useEffect(() => {
    setCurrentPageType(paths.getPageType(location.pathname))
    setCurrentPageviewId(generateUUID())
  }, [location.pathname, setCurrentPageviewId, setCurrentPageType])

  useEffect(() => {
    if (
      currentPageviewId &&
      currentPageviewId !== lastTrackedPageviewIdRef.current
    ) {
      lastTrackedPageviewIdRef.current = currentPageviewId
      logEvent(AhoyEventTypeEnum.PageViewed, {
        view_context: getViewContext(),
      })
    }
  }, [currentPageviewId, logEvent])

  const value = useMemo(() => {
    return {
      logEvent,
      currentPageviewId,
    }
  }, [logEvent, currentPageviewId])

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  )
}

export const useLogEvent = () => {
  const contextValue = useContext(EventsContext)

  invariant(contextValue, "Context has not been Provided!")

  return useMemo(
    () => ({
      logEvent: contextValue.logEvent,
      currentPageviewId: contextValue.currentPageviewId,
    }),
    [contextValue]
  )
}

export const LOG_ANALYTICS_EVENT = gql`
  mutation logAnalyticsEvent($input: LogEventInput!) {
    logEvent(input: $input) {
      event {
        name
      }
    }
  }
`
