import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react"
import { MarkReadEnum } from "~/__generated__/graphql"
import { MARK_READ_MUTATION } from "~/common/userUpdateMutation"
import { useSafeMutation } from "~/common/useSafeMutation"

interface PostReadContextType {
  markRead: (postId: string) => void
}

const PostReadContext = createContext<PostReadContextType | null>(null)

let postIds: string[] = []

export const PostReadProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [runMarkRead] = useSafeMutation(MARK_READ_MUTATION)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (postIds.length === 0) return
      runMarkRead({
        variables: {
          input: {
            target: MarkReadEnum.Posts,
            postIds,
          },
        },
      })
      postIds = []
    }, 500)

    return () => clearInterval(intervalId)
  }, [runMarkRead])

  const markRead = useCallback((id: string) => {
    postIds = [...postIds, id]
  }, [])

  const value = useMemo(() => {
    return { markRead }
  }, [markRead])

  return (
    <PostReadContext.Provider value={value}>
      {children}
    </PostReadContext.Provider>
  )
}

export const usePostRead = () => {
  const contextValue = useContext(PostReadContext)
  if (contextValue === null) {
    throw Error("PostReadContext has not been Provided!")
  }
  return contextValue
}
