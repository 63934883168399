import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { ComposerOnSave, PostComposer } from "~/post-composer/PostComposer"
import { PageWithRightSidebar } from "~/layouts/PageWithRightSidebar"
import { FeedSidebar } from "~/feed/FeedSidebar"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"
import { postPath } from "~/common/paths"
import { Post_CardFragment } from "~/__generated__/graphql"
import { useEffect, useState } from "react"

export const EditPostScreen = () => {
  const { postId } = useParams()
  invariant(postId)

  const [post, setPost] = useState<Post_CardFragment | null>(null)
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const { data, loading, error } = useQuery(EDIT_POST_QUERY_DOCUMENT, {
    variables: { postId },
  })

  useEffect(() => {
    if (data) {
      setPost(data.post)
    }
  }, [data])

  const [runPostUpdate, postUpdateResult] =
    useSafeMutation(POST_UPDATE_MUTATION)

  const afterPostPath = (postId: string) => {
    let newPath = postPath({ postId: postId })
    if (searchParams.get("from") === "channel") {
      newPath += `?${createSearchParams({ from: "channel" })}`
    }
    return newPath
  }

  const updatePost: ComposerOnSave = async ({
    content,
    tagId,
    channelId,
    attachmentIds,
    videoUrl,
  }) => {
    const { data, errors } = await runPostUpdate({
      variables: {
        input: {
          postId,
          tagId,
          channelId,
          content,
          attachmentIds,
          videoUrl,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return false
    } else {
      toast.success("Updated post")
      invariant(data?.postUpdate.post.id)
      setPost(data.postUpdate.post)
      setTimeout(() => navigate(afterPostPath(data.postUpdate.post.id)), 100)
      return true
    }
  }

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading post." />

  return (
    <PageWithRightSidebar sidebar={<FeedSidebar />}>
      {post && (
        <PostComposer
          isEditing
          channels={data.channels}
          tags={data.tags}
          title="Edit Post"
          cancelPath={afterPostPath(postId)}
          post={post}
          author={post.user}
          onSave={updatePost}
          isSaving={postUpdateResult.loading}
        />
      )}
    </PageWithRightSidebar>
  )
}

const EDIT_POST_QUERY_DOCUMENT = gql(`
  query EditPost($postId: ID!) {
    post(postId: $postId) {
      ...Post_Card
    }

    channels {
      ...ChannelConnection_Composer
    }
    tags {
      ...TagConnection_Composer
    }
  }
`)

const POST_UPDATE_MUTATION = gql(`
  mutation PostUpdate($input: PostUpdateInput!) {
    postUpdate(input: $input) {
      post {
        ...Post_Card
      }
    }
  }
`)
