import { SatelliteDish } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { Events } from "~/admin/Events"

export const AdminEventsScreen = () => {
  return (
    <>
      <AdminHeader title="Ahoy Events" Icon={SatelliteDish} />
      <Events />
    </>
  )
}
