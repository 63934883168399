import { ReactNode } from "react"

export const PageWithRightSidebar = ({
  sidebar,
  children,
}: {
  sidebar: ReactNode
  children: ReactNode
}) => {
  return (
    <div className="flex-1 flex">
      <div className="flex-1">{children}</div>
      <div className="hidden md:block w-[300px] ml-5 ">{sidebar}</div>
    </div>
  )
}
