import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import { format } from "date-fns"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Scalars } from "~/__generated__/graphql"
import { UserName } from "~/directory/UserName"
import { Card } from "~/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/ui/table"
import { Button } from "~/ui/button"

const formatDate = (date: Scalars["ISO8601DateTime"]["input"]) => {
  return format(date, "MMM do, yyyy")
}

export const ActivitiesTable = () => {
  const { data, loading, error, refetch } = useQuery(USERS_QUERY_DOCUMENT)

  const [runManualHrisDataDestroy] = useSafeMutation(
    MANUAL_HRIS_DATA_DESTROY_MUTATION
  )

  const [runMergeLinkDestroy] = useSafeMutation(MERGE_LINK_DESTROY_MUTATION)

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading articles." />

  const usersWithHrisData = data.users.nodes.filter(
    (user) => user.hrisIntegration || user.manualHrisData
  )

  return (
    <>
      <Card>
        <Table className="w-full bg-white">
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Source</TableHead>
              <TableHead>Backend</TableHead>
              <TableHead>Last updated</TableHead>
              <TableHead className="text-center">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {usersWithHrisData.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <span className="font-medium">
                    <UserName user={user} />
                  </span>
                  <br />
                  <span className="text-gray-500"> {user.email}</span>
                </TableCell>
                {user.hrisIntegration && (
                  <>
                    <TableCell>
                      <span className="font-medium">Merge</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-medium">
                        {user.hrisIntegration.name}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="font-medium">
                        {user.hrisIntegration.lastSync
                          ? formatDate(user.hrisIntegration.lastSync)
                          : "NA"}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button
                        type="button"
                        onClick={() => {
                          if (!user.hrisIntegration) {
                            return
                          }

                          runMergeLinkDestroy({
                            variables: {
                              input: {
                                id: user.hrisIntegration.id,
                              },
                            },
                          }).then(
                            (data) => {
                              if (data.errors) {
                                displayErrors(data.errors)
                                console.log(data.errors)
                              } else {
                                toast.success("Merge Link Deleted")
                                refetch()
                              }
                            },
                            (errors) => {
                              displayErrors(errors)
                              console.log(errors)
                            }
                          )
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </>
                )}
                {user.manualHrisData && (
                  <>
                    <TableCell>
                      <span className="font-medium">Manual</span>
                    </TableCell>
                    <TableCell>
                      <span className="font-medium">
                        {user.manualHrisData.hrisBackend}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="font-medium">
                        {formatDate(user.manualHrisData.updatedAt)}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Button
                        type="button"
                        onClick={() => {
                          if (!user.manualHrisData) {
                            return
                          }

                          runManualHrisDataDestroy({
                            variables: {
                              input: {
                                id: user.manualHrisData.id,
                              },
                            },
                          }).then(
                            (data) => {
                              if (data.errors) {
                                displayErrors(data.errors)
                                console.log(data.errors)
                              } else {
                                toast.success("Manual Hris Data Deleted")
                                refetch()
                              }
                            },
                            (errors) => {
                              displayErrors(errors)
                              console.log(errors)
                            }
                          )
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </>
  )
}

const USERS_QUERY_DOCUMENT = gql(`
  query AdminBenchmarkData {
    users {
      nodes {
        id
        name
        email
        admin
        hrisIntegration {
          id
          name
          slug
          category
          identifier
          industry
          companySize
          lastSync
          nextSync
          initialSyncComplete
        }
        manualHrisData {
          id
          hrisBackend
          industry
          companySize
          avgCostPerEmployeeDollars
          avgTenureMonths
          avgRetentionPercent
          avgTurnoverPercent
          updatedAt
        }
      }
    }
  }
`)

const MANUAL_HRIS_DATA_DESTROY_MUTATION = gql(`
  mutation ManualHrisDataDestroy($input: ManualHrisDataDestroyInput!) {
    manualHrisDataDestroy(input: $input) {
      manualHrisData {
        id
        hrisBackend
      }
    }
  }
`)

const MERGE_LINK_DESTROY_MUTATION = gql(`
  mutation MergeLinkDestroy($input: MergeLinkDestroyInput!) {
    mergeLinkDestroy(input: $input) {
      mergeLink {
        id
        name
      }
    }
  }
`)
