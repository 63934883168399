import { LucideIcon } from "lucide-react"
import { ReactNode } from "react"

interface AdminHeaderProps {
  title: JSX.Element | string
  subtitle?: JSX.Element | string
  Icon?: LucideIcon
  children?: ReactNode
}

export const AdminHeader = ({
  title,
  subtitle,
  Icon,
  children,
}: AdminHeaderProps) => {
  return (
    <div className="flex items-center justify-start gap-4 my-12">
      {Icon && (
        <div className="flex-shrink">
          <Icon size={54} strokeWidth={1} />
        </div>
      )}

      <div className="flex-grow flex flex-col gap-2">
        <h1 className="text-4xl font-serif">{title}</h1>
        {subtitle && <h2 className="text-sm text-slate-500">{subtitle}</h2>}
      </div>
      <div>{children}</div>
    </div>
  )
}
