import { Card, CardContent } from "~/ui/card"
import { Switch } from "~/ui/switch"

import { zodResolver } from "@hookform/resolvers/zod"
import { Bug } from "lucide-react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { z } from "zod"
import { gql } from "~/__generated__"
import { DebugSettingKeyEnum, JobClassEnum } from "~/__generated__/graphql"
import { AdminHeader } from "~/admin/AdminHeader"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useDebugSettings } from "~/community/useCommunity"
import { Button } from "~/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "~/ui/form"
import { Label } from "~/ui/label"

const FormSchema = z.object({
  autoOnboarding: z.boolean(),
  markFitOnWaitingScreen: z.boolean(),
})

export const AdminDebugSettingsScreen = () => {
  const { debugSettings, loading } = useDebugSettings()
  const [upsertDebugSetting] = useSafeMutation(UPSERT_DEBUG_SETTING_MUTATION)
  const [runJob] = useSafeMutation(RUN_JOB_MUTATION)

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      autoOnboarding: false,
      markFitOnWaitingScreen: false,
    },
  })

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    const results = await Promise.all([
      upsertDebugSetting({
        variables: {
          input: {
            key: DebugSettingKeyEnum.AutoOnboarding,
            valueBoolean: data.autoOnboarding,
          },
        },
      }),

      upsertDebugSetting({
        variables: {
          input: {
            key: DebugSettingKeyEnum.MarkFitOnWaitingScreen,
            valueBoolean: data.markFitOnWaitingScreen,
          },
        },
      }),
    ])

    if (results.some((result) => result.errors)) {
      return toast.error("Failed to save settings")
    }

    toast.success("Settings saved")
  }

  const doRunJob = async (jobClass: JobClassEnum) => {
    const { data, errors } = await runJob({
      variables: {
        input: {
          jobClass,
        },
      },
    })

    if (errors) {
      return toast.error("Failed to run job")
    }

    toast.success(`Job ${data?.jobRun.job.jobId} started`)
  }

  useEffect(() => {
    if (loading || !debugSettings) return

    form.reset({
      autoOnboarding: !!debugSettings[DebugSettingKeyEnum.AutoOnboarding],
      markFitOnWaitingScreen:
        !!debugSettings[DebugSettingKeyEnum.MarkFitOnWaitingScreen],
    })
  }, [form, debugSettings, loading])

  if (loading) {
    return null
  }

  return (
    <>
      <AdminHeader title="Debug Settings" Icon={Bug} />
      <div className="flex flex-col gap-4">
        <Card>
          <CardContent className="pt-4">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full space-y-6"
              >
                <div>
                  <div className="space-y-4">
                    <FormField
                      control={form.control}
                      name="autoOnboarding"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                          <div className="space-y-0.5">
                            <FormLabel className="text-base">
                              Auto Onboarding
                            </FormLabel>
                            <FormDescription>
                              After subscribing via Stripe, automatically
                              complete the application and onboarding processes.
                            </FormDescription>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="markFitOnWaitingScreen"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                          <div className="space-y-0.5">
                            <FormLabel className="text-base">
                              Mark Fit from Waiting Screen
                            </FormLabel>
                            <FormDescription>
                              Show buttons on the waiting screen allowing you to
                              mark the current user as fit or unfit.
                            </FormDescription>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                              disabled={form.getValues("autoOnboarding")}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button type="submit">Save Settings</Button>
                </div>
              </form>
            </Form>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="pt-4 space-y-4">
            <div className="flex flex-row items-center justify-between rounded-lg border p-4">
              <div className="space-y-0.5">
                <Label className="text-base">
                  Fire Celebration Anniversary Events
                </Label>
                <p className="text-sm text-slate-500 dark:text-slate-400">
                  Fire events for users who are celebrating a birthday or
                  anniversary today.
                </p>
              </div>
              <Button
                type="button"
                onClick={() =>
                  doRunJob(JobClassEnum.FireCelebrationAnniversaryEventsJob)
                }
              >
                Fire Events
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

const UPSERT_DEBUG_SETTING_MUTATION = gql(`
  mutation UpsertDebugSetting($input: DebugSettingUpsertInput!) {
    debugSettingUpsert(input: $input) {
      debugSetting {
        id
        key
        value
        valueBoolean
        valueString
        valueInteger
        valueDatetime
        valueJson
      }
    }
  }
`)

export const RUN_JOB_MUTATION = gql(`
  mutation RunJob($input: JobRunInput!) {
    jobRun(input: $input) {
      job {
        jobId
      }
    }
  }
`)
