import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"

export const TAGS_QUERY_DOCUMENT = gql(`
  query Tags {
    tags {
      nodes {
        id
        name
      }
    }
  }
`)

export const useTags = () => {
  const { data } = useQuery(TAGS_QUERY_DOCUMENT)
  return data?.tags.nodes
}
