import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import {
  CelebrationTypeEnum,
  CommunitySlug,
  IndustryEnum,
  JobFunctionEnum,
  VerticalEnum,
} from "~/__generated__/graphql"
import { getMetaVar } from "./getMetaVar"
import { community } from "~/community"

const environment = getMetaVar("environment")

type CommunityTranslations = {
  celebrations: {
    [key in CelebrationTypeEnum]: {
      label: string
      emoji: string
    }
  }
  community: {
    creatorFirstName: string
    directoryLabel: string
  }
  industries: {
    [key in IndustryEnum]: string
  }
  job_functions: {
    [key in JobFunctionEnum]: string
  }
  library: {
    contributeBannerTitle: string
    contributeBannerCopy: string
  }
  offers: {
    plusUpsell: {
      features: string
      title: string
      body: string
      thankYou: string
    }
  }
  onboarding: {
    activateScreenTitle: string
    applicationsPausedModal: {
      title: string
      body: string
      confirmText: string
    }
    homeBaseLabel: string
    bioScreenTitle: string
    bioFieldLabel: string
    bioFieldPlaceholder: string
    interestsScreenTitle: string
    expertiseScreenTitle: string
    introductionsScreen: {
      title: string
      subtitle: string
      introName: string
      introCompany: string
      introBio: string
      introLocation: string
      introTitle: string
    }
    experienceScreen: {
      companySize: string
      industry: string
      jobFunction: string
      linkedinProfileUrl: string
      oops: string
      oops_plural: string
      title: string
      vertical: string
      yearsOfExperience: string
    }
    videoIntro: string
  }
  signin: {
    signinTitle: string
    signinSubtitle: string
  }
  time: {
    dayWithCount: string
    dayWithCount_plural: string
    hourWithCount: string
    hourWithCount_plural: string
    minuteWithCount: string
    minuteWithCount_plural: string
  }
  verticals: {
    [key in VerticalEnum]: string
  }
  unfitScreen: {
    title: string
    body: string
  }
}

const DEFAULT_TRANSLATIONS: CommunityTranslations = {
  celebrations: {
    [CelebrationTypeEnum.Birthday]: {
      label: "Birthday",
      emoji: "🎂",
    },
    [CelebrationTypeEnum.WorkAnniversary]: {
      label: "Work Anniversary",
      emoji: "📆",
    },
    [CelebrationTypeEnum.ToxicWorkplaceBreakup]: {
      label: "Toxic Workplace Breakup Anniversary",
      emoji: "🍾",
    },
    [CelebrationTypeEnum.Other]: {
      label: "Other",
      emoji: "✨",
    },
  },
  community: {
    creatorFirstName: "[CREATOR]",
    directoryLabel: "Directory",
  },
  industries: {
    [IndustryEnum.AccountingFinance]: "Accounting & Finance",
    [IndustryEnum.AerospaceDefense]: "Aerospace & Defense",
    [IndustryEnum.AgricultureFarming]: "Agriculture & Farming",
    [IndustryEnum.Architecture]: "Architecture",
    [IndustryEnum.Art]: "Art",
    [IndustryEnum.Automotive]: "Automotive",
    [IndustryEnum.Beauty]: "Beauty",
    [IndustryEnum.Biotech]: "Biotech",
    [IndustryEnum.CannabisRecreationalSupplements]:
      "Cannabis & Recreational Supplements",
    [IndustryEnum.CommunityOrganizations]: "Community Organizations",
    [IndustryEnum.Construction]: "Construction",
    [IndustryEnum.Consulting]: "Consulting",
    [IndustryEnum.ConsumerPackagedGoods]: "Consumer Packaged Goods",
    [IndustryEnum.ConsumerServices]: "Consumer Services",
    [IndustryEnum.ConsumerTech]: "Consumer Tech",
    [IndustryEnum.CorporateServices]: "Corporate Services",
    [IndustryEnum.Cybersecurity]: "Cybersecurity",
    [IndustryEnum.Design]: "Design",
    [IndustryEnum.Ecom]: "Ecom",
    [IndustryEnum.EducationEdtech]: "Education & Edtech",
    [IndustryEnum.Electronics]: "Electronics",
    [IndustryEnum.Energy]: "Energy",
    [IndustryEnum.Entertainment]: "Entertainment",
    [IndustryEnum.EnvironmentalServices]: "Environmental Services",
    [IndustryEnum.EventProduction]: "Event Production",
    [IndustryEnum.Fashion]: "Fashion",
    [IndustryEnum.FinancialServices]: "Financial Services",
    [IndustryEnum.Fintech]: "Fintech",
    [IndustryEnum.Gaming]: "Gaming",
    [IndustryEnum.GovernmentAffairs]: "Government Affairs",
    [IndustryEnum.Healthcare]: "Healthcare",
    [IndustryEnum.HealthWellness]: "Health & Wellness",
    [IndustryEnum.Hr]: "HR",
    [IndustryEnum.Insurance]: "Insurance",
    [IndustryEnum.It]: "IT",
    [IndustryEnum.Legal]: "Legal",
    [IndustryEnum.LuxuryGoods]: "Luxury Goods",
    [IndustryEnum.Manufacturing]: "Manufacturing",
    [IndustryEnum.Marketing]: "Marketing",
    [IndustryEnum.Media]: "Media",
    [IndustryEnum.Medtech]: "Medtech",
    [IndustryEnum.MentalHealth]: "Mental Health",
    [IndustryEnum.NonProfit]: "Non Profit",
    [IndustryEnum.PetCare]: "Pet Care",
    [IndustryEnum.Policy]: "Policy",
    [IndustryEnum.PublicAffairs]: "Public Affairs",
    [IndustryEnum.PublicRelations]: "Public Relations",
    [IndustryEnum.PublicSafety]: "Public Safety",
    [IndustryEnum.RealEstate]: "Real Estate",
    [IndustryEnum.ResearchAcademia]: "Research & Academia",
    [IndustryEnum.Restaurants]: "Restaurants",
    [IndustryEnum.Retail]: "Retail",
    [IndustryEnum.SoftwareTech]: "Software & Tech",
    [IndustryEnum.Sports]: "Sports",
    [IndustryEnum.SupplyChainLogistics]: "Supply Chain & Logistics",
    [IndustryEnum.Sustainability]: "Sustainability",
    [IndustryEnum.Telecom]: "Telecom",
    [IndustryEnum.Transportation]: "Transportation",
    [IndustryEnum.TravelTourism]: "Travel & Tourism",
    [IndustryEnum.VentureCapitalPrivateEquity]:
      "Venture Capital & Private Equity",
  },
  job_functions: {
    [JobFunctionEnum.Accounting]: "Accounting",
    [JobFunctionEnum.Business]: "Business",
    [JobFunctionEnum.Corporate]: "Corporate",
    [JobFunctionEnum.Finance]: "Finance",
    [JobFunctionEnum.Growth]: "Growth",
    [JobFunctionEnum.Hr]: "HR",
    [JobFunctionEnum.Innovation]: "Innovation",
    [JobFunctionEnum.MA]: "M&A",
    [JobFunctionEnum.Marketing]: "Marketing",
    [JobFunctionEnum.Operations]: "Operations",
    [JobFunctionEnum.Other]: "Other",
    [JobFunctionEnum.Sales]: "Sales",
    [JobFunctionEnum.Strategy]: "Strategy",
  },
  library: {
    contributeBannerTitle: "Contribute to the (private) community library!",
    contributeBannerCopy:
      "Share a guide or resource with your peers! And don't worry, what's shared here, stays here.",
  },
  offers: {
    plusUpsell: {
      title: "Hey {{firstName}},",
      features: `
        <ul>
          <li>📓 Premium Content</li>
          <li>🤝 Curated Introductions</li>
          <li>📩 DMs</li>
          <li>💡 Member-Led Masterminds</li>
        </ul>
      `,
      body: `
        <p>You’ve been loving {{communityName}} and we thought we'd give you a reason to join us as a Plus member so you can get SO. MUCH. MORE. including:</p>
        <Alert>
          $t(plusUpsell.features)
        </Alert>
        <p><strong>Try Plus Quarterly and take {{discountAmount}} off the first Quarter!</strong></p>
        <ClaimButton>Claim Offer</ClaimButton>
        <CreatorSignature/>
        <p>P.S. I'm holding this sweet deal for you for 7 days.</p>
      `,
      thankYou: `
        <h5><CommunityName /> just got so much better!</h5>
        <p>You now have access to:</p>
        <AlertHighlight>
          $t(plusUpsell.features)
        </AlertHighlight>
        <p>Cheers,</p>
        <CreatorSignature />
      `,
    },
  },
  onboarding: {
    activateScreenTitle: "",
    applicationsPausedModal: {
      title: "You're on the waitlist!",
      body: `
        <p>Thank you SO MUCH for applying to join!</p>
        <p>We're currently experiencing a high volume of applications and are working hard to review them all.</p>
        <p>We'll be in touch soon with an update on your application status.</p>
      `,
      confirmText: "Got it!",
    },
    homeBaseLabel: "Your Location",
    bioScreenTitle: "",
    bioFieldLabel: "",
    bioFieldPlaceholder: "",
    interestsScreenTitle: "What are you most excited to learn about?",
    expertiseScreenTitle: "What do you believe you’re an expert in?",
    introductionsScreen: {
      title: "Wait, one more question!",
      subtitle: "",
      introName: "",
      introCompany: "",
      introBio: "",
      introLocation: "",
      introTitle: "",
    },
    experienceScreen: {
      companySize: "What is your company size?",
      industry: "Which industry do you work in?",
      jobFunction: "What is your job function?",
      linkedinProfileUrl: "Please drop your Linkedin profile URL",
      oops: "Welp. We missed asking you this question. Can you help us out and answer this so we can make your membership experience even better?",
      oops_plural:
        "Welp. We missed asking you these questions. Can you help us out and answer these so we can make your membership experience even better?",
      title: "Title",
      vertical: "Which industry vertical do you work in?",
      yearsOfExperience: "How many years of experience do you have?",
    },
    videoIntro:
      "Before you dive in, here's a short video from {{creatorName}}.<br>We're so glad you're here.",
  },
  signin: {
    signinTitle: "Sign in to [COMMUNITY]",
    signinSubtitle: "",
  },
  time: {
    dayWithCount: "{{count}} day",
    dayWithCount_plural: "{{count}} days",
    hourWithCount: "{{count}} hour",
    hourWithCount_plural: "{{count}} hours",
    minuteWithCount: "{{count}} minute",
    minuteWithCount_plural: "{{count}} minutes",
  },
  verticals: {
    [VerticalEnum.Academia]: "Academia",
    [VerticalEnum.AmbulatorySurgicalCenter]: "Ambulatory Surgical Center",
    [VerticalEnum.BankingPe]: "Banking / PE",
    [VerticalEnum.BehavioralHealth]: "Behavioral Health",
    [VerticalEnum.ConsultingAdvisor]: "Consulting / Advisor",
    [VerticalEnum.HealthItSoftwareHealthTech]:
      "Health IT / Software / Health Tech",
    [VerticalEnum.HospitalHealthSystem]: "Hospital / Health System",
    [VerticalEnum.Legal]: "Legal",
    [VerticalEnum.OtherRiskBearingOrgAcoCinEnablement]:
      "Other Risk-Bearing Org (ACO/CIN/Enablement)",
    [VerticalEnum.PayorHealthPlan]: "Payor / Health Plan",
    [VerticalEnum.PhysicianPractice]: "Physician Practice",
    [VerticalEnum.TechEnabledServices]: "Tech-Enabled Services",
  },
  unfitScreen: {
    title: "We're Sorry",
    body: `
      <p>Unfortunately, based on the information you gave us, we won't be able to activate your <CommunityName /> Membership.</p>
      <p>We know this isn't what you want to hear, but rest assured, we are developing future memberships for professionals like you.</p>
      <p>We are excited to connect with you in the future.</p>
      <p>If you have any questions or believe we've made a mistake, please reach out to us at <EmailLink />.</p>
    `,
  },
}

const overrideTranslations = (
  translations: any,
  acc: any = DEFAULT_TRANSLATIONS
) => {
  let result = { ...acc }

  for (const key in translations) {
    if (typeof translations[key] === "string") {
      result[key] = translations[key]
    } else {
      result[key] = overrideTranslations(translations[key], result[key])
    }
  }

  return result
}

export const languageResources: Record<CommunitySlug, any> = {
  [CommunitySlug.Safespace]: overrideTranslations({
    community: {
      creatorFirstName: "Hebba",
    },
    signin: {
      signinTitle: "The HR community that's in your corner",
      signinSubtitle: "For the people prioritizing their people",
    },
    onboarding: {
      activateScreenTitle: "Welcome to your Safe Space",
      bioScreenTitle: "We all have a HR <em>hot</em> take. What's yours?",
      bioFieldLabel:
        "Feel free to also add anything else you'd like displayed on your profile",
      bioFieldPlaceholder:
        "As a new mom, I fight for better parental leave benefits at every organization. I'm also a book worm and love Gone Girl. DM me anytime!",
      introductionsScreen: {
        subtitle:
          "HR is more fun when you can jam with other People Leaders who get it. That’s why our premium memberships includes curated introductions it as a default benefit.",
        introName: "KP Larimore",
        introCompany: "Indiana Packers",
        introBio:
          "Accidentally fell into HR without a formal HR background and education. Strongly feel that experience goes longer than education validation. Always looking to learn different perspectives and ideas.",
        introLocation: "Logansport, IN",
        introTitle: "Sr. HR Generalist & OD Specialist",
      },
      experienceScreen: {
        title: "Your HR experience",
      },
      videoIntro:
        "Hear from Hebba who shares her freshest tips on how to make the most of your membership.",
    },
  }),
  [CommunitySlug.Boardroom]: overrideTranslations({
    community: {
      creatorFirstName: "Blake",
    },
    signin: {
      signinTitle: "Your home base for healthcare strategy",
      signinSubtitle:
        "For the people strengthening the pulse of the healthcare industry",
    },
    offers: {
      plusUpsell: {
        features: `
          <ul>
            <li>
              📈 Deep Dives I spend 20 hours putting together
            </li>
            <li>
              🤝 Curated Introductions
            </li>
            <li>
              📩 Direct Messaging to all community members
            </li>
            <li>
              💡 Fireside Chats
            </li>
          </ul>
        `,
        body: `
          <p>
            You've already added value to this community by being here and I want to make sure you get the COMPLETE Hospitalogy experience. Pair the discussions with Plus membership so you can bring next-level Strategic advice to your organization and grow your rolodex on auto.
          </p>
          <Alert>
            $t(plusUpsell.features)
          </Alert>
          Try Plus Quarterly and take 40% off the first quarter!
          <ClaimButton>Claim Offer</ClaimButton>
          <CreatorSignature />
          <p>
            P.S. I'm holding this offer for your for 7 days in case you can get it expensed
          </p>
        `,
      },
    },
    onboarding: {
      activateScreenTitle: "Welcome to Hospitalogy!",
      bioScreenTitle:
        "How would you introduce yourself to peer healthcare strategists?",
      bioFieldLabel:
        "This short bio will be used to introduce you to peers bi-weekly!",
      bioFieldPlaceholder:
        "Since starting my career in hospital administration, I've forged hundreds of partnerships for new local clinics to start and scale. I live with my Wife and two children in Nashville, TN! You can likely find me reading Blake's Deep Dives or on the golf course.",
      introductionsScreen: {
        title: "Expand your healthcare network via introductions?",
        subtitle:
          "Get relevant introductions to peers in the community. We curate intros carefully based on your location, interests, and title.  <br><br>Introductions are sent directly via email every other Monday morning! You will be able to set up time to connect with a peer by replying to the email or via Direct Messages in the Hospitalogy web app.",
        introName: "Ann Somers Hogg",
        introBio:
          "Ann Somers is the director of health care research at the Clayton Christensen Institute. Her research focuses on business model innovation and disruption, particularly as they relate to drivers of health and maternal health. Prior to joining the Institute, Ann Somers worked for Atrium Health (now Advocate Health), where she most recently served as the AVP of Strategy and Transformation.",
        introLocation: "Richmond, VA",
        introCompany: "Christensen Institute",
        introTitle: "Director, Health Care Research",
      },
      experienceScreen: {
        title: "Your healthcare experience",
        vertical: "Which healthcare vertical do you work in?",
        yearsOfExperience:
          "How many years of healthcare experience do you have?",
      },
    },
    library: {
      contributeBannerTitle:
        "Share resources, research, or notes with the Hospitalogy community",
      contributeBannerCopy:
        "Contribute to the private Hospitalogy library and get listed as an author! Shared knowledge is always better.",
    },
  }),
  [CommunitySlug.Marketingland]: overrideTranslations({
    community: {
      creatorFirstName: "Daniel",
      directoryLabel: "Besties",
    },
    signin: {
      signinTitle: "Where Marketers gather to stay ahead of the curve",
      signinSubtitle:
        "Marketing moves fast.<br>The Marketingland Besties move faster together.",
    },
    offers: {
      plusUpsell: {
        features: `
          <ul>
            <li>
              📈 Relevant, actionable content
            </li>
            <li>
              🤝 Curated Introductions to peer Besties
            </li>
            <li>
              📩 Direct Messaging
            </li>
            <li>
              💡 Coach's Corner Events
            </li>
          </ul>
        `,
        body: `
          <p>
            Joining Marketingland makes you a Bestie, but I want you to have ALL. THE. PERKS. Let's grow together because marketing moves fast, but we can move faster when you have access to everything including:
          </p>
          <Alert>
            $t(plusUpsell.features)
          </Alert>
          Try Plus Quarterly and take 40% off the first quarter!
          <ClaimButton>Claim Offer</ClaimButton>
          <CreatorSignature />
          <p>
            P.S. i'm holding this sweet deal for you for 7 days
          </p>
        `,
      },
    },
    onboarding: {
      activateScreenTitle: "Welcome home,<br>Marketing Bestie",
      applicationsPausedModal: {
        body: `
          <p>
            Thank you SO MUCH for applying to join the Marketingland Membership!
          </p>
          <p>
            We're temporarily pausing new members — want to make sure we get things right for our first 400 Marketing
            Besties on the ground floor.
          </p>
          <p>
            Doors will re-open in a few weeks. Stay tuned...
          </p>
        `,
      },
      bioScreenTitle: "How would you introduce yourself to peers in marketing?",
      bioFieldLabel: "Keep it simple like Sabrina Carpenter, short and sweet",
      bioFieldPlaceholder:
        "I’ve been a B2B marketer for 10 years and let me tell you, I love that memes are a part of my vocabulary now. I live in upstate New York with my partner and two dogs. When I’m not churning out content and ideating on new strategies, you can find me in my backyard gardening!",
      interestsScreenTitle:
        "What are you excited to dive into in the next year?",
      expertiseScreenTitle: "What do you believe is your marketing superpower?",
      introductionsScreen: {
        subtitle:
          "Marketing is more fun when you can jam with other top marketers who get it. That’s why our premium memberships include it as a default benefit.",
        introName: "Jay Schwedelson",
        introCompany: "SubjectLine",
        introBio:
          "Jay is the Founder of SubjectLine.com, the leading free subject-line rating tool, ranked in the top 1% of all websites worldwide. He is also the Founder of GURU Media Hub, which hosts the GURU conference, the world’s largest email marketing event, as well as other major marketing events that attract more than 50,000 attendees annually.",
        introLocation: "Boca Raton, FL",
        introTitle: "Marketing Expert",
      },
      experienceScreen: {
        title: "Your marketing experience",
        yearsOfExperience:
          "How many years of marketing experience do you have?",
      },
    },
  }),
}

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  debug: environment === "development",
  lng: "en-US",
  resources: {
    "en-US": languageResources[community?.slug as CommunitySlug],
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ["br", "strong", "em", "i", "p"], // allow <br/> and simple html elements in translations
  },
})

export default i18n
