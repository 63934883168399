import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, FieldValues, UseFormSetError } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { Button } from "~/ui/button"
import { TextField } from "~/components/forms/TextField"
import { DateField } from "~/components/forms/DateField"
import { ToggleField } from "~/components/forms/ToggleField"
import { UserSelectField } from "~/components/forms/UserSelectField"
import { Form } from "~/ui/form"

const formSchema = z.object({
  title: z.string().min(1, { message: "Required" }),
  description: z.string().min(1, { message: "Required" }),
  date: z.string(),
  hostId: z.string().min(1, { message: "Required" }),
  ctaCopy: z.string().min(1, { message: "Required" }),
  ctaUrl: z.string().min(1, { message: "Required" }),
  published: z.boolean(),
})

export type ProEventFormValues = z.infer<typeof formSchema>

export type SubmitHandlerWithSetError<TFieldValues extends FieldValues> = (
  data: TFieldValues,
  setFieldError: UseFormSetError<TFieldValues>,
  event?: React.BaseSyntheticEvent
) => unknown | Promise<unknown>

export const ProEventForm = ({
  defaultValues,
  onSubmit,
  loading,
  host,
}: {
  defaultValues: ProEventFormValues
  onSubmit: SubmitHandlerWithSetError<ProEventFormValues>
  loading: boolean
  host?: { value: string; label: string }
}) => {
  const form = useForm<ProEventFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  })
  const navigate = useNavigate()

  const handleSubmit = async (data: ProEventFormValues) => {
    onSubmit(data, form.setError)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="flex flex-col gap-4">
          <TextField
            control={form.control}
            name="title"
            label="Title"
            required={true}
          />
          <TextField
            control={form.control}
            name="description"
            label="Description"
            required={true}
          />
          <DateField
            control={form.control}
            name="date"
            label="Event Date"
            required={true}
          />
          <UserSelectField
            control={form.control}
            name="hostId"
            label="Host"
            initialValue={host}
            required={true}
            placeholder="Select a host for the event"
          />
          <TextField
            control={form.control}
            name="ctaCopy"
            label="CTA Copy"
            required={true}
          />
          <TextField
            control={form.control}
            name="ctaUrl"
            label="CTA URL"
            required={true}
          />
          <ToggleField
            control={form.control}
            name="published"
            label="Published"
            required={true}
          />
        </div>

        <div className="h-[1px] bg-gray-300 my-8" />

        <div className="flex justify-end items center gap-4">
          <Button
            type="button"
            className="px-10"
            variant="outline"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="px-10"
            disabled={loading}
            isLoading={loading}
          >
            Save Pro Event
          </Button>
        </div>
      </form>
    </Form>
  )
}
