import { useState, useEffect, useRef } from "react"
import clsx from "clsx"
import ChevronsRight from "../images/icons/chevrons-right.svg?react"

export const HorizontalContainer = ({
  title,
  children,
  headerHidden = false,
}: {
  title?: string
  children: React.ReactNode
  headerHidden?: boolean
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [isScrolledLeft, setIsScrolledLeft] = useState(false)
  const [isScrolledRight, setIsScrolledRight] = useState(false)

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      if (container) {
        const scrollLeft = container.scrollLeft
        const scrollWidth = container.scrollWidth
        const clientWidth = container.clientWidth

        setIsScrolledLeft(scrollLeft > 0)
        setIsScrolledRight(scrollLeft + clientWidth < scrollWidth)
      }
    }

    if (container) {
      container.addEventListener("scroll", handleScroll)

      return () => {
        container.removeEventListener("scroll", handleScroll)
      }
    }
  }, [children])

  return (
    <>
      {!headerHidden && (
        <div className="flex items-center justify-between px-4 mb-2">
          <h3 className="text-sm font-semibold tracking-wide">{title}</h3>
          <ChevronsRight className="ml-1" />
        </div>
      )}
      <div className="horizontal-scroll-container">
        <div
          ref={containerRef}
          className={clsx("horizontal-content", {
            "scrolled-left": isScrolledLeft,
            "scrolled-right": isScrolledRight,
          })}
        >
          {children}
        </div>
      </div>
    </>
  )
}
