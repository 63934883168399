import { useNavigate } from "react-router-dom"
import {
  onboardingExpertisePath,
  onboardingFitWaitingPath,
} from "~/common/paths"
import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { Button, FakeButton } from "~/ui/button"
import { OnboardingTitle } from "~/ui/Onboarding"
import { useState } from "react"
import { Card, CardContent } from "~/ui/card"
import { CalendarDateField } from "~/ui/CalendarDateField"
import { useSafeMutation } from "~/common/useSafeMutation"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { displayErrors } from "~/common/validations"
import { add, startOfDay } from "date-fns"
import { useLogEvent } from "~/analytics/EventsContext"
import {
  AhoyEventTypeEnum,
  CommunitySlug,
  OnboardingStateEnum,
  TierIntervalEnum,
  TierLevelEnum,
  User,
  UserOffer_DisplayFragment,
  UserRoleEnum,
} from "~/__generated__/graphql"
import { sleep } from "~/common/sleep"
import { Check } from "lucide-react"
import { cn } from "~/lib/utils"
import { Trans, useTranslation } from "react-i18next"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import EnvelopeOpen from "~/images/icons/envelope-open.svg?react"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import kpIntroImage from "~/images/kp-intro.png"
import annIntroImage from "~/images/ann-intro.jpeg"
import jsIntroImage from "~/images/js_portrait.jpg"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useCommunity } from "~/community/useCommunity"
import { UserName } from "~/directory/UserName"
import { useUserOffers } from "~/offers/UserOffersProvider"
import { useTiers } from "~/tiers/TiersProvider"

export const OnboardingIntroductionsScreen = () => {
  const navigate = useNavigate()
  const [snoozeDialogOpen, setSnoozeDialogOpen] = useState(false)
  const [date, setDate] = useState<Date | undefined>(undefined)
  const { logEvent } = useLogEvent()
  const [indicateOptIn, setIndicateOptIn] = useState(false)
  const [indicateSnooze, setIndicateSnooze] = useState(false)
  const { t } = useTranslation("onboarding")
  const { currentUser } = useCurrentUser()
  const canUseIntroductions = currentUser.permissions.canUseIntroductions
  const { openSubscriptionWizard } = useSubscription()
  const community = useCommunity()
  const { plusTier } = useTiers()

  const [runUserUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)

  const introUserImage: Record<CommunitySlug, string> = {
    BOARDROOM: annIntroImage,
    SAFESPACE: kpIntroImage,
    MARKETINGLAND: jsIntroImage,
  }

  const onComplete = async (snoozeUntil?: Date | null) => {
    if (snoozeUntil) {
      setIndicateSnooze(true)
    } else {
      setIndicateOptIn(true)
    }
    await sleep(1000)

    const introductionsSnoozedUntil = snoozeUntil
      ? startOfDay(snoozeUntil).toISOString()
      : null

    const { errors } = await runUserUpdate({
      variables: {
        input: {
          introductionsSnoozedUntil,
          onboardingState: OnboardingStateEnum.FitWaiting,
        },
      },
    })
    if (errors) {
      displayErrors(errors)
    } else {
      if (introductionsSnoozedUntil) {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "SNOOZED",
        })
      } else {
        logEvent(AhoyEventTypeEnum.IntroductionPreferenceSelected, {
          introduction_preference: "ACTIVE",
        })
      }
    }
    navigate(onboardingFitWaitingPath.pattern)
  }

  const { getBestUserOffer } = useUserOffers()
  const seeUpgradeOptions = () => {
    let bestOffer: UserOffer_DisplayFragment | null = null

    if (
      currentUser.tier?.level === TierLevelEnum.Free &&
      plusTier?.permissions.canUseIntroductions
    ) {
      bestOffer =
        bestOffer ||
        getBestUserOffer(TierLevelEnum.Plus, TierIntervalEnum.Quarter)
      bestOffer =
        bestOffer || getBestUserOffer(TierLevelEnum.Plus, TierIntervalEnum.Year)
    }

    if (currentUser.tier?.level !== TierLevelEnum.Pro) {
      bestOffer =
        bestOffer ||
        getBestUserOffer(TierLevelEnum.Pro, TierIntervalEnum.Quarter)
      bestOffer =
        bestOffer || getBestUserOffer(TierLevelEnum.Pro, TierIntervalEnum.Year)
    }

    if (bestOffer) {
      openSubscriptionWizard("SpecialOfferStep", {
        offer: bestOffer.offer,
        source: "OnboardingIntroductionsScreen",
      })
    } else {
      openSubscriptionWizard("PricingTableStep", {
        source: "OnboardingInstructionsScreen",
        requiredFeature: "canUseIntroductions",
      })
    }
  }

  return (
    <OnboardingLayout
      step={3}
      hideCommunityFooter
      fullWidth
      containerInnerClass="max-w-[750px]"
    >
      <OnboardingTitle>
        <Trans i18nKey="introductionsScreen.title" ns="onboarding" />
      </OnboardingTitle>

      <div className="text-center mb-10">
        <Trans i18nKey="introductionsScreen.subtitle" ns="onboarding" />

        {currentUser.tier?.level === TierLevelEnum.Free &&
          " Get introductions by upgrading to a premium membership today."}
      </div>

      <div className="flex items-center flex-wrap md:flex-nowrap w-[100%]">
        <IntroductionCard user={currentUser} />
        <div className="text-[44px] md:mx-4 my-2 w-full md:w-[unset] flex justify-center">
          🤝
        </div>
        <IntroductionCard
          user={{
            id: "intro-user",
            name: t("introductionsScreen.introName"),
            photoUrl: introUserImage[community.slug],
            companyName: t("introductionsScreen.introCompany"),
            jobTitle: t("introductionsScreen.introTitle"),
            placeString: t("introductionsScreen.introLocation"),
            bio: t("introductionsScreen.introBio"),
            role: UserRoleEnum.Member,
          }}
        />
      </div>

      <div className="flex-col border-t border-pretext-light-gray pt-8 border-opacity-30 mt-8">
        {canUseIntroductions ? (
          <>
            <div className="flex flex-col items-center">
              <Button
                variant="onboarding"
                type="button"
                onClick={() => onComplete()}
                disabled={indicateOptIn}
                className="min-w-[400px] disabled:opacity-100 py-4 text-base"
              >
                {indicateOptIn ? <Check /> : "Sounds good, I'm in"}
              </Button>
            </div>
            <div
              className="mt-8 onboarding-footer-link"
              onClick={() => {
                setSnoozeDialogOpen(true)
                setTimeout(
                  () =>
                    window.scrollTo({
                      left: 0,
                      top: document.body.scrollHeight,
                      behavior: "smooth",
                    }),
                  100
                )
              }}
            >
              No thanks, I'd rather not
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center">
              <Button
                variant="onboarding"
                type="button"
                onClick={seeUpgradeOptions}
                className="min-w-[400px] disabled:opacity-100 py-4 text-base"
              >
                See Upgrade Options
              </Button>
            </div>
            <div
              className="mt-8 onboarding-footer-link flex justify-center"
              onClick={() => onComplete(add(new Date(), { years: 50 }))}
            >
              {indicateSnooze ? (
                <Check />
              ) : (
                "No thanks, I'll stick to the free tier"
              )}
            </div>
          </>
        )}

        {!snoozeDialogOpen && (
          <div
            className="!mt-20 onboarding-footer-link text-xs"
            onClick={() => navigate(onboardingExpertisePath.pattern)}
          >
            Back
          </div>
        )}
      </div>

      {snoozeDialogOpen && (
        <>
          <Card className="mt-12 px-6 py-8">
            <CardContent>
              <div className="font-semibold mb-3">
                Not ready for intros? No problem.
              </div>
              <div className="mb-5">
                You can pause introductions for now. We’ll hold on introducing
                you to peers until after your snooze date. You can adjust this
                in your settings later anytime
              </div>
              <div className="flex items-center">
                <div className="mr-4">Snooze until:</div>
                <div className="flex-1">
                  <CalendarDateField
                    date={date}
                    onChangeDate={setDate}
                    fromYear={new Date().getFullYear()}
                    toYear={new Date().getFullYear() + 2}
                    disabled={{
                      before: new Date(),
                      after: add(new Date(), { years: 2 }),
                    }}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
          <div className="flex justify-center mt-4">
            <Button
              type="button"
              variant="onboarding"
              className={cn(
                "text-lg py-2 px-8 min-w-[150px]",
                indicateSnooze && "disabled:opacity-100"
              )}
              disabled={!date || loading || indicateSnooze}
              onClick={() => onComplete(date)}
            >
              {indicateSnooze ? <Check /> : "Continue"}
            </Button>
          </div>
        </>
      )}
    </OnboardingLayout>
  )
}

interface CardUser
  extends Pick<
    User,
    "id" | "name" | "photoUrl" | "jobTitle" | "companyName" | "bio" | "role"
  > {
  placeString?: string | null
  place?: { full: string } | null
}

const IntroductionCard = ({ user }: { user: CardUser }) => {
  const location = user.placeString || user.place?.full || ""

  return (
    <Card className="p-4 self-stretch flex flex-col w-full md-w-[unset]">
      <div className="flex gap-2 items-start">
        <AvatarWithFallback user={user} size="2xl" />
        <div className="flex-1 mt-2">
          <div className="text-sm font-semibold">
            <UserName user={user} />
          </div>
          {user.jobTitle && user.jobTitle.length > 0 && (
            <div className="mt-[2px] text-2xs">{user.jobTitle}</div>
          )}
          {(user.companyName?.length || location?.length) && (
            <div className={cn("mt-[2px] text-[#999999] text-2xs")}>
              {user.companyName}
              {user.companyName?.length && location?.length ? " - " : ""}
              {location}
            </div>
          )}
        </div>
      </div>
      <div className="my-4 text-xs">{user.bio}</div>
      <FakeButton className="text-2xs mt-auto">
        <EnvelopeOpen className="mr-2" />
        Message
      </FakeButton>
    </Card>
  )
}
