export const commaSeparatedList = (list: string[]) => {
  return list.reduce((acc, item, index) => {
    if (index === 0) {
      return item
    } else if (index === list.length - 1) {
      return `${acc} and ${item}`
    } else {
      return `${acc}, ${item}`
    }
  }, "")
}
