import { useEffect, useRef, useState } from "react"

export const useScrollingStopped = ({
  stoppedForMs,
}: {
  stoppedForMs: number
}) => {
  const [scrollingStopped, setScrollingStopped] = useState(false)
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (scrollingStopped) return

    const scrollListener = () => {
      if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current)

      scrollTimeoutRef.current = setTimeout(function () {
        console.log("User has stopped scrolling for n MS")
        setScrollingStopped(true)
      }, stoppedForMs)
    }

    window.addEventListener("scroll", scrollListener)

    return () => {
      window.removeEventListener("scroll", scrollListener)
      if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current)
    }
  }, [scrollingStopped, stoppedForMs])

  return scrollingStopped
}
