import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const BENCHMARK_QUERY_DOCUMENT = gql(`
  query Benchmarks($locations: [Location!], $industries: [IndustryEnum!], $companySizes: [CompanySize!], $startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
    benchmarks(locations: $locations, industries: $industries, companySizes: $companySizes, startDate: $startDate, endDate: $endDate) {
      costPerEmployee {
        data {
          name
          user
          community
          benchmark
        }
      }
      tenure {
        data {
          name
          user
          community
          benchmark
        }
      }
      turnover {
        data {
          name
          user
          community
          benchmark
        }
      }
      retention {
        data {
          name
          user
          community
          benchmark
        }
      }
    }
  }
`)
