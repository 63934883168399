import { PollEditor, PollEditorFragment } from "~/polls/PollEditor"
import {
  API,
  BlockAPI,
  BlockToolConstructorOptions,
  ToolConfig,
} from "@editorjs/editorjs"
import { ReactPortal } from "react"
import BarChartIcon from "~/images/icons/bar-chart.svg?raw"
import invariant from "tiny-invariant"

interface PollConfig extends ToolConfig {
  createPortal: (
    blockId: string,
    node: React.ReactNode,
    container: HTMLElement
  ) => ReactPortal
  removePortal: (blockId: string) => void
}

interface PollData {
  pollId: string
}

interface PollParams extends BlockToolConstructorOptions {
  data: PollData
  config?: PollConfig
  block: BlockAPI
}

export class PollTool {
  api: API
  block: BlockAPI
  pollId: string | null = null
  createPortal: PollConfig["createPortal"]
  removePortal: PollConfig["removePortal"]
  portal: ReactPortal | null = null

  constructor(config: PollParams) {
    invariant(config.config?.createPortal, "createPortal is required in config")

    this.block = config.block
    this.api = config.api
    this.pollId = config.data.pollId || null
    this.createPortal = config.config.createPortal
    this.removePortal = config.config.removePortal
    return this
  }

  static get toolbox() {
    return {
      title: "Poll",
      icon: BarChartIcon,
    }
  }

  render() {
    const rootEl = document.createElement("div")
    this.portal = this.createPortal(
      this.block.id,
      <PollEditor
        poll={this.pollId}
        openIfEmpty
        onPollChange={(poll) => this.onPollChange(poll)}
        tool={this}
      />,
      rootEl
    )
    return rootEl
  }

  onPollChange(poll: PollEditorFragment) {
    this.pollId = poll.id
  }

  remove() {
    const totalBlocks = this.api.blocks.getBlocksCount()
    for (let i = 0; i < totalBlocks; i++) {
      const block = this.api.blocks.getBlockByIndex(i)
      if (block && block.id === this.block.id) {
        this.api.blocks.delete(i)
        break
      }
    }
  }

  removed() {
    this.removePortal(this.block.id)
  }

  save() {
    return {
      pollId: this.pollId,
    }
  }
}
