import { LoadingIndicator } from "~/ui/LoadingIndicator"

export const LoadingStep = () => {
  return (
    <div className="h-72 flex justify-center items-center">
      <LoadingIndicator />
    </div>
  )
}

LoadingStep.displayName = "LoadingStep"
