import { User } from "~/__generated__/graphql"
import { dmPath } from "~/common/paths"
import { cn } from "~/lib/utils"
import { UserName } from "~/directory/UserName"
import { LinkButton } from "~/ui/button"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"

export const ArticleLockedBanner = ({
  lockedByUser,
}: {
  lockedByUser?: Pick<
    User,
    | "id"
    | "name"
    | "firstName"
    | "lastName"
    | "photoUrl"
    | "coach"
    | "admin"
    | "role"
  >
}) => {
  if (!lockedByUser) return null

  return (
    <div className="bg-white py-3 px-4 flex items-center rounded-2xl border border-mercury mb-6">
      <div
        key={lockedByUser.id}
        className={cn(
          "rounded-full border border-[#FBBC05] p-[2px] w-[38px] h-[38px] bg-white mr-4"
        )}
      >
        <AvatarWithFallback user={lockedByUser} />
      </div>

      <div className="flex-1">
        <div className="text-sm">
          <span className="font-semibold">
            <UserName user={lockedByUser} />
          </span>{" "}
          is currently working on this content...
        </div>
        <div className="text-2xs text-[#666]">
          Note: Editing content while another collaborator is using the edit
          could overwrite content.
        </div>
      </div>

      <LinkButton
        className="text-xs"
        to={dmPath({ otherUserId: lockedByUser.id })}
      >
        Message
      </LinkButton>
    </div>
  )
}
