import { TooltipContentProps, TooltipProps } from "@radix-ui/react-tooltip"
import React from "react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"

interface SimpleTooltipProps extends Omit<TooltipContentProps, "content"> {
  content?: JSX.Element | string | null
  children: JSX.Element | string
  cursor?: string
  delayDuration?: TooltipProps["delayDuration"]
  enabled?: boolean
}

export const SimpleTooltip = ({
  content,
  children,
  cursor = "help",
  delayDuration = 0,
  enabled = true,
  ...contentProps
}: SimpleTooltipProps) =>
  !content || !enabled ? (
    <>{children}</>
  ) : (
    <TooltipProvider>
      <Tooltip delayDuration={delayDuration}>
        <TooltipTrigger asChild className={`!cursor-${cursor}`}>
          {typeof children === "string" ? <span>{children}</span> : children}
        </TooltipTrigger>
        <TooltipContent {...contentProps}>{content}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
