import { useEffect } from "react"
import { useCommunityFeatures } from "~/community/useCommunity"
import { useNavigate } from "react-router-dom"
import { feedPath } from "~/common/paths"
import { FeatureFlag } from "~/common/FeatureFlag"

export const AskTheBotScreen = () => {
  const features = useCommunityFeatures()
  const navigate = useNavigate()

  useEffect(() => {
    if (!(FeatureFlag.get("delphiBot") && features.AskTheBot)) {
      navigate(feedPath({}))
    }
  }, [features, navigate])

  if (!(FeatureFlag.get("delphiBot") && features.AskTheBot)) {
    return null
  }

  return (
    <div className="mt-10 flex-grow rounded-2xl overflow-hidden">
      <iframe
        title="Chat with Hebba"
        src="https://www.delphi.ai/web_embed/490a10cd-cb06-4ba7-8bb4-e4a2f9f6d372"
        width="100%"
        height="810px"
        allow="microphone"
      ></iframe>
    </div>
  )
}
