import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

export const useReactions = ({ postId }: { postId: string }) => {
  const [runReactionCreate] = useSafeMutation(REACTION_CREATE_MUTATION)

  const addReaction = async (emoji: string) => {
    const { errors } = await runReactionCreate({
      variables: {
        input: {
          emoji,
          postId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    }
  }

  const [runReactionDestroy] = useSafeMutation(REACTION_DESTROY_MUTATION)

  const removeReaction = async (emoji: string) => {
    const { errors } = await runReactionDestroy({
      variables: {
        input: {
          emoji,
          postId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    }
  }

  return { addReaction, removeReaction }
}

const REACTION_CREATE_MUTATION = gql(`
  mutation ReactionCreate($input: ReactionCreateInput!) {
    reactionCreate(input: $input) {
      post {
        ...Post_Card
      }
    }
  }
`)

const REACTION_DESTROY_MUTATION = gql(`
  mutation ReactionDestroy($input: ReactionDestroyInput!) {
    reactionDestroy(input: $input) {
      post {
        ...Post_Card
      }
    }
  }
`)
