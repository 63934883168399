import { gql } from "~/__generated__/gql"
import { toDate, formatInTimeZone } from "date-fns-tz"

export const PRO_EVENT_FIXED_TIMEZONE = "America/New_York"

export const PRO_EVENTS_ADMIN_LIST_QUERY_DOCUMENT = gql(`
  query AdminProEventsList($after: String) {
    proEvents(first: 20, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          title
          date
          published
        }
      }
    }
  }
`)

export const PRO_EVENTS_LIST_QUERY_DOCUMENT = gql(`
  query MemberProEventsList($limit: Int) {
    proEvents(first: $limit, published: true, sort: soonest) {
      edges {
        node {
          id
          title
          description
          date
          host {
            ...User_Avatar
          }
          ctaCopy
          ctaUrl
        }
      }
    }
  }
`)

gql(`
  fragment ProEvent_Admin on ProEvent {
    id
    title
    description
    date
    host {
      ...User_Avatar
    }
    ctaCopy
    ctaUrl
    published
  }
`)

export const PRO_EVENT_ADMIN_QUERY_DOCUMENT = gql(`
  query ProEvent($proEventId: ID!) {
    proEvent(proEventId: $proEventId) {
      ...ProEvent_Admin
    }
  }
`)

export const PRO_EVENT_ADMIN_CREATE_MUTATION = gql(`
  mutation ProEventCreate($input: ProEventCreateInput!) {
    proEventCreate: proEventCreate(input: $input) {
      proEvent {
        ...ProEvent_Admin
      }
    }
  }
`)

export const PRO_EVENT_ADMIN_UPDATE_MUTATION = gql(`
  mutation ProEventUpdate($input: ProEventUpdateInput!) {
    proEventUpdate: proEventUpdate(input: $input) {
      proEvent {
        ...ProEvent_Admin
      }
    }
  }
`)

export const parseDateField = (dateString: string) => {
  return toDate(dateString, { timeZone: PRO_EVENT_FIXED_TIMEZONE })
}

export const utcDateToIntroductionsDateField = (date: Date) => {
  return formatInTimeZone(date, PRO_EVENT_FIXED_TIMEZONE, "yyyy-MM-dd")
}
