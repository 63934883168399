import { Dialog, DialogContent } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { useEffect } from "react"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import invariant from "tiny-invariant"

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Please enter a group name",
  }),
})

export type FormValues = z.infer<typeof formSchema>

export const RenameRoomModal = ({
  isOpen,
  setIsOpen,
  roomId,
  existingName,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  roomId: string
  existingName?: string | null
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
    },
  })

  const [runMutation, mutationResult] = useSafeMutation(ROOM_UPDATE_MUTATION)

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runMutation({
      variables: {
        input: {
          name: values.name,
          roomId,
        },
      },
    })
    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      invariant(data)
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen === true) return
    form.reset()
    form.setValue("name", existingName || "")
  }, [isOpen, form, existingName])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="mb-6 text-center font-serif text-xl">
          Create a Group
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col mb-4 gap-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required className="block">
                      Group name
                    </FormLabel>
                    <FormControl>
                      <input
                        type="text"
                        className="bg-white w-full border-mercury rounded-lg py-2 px-4"
                        placeholder="Friday Happy Hour Group"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="h-[1px] bg-gray-300 my-8" />

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                className="px-10"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="px-10"
                isLoading={mutationResult.loading}
                disabled={mutationResult.loading}
              >
                Save
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const ROOM_UPDATE_MUTATION = gql(`
  mutation RoomNameUpdateModal($input: RoomUpdateInput!) {
    roomUpdate(input: $input) {
      room {
        id
        name
      }
    }
  }
`)
