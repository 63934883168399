import { Link } from "react-router-dom"
import { useCommunity } from "~/community/useCommunity"
import SadComputer from "~/images/error-screen.png"
import { buttonVariants } from "~/ui/button"
import XOctogon from "~/images/x-octogon.svg?react"
import { useAuthenticatedLayout } from "~/layouts/AuthenticatedLayout"
import { useEffect } from "react"

export const NotFoundScreen = () => {
  const community = useCommunity()
  const { setLeftSidebar, resetLeftSidebar } = useAuthenticatedLayout()

  useEffect(() => {
    setLeftSidebar(null)
    return () => resetLeftSidebar()
  }, [setLeftSidebar, resetLeftSidebar])

  return (
    <div className="flex items-center min-h-[50vh] w-full">
      <div className="flex flex-col items-center space-y-4 text-foreground text-center mx-auto">
        {community.slug === "SAFESPACE" ? (
          <img
            src={SadComputer}
            alt="Sad Computer"
            className={"w-[120px] h-[120px]"}
          />
        ) : (
          <XOctogon className="w-[84px] h-[84px]" />
        )}
        <h1 className="text-6xl font-semibold">Error 404</h1>
        <p className="text-sm font-medium">
          Whoops! You've landed on a 404 page.
          <br />
          Go back to the community instead?
        </p>
        <Link to="/" className={buttonVariants({ variant: "default" })}>
          Back to the Community
        </Link>
      </div>
    </div>
  )
}
