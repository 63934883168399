import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

const CONFIRMATION_MESSAGE = "Are you sure you want to delete this post?"

export const usePostDestroy = ({ postId }: { postId: string }) => {
  const [runPostDestroy] = useSafeMutation(POST_DESTROY_MUTATION)

  const destroyPostWithConfirmation = async () => {
    if (!window.confirm(CONFIRMATION_MESSAGE)) {
      return false
    }

    const { errors } = await runPostDestroy({
      variables: {
        input: {
          postId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return false
    } else {
      toast.success("Post deleted.")
      return true
    }
  }

  return { destroyPostWithConfirmation }
}

const POST_DESTROY_MUTATION = gql(`
  mutation PostDestroy($input: PostDestroyInput!) {
    postDestroy(input: $input) {
      post {
        ...Post_Card

        parentPost {
          ...Post_Card
        }
      }
    }
  }
`)
