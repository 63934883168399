import { getMetaVar } from "./getMetaVar"
import { Base64 } from "js-base64"

class FeatureFlag_ {
  flags: Record<string, boolean>

  constructor() {
    this.flags = JSON.parse(Base64.decode(getMetaVar("feature-flags")))
  }

  get(flagName: string) {
    const value = this.flags[flagName]

    if (value === undefined) {
      throw new Error(`unknown flag: ${flagName}`)
    }

    return value
  }
}

export const FeatureFlag = new FeatureFlag_()
