import { Construction, Layout } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { Card, CardContent } from "~/ui/card"

export const AdminDashboardScreen = () => {
  return (
    <>
      <AdminHeader title="Dashboard" Icon={Layout} />
      <Card className="sm:max-w-3xl mx-auto my-32">
        <CardContent className="p-24">
          <div className="flex justify-center text-foreground text-4xl font-bold items-center gap-6">
            <Construction size={64} />
            <div>Coming soon!</div>
          </div>
        </CardContent>
      </Card>
    </>
  )
}
