import { useQuery } from "@apollo/client"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { adminIntroductionsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import {
  INTRODUCTION_CYCLES_ADMIN_UPDATE_MUTATION,
  INTRODUCTION_CYCLES_ADMIN_FIND_QUERY_DOCUMENT,
} from "~/introduction-cycles/api"
import {
  IntroductionCycleForm,
  IntroductionCycleFormValues,
  parseIntroductionsDateField,
  utcDateToIntroductionsDateField,
} from "~/introduction-cycles/IntroductionCycleForm"
import { Error } from "~/ui/Error"
import { LoadingIndicator } from "~/ui/LoadingIndicator"

import { parseISO } from "date-fns"
import { HeartHandshake } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"
import { Card, CardContent } from "~/ui/card"

export const AdminEditIntroductionCycleScreen = () => {
  const { introductionCycleId } = useParams()
  invariant(introductionCycleId)
  const navigate = useNavigate()

  const { data, loading } = useQuery(
    INTRODUCTION_CYCLES_ADMIN_FIND_QUERY_DOCUMENT,
    {
      variables: {
        introductionCycleId: introductionCycleId,
      },
    }
  )
  const [runIntroductionCycleUpdate, introductionCycleUpdateResult] =
    useSafeMutation(INTRODUCTION_CYCLES_ADMIN_UPDATE_MUTATION)

  const onSubmit = async (values: IntroductionCycleFormValues) => {
    const { errors } = await runIntroductionCycleUpdate({
      variables: {
        input: {
          introductionCycleId: introductionCycleId,
          name: values.name,
          sendAt: parseIntroductionsDateField(values.sendAt).toISOString(),
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      toast.success("Updated introduction cycle")
      navigate(adminIntroductionsPath.pattern)
    }
  }

  console.log({ data })

  return (
    <>
      <AdminHeader
        title="Introductions"
        subtitle={`Edit Introduction Cycle: ${data?.introductionCycle?.name}`}
        Icon={HeartHandshake}
      />
      {loading ? (
        <LoadingIndicator />
      ) : data?.introductionCycle ? (
        <Card>
          <CardContent className="pt-4">
            <IntroductionCycleForm
              defaultValues={{
                name: data.introductionCycle.name,
                sendAt: utcDateToIntroductionsDateField(
                  parseISO(data.introductionCycle.sendAt)
                ),
              }}
              onSubmit={onSubmit}
              loading={introductionCycleUpdateResult.loading}
            />
          </CardContent>
        </Card>
      ) : (
        <Error message="Error loading data." />
      )}
    </>
  )
}
