import { useCommunity } from "~/community/useCommunity"
import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { useWizard } from "~/ui/Wizard"

export const ResumedStep = () => {
  const community = useCommunity()
  const { close } = useWizard()

  return (
    <>
      <DialogHeader>
        <DialogTitle>We’re so glad you’ll stay.</DialogTitle>
      </DialogHeader>
      <Alert className="flex gap-2 justify-center items-center">
        <div>🎉</div>
        <div>Cheers to another year of {community.name}</div>
      </Alert>

      <Separator />

      <DialogFooter className="justify-center">
        <Button onClick={close}>Back to Membership</Button>
      </DialogFooter>
    </>
  )
}

ResumedStep.displayName = "ResumedStep"
