import { useMemo } from "react"
import { gql } from "~/__generated__"
import { CommunitySlug } from "~/__generated__/graphql"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import {
  AhoyEventTypeEnum,
  Celebration,
  User_CardFragment,
} from "~/__generated__/graphql"
import { Link } from "~/ui/Link"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { cn } from "~/lib/utils"
import { socialMediaUrl, InstagramImg } from "~/common/social-media"
import { dmPath, postPath } from "~/common/paths"

import { LinkButton } from "~/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/ui/card"
import { Dialog, DialogContent } from "~/ui/dialog"

import { PostPreview } from "~/components/PostPreview"

import DialogClose from "~/images/icons/dialog-close.svg?react"
import EnvelopeOpen from "~/images/icons/envelope-open.svg?react"
import LinkedinIcon from "~/images/icons/social/linkedin.svg?react"
import TwitterIcon from "~/images/icons/social/x.svg?react"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/ui/tabs"
import { ShowCelebration } from "~/celebrations"
import { ContentTypeIconWithToolTip } from "~/ui/TagBubble"
import { articlePath, libraryPath } from "~/common/paths"
import ArrowRight from "~/images/icons/arrow-right.svg?react"
import HeartHandshake from "~/images/icons/heart-handshake.svg?react"
import { useCommunityClassname } from "~/community/useCommunity"
import { format, parseISO } from "date-fns"
import { UserName } from "./UserName"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"

const CONTENT_LIMIT = 3

export const UserDialog = ({
  isOpen,
  onClose,
  user,
  isLoading = false,
  isError = false,
}: {
  isOpen: boolean
  onClose: () => void
  user: User_CardFragment | null
  isLoading?: boolean
  isError?: boolean
}) => {
  const { currentUser } = useCurrentUserMaybe()
  const { logEvent } = useLogEvent()
  const ccls = useCommunityClassname()
  const { openSubscriptionWizard } = useSubscription()

  const userId = user?.id

  const { data, loading } = useQuery(AUTHORED_ARTICLES_QUERY_DOCUMENT, {
    skip: !isOpen || !userId,
    variables: {
      userId: userId!,
      first: CONTENT_LIMIT,
    },
  })
  const hasArticles = useMemo(() => {
    return data?.articles?.nodes && data.articles.nodes.length > 0
  }, [data])

  const onClickSocialMediaLink = (e: React.MouseEvent) => {
    if (!currentUser?.permissions.canViewSocialMediaLinks) {
      e.preventDefault()
      openSubscriptionWizard("PricingTableStep", {
        source: "SocialMediaLink",
        requiredFeature: "canViewSocialMediaLinks",
        contentId: userId,
      })
    }
  }

  const SocialMediaLinks = () => (
    <div
      className={cn(
        "flex gap-[10px] items-center",
        !currentUser?.permissions.canViewSocialMediaLinks && "blur-xs"
      )}
    >
      {!!user?.linkedin?.length && (
        <Link
          href={socialMediaUrl("linkedin", user.linkedin)}
          target="_blank"
          rel="noreferrer"
          onClick={onClickSocialMediaLink}
        >
          <LinkedinIcon className="w-[16px] h-auto" />
        </Link>
      )}
      {!!user?.twitter?.length && (
        <Link
          href={socialMediaUrl("twitter", user.twitter)}
          target="_blank"
          rel="noreferrer"
          onClick={onClickSocialMediaLink}
        >
          <TwitterIcon className="w-[16px] h-auto" />
        </Link>
      )}
      {!!user?.instagram?.length && (
        <Link
          href={socialMediaUrl("instagram", user.instagram)}
          target="_blank"
          rel="noreferrer"
          onClick={onClickSocialMediaLink}
        >
          <InstagramImg className="w-[16px] h-auto" />
        </Link>
      )}
    </div>
  )

  return (
    <Dialog open={isOpen} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent
        noCloseButton={true}
        onEscapeKeyDown={onClose}
        className="p-0 outline-0 bg-transparent border-0"
      >
        <Card className={cn("rounded-lg [box-shadow:none]")}>
          <CardHeader>
            <div className="flex flex-row items-center justify-between">
              <CardTitle>Directory Contact</CardTitle>
              <DialogClose
                className="cursor-pointer text-placeholder"
                onClick={onClose}
              />
            </div>
          </CardHeader>
          {user?.currentUserIntroducedAt && (
            <div className="border-b border-borderColor items-center justify-center flex bg-hover text-2xs p-3 tracking-wide">
              <HeartHandshake className="mr-2" />
              <div>
                Introduced on{" "}
                <span className="font-semibold">
                  {format(
                    parseISO(user.currentUserIntroducedAt),
                    "MMM d, yyyy"
                  )}
                </span>
              </div>
            </div>
          )}
          <CardContent className={cn("px-6 py-4 tracking-[0.5px]")}>
            {isError ? (
              <div className="h-[200px] flex items-center justify-center">
                Error loading directory card.
              </div>
            ) : isLoading ? (
              <div className="h-[200px] flex items-center justify-center">
                <LoadingIndicator />
              </div>
            ) : user ? (
              <>
                <div className="flex flex-col gap-3 items-center sm:flex-row">
                  <AvatarWithFallback user={user} size="dialog" />
                  <div className="flex-1 flex flex-col items-center sm:block">
                    <div className="text-center sm:text-left">
                      <div className="flex items-end gap-2">
                        <div className="text-[20px] leading-[23px] font-semibold">
                          <UserName user={user} />
                        </div>
                        <div className="text-2xs">{user.pronouns}</div>
                      </div>

                      {(!!user.linkedin?.length ||
                        !!user.twitter?.length ||
                        !!user.instagram?.length) && (
                        <div className="ml-3 inline-block sm:hidden relative top-[1px]">
                          <SocialMediaLinks />
                        </div>
                      )}
                    </div>
                    {user.jobTitle && user.jobTitle.length > 0 && (
                      <div className="mt-2 text-2xs">{user.jobTitle}</div>
                    )}
                    {(user.companyName?.length || user.place) && (
                      <div className={cn("mt-[2px] text-[#999999] text-2xs")}>
                        {[user.companyName, user.place?.full]
                          .filter(Boolean)
                          .join(" - ")}
                      </div>
                    )}
                    {(!!user.linkedin?.length ||
                      !!user.twitter?.length ||
                      !!user.instagram?.length) && (
                      <div className="mt-5 hidden sm:block">
                        <SocialMediaLinks />
                      </div>
                    )}
                  </div>
                  {!user.anonymous && user.id !== currentUser?.id && (
                    <LinkButton
                      className="text-2xs"
                      to={dmPath({ otherUserId: user.id })}
                      onClick={() => {
                        logEvent(AhoyEventTypeEnum.DirectMessageInitiated, {
                          user_id_messaged: user.id,
                        })
                      }}
                    >
                      <EnvelopeOpen className="mr-2" />
                      Message
                    </LinkButton>
                  )}
                </div>

                {user.bio?.length && (
                  <div className="mt-4 text-[14px] sm:text-[16px] text-black tracking-normal">
                    {user.bio}
                  </div>
                )}
                <div className="mt-4 text-2xs">
                  Member Since:{" "}
                  {format(new Date(user.createdAt), "MMM d, yyyy")}
                </div>
                {(user.interests.length > 0 || user.expertise.length > 0) && (
                  <div
                    className={cn(
                      "grid mt-4 gap-3",
                      user.interests.length > 0 &&
                        user.expertise.length > 0 &&
                        "md:grid-cols-2"
                    )}
                  >
                    {user.expertise.length > 0 && (
                      <div>
                        <div className="text-2xs text-textMedium">
                          Expertise
                        </div>
                        <div className="ml-[-4px] mt-1">
                          {user.expertise.map((tag) => (
                            <div
                              className={cn(
                                "inline-block ml-1 border text-2xs px-3 py-1 mt-1 rounded-lg font-bold",
                                ccls({
                                  [CommunitySlug.Safespace]:
                                    "border-darkGreen text-darkGreen",
                                  [CommunitySlug.Boardroom]:
                                    "border-dodger-blue text-dodger-blue",
                                  [CommunitySlug.Marketingland]:
                                    "border-dodger-blue text-dodger-blue",
                                })
                              )}
                              key={`expertise-${tag.id}`}
                            >
                              {tag.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {user.interests.length > 0 && (
                      <div>
                        <div className="text-2xs text-textMedium">
                          Interested In
                        </div>
                        <div className="ml-[-4px] mt-1">
                          {user.interests.map((tag) => (
                            <div
                              className="inline-block ml-1 border border-highlight text-highlight text-2xs px-3 py-1 mt-1 rounded-lg font-bold"
                              key={`expertise-${tag.id}`}
                            >
                              {tag.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {user.pinnedPost && !user.pinnedPost.deleted && (
                  <div>
                    <div className="mt-4 text-2xs text-textMedium">
                      Pinned Post
                    </div>
                    <Link to={postPath({ postId: user.pinnedPost.id })}>
                      <PostPreview post={user.pinnedPost} className="mt-2" />
                    </Link>
                  </div>
                )}
                {loading && (
                  <div className="mt-4">
                    <LoadingIndicatorCentered />
                  </div>
                )}
                {!loading && (
                  <Tabs
                    defaultValue={
                      user.celebrations.length > 0
                        ? "celebrations"
                        : "contributions"
                    }
                    className="mt-4"
                  >
                    <TabsList className="border-b w-full bg-transparent py-0">
                      {user.celebrations.length > 0 && (
                        <TabsTrigger
                          value="celebrations"
                          className="text-md data-[state=active]:font-bold border-b-4 border-transparent data-[state=active]:border-primary rounded-none"
                        >
                          🥳 Celebrations
                        </TabsTrigger>
                      )}
                      <TabsTrigger
                        value="contributions"
                        className="text-md data-[state=active]:font-bold border-b-4 border-transparent data-[state=active]:border-primary rounded-none"
                      >
                        ✏️ Contributions
                      </TabsTrigger>
                    </TabsList>
                    {user.celebrations.length > 0 && (
                      <TabsContent
                        value="celebrations"
                        className="focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
                      >
                        <div className="divide-y">
                          {user.celebrations.map((celebration) => (
                            <div className="py-2" key={celebration.id}>
                              <ShowCelebration
                                celebration={celebration as Celebration}
                              />
                            </div>
                          ))}
                        </div>
                      </TabsContent>
                    )}

                    <TabsContent value="contributions">
                      {hasArticles ? (
                        <ol className="divide-y">
                          {data?.articles.nodes.map((article) => (
                            <Link
                              key={article.id}
                              to={articlePath({ articleId: article.id })}
                            >
                              <li
                                key={article.id}
                                className="py-1 flex justify-between items-center space-x-2 w-full"
                              >
                                <div className="flex space-x-3">
                                  {article.approvedRevision?.contentType && (
                                    <ContentTypeIconWithToolTip
                                      contentType={
                                        article.approvedRevision.contentType
                                      }
                                      colorClass={"text-frenchRose"}
                                    />
                                  )}
                                  <div className="line-clamp-1 font-semibold py-1">
                                    {article.approvedRevision?.title}
                                  </div>
                                </div>
                                <ArrowRight
                                  width={24}
                                  height={24}
                                  className="ml-5 min-w-[24px] text-primary"
                                />
                              </li>
                            </Link>
                          ))}
                        </ol>
                      ) : (
                        <div className="text-center w-2/3 py-5 mx-auto flex flex-col space-y-2">
                          <div>
                            <span className="font-semibold">
                              {user.firstName}
                            </span>{" "}
                            hasn't shared resource yet, invite them to
                            collaborate on a resource with you?
                          </div>
                          <div className="w-auto self-auto">
                            <LinkButton
                              to={
                                libraryPath.pattern +
                                `?collaborators=${user.id}`
                              }
                              variant="ghost"
                              onClick={() => {
                                logEvent(
                                  AhoyEventTypeEnum.CollaborationInviteClicked,
                                  { userId: user.id }
                                )
                              }}
                              className={cn(
                                "rounded-lg px-2 border border-mercury"
                              )}
                            >
                              Invite Them
                            </LinkButton>
                          </div>
                        </div>
                      )}
                      {data?.articles.totalCount! > CONTENT_LIMIT && (
                        <Link
                          to={libraryPath.pattern + `?search=${user.name}`}
                          className="flex items-center space-x-2 justify-center"
                        >
                          <div className="text-primary font-semibold text-sm tracking-wide py-3">
                            & view {data?.articles.totalCount! - CONTENT_LIMIT}{" "}
                            more articles
                          </div>
                          <ArrowRight />
                        </Link>
                      )}
                    </TabsContent>
                  </Tabs>
                )}
              </>
            ) : null}
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

const AUTHORED_ARTICLES_QUERY_DOCUMENT = gql(`
  query AuthoredArticlesQueryDocument($userId: ID!, $first: Int) {
    articles(collaborators: [$userId], sort: CREATED_AT_DESC, first: $first) {
      totalCount
      nodes {
        id
        createdAt
        approvedRevision {
          title
          contentType
          tag {
            name
          }
        }
      }
    }
  }
`)
