import { createContext, useContext, useEffect, useRef, useState } from "react"
import { Link, LinkProps } from "react-router-dom"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuContentProps,
} from "~/ui/dropdown-menu"
import { cn } from "~/lib/utils"
import DropdownArrow from "~/images/dropdown-arrow.svg?react"
import { useCommunityClassname } from "~/community/useCommunity"
import { CommunitySlug } from "~/__generated__/graphql"

type StyledDropdownSize = "default" | "small"

interface StyledDropdownContextType {
  size: StyledDropdownSize
}

const StyledDropdownContext = createContext<StyledDropdownContextType>({
  size: "default",
})

interface StyledDropdownInnerProps
  extends React.ComponentPropsWithoutRef<"div"> {
  icon?: React.ReactNode
  title: string
}

export const StyledDropdownInner = ({
  icon,
  title,
  ...props
}: StyledDropdownInnerProps) => {
  const contextValue = useContext(StyledDropdownContext)
  const ccls = useCommunityClassname()

  const iconSize = contextValue.size === "default" ? 20 : 16

  return (
    <div
      className={cn(
        "flex items-center gap-[10px] w-full text-dark-gray font-medium rounded-lg pr-4",
        ccls({
          [CommunitySlug.Safespace]: "hover:!bg-linen",
          default: "hover:!bg-gray-100",
        }),
        icon ? "pl-3" : "pl-4",
        contextValue.size === "default"
          ? "text-[15px] py-2"
          : "text-[14px] py-1"
      )}
      {...props}
    >
      {icon && (
        <div
          style={{ width: iconSize, height: iconSize }}
          className="flex justify-center items-center text-primary"
        >
          {icon}
        </div>
      )}
      {title}
    </div>
  )
}

export const StyledDropdownItemWrapper = ({
  asChild = false,
  children,
}: {
  asChild?: boolean
  children: React.ReactNode
}) => {
  const ccls = useCommunityClassname()
  return (
    <DropdownMenuItem
      className={cn(
        "!p-0 !cursor-pointer hover:!bg-transparent !rounded-lg",
        ccls({
          [CommunitySlug.Safespace]: "focus:!bg-linen",
          default: "focus:!bg-gray-100",
        })
      )}
      asChild={asChild}
    >
      {children}
    </DropdownMenuItem>
  )
}

export const StyledDropdownItem = ({ ...props }: StyledDropdownInnerProps) => {
  return (
    <StyledDropdownItemWrapper>
      <StyledDropdownInner {...props} />
    </StyledDropdownItemWrapper>
  )
}

interface StyledDropdownLinkProps extends StyledDropdownInnerProps {
  linkProps: LinkProps
}

export const StyledDropdownLink = ({
  linkProps,
  ...props
}: StyledDropdownLinkProps) => {
  return (
    <StyledDropdownItemWrapper asChild>
      <Link className="w-full" {...linkProps}>
        <StyledDropdownInner {...props} />
      </Link>
    </StyledDropdownItemWrapper>
  )
}

interface StyledDropdownProps
  extends React.ComponentPropsWithoutRef<typeof DropdownMenu> {
  size?: StyledDropdownSize
  trigger: React.ReactElement
  contentProps?: DropdownMenuContentProps
  children: React.ReactNode
}

export const StyledDropdown = ({
  size = "default",
  trigger,
  contentProps,
  children,
  ...props
}: StyledDropdownProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const [arrowOffset, setArrowOffset] = useState<number>(0)

  useEffect(() => {
    setArrowOffset(Math.floor((triggerRef.current?.offsetWidth || 0) / 2) + 4)
  }, [triggerRef, triggerRef.current?.offsetWidth])

  return (
    <StyledDropdownContext.Provider value={{ size }}>
      <DropdownMenu {...props}>
        <DropdownMenuTrigger ref={triggerRef} className="shrink-0">
          {trigger}
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          avoidCollisions={false}
          sideOffset={10}
          alignOffset={-10}
          onCloseAutoFocus={(e) => e.preventDefault()}
          className="!animate-none p-2 flex flex-col"
          {...contentProps}
        >
          {children}
          <DropdownArrow
            style={{ right: arrowOffset }}
            className="absolute top-[-7px]"
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </StyledDropdownContext.Provider>
  )
}
