import { useRef } from "react"
import { Input } from "~/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { CalendarDateField } from "~/ui/CalendarDateField"
import { CelebrationTypeEnum } from "~/__generated__/graphql"
import { useTranslation } from "react-i18next"

type CelebrationFormProps = {
  celebrationType: CelebrationTypeEnum
  customCelebrationType?: string
  date?: Date
  isDisabled?: boolean
  availableTypes: CelebrationTypeEnum[]
  onChangeCelebrationType: (selected: CelebrationTypeEnum) => void
  onChangeCustomCelebrationType: (customCelebrationType: string) => void
  onChangeDate: (date?: Date) => void
}

export const CelebrationForm = ({
  celebrationType,
  customCelebrationType,
  date,
  isDisabled,
  availableTypes,
  onChangeCelebrationType,
  onChangeCustomCelebrationType,
  onChangeDate,
}: CelebrationFormProps) => {
  const { t: translateCelebrationType } = useTranslation("celebrations")
  const customCelebrationTypeRef = useRef<HTMLInputElement>(null)

  const onCreateOption = (inputValue: string) => {
    onChangeCelebrationType(CelebrationTypeEnum.Other)
    onChangeCustomCelebrationType(inputValue)
  }

  const onValueChange = (selected: string) => {
    const selectedType = availableTypes.find((type) => type === selected)
    if (selectedType) {
      onChangeCelebrationType(selectedType)

      if (selectedType === CelebrationTypeEnum.Other) {
        setTimeout(() => {
          customCelebrationTypeRef.current?.focus()
        }, 20)
      }
    }
  }

  return (
    <div className="grid grid-cols-2 gap-4 flex-grow">
      <div className="flex flex-col space-y-2">
        <label className="onboarding-field flex">
          <div className="onboarding-field__prefix">
            <div className="leading-[17px]">
              {translateCelebrationType(`${celebrationType}.emoji`)}
            </div>
          </div>
          <div className="flex-grow">
            <Select
              onValueChange={onValueChange}
              value={celebrationType}
              disabled={isDisabled}
            >
              <SelectTrigger className="text-dark-gray !rounded-l-none min-h-[50px]">
                <SelectValue placeholder="Choose what you're celebrating!" />
              </SelectTrigger>
              <SelectContent>
                {availableTypes.map((type) => (
                  <SelectItem value={type} key={type}>
                    {type === CelebrationTypeEnum.Other ? (
                      <span className="text-gray-500">Custom</span>
                    ) : (
                      <span>{translateCelebrationType(`${type}.label`)}</span>
                    )}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </label>
        {celebrationType === CelebrationTypeEnum.Other && (
          <div className="flex">
            <div className="onboarding-field__prefix invisible">
              <div className="leading-[17px]">
                {translateCelebrationType(`${celebrationType}.emoji`)}
              </div>
            </div>
            <Input
              ref={customCelebrationTypeRef}
              disabled={isDisabled}
              placeholder="Enter custom celebration type"
              value={customCelebrationType || ""}
              autoFocus
              onChange={(e) => onCreateOption(e.target.value)}
            />
          </div>
        )}
      </div>
      <div>
        <CalendarDateField
          date={date}
          isDisabled={isDisabled}
          onChangeDate={onChangeDate}
          displayWithoutYear={celebrationType === CelebrationTypeEnum.Birthday}
        />
      </div>
    </div>
  )
}
