import {
  addMonths,
  isSameYear,
  setMonth,
  setYear,
  startOfMonth,
  startOfYear,
} from "date-fns"
import {
  useDayPicker,
  MonthChangeEventHandler,
  CaptionProps,
  useNavigation,
  Caption,
} from "react-day-picker"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { buttonVariants } from "../button"

export const CustomCaption = (props: CaptionProps) => {
  const { captionLayout } = useDayPicker()

  let caption: JSX.Element

  if (captionLayout !== "dropdown") {
    caption = <Caption {...props} />
  } else {
    caption = <CaptionDropdowns {...props} />
  }

  return caption
}

const CaptionDropdowns = (props: CaptionProps) => {
  const { goToMonth } = useNavigation()

  const handleMonthChange: MonthChangeEventHandler = (newMonth) => {
    goToMonth(addMonths(newMonth, props.displayIndex ? -props.displayIndex : 0))
  }
  return (
    <div className="flex items-center justify-center">
      <div>
        <MonthsDropdown
          onChange={handleMonthChange}
          displayMonth={props.displayMonth}
        />
      </div>
      <div>
        <YearsDropdown
          onChange={handleMonthChange}
          displayMonth={props.displayMonth}
        />
      </div>
    </div>
  )
}

interface MonthsDropdownProps {
  displayMonth: Date
  onChange: MonthChangeEventHandler
}

export const MonthsDropdown = ({
  displayMonth,
  onChange,
}: MonthsDropdownProps) => {
  const {
    fromDate,
    toDate,
    locale,
    formatters: { formatMonthCaption },
  } = useDayPicker()

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate) return <></>
  if (!toDate) return <></>

  const dropdownMonths: Date[] = []

  if (isSameYear(fromDate, toDate)) {
    // only display the months included in the range
    const date = startOfMonth(fromDate)
    for (let month = fromDate.getMonth(); month <= toDate.getMonth(); month++) {
      dropdownMonths.push(setMonth(date, month))
    }
  } else {
    // display all the 12 months
    const date = startOfMonth(new Date()) // Any date should be OK, as we just need the year
    for (let month = 0; month <= 11; month++) {
      dropdownMonths.push(setMonth(date, month))
    }
  }

  const handleChange = (value: string) => {
    const selectedMonth = Number(value)
    const newMonth = setMonth(startOfMonth(displayMonth), selectedMonth)
    onChange(newMonth)
  }

  return (
    <Select value={`${displayMonth.getMonth()}`} onValueChange={handleChange}>
      <SelectTrigger className={buttonVariants({ variant: "subtle" })}>
        <SelectValue placeholder="Month"></SelectValue>
      </SelectTrigger>
      <SelectContent>
        {dropdownMonths.map((m) => (
          <SelectItem key={m.getMonth()} value={`${m.getMonth()}`}>
            {formatMonthCaption(m, { locale })}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export interface YearsDropdownProps {
  /** The month where the drop-down is displayed. */
  displayMonth: Date
  /** Callback to handle the `change` event. */
  onChange: MonthChangeEventHandler
}

/**
 * Render a dropdown to change the year. Take in account the `nav.fromDate` and
 * `toDate` from context.
 */
export function YearsDropdown({
  displayMonth,
  onChange,
}: YearsDropdownProps): JSX.Element {
  const {
    fromDate,
    toDate,
    locale,
    formatters: { formatYearCaption },
  } = useDayPicker()

  const years: Date[] = []

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate) return <></>
  if (!toDate) return <></>

  const fromYear = fromDate.getFullYear()
  const toYear = toDate.getFullYear()
  for (let year = fromYear; year <= toYear; year++) {
    years.push(setYear(startOfYear(new Date()), year))
  }

  const handleChange = (value: string) => {
    const newMonth = setYear(startOfMonth(displayMonth), Number(value))
    onChange(newMonth)
  }

  return (
    <Select
      value={`${displayMonth.getFullYear()}`}
      onValueChange={handleChange}
    >
      <SelectTrigger className={buttonVariants({ variant: "subtle" })}>
        <SelectValue placeholder="Year"></SelectValue>
      </SelectTrigger>
      <SelectContent>
        {years.map((year) => (
          <SelectItem key={year.getFullYear()} value={`${year.getFullYear()}`}>
            {formatYearCaption(year, { locale })}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
