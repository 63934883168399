import { useState, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { Button } from "~/ui/button"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { experiencePath } from "~/common/paths"
import { displayErrors } from "~/common/validations"
import { imgixResize } from "~/common/imgix"
import { OnboardingLayout } from "~/layouts/OnboardingLayout"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  OnboardingCard,
  OnboardingCardDataType,
} from "~/components/onboarding/OnboardingCard"
import { OnboardingDetailsFields } from "~/components/onboarding/OnboardingDetailsFields"
import { OnboardingPhotoField } from "~/components/onboarding/OnboardingPhotoField"
import { OnboardingTitle } from "~/ui/Onboarding"

interface IFormInput {
  firstName: string | null
  lastName: string | null
  companyName: string | null
  jobTitle: string | null
  placeId: string | null
  photo: string | null
}

export const AboutYouScreen = () => {
  const [runUserUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)

  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  const [imageURL, setImageURL] = useState<string | null>(
    currentUser.photoUrl
      ? imgixResize(currentUser.photoUrl, {
          width: 192,
          height: 192,
          fit: "crop",
        })
      : null
  )

  const form = useForm<IFormInput>({})

  useEffect(
    () =>
      form.reset({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        companyName: currentUser.companyName,
        jobTitle: currentUser.jobTitle,
        placeId: currentUser.place?.id,
      }),
    [currentUser, form]
  )

  const [cardLocation, setCardLocation] = useState<string | null>(null)

  const formWatch = form.watch()
  const cardData = useMemo(() => {
    const data: OnboardingCardDataType = {
      id: currentUser.id,
      firstName: formWatch.firstName || currentUser.firstName,
      lastName: formWatch.lastName || currentUser.lastName,
      companyName: formWatch.companyName,
      jobTitle: formWatch.jobTitle,
      photoUrl: imageURL,
      role: currentUser.role,
    }

    if (cardLocation) {
      data.location = cardLocation
    }

    return data
  }, [
    formWatch,
    imageURL,
    cardLocation,
    currentUser.firstName,
    currentUser.lastName,
    currentUser.id,
    currentUser.role,
  ])

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    const { errors } = await runUserUpdate({
      variables: {
        input: formData,
      },
    })

    if (errors) {
      displayErrors(errors)
      return false
    } else {
      navigate(experiencePath.pattern)
    }
  }

  return (
    <OnboardingLayout step={1}>
      <OnboardingTitle>Let's start with the basics</OnboardingTitle>
      <div className="relative">
        <OnboardingCard user={currentUser} formData={cardData} />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <OnboardingDetailsFields setCardLocation={setCardLocation} />
            <OnboardingPhotoField
              value={imageURL}
              onChange={setImageURL}
              variant="onboarding"
            />
            <div className="onboarding-footer">
              <div className="onboarding-footer-link">
                <span className="text-highlight">*</span>
                Indicates required fields
              </div>
              <Button variant="onboarding" type="submit" disabled={loading}>
                Continue
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </OnboardingLayout>
  )
}
