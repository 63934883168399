import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"

export const useUpvotes = ({
  postId,
  articleId,
}: {
  postId?: string
  articleId?: string
}) => {
  const [runUpvoteCreate] = useSafeMutation(UPVOTE_CREATE_MUTATION)

  const addUpvote = async () => {
    const { errors } = await runUpvoteCreate({
      variables: {
        input: {
          postId,
          articleId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    }
  }

  const [runUpvoteDestroy] = useSafeMutation(UPVOTE_DESTROY_MUTATION)

  const removeUpvote = async () => {
    const { errors } = await runUpvoteDestroy({
      variables: {
        input: {
          postId,
          articleId,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    }
  }

  return { addUpvote, removeUpvote }
}

const UPVOTE_CREATE_MUTATION = gql(`
  mutation UpvoteCreate($input: UpvoteCreateInput!) {
    upvoteCreate(input: $input) {
      post {
        ...Post_Card
      }
      article {
        id
        currentUserUpvoted
        upvotesCount
      }
    }
  }
`)

const UPVOTE_DESTROY_MUTATION = gql(`
  mutation UpvoteDestroy($input: UpvoteDestroyInput!) {
    upvoteDestroy(input: $input) {
      post {
        ...Post_Card
      }
      article {
        id
        currentUserUpvoted
        upvotesCount
      }
    }
  }
`)
