import { Dialog, DialogContent, DialogTitle } from "~/ui/dialog"
import { useUserOffers } from "./UserOffersProvider"
import { Translation } from "~/common/Translation"
import { Button } from "~/ui/button"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  AhoyEventTypeEnum,
  OfferCategoryEnum,
  UserOffer_DisplayFragment,
} from "~/__generated__/graphql"
import { useDiscountedAmount } from "./useDiscountedAmount"
import { Card, CardContent, CardHeader } from "~/ui/card"
import {
  differenceInDays,
  differenceInSeconds,
  Duration,
  formatDistanceToNow,
  intervalToDuration,
  parseISO,
} from "date-fns"
import { useTranslation } from "react-i18next"
import { useSubscription } from "~/subscriptions/SubscriptionProvider"
import { useCommunity } from "~/community/useCommunity"
import { useTiers } from "~/tiers/TiersProvider"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser } from "~/auth/CurrentUserContext"

export const UserOfferModule = () => {
  const { openSubscriptionWizard } = useSubscription()
  const {
    unacknowledgedUserOffers,
    acknowledgedUserOffers,
    acceptedUserOffers,
    redeemedUserOffers,
    acknowledgeUserOffer,
    refetchUserOffers,
  } = useUserOffers()
  const { tiers } = useTiers()
  const { creator } = useCommunity()
  const { currentUser } = useCurrentUser()

  const unacknowledgedUserOffer = useMemo(
    () =>
      unacknowledgedUserOffers?.find(
        (userOffer) =>
          userOffer.offer.category === OfferCategoryEnum.PaidMembershipUpsell
      ),
    [unacknowledgedUserOffers]
  )
  const acknowledgedUserOffer = useMemo(
    () =>
      acknowledgedUserOffers?.find(
        (userOffer) =>
          userOffer.offer.category === OfferCategoryEnum.PaidMembershipUpsell
      ),
    [acknowledgedUserOffers]
  )

  const getRedeemedAt = (userOffer: UserOffer_DisplayFragment) => {
    if (userOffer.redeemedAt) {
      return parseISO(userOffer.redeemedAt)
    }
    return parseISO(userOffer.acceptedAt!)
  }

  // First redeemed offer within last 7 days
  const redeemedUserOffer = useMemo(
    () =>
      [...redeemedUserOffers, ...acceptedUserOffers].find(
        (userOffer) =>
          userOffer.offer.category === OfferCategoryEnum.PaidMembershipUpsell &&
          differenceInDays(getRedeemedAt(userOffer), new Date()) <= 7
      ),
    [redeemedUserOffers, acceptedUserOffers]
  )

  const claimOffer = () => {
    setIsDialogOpen(false)
    openOffer("UserOfferModal")
  }

  const ClaimButton = ({ children }: { children?: JSX.Element }) => (
    <div className="my-4">
      <Button onClick={claimOffer}>{children}</Button>
    </div>
  )

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedUserOffer, setSelectedUserOffer] =
    useState<UserOffer_DisplayFragment | null>(null)
  const { discount } = useDiscountedAmount(selectedUserOffer?.offer)
  const { logEvent } = useLogEvent()

  useEffect(() => {
    if (unacknowledgedUserOffer) {
      setSelectedUserOffer(unacknowledgedUserOffer)
      setIsDialogOpen(true)
    } else if (acknowledgedUserOffer) {
      setSelectedUserOffer(acknowledgedUserOffer)
    }
  }, [unacknowledgedUserOffer, acknowledgedUserOffer])

  const onOpenChange = useCallback(
    async (open: boolean) => {
      if (!open && selectedUserOffer) {
        await acknowledgeUserOffer(selectedUserOffer)
      }
      setIsDialogOpen(false)
    },
    [acknowledgeUserOffer, selectedUserOffer]
  )

  const hasLoggedUpgradeModalViewedRef = useRef(false)
  useEffect(() => {
    if (
      isDialogOpen &&
      selectedUserOffer &&
      selectedUserOffer.offer.category ===
        OfferCategoryEnum.PaidMembershipUpsell
    ) {
      logEvent(
        AhoyEventTypeEnum.UpgradeModalViewed,
        {
          stripe_coupon_id: selectedUserOffer.offer.stripeCoupon.id,
          source: "UserOfferModal",
        },
        hasLoggedUpgradeModalViewedRef
      )
    }
    if (!isDialogOpen) {
      hasLoggedUpgradeModalViewedRef.current = false
    }
  }, [isDialogOpen, selectedUserOffer, logEvent, currentUser.tier])

  const [timeRemaining, setTimeRemaining] = useState<Duration>({})

  const { t } = useTranslation("time")

  useEffect(() => {
    if (!acknowledgedUserOffer || !acknowledgedUserOffer.expiredAt) {
      return
    }
    const duration = intervalToDuration({
      start: Date.now(),
      end: parseISO(acknowledgedUserOffer.expiredAt),
    })
    setTimeRemaining(duration)

    const countdown = setInterval(() => {
      if (!acknowledgedUserOffer || !acknowledgedUserOffer.expiredAt) {
        clearInterval(countdown)
        return
      }
      const duration = intervalToDuration({
        start: Date.now(),
        end: parseISO(acknowledgedUserOffer.expiredAt),
      })
      setTimeRemaining(duration)
    }, 1000)

    return () => clearInterval(countdown)
  }, [acknowledgedUserOffer])

  const openNote = (userOffer: UserOffer_DisplayFragment) => {
    setSelectedUserOffer(userOffer)
    setIsDialogOpen(true)
  }

  const [isClaimingOffer, setIsClaimingOffer] = useState(false)

  const openOffer = async (source: string) => {
    if (!selectedUserOffer) {
      return
    }

    setIsClaimingOffer(true)
    await acknowledgeUserOffer(selectedUserOffer)

    const tier = tiers.find((t) => t.id === selectedUserOffer.offer.tier.id)
    openSubscriptionWizard("MigrateToTierStep", {
      source: source,
      offer: selectedUserOffer.offer,
      selectedTier: tier,
      selectedInterval: selectedUserOffer.offer.tierInterval,
    })
  }

  const isAcknowledgedUserOfferExpired = useMemo(() => {
    if (!acknowledgedUserOffer || !acknowledgedUserOffer.expiredAt) return false
    if (
      differenceInSeconds(
        new Date(acknowledgedUserOffer.expiredAt),
        new Date()
      ) <= 0
    )
      return true
    if (timeRemaining.days || timeRemaining.hours || timeRemaining.minutes)
      return false
  }, [acknowledgedUserOffer, timeRemaining])

  useEffect(() => {
    if (isAcknowledgedUserOfferExpired) {
      refetchUserOffers()
    }
  }, [isAcknowledgedUserOfferExpired, refetchUserOffers])

  const timeRemainingFormatted = useMemo(() => {
    const parts: string[] = []
    if (timeRemaining.days) {
      parts.push(t("dayWithCount", { count: timeRemaining.days }))
    }
    if (timeRemaining.hours) {
      parts.push(t("hourWithCount", { count: timeRemaining.hours }))
    }

    if (!parts.length && acknowledgedUserOffer?.expiredAt) {
      parts.push(formatDistanceToNow(new Date(acknowledgedUserOffer.expiredAt)))
    }
    return parts.join(" ")
  }, [timeRemaining, t, acknowledgedUserOffer])

  const hasLoggedUserOfferViewedRef = useRef(false)
  useEffect(() => {
    if (
      acknowledgedUserOffer &&
      !isAcknowledgedUserOfferExpired &&
      !isClaimingOffer
    ) {
      logEvent(
        AhoyEventTypeEnum.UpgradeModalViewed,
        {
          stripe_coupon_ids: acknowledgedUserOffer.offer.stripeCoupon.id,
          source: "UserOfferModule",
        },
        hasLoggedUserOfferViewedRef
      )
    }
  }, [
    acknowledgedUserOffer,
    isAcknowledgedUserOfferExpired,
    isClaimingOffer,
    logEvent,
  ])

  return (
    <>
      {selectedUserOffer && (
        <Dialog open={isDialogOpen} onOpenChange={onOpenChange}>
          <DialogContent variant="letter">
            <DialogTitle>
              <Translation
                i18nKey={`${selectedUserOffer.offer.translationNamespace}.title`}
                ns="offers"
                values={{ discountAmount: discount }}
              />
            </DialogTitle>
            <div className="flex flex-col gap-4">
              <Translation
                i18nKey={`${selectedUserOffer.offer.translationNamespace}.body`}
                ns="offers"
                values={{ discountAmount: discount }}
                components={{
                  ClaimButton: <ClaimButton />,
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}

      {acknowledgedUserOffer &&
        !isAcknowledgedUserOfferExpired &&
        !isClaimingOffer && (
          <Card>
            {timeRemainingFormatted && (
              <CardHeader className="text-center py-4">
                <div className="text-xs mb-1">Offer Valid For</div>
                <div className="capitalize text-highlight font-semibold text-md">
                  {timeRemainingFormatted}
                </div>
              </CardHeader>
            )}

            <CardContent className="bg-primary/5 text-center p-6 flex flex-col gap-4">
              <div className="font-bold text-xl text-center">
                <span className="text-2xl">🎁</span> Try Plus Membership with
                40% off Quarterly!
              </div>
              <div>
                <Button
                  type="button"
                  onClick={() => openOffer("UserOfferModule")}
                >
                  Claim Offer
                </Button>
              </div>
              <p className="text-2xs font-normal">
                P.S. See note from{" "}
                <Button
                  className="text-2xs underline"
                  variant="link"
                  size="inline"
                  type="button"
                  onClick={() => openNote(acknowledgedUserOffer)}
                >
                  {creator.firstName}
                </Button>
              </p>
            </CardContent>
          </Card>
        )}

      {redeemedUserOffer && (
        <Card>
          <CardContent className="p-4 flex flex-col gap-4 text-xs">
            <Translation
              i18nKey={`${redeemedUserOffer.offer.translationNamespace}.thankYou`}
              ns="offers"
            />
          </CardContent>
        </Card>
      )}
    </>
  )
}
