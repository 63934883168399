import { Dispatch, SetStateAction } from "react"
import {
  Section_FullFragment,
  Article_EditorContentFragment,
  Lesson_EditorContentFragment,
  ArticleCollaborator_AddModalFragment,
  Course_EditorContentFragment,
} from "~/__generated__/graphql"

export type StateSetter<T> = Dispatch<SetStateAction<T>>

export const generateTempId = (prefix: string) => {
  return `${prefix}-${Date.now()}-${Math.random().toString(36).slice(2)}`
}

export const withoutId = (object: any) => {
  const { id, ...rest } = object
  return rest
}

type SavedCourse = Omit<Course_EditorContentFragment, "sections"> & {
  sections: SavedOrUnsavedSection[]
}

export type SavedOrUnsavedCourse = SavedCourse

export type UnsavedSection = Omit<Section_FullFragment, "id" | "lessons"> & {
  tempId: string
  lessons: UnsavedLesson[]
}

type SavedSection = Omit<Section_FullFragment, "lessons"> & {
  lessons: SavedOrUnsavedLesson[]
}

type UnsavedDraftRevision = Omit<
  Article_EditorContentFragment["draftRevision"],
  "id"
> & { tempId: string }

type SavedOrUnsavedDraftRevision =
  | UnsavedDraftRevision
  | Article_EditorContentFragment["draftRevision"]

export const isSavedDraftRevision = (
  revision: SavedOrUnsavedDraftRevision
): revision is Article_EditorContentFragment["draftRevision"] => {
  return "id" in revision
}

export const draftRevisionId = (revision: SavedOrUnsavedDraftRevision) => {
  return isSavedDraftRevision(revision) ? revision.id : revision.tempId
}

type UnsavedArticle = Omit<
  Article_EditorContentFragment,
  "id" | "draftRevision" | "approvedRevision" | "collaborators"
> & {
  tempId: string
  approvedRevision?: Article_EditorContentFragment["approvedRevision"]
  draftRevision: SavedOrUnsavedDraftRevision
  collaborators?: UnsavedArticleCollaborator[]
}

type SavedArticle = Omit<Article_EditorContentFragment, "collaborators"> & {
  collaborators?: SavedOrUnsavedArticleCollaborator[]
}

export type SavedOrUnsavedArticle = SavedArticle | UnsavedArticle

export const isSavedArticle = (
  article: SavedOrUnsavedArticle
): article is SavedArticle => {
  return "id" in article
}

export const getArticleId = (article: SavedOrUnsavedArticle) => {
  return isSavedArticle(article) ? article.id : article.tempId
}

export type UnsavedLesson = Omit<
  Lesson_EditorContentFragment,
  "id" | "article"
> & {
  tempId: string
  article: UnsavedArticle
}

type SavedLesson = Omit<Lesson_EditorContentFragment, "article"> & {
  article: SavedOrUnsavedArticle
}

export type SavedOrUnsavedSection = SavedSection | UnsavedSection

export type SavedOrUnsavedLesson = SavedLesson | UnsavedLesson

export const isSavedSection = (
  section: SavedOrUnsavedSection
): section is SavedSection => {
  return "id" in section
}

export const sectionId = (section: SavedOrUnsavedSection) => {
  return isSavedSection(section) ? section.id : section.tempId
}

export const isSavedLesson = (
  lesson: Pick<SavedLesson, "id"> | Pick<UnsavedLesson, "tempId">
): lesson is SavedLesson => {
  return "id" in lesson
}

export const lessonId = (
  lesson: Pick<SavedLesson, "id"> | Pick<UnsavedLesson, "tempId">
) => {
  return isSavedLesson(lesson) ? lesson.id : (lesson as UnsavedLesson).tempId
}

export const isSavedArticleCollaborator = (
  collaborator: SavedOrUnsavedArticleCollaborator
): collaborator is ArticleCollaborator_AddModalFragment => {
  return "id" in collaborator
}

export const articleCollaboratorId = (
  collaborator: SavedOrUnsavedArticleCollaborator
) => {
  return isSavedArticleCollaborator(collaborator)
    ? collaborator.id
    : collaborator.tempId
}

export type UnsavedArticleCollaborator = Omit<
  ArticleCollaborator_AddModalFragment,
  "id"
> & { tempId: string }
export type SavedOrUnsavedArticleCollaborator =
  | ArticleCollaborator_AddModalFragment
  | UnsavedArticleCollaborator
