import { RenderFn } from "editorjs-blocks-react-renderer"
import AttachesTool from "@editorjs/attaches"
import { ArrowDown } from "lucide-react"

interface AttachmentBlockFileData {
  contentType: string
  extension?: string
  name: string
  id: string
  size: number
  url: string
}

export interface AttachmentBlockData {
  file: AttachmentBlockFileData
  title: string
}

export const AttachmentRenderer: RenderFn<AttachmentBlockData> = ({ data }) => {
  const title = data.title.length > 0 ? data.title : data.file.name
  const { formattedSize, sizePrefix } = formatSize(data.file.size)

  return (
    <a
      href={data.file.url}
      className="nostyle mb-6 py-2 px-4 flex items-center rounded-lg border border-mercury"
    >
      {data.file.extension && (
        <div
          className="text-white text-xs uppercase font-bold tracking-wider p-2 rounded mr-4"
          style={{ backgroundColor: getAttachmentColor(data.file.extension) }}
        >
          {data.file.extension}
        </div>
      )}
      <div className="flex-1">
        <div>{title}</div>
        <div className="text-sm text-slate-500">
          {formattedSize} {sizePrefix}
        </div>
      </div>
      <div className="rounded bg-slate-100 p-2 aspect-square flex items-center justify-center">
        <div className="">
          <ArrowDown size={18} />
        </div>
      </div>
    </a>
  )
}

// copied from https://github.com/editor-js/attaches/blob/master/src/index.js#L419
export const formatSize = (size: number) => {
  let sizePrefix
  let formattedSize

  if (Math.log10(+size) >= 6) {
    sizePrefix = "MiB"
    formattedSize = size / Math.pow(2, 20)
  } else {
    sizePrefix = "KiB"
    formattedSize = size / Math.pow(2, 10)
  }

  return { sizePrefix, formattedSize: formattedSize.toFixed(1) }
}

export const getAttachmentColor = (extension?: string) => {
  if (!extension || extension.length === 0) return "#000"

  return (
    new AttachesTool({ data: {}, config: {} }).EXTENSIONS[extension] || "#000"
  )
}
