import { useEffect, useRef, useState } from "react"
import { Post_CardFragment } from "~/__generated__/graphql"

export const usePostHighlighting = ({
  inView,
  post,
  enabled,
}: {
  inView: boolean
  post: Post_CardFragment
  enabled: boolean
}) => {
  const [isHighlighted, setIsHighlighted] = useState(
    enabled && !post.currentUserHasRead
  )
  const wasInViewRef = useRef(false)

  useEffect(() => {
    if (!isHighlighted) return

    if (inView) {
      wasInViewRef.current = true
    } else if (wasInViewRef.current) {
      setIsHighlighted(false)
    }
  }, [inView, setIsHighlighted, isHighlighted])

  return { isHighlighted }
}
