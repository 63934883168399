import { Check } from "lucide-react"
import { Button } from "~/ui/button"
import { ButtonGroupToggle } from "~/ui/ButtonGroupToggle"

interface ArticleBottomActionToolbarProps {
  preview: () => void
  edit: () => void
  save: () => void
  isPreviewing: boolean
  isSaving: boolean
  isDirty: boolean
  isLocked: boolean
}

export const ArticleBottomActionToolbar = ({
  preview,
  edit,
  isPreviewing,
  save,
  isSaving,
  isDirty,
  isLocked,
}: ArticleBottomActionToolbarProps) => {
  if (isLocked) return null

  return (
    <div className="py-4 fixed bottom-0 left-0 right-0 z-40 bg-background border-t border-mercury">
      <div className="container mx-auto flex justify-end items-center gap-4">
        <ButtonGroupToggle
          leftButtonCopy="Edit"
          leftButtonOnClick={edit}
          rightButtonCopy="Preview"
          rightButtonOnClick={preview}
          activeButton={isPreviewing ? "right" : "left"}
        />

        <Button
          onClick={save}
          className="text-xs"
          isLoading={isSaving}
          disabled={isSaving || !isDirty}
        >
          {isDirty ? (
            "Save"
          ) : (
            <>
              Saved
              <Check size={18} className="ml-2" />
            </>
          )}
        </Button>
      </div>
    </div>
  )
}
