import { useDndContext } from "@dnd-kit/core"
import { SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import {
  DotsVerticalIcon,
  ExclamationTriangleIcon,
  EyeNoneIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons"
import { cva } from "class-variance-authority"
import { CheckCircleIcon, GripVertical, Plus } from "lucide-react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ArticleCollaboratorsModal } from "~/articles/ArticleCollaboratorsModal"
import { cn } from "~/lib/utils"
import {
  SavedOrUnsavedArticle,
  SavedOrUnsavedLesson,
  SavedOrUnsavedSection,
  isSavedLesson,
  isSavedSection,
  sectionId as getSectionId,
  generateTempId,
  withoutId,
} from "~/types"
import { Button } from "~/ui/button"
import { Card, CardContent, CardHeader } from "~/ui/card"
import { CreateLessonModal } from "./CreateLessonModal"
import { Lesson } from "./Lesson"
import { useConfirm } from "~/ui/Confirm"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import invariant from "tiny-invariant"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { useCourseEditorContext } from "./CourseEditor"

export type SectionType = "Section"

export interface SectionDragData {
  type: SectionType
  section: SavedOrUnsavedSection
}

interface SectionProps {
  section: SavedOrUnsavedSection
  isOverlay?: boolean
  onCreateLesson: (lesson: SavedOrUnsavedLesson) => void
  onUpdateLesson: (lesson: SavedOrUnsavedLesson) => void
}

const sectionHasChanges = (
  section: SavedOrUnsavedSection,
  initialSection: SavedOrUnsavedSection | null
) => {
  return (
    !isSavedSection(section) ||
    section.title !== initialSection?.title ||
    section.hidden !== initialSection?.hidden ||
    section.lessons.some((lesson) => !isSavedLesson(lesson))
  )
}

export function Section({
  section,
  isOverlay,
  onCreateLesson,
  onUpdateLesson,
}: SectionProps) {
  const { article, course, setSections } = useCourseEditorContext()
  const initialSection = useMemo(() => {
    const matchedSections = course.sections.filter(
      (s) => getSectionId(s) === getSectionId(section)
    )
    return matchedSections.length > 0 ? matchedSections[0] : null
  }, [course, section])
  const [isExpanded, setIsExpanded] = useState(false)
  const [isCreateLessonModalOpen, setIsCreateLessonModalOpen] = useState(false)
  const lessons = useMemo<SavedOrUnsavedLesson[]>(
    () => section.lessons,
    [section]
  )
  const lessonIds = useMemo(() => {
    return lessons.map((lesson) =>
      isSavedLesson(lesson) ? lesson.id : lesson.tempId
    )
  }, [lessons])
  const sectionId = useMemo(
    () => (isSavedSection(section) ? section.id : section.tempId),
    [section]
  )

  const showConfirm = useConfirm()

  const rename = useCallback(() => {
    showConfirm({
      title: "Rename Module",
      prompt: {
        label: "Module Name",
        value: section.title,
        required: true,
        placeholder: "Enter a name for this module",
        type: "text",
      },
      onConfirm: (newTitle) => {
        invariant(newTitle)
        setSections((sections) => {
          return sections.map((s) => {
            if (getSectionId(s) === getSectionId(section)) {
              return {
                ...s,
                title: newTitle,
              }
            }
            return s
          })
        })
      },
    })
  }, [section, setSections, showConfirm])

  const toggleHidden = useCallback(() => {
    showConfirm({
      title: section.hidden ? "Un-hide Module" : "Hide Module",
      body: `Are you sure you want to ${
        section.hidden ? "un-hide" : "hide"
      } this module?`,
      onConfirm: () => {
        setSections((sections) => {
          return sections.map((s) => {
            if (getSectionId(s) === getSectionId(section)) {
              return {
                ...s,
                hidden: !s.hidden,
              }
            }
            return s
          })
        })
      },
    })
  }, [section, setSections, showConfirm])

  const duplicate = useCallback(() => {
    showConfirm({
      title: "Duplicate Module",
      body: "Are you sure you want to duplicate this module and all lessons contained within?",
      onConfirm: () => {
        setSections((sections) => {
          const duplicatedSection = {
            ...withoutId(section),
            tempId: generateTempId("section"),
            title: `${section.title} (Copy)`,
            lessons: [] as SavedOrUnsavedLesson[],
          }

          duplicatedSection.lessons = section.lessons.map((lesson) => ({
            ...withoutId(lesson),
            sectionId: duplicatedSection.tempId,
            tempId: generateTempId("lesson"),
            article: {
              ...withoutId(lesson.article),
              tempId: generateTempId("article"),
              draftRevision: {
                ...withoutId(lesson.article.draftRevision),
                title: `${lesson.article.draftRevision.title} (Copy)`,
                tempId: generateTempId("revision"),
              },
              collaborators:
                lesson.article.collaborators?.map((collaborator) => ({
                  ...withoutId(collaborator),
                  tempId: generateTempId("collaborator"),
                })) || [],
            },
          }))

          return [...sections, duplicatedSection]
        })
      },
    })
  }, [section, setSections, showConfirm])

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: sectionId,
    data: {
      type: "Section",
      section,
    } satisfies SectionDragData,
    attributes: {
      roleDescription: `Section: ${section.title}`,
    },
  })
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  }
  const dndContext = useDndContext()

  useEffect(() => {
    if (
      dndContext.over?.id === sectionId &&
      dndContext.active?.data.current?.type === "Lesson"
    ) {
      setIsExpanded(true)
    }
  }, [dndContext, sectionId])

  const variants = cva("flex flex-row items-center", {
    variants: {
      dragging: {
        default: "border-2 border-transparent",
        over: "ring-2 opacity-30",
        overlay: "ring-2 ring-primary",
      },
      visibility: {
        visible: "border-solid border border-gray-300",
        hidden: "border-dashed border-2 border-gray-300",
      },
    },
  })

  const handleCreateLesson = useCallback(
    (lesson: SavedOrUnsavedLesson) => {
      onCreateLesson(lesson)
      setIsExpanded(true)
      setIsCreateLessonModalOpen(false)
      setCollaboratorsModalLesson(lesson)
      setCollaboratorsModalOpen(true)
    },
    [onCreateLesson]
  )

  const handleUpdateLesson = useCallback(
    (lesson: SavedOrUnsavedLesson) => {
      onUpdateLesson(lesson)
    },
    [onUpdateLesson]
  )

  const [collaboratorsModalOpen, setCollaboratorsModalOpen] = useState(false)
  const [collaboratorsModalLesson, setCollaboratorsModalLesson] =
    useState<SavedOrUnsavedLesson | null>(null)

  const contextMenuTriggerRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <CreateLessonModal
        section={section}
        loading={false}
        onCreateLesson={handleCreateLesson}
        open={isCreateLessonModalOpen}
        onOpenChange={setIsCreateLessonModalOpen}
      />
      {collaboratorsModalOpen && (
        <ArticleCollaboratorsModal
          includeCollaborators={article.collaborators.map((c) => c.user.id)}
          setIsOpen={setCollaboratorsModalOpen}
          onSubmit={(collaborators) => {
            if (collaboratorsModalLesson) {
              handleUpdateLesson({
                ...collaboratorsModalLesson,
                article: {
                  ...collaboratorsModalLesson.article,
                  collaborators,
                } as SavedOrUnsavedArticle,
              } as SavedOrUnsavedLesson)
            }
            setCollaboratorsModalOpen(false)
          }}
        />
      )}

      <Card
        ref={setNodeRef}
        style={style}
        className={variants({
          dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
          visibility: section.hidden ? "hidden" : "visible",
        })}
      >
        <div className="shrink-0">
          <Button
            variant={"ghost"}
            {...attributes}
            {...listeners}
            className="p-3 h-auto cursor-grab relative"
          >
            <span className="sr-only">{`Move section: ${section.title}`}</span>
            <GripVertical className="w-[12px] h-[12px]" />
          </Button>
        </div>
        <div className="flex-grow border-l">
          <ContextMenu>
            <ContextMenuContent>
              <ContextMenuItem onClick={rename} className="cursor-pointer">
                Rename
              </ContextMenuItem>
              <ContextMenuItem
                onClick={toggleHidden}
                className="cursor-pointer"
              >
                {section.hidden ? "Un-hide" : "Hide"}
              </ContextMenuItem>
              <ContextMenuItem onClick={duplicate} className="cursor-pointer">
                Duplicate
              </ContextMenuItem>
            </ContextMenuContent>
            <ContextMenuTrigger asChild>
              <CardHeader
                className="p-2 font-semibold border-none text-left flex flex-row justify-between items-center"
                ref={contextMenuTriggerRef}
              >
                <Button
                  variant="ghost"
                  pre={isExpanded ? <TriangleUpIcon /> : <TriangleDownIcon />}
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="p-2 text-foreground"
                >
                  <div className="flex items-baseline gap-2">
                    <SimpleTooltip content={section.description}>
                      {section.title}
                    </SimpleTooltip>

                    {section.hidden && (
                      <SimpleTooltip content="This section is hidden">
                        <EyeNoneIcon className="w-[12px] h-[12px]" />
                      </SimpleTooltip>
                    )}
                    {!sectionHasChanges(section, initialSection) ? (
                      <SimpleTooltip content="All changes saved">
                        <CheckCircleIcon className="w-[12px] h-[12px] text-success" />
                      </SimpleTooltip>
                    ) : (
                      <SimpleTooltip content="Unsaved changes">
                        <ExclamationTriangleIcon className="w-[12px] h-[12px] text-warning" />
                      </SimpleTooltip>
                    )}
                  </div>
                </Button>
                <div className="flex gap-2">
                  <Button
                    variant="ghost"
                    pre={<Plus className="w-[12px] h-[12px]" />}
                    className="text-2xs"
                    onClick={() => setIsCreateLessonModalOpen(true)}
                  >
                    Add New Lesson
                  </Button>
                  <Button
                    variant="ghost"
                    className="p-2"
                    onClick={triggerContextMenu}
                  >
                    <DotsVerticalIcon className="w-[12px] h-[12px]" />
                  </Button>
                </div>
              </CardHeader>
            </ContextMenuTrigger>
          </ContextMenu>
          <CardContent
            className={cn(
              "flex flex-grow flex-col gap-2 p-2 border-t",
              isExpanded ? "block" : "hidden"
            )}
          >
            <SortableContext items={lessonIds}>
              {lessons.map((lesson) => (
                <Lesson
                  key={isSavedLesson(lesson) ? lesson.id : lesson.tempId}
                  lesson={lesson}
                  onUpdateLesson={handleUpdateLesson}
                />
              ))}
            </SortableContext>
          </CardContent>
        </div>
      </Card>
    </>
  )
}

export function SectionContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex gap-4 items-center flex-col justify-center">
      {children}
    </div>
  )
}
