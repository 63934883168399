import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { useWizard } from "~/ui/Wizard"
import { useCommunity } from "~/community/useCommunity"
import { useLogEvent } from "~/analytics/EventsContext"
import { useEffect, useRef } from "react"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

export const SentAnExpenseTemplateStep = () => {
  const { close, meta } = useWizard()
  const community = useCommunity()
  const { logEvent } = useLogEvent()
  const hasLoggedEvent = useRef(false)

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.ExpenseTemplateViewed,
      {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      },
      hasLoggedEvent
    )
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          Sweet! We’ve also sent the expense template to your email.
        </DialogTitle>
      </DialogHeader>
      <Alert>
        Let us know if there's anything else we can support you with as you try
        to expense your {community.name} membership.
      </Alert>

      <Separator />

      <DialogFooter className="justify-center">
        <Button onClick={close}>Back to Membership</Button>
      </DialogFooter>
    </>
  )
}

SentAnExpenseTemplateStep.displayName = "SentAnExpenseTemplateStep"
