import * as React from "react"
import { cn } from "~/lib/utils"

import { Check, X, ChevronsUpDown } from "lucide-react"
import { Button } from "~/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import { Badge } from "~/ui/badge"

export type OptionType = {
  label: string
  value: string
}

interface MultiSelectProps {
  options: OptionType[]
  selected: string[]
  onChange: React.Dispatch<React.SetStateAction<string[]>>
  placeholder?: string
  className?: string
  disabled?: boolean
}

function MultiSelect({
  options,
  selected,
  onChange,
  className,
  placeholder,
  disabled = false,
  ...props
}: MultiSelectProps) {
  const [open, setOpen] = React.useState(false)

  const handleUnselect = (item: string) => {
    onChange(selected.filter((i) => i !== item))
  }

  const labelMap = React.useMemo(() => {
    return options.reduce((acc: { [key: string]: string }, option) => {
      acc[option.value] = option.label

      return acc
    }, {})
  }, [options])

  return (
    <Popover open={open} onOpenChange={setOpen} {...props}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          disabled={disabled}
          aria-expanded={open}
          className={`w-full pr-6 justify-between rounded-lg bg-white ${
            selected.length > 1 ? "h-full" : "h-full"
          }`}
          onClick={() => setOpen(!open)}
        >
          <div className="flex gap-1 flex-wrap w-full">
            {selected.length === 0 ? (
              <span className="text-placeholder text-xs">{placeholder}</span>
            ) : (
              selected.map((item) => (
                <Badge
                  variant="default"
                  key={item}
                  className="mr-1 mb-1 mt-1 overflow-hidden bg-primary text-white"
                  onClick={() => handleUnselect(item)}
                >
                  <span className="truncate">{labelMap[item]}</span>
                  <span
                    className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleUnselect(item)
                      }
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onClick={() => handleUnselect(item)}
                  >
                    <X className="h-3 w-3 text-muted-foreground hover:text-dark-gray" />
                  </span>
                </Badge>
              ))
            )}
          </div>
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-2">
        <Command className={className}>
          <CommandInput className="m-2" placeholder="Search ..." />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-auto">
            {options.map((option) => (
              <CommandItem
                key={option.value}
                value={option.value}
                onSelect={() => {
                  onChange(
                    selected.includes(option.value)
                      ? selected.filter((item) => item !== option.value)
                      : [...selected, option.value]
                  )
                  setOpen(true)
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    selected.includes(option.value)
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
                {option.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export { MultiSelect }
