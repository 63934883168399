import { Article_EditorContentFragment } from "~/__generated__/graphql"
import ArrowRight from "~/images/icons/arrow-right.svg?react"
import { TagAndContentType } from "~/ui/TagBubble"
import { ArticleDetailsModal } from "~/articles/ArticleDetailsModal"
import { useArticleDetailsModal } from "~/articles/useArticleDetailsModal"
import {
  ArticleCollaboratorsModal,
  useCreateCollaborator,
  useDestroyCollaborator,
} from "~/articles/ArticleCollaboratorsModal"
import React, { Fragment, useState } from "react"
import { RevisionHistoryDrawer } from "~/articles/RevisionHistoryDrawer"
import { StackedUserAvatars } from "~/ui/StackedUserAvatars"
import { ExternalShareSettingsModal } from "~/articles/ExternalShareSettingModal"
import { UserName } from "~/directory/UserName"

export const EditorMetadataSection = ({
  article,
  revisionHistoryDefaultOpen = false,
}: {
  article: Article_EditorContentFragment
  revisionHistoryDefaultOpen?: boolean
}) => {
  const [collaboratorsModalOpen, setCollaboratorsModalOpen] = useState(false)
  const articleDetailsModalHelpers = useArticleDetailsModal({
    existingArticle: article,
  })
  const [externalSharingModalOpen, setExternalSharingModalOpen] =
    useState(false)

  const { createCollaborator } = useCreateCollaborator()
  const { destroyCollaborator } = useDestroyCollaborator()

  return (
    <div className="flex border-b border-mercury">
      <div className="px-6 py-4 flex-1 items-start border-r border-mercury">
        <div className="flex items-center mb-4">
          <StackedUserAvatars
            users={article.collaborators.map((c) => c.user)}
          />

          <div className="ml-3 text-2xs tracking-wide">
            {article.collaborators
              .map((c) => c.user)
              .map((u, i, original) => (
                <Fragment key={u.id}>
                  <UserName user={u} />
                  {i < original.length - 1 && ", "}
                </Fragment>
              ))}
          </div>
        </div>
        <h1 className="font-semibold text-sm tracking-wide mb-2">
          {article.draftRevision.title}
        </h1>
        <p className="text-gray-500 text-2xs tracking-wide mb-3">
          {article.draftRevision.description}
        </p>
        <div>
          <TagAndContentType
            tagName={article.draftRevision.tag.name}
            contentType={article.draftRevision.contentType}
          />
        </div>
      </div>

      <div className="px-6 py-4">
        <ContentModalLink
          text="Edit Content Details"
          onClick={() => articleDetailsModalHelpers.setIsOpen(true)}
        />
        <ContentModalLink
          text="Edit / Invite Collaborators"
          onClick={() => setCollaboratorsModalOpen(true)}
        />
        {article.currentUserCanChangeExternalShareSetting && (
          <ContentModalLink
            text={
              article.allowExternalShare
                ? "External Sharing ON"
                : "External Sharing OFF"
            }
            onClick={() => setExternalSharingModalOpen(true)}
          />
        )}

        {article.currentUserCanViewRevisionLogs.value && (
          <RevisionHistoryDrawer
            articleId={article.id}
            defaultOpen={revisionHistoryDefaultOpen}
          >
            <ContentModalLink text="Revision History" />
          </RevisionHistoryDrawer>
        )}
      </div>

      <ArticleDetailsModal {...articleDetailsModalHelpers} />
      <ExternalShareSettingsModal
        isOpen={externalSharingModalOpen}
        setIsOpen={setExternalSharingModalOpen}
        articleId={article.id}
        allowExternalShare={article.allowExternalShare}
      />
      {collaboratorsModalOpen && (
        <ArticleCollaboratorsModal
          setIsOpen={setCollaboratorsModalOpen}
          articleId={article.id}
          collaborators={article.collaborators}
          onAddCollaborator={createCollaborator}
          onRemoveCollaborator={destroyCollaborator}
          onSubmit={() => setCollaboratorsModalOpen(false)}
          isEditing
        />
      )}
    </div>
  )
}

const ContentModalLink = React.forwardRef(
  ({ text, onClick }: { onClick?: () => void; text: string }, ref) => (
    <div
      className="mb-2 flex items-center text-xs font-medium cursor-pointer"
      onClick={onClick}
    >
      {text}
      <ArrowRight className="ml-2 text-blueCharcoal" />
    </div>
  )
)
