import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { useState } from "react"
import { useDebounce } from "use-debounce"
import { gql } from "~/__generated__"
import { AhoyEventTypeEnum, BookmarkTypeEnum } from "~/__generated__/graphql"
import { Card, CardHeader, CardTitle, CardContent } from "~/ui/card"
import { PageWithRightSidebar } from "~/layouts/PageWithRightSidebar"
import { BookmarkCard } from "~/bookmarks/BookmarkCard"
import { BookmarkTabs } from "~/bookmarks/BookmarkTabs"
import {
  DropdownMenuBadges,
  BadgeOptionType,
} from "~/common/DropdownMenuBadges"
import { useLogEvent } from "~/analytics/EventsContext"
import { SearchFeedback } from "~/common/SearchFeedback"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { Error } from "~/ui/Error"
import { SearchInput } from "~/ui/SearchInput"

// return the bookmark type if it is a valid BookmarkTypeEnum, otherwise return undefined
const sanitizeBookmarkType = (bookmarkType: string | undefined) =>
  (Object.values<string | undefined>(BookmarkTypeEnum).includes(bookmarkType)
    ? bookmarkType
    : BookmarkTypeEnum.All) as BookmarkTypeEnum

export const BookmarksScreen = () => {
  const { bookmarkType } = useParams()
  const sanitizedBookmarkType = sanitizeBookmarkType(bookmarkType)
  const [topics, setTopics] = useState<BadgeOptionType[]>([])
  const [query, setQuery] = useState<string>("")
  const [debouncedQuery] = useDebounce(query, 200)
  const { logEvent } = useLogEvent()

  const {
    data: currentData,
    previousData,
    loading,
    error,
    fetchMore,
  } = useQuery(BOOKMARKS_QUERY_DOCUMENT, {
    variables: {
      bookmarkType:
        sanitizedBookmarkType === BookmarkTypeEnum.All
          ? undefined
          : sanitizedBookmarkType,
      topicIds: topics.length > 0 ? topics.map((t) => t.value) : undefined,
      query: debouncedQuery,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    skip: !sanitizedBookmarkType,
    onCompleted: () => {
      if (debouncedQuery.length !== 0) {
        logEvent(AhoyEventTypeEnum.ContentSearched, {
          search_query: debouncedQuery,
        })
      }
    },
  })
  const data = currentData || previousData

  const topicOptions = data?.tags.nodes
    ? data.tags.nodes.map((topic) => ({
        value: topic.id,
        label: topic.name,
      }))
    : []

  const clearSearch = () => {
    setQuery("")
    setTopics([])
  }

  if (!data && loading)
    return (
      <div className="mt-8 flex items-center">
        <LoadingIndicator /> Loading bookmarks...
      </div>
    )
  if (error || !data) return <Error message="Error loading feed." />

  const bookmarks = data.bookmarks?.edges.map((e) => e.node) || []
  const searchActive = debouncedQuery !== "" || topics.length > 0

  return (
    <PageWithRightSidebar sidebar={null}>
      <Card>
        <CardHeader>
          <CardTitle>Bookmarks</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col p-4 gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="flex-grow">
              <SearchInput
                placeholder="Search your bookmarks..."
                searchTerm={query}
                setSearchTerm={setQuery}
              />
            </div>
            <div className="w-36">
              <DropdownMenuBadges
                title={
                  topics.length > 0
                    ? `${topics.length} Selected`
                    : "Filter Topic"
                }
                optionTitle="FILTER BY TOPIC"
                options={topicOptions}
                selected={topics}
                onChange={(data) => {
                  const topicNames = (data || []).map((t) => t.label)

                  if (topicNames.length !== 0) {
                    logEvent(AhoyEventTypeEnum.TopicFilterApplied, {
                      topics: topicNames.join(", "),
                    })
                  }

                  setTopics(data)
                }}
              />
            </div>
          </div>
          {searchActive && (
            <SearchFeedback
              totalCount={data?.bookmarks?.totalCount || 0}
              onClearSearch={() => {
                logEvent(AhoyEventTypeEnum.ContentSearchCleared, {
                  search_query: query,
                  topics: (topics || []).map((t) => t.label).join(", "),
                })
                clearSearch()
              }}
            />
          )}
        </CardContent>
      </Card>
      {sanitizedBookmarkType && (
        <BookmarkTabs currentTab={sanitizedBookmarkType} />
      )}
      <div className="pb-16">
        <div className="flex flex-col gap-4">
          {bookmarks.map((bookmark) => (
            <BookmarkCard key={bookmark.id} bookmark={bookmark} query={query} />
          ))}
        </div>
        {data.bookmarks && (
          <InfiniteLoadMore
            onEndReached={() => {
              if (!data.bookmarks) {
                return
              }
              fetchMore({
                variables: { after: data.bookmarks.pageInfo.endCursor },
              })
            }}
            canLoadMore={!loading && data.bookmarks.pageInfo.hasNextPage}
            loadingText="Loading bookmarks..."
            loading={loading && bookmarks.length > 0}
          />
        )}
      </div>
    </PageWithRightSidebar>
  )
}

gql(`
  fragment Bookmark_Card on Bookmark {
    ...Bookmark
    article {
      ...Article_Card
    }
    post {
      ...Post_Card
    }
  }
`)

export const BOOKMARKS_QUERY_DOCUMENT = gql(`
  query BookmarksScreen($bookmarkType: BookmarkTypeEnum, $topicIds: [ID!], $query: String, $after: String) {
    bookmarks(bookmarkType: $bookmarkType, topicIds: $topicIds, query: $query, first: 20, after: $after) {
      edges {
        node {
          ...Bookmark_Card
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
    tags {
      nodes {
        id
        name
      }
    }
  }
`)
