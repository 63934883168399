import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { feedPath } from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"

export const RequireUserOnboarding = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (!result.loading) {
      if (currentUser && currentUser.onboarded) {
        navigate(feedPath.pattern)
      }
    }
  }, [currentUser, result.loading, navigate])

  if (result.loading || !currentUser || currentUser.onboarded) {
    return null
  }

  return (
    <UserDialogContextProvider>
      <Outlet />
    </UserDialogContextProvider>
  )
}
