import invariant from "tiny-invariant"
import { ArrowDown } from "lucide-react"
import { usePostInViewport } from "./PostInViewportContext"
import { useMemo } from "react"
import { parseISO } from "date-fns"
import { Selector_ChannelFragment } from "~/__generated__/graphql"
import { useScrollingStopped } from "~/common/useScrollingStopped"

export const UnreadPostsIndicator = ({
  channel,
}: {
  channel?: Selector_ChannelFragment | null
}) => {
  const scrollingStopped = useScrollingStopped({ stoppedForMs: 3000 })
  const { postsInView } = usePostInViewport() || {}
  invariant(postsInView, "PostInViewport context must be provided")

  const hasMoreUnreads = useMemo(() => {
    if (!channel) return

    const firstPostInViewCreatedAt = postsInView
      .map((post) => parseISO(post.createdAt))
      .sort()[0]

    if (!firstPostInViewCreatedAt) return false

    const oldestPostTime = channel?.channelOldestUnreadPostTime
      ? parseISO(channel.channelOldestUnreadPostTime)
      : null

    return !!oldestPostTime && oldestPostTime < firstPostInViewCreatedAt
  }, [postsInView, channel])

  if (!hasMoreUnreads || !scrollingStopped) return null

  return (
    <div className="flex justify-center fixed bottom-4 left-0 right-0 z-50">
      <div className="bg-highlight text-white flex items-center gap-4 px-6 py-2 rounded-full">
        <div className="text-xs font-medium tracking-wide">More Unread</div>
        <ArrowDown size={20} />
      </div>
    </div>
  )
}
