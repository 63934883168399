import { useEffect } from "react"
import toast from "react-hot-toast"
import { Outlet, useNavigate } from "react-router-dom"
import { signInPath } from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireUserSignedIn = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (!result.loading) {
      if (!currentUser) {
        toast.error("Please sign in to continue.")
        navigate(signInPath.pattern)
      }
    }
  }, [currentUser, result.loading, navigate])

  if (result.loading || !currentUser) {
    return null
  }

  return <Outlet />
}
