import invariant from "tiny-invariant"

export const getMetaVar = (name: string) => {
  const metaTag = document.querySelector(`meta[name=${name}]`)
  invariant(metaTag)
  const content = metaTag.getAttribute("content")

  invariant(content)

  return content
}

export const getMetaVarMaybe = (name: string) => {
  const metaTag = document.querySelector(`meta[name=${name}]`)
  invariant(metaTag)
  const content = metaTag.getAttribute("content")

  return content
}
