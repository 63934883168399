import { Search, X } from "lucide-react"
import { IconInputAdornment, Input, InputProps } from "~/ui/input"

interface SearchInputProps extends InputProps {
  searchTerm: string
  setSearchTerm: (value: string) => void
}

export const SearchInput = ({
  searchTerm,
  setSearchTerm,
  ...props
}: SearchInputProps) => {
  return (
    <Input
      startAdornment={
        <IconInputAdornment>
          <Search className="w-4 h-4" />
        </IconInputAdornment>
      }
      endAdornment={
        searchTerm ? (
          <IconInputAdornment>
            <X
              className="w-4 h-4 cursor-pointer"
              onClick={() => setSearchTerm("")}
            />
          </IconInputAdornment>
        ) : undefined
      }
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      {...props}
    />
  )
}
