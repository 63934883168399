import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { Post_CardFragment } from "~/__generated__/graphql"

interface PostInViewportContextType {
  updateInView: (args: { post: Post_CardFragment; inView: boolean }) => void
  postsInView: Post_CardFragment[]
}

const PostInViewportContext = createContext<PostInViewportContextType | null>(
  null
)

export const PostInViewportProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [postsInView, setPostsInView] = useState<Post_CardFragment[]>([])

  const updateInView = useCallback(
    ({ post, inView }: { post: Post_CardFragment; inView: boolean }) => {
      setPostsInView((_postsInView) => {
        const existingIndex = _postsInView.findIndex((p) => p.id === post.id)

        if (existingIndex > -1 && inView) {
          return _postsInView
        } else if (existingIndex > -1 && !inView) {
          return _postsInView.toSpliced(existingIndex, 1)
        } else if (inView) {
          return [..._postsInView, post]
        } else {
          return _postsInView
        }
      })
    },
    [setPostsInView]
  )

  const value = useMemo(() => {
    return { updateInView, postsInView }
  }, [updateInView, postsInView])

  return (
    <PostInViewportContext.Provider value={value}>
      {children}
    </PostInViewportContext.Provider>
  )
}

export const usePostInViewport = () => {
  return useContext(PostInViewportContext)
}
