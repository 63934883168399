import { useCallback } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { Article } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { ConfirmContextProviderProps, useConfirm } from "~/ui/Confirm"

export type RequestEditsConfirmProps = {
  article: Pick<Article, "id">
} & Partial<ConfirmContextProviderProps>

export const useRequestEditsConfirm = ({
  article,
  ...confirmProps
}: RequestEditsConfirmProps) => {
  const showConfirm = useConfirm()
  const [runRequestEdits] = useSafeMutation(REQUEST_EDITS_MUTATION)

  const submitForReview = useCallback(() => {
    showConfirm({
      title: "Request Edits",
      body: "Request that the author makes edits to their content.",
      confirmText: "Request Edits",
      prompt: {
        label: "Requested changes",
        placeholder: "Describe the issues or changes you'd like to see",
        required: true,
      },
      onConfirm: async (promptValue) => {
        invariant(promptValue)
        const { errors } = await runRequestEdits({
          variables: {
            input: {
              articleId: article.id,
              message: promptValue,
            },
          },
        })

        if (errors) {
          displayErrors(errors)
          console.log(errors)
        } else {
          toast.success("Requested edits")
        }
      },
      ...confirmProps,
    })
  }, [showConfirm, confirmProps, runRequestEdits, article.id])

  return submitForReview
}

export const REQUEST_EDITS_MUTATION = gql(`
  mutation ArticleRequestEdits($input: ArticleRequestEditsInput!) {
    articleRequestEdits(input: $input) {
      article {
        ...Article_EditorContent
      }
    }
  }
`)
