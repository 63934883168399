import { formatDate } from "~/common/formatDate"
import Edit from "../images/icons/edit.svg?react"
import Trash from "../images/icons/trash-2.svg?react"
import { useMemo, useState } from "react"
import { UpdateCelebration } from "./UpdateCelebration"
import { Celebration, CelebrationTypeEnum } from "~/__generated__/graphql"
import { Button } from "~/ui/button"
import { useDestroyCelebration } from "./useDestroyCelebration"
import { useTranslation } from "react-i18next"

type CelebrationFragment = Pick<
  Celebration,
  "id" | "celebrationType" | "customCelebrationType" | "date"
>

type ShowCelebrationProps = {
  celebration: CelebrationFragment
  editable?: boolean
}

export const ShowCelebration = ({
  celebration,
  editable,
}: ShowCelebrationProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const formattedDate = useMemo(
    () =>
      celebration.celebrationType === CelebrationTypeEnum.Birthday
        ? formatDate(celebration.date, "MMM d")
        : formatDate(celebration.date),
    [celebration.celebrationType, celebration.date]
  )

  const { destroy: destroyCelebration } = useDestroyCelebration()

  const onCelebrationUpdated = () => {
    setIsEditing(false)
  }

  const onEditClicked = () => {
    setIsEditing(true)
  }

  const onDestroyClicked = () => {
    destroyCelebration({ celebrationId: celebration.id })
  }

  const { t: translateCelebrationType } = useTranslation("celebrations")

  return isEditing ? (
    <div className="py-4">
      <UpdateCelebration
        celebration={celebration}
        onSave={onCelebrationUpdated}
      />
    </div>
  ) : (
    <div className="flex space-x-2 items-center text-sm">
      <div>
        {translateCelebrationType(`${celebration.celebrationType}.emoji`)}
      </div>
      <div className="mr-2 flex-grow font-medium">
        {celebration.celebrationType === CelebrationTypeEnum.Other
          ? celebration.customCelebrationType
          : translateCelebrationType(`${celebration.celebrationType}.label`)}
      </div>
      <div className="mr-2">{formattedDate}</div>
      {editable && (
        <div className="flex">
          <Button onClick={onEditClicked} variant="ghost" size="icon">
            <Edit className="text-highlight" />
          </Button>
          <Button onClick={onDestroyClicked} variant="ghost" size="icon">
            <Trash className="text-highlight" />
          </Button>
        </div>
      )}
    </div>
  )
}
