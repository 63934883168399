import { DefaultTooltipContent } from "recharts"

import {
  BENCHMARK_DATA_KEY,
  BENCHMARK_DATA_KEY_FOR_DISPLAY,
} from "~/benchmarks/BenchmarkChart"

interface PayloadItem {
  name: string
}

interface PayloadProps {
  payload?: PayloadItem[]
}

export const CustomTooltip: React.FC<PayloadProps & { [key: string]: any }> = (
  props
) => {
  const { payload, ...rest } = props

  if (payload != null && payload.length > 0) {
    const newPayload = payload
      .filter((item: PayloadItem) => item.name !== "area")
      .map((item: PayloadItem) => {
        if (item.name === BENCHMARK_DATA_KEY) {
          return {
            ...item,
            name: BENCHMARK_DATA_KEY_FOR_DISPLAY,
          }
        } else {
          return item
        }
      })

    return <DefaultTooltipContent {...rest} payload={newPayload} />
  }

  return <DefaultTooltipContent {...rest} />
}
