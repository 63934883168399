import {
  USER_DATA_KEY_FOR_DISPLAY,
  COMMUNITY_DATA_KEY_FOR_DISPLAY,
} from "~/benchmarks/BenchmarkChart"

const LegendIcon = ({ color }: { color: string }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 32 32">
      <path
        stroke="none"
        fill={color}
        d="M0,4h32v24h-32z"
        className="recharts-legend-icon"
      ></path>
    </svg>
  )
}

interface LegendPayload {
  value: number // or any other appropriate type
  color: string
  dataKey: string
}

// Recharts does not allow specifying which data keys are displayed in the
// legend. This implements a custom legend styled to look like an existing
// legend which allows specifying keys.
export const CustomLegend = ({ payload }: { payload?: LegendPayload[] }) => {
  return (
    <div className="w-full flex items-center justify-center gap-1">
      {(payload || []).map(
        (entry, index) =>
          [USER_DATA_KEY_FOR_DISPLAY, COMMUNITY_DATA_KEY_FOR_DISPLAY].includes(
            entry.dataKey
          ) && (
            <div key={`item-${index}`} className="charts-legend-item">
              <div className="flex items-center gap-1">
                <LegendIcon color={entry.color} />
                <span style={{ color: entry.color }}>{entry.value}</span>
              </div>
            </div>
          )
      )}
    </div>
  )
}
