import { useEffect, useRef, useState } from "react"
import { MarkReadEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { MARK_READ_MUTATION } from "~/common/userUpdateMutation"

export const useReadIndicators = ({
  target,
}: {
  target: MarkReadEnum
}): { unviewedIds: string[] } => {
  const markedReadRef = useRef(false)
  const [runMarkRead] = useSafeMutation(MARK_READ_MUTATION)
  const [unviewedIds, setUnviewedIds] = useState<string[]>([])

  useEffect(() => {
    if (markedReadRef.current) return
    markedReadRef.current = true

    const run = async () => {
      const { data } = await runMarkRead({
        variables: {
          input: {
            target,
          },
        },
      })

      if (data?.markRead.unviewedIds) {
        setUnviewedIds(data.markRead.unviewedIds)
      }
    }

    run()
  }, [runMarkRead, target])

  return {
    unviewedIds,
  }
}
