import { useQuery } from "@apollo/client"
import { RenderFn } from "editorjs-blocks-react-renderer"
import { useEffect, useState } from "react"
import { PollDisplayFragment } from "~/__generated__/graphql"
import { Poll, POLL_QUERY } from "~/polls/Poll"
import { LoadingIndicator } from "~/ui/LoadingIndicator"

export interface PollBlockData {
  pollId: string
}

export const PollRenderer: RenderFn<PollBlockData> = ({ data: { pollId } }) => {
  const { data, loading } = useQuery(POLL_QUERY, { variables: { pollId } })
  const [poll, setPoll] = useState<PollDisplayFragment | undefined>(data?.poll)

  useEffect(() => {
    if (data?.poll) {
      setPoll(data.poll)
    }
  }, [data])

  if (!poll) return <></>
  return (
    <>
      {loading ? (
        <div className="flex flex-col gap-2 mb-6 text-xs">
          <div className="font-semibold">Loading poll...</div>
          <LoadingIndicator />
        </div>
      ) : (
        <Poll poll={poll} />
      )}
    </>
  )
}
