import { useQuery } from "@apollo/client"
import { DotsHorizontalIcon, OpenInNewWindowIcon } from "@radix-ui/react-icons"
import { capitalCase } from "change-case"
import { useMemo, useRef } from "react"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { Offer_AdminFragment } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/ui/button"
import { Card } from "~/ui/card"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from "~/ui/table"
import { useConfirm } from "~/ui/Confirm"
import { CopyToClipboard } from "~/ui/CopyToClipboard"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { TicketPercent } from "lucide-react"
import { AdminHeader } from "~/admin/AdminHeader"

export const AdminOffersScreen = () => {
  return (
    <>
      <AdminHeader title="Offers" Icon={TicketPercent} />
      <OffersTable />
    </>
  )
}

const OffersTable = () => {
  const {
    data: currentData,
    previousData,
    loading,
    fetchMore,
  } = useQuery(OFFERS_QUERY_DOCUMENT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const data = currentData || previousData

  const offers: any[] = useMemo(() => {
    return data?.offers?.edges.map((e) => e.node) || []
  }, [data])

  const hasNextPage = !!data?.offers?.pageInfo.hasNextPage

  return (
    <>
      {(offers.length > 0 || loading) && (
        <Card>
          <Table className={cn("rounded-tl-2xl")}>
            <TableHeader>
              <TableRow className="rounded-tl-2xl">
                <TableHead>Name</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Stripe Coupon ID</TableHead>
                <TableHead>Tier</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {offers.length > 0 &&
                offers.map((offer) => (
                  <OfferRow key={offer.id} offer={offer} />
                ))}
            </TableBody>
          </Table>
        </Card>
      )}

      {!loading && offers.length === 0 && (
        <div className="text-center text-gray-500">No offers found.</div>
      )}

      <div className="p-16 w-full flex items-center justify-center">
        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: {
                after: data?.offers?.pageInfo.endCursor,
              },
            })
          }
          canLoadMore={!loading && hasNextPage}
          loadingText="Loading more offers..."
          loading={loading && offers.length > 0 && hasNextPage}
        />
      </div>
    </>
  )
}

const OfferRow = ({ offer }: { offer: Offer_AdminFragment }) => {
  const [runUpdateOffer] = useSafeMutation(OFFERS_UPDATE_MUTATION)
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  const showConfirm = useConfirm()

  const changeStripeCoupon = () => {
    showConfirm({
      title: "Change Stripe Coupon",
      body: "Are you sure you want to change the Stripe coupon for this offer? This change will take place immediately.",
      prompt: {
        label: "Enter the new Stripe coupon ID",
        type: "text",
        placeholder: "coupon_12345",
        value: offer.stripeCouponId,
        required: true,
      },
      onConfirm: async (value) => {
        const { errors } = await runUpdateOffer({
          variables: {
            input: {
              id: offer.id,
              stripeCouponId: value,
            },
          },
        })

        if (errors) {
          displayErrors(errors)
          return
        }

        toast.success("Offer updated.")
      },
    })
  }

  const openCouponInStripe = () => {
    window.open(
      `https://dashboard.stripe.com/${
        offer.stripeCoupon.livemode ? "" : "test/"
      }coupons/${offer.stripeCouponId}`,
      "_blank"
    )
  }

  return (
    <ContextMenu>
      <ContextMenuContent>
        <ContextMenuItem
          className="cursor-pointer"
          onClick={changeStripeCoupon}
        >
          Change Stripe Coupon
        </ContextMenuItem>
        <ContextMenuItem
          className="cursor-pointer"
          onClick={openCouponInStripe}
        >
          View Coupon on Stripe <OpenInNewWindowIcon className="w-4 h-4" />
        </ContextMenuItem>
      </ContextMenuContent>
      <ContextMenuTrigger asChild>
        <TableRow ref={contextMenuTriggerRef} className="group">
          <TableCell className="border-0 px-4">
            <div className="max-w-64 truncate">{offer.name}</div>
          </TableCell>

          <TableCell className="border-0 px-4">
            <div className="max-w-48 truncate">
              {capitalCase(offer.category)}
            </div>
          </TableCell>

          <TableCell className="border-0 px-4">
            <CopyToClipboard text={offer.stripeCouponId}>
              {offer.stripeCouponId}
            </CopyToClipboard>
          </TableCell>

          <TableCell className="border-0 px-4">
            {capitalCase(offer.tier?.level)}
          </TableCell>

          <TableCell className="border-0 px-4">
            <div className="flex justify-end gap-2">
              <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
                <DotsHorizontalIcon />
              </Button>
            </div>
          </TableCell>
        </TableRow>
      </ContextMenuTrigger>
    </ContextMenu>
  )
}

gql(`
  fragment Offer_Admin on Offer {
    id
    category
    name
    stripeCouponId
    stripeCoupon {
      id
      livemode
      amountOff
      percentOff
    }
    tier {
      id
      level
    }
    effects {
      ...RulesEngineEffect_Admin
    }
  }
`)

const OFFERS_QUERY_DOCUMENT = gql(`
  query Offers(
    $categories: [OfferCategoryEnum!],
    $first: Int,
    $after: String,
  ) {
    offers(
      categories: $categories,
      first: $first,
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Offer_Admin
        }
      }
    }

  }
`)

const OFFERS_UPDATE_MUTATION = gql(`
  mutation OfferUpdate($input: OfferUpdateInput!) {
    offerUpdate(input: $input) {
      offer {
        ...Offer_Admin
      }
    }
  }
`)
