import { useEffect, useMemo, useRef } from "react"
import { useCommunity } from "~/community/useCommunity"
import { Alert } from "~/ui/alert"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Separator } from "~/ui/separator"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { formatDate } from "date-fns"
import { formatCurrency } from "~/common/formatCurrency"
import { useLogEvent } from "~/analytics/EventsContext"
import {
  AhoyEventTypeEnum,
  TierIntervalEnum,
  Offer,
} from "~/__generated__/graphql"
import { useTiers } from "~/tiers/TiersProvider"
import { PricingTableTier } from "./PricingTableStep"

export const SuccessStep = () => {
  const community = useCommunity()
  const { close, meta } = useWizard()
  const { subscription } = useSubscription()
  const { logEvent } = useLogEvent()
  const hasLoggedEvent = useRef(false)
  const { tiers, formatTierName } = useTiers()

  const offer = useMemo(() => {
    return meta.offer ? (meta.offer as Offer) : null
  }, [meta])

  const { selectedTier, selectedInterval } = useMemo(() => {
    return {
      selectedTier: meta.selectedTier as PricingTableTier | null,
      selectedInterval: meta.selectedInterval as TierIntervalEnum | null,
    }
  }, [meta.selectedTier, meta.selectedInterval])

  useEffect(() => {
    logEvent(
      AhoyEventTypeEnum.MembershipRenewalViewed,
      {
        cancellation_reason: meta.cancellationReason,
        other_cancellation_reason: meta.otherReason,
      },
      hasLoggedEvent
    )
  }, [logEvent, meta.cancellationReason, meta.otherReason])

  const discountedPrice = useMemo(() => {
    if (!subscription) return null

    return formatCurrency(subscription.upcomingInvoice?.amountDue || 0)
  }, [subscription])

  const stripePrice = useMemo(() => {
    const tier = tiers.find((t) => t.id === selectedTier?.id)
    return selectedInterval === TierIntervalEnum.Quarter
      ? tier?.quarterlyStripePrice
      : tier?.yearlyStripePrice
  }, [selectedTier, selectedInterval, tiers])

  if (!offer) return null

  return (
    <div className="flex flex-col gap-4 max-w-2xl">
      <DialogHeader>
        <DialogTitle>
          You've successfully renewed your{" "}
          <span className="whitespace-nowrap">
            {community.name} {formatTierName(selectedTier, selectedInterval)}
          </span>
          !
        </DialogTitle>
      </DialogHeader>
      <Alert className="flex gap-2 justify-center items-center">
        <div>🎉</div>
        <div>
          Your membership is set to renew on{" "}
          {formatDate(subscription!.currentPeriodEnd, "MMM d, yyyy")}
          {stripePrice?.unitAmount && (
            <>
              {" "}
              for{" "}
              {(subscription!.upcomingInvoice?.amountDue || 0) <
              stripePrice.unitAmount ? (
                <>
                  <span className="line-through">
                    {formatCurrency(stripePrice.unitAmount)}
                  </span>{" "}
                  {discountedPrice}
                </>
              ) : (
                formatCurrency(stripePrice.unitAmount)
              )}{" "}
              for the following {selectedInterval}.
            </>
          )}
          .
        </div>
      </Alert>

      <Separator />

      <DialogFooter className="justify-center">
        <Button onClick={close}>Back to Membership</Button>
      </DialogFooter>
    </div>
  )
}

SuccessStep.displayName = "SuccessStep"
