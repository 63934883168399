import React from "react"
import { Fragment } from "react"
import { cn } from "~/lib/utils"
import { STOP_WORDS } from "~/common/stopWords"

const STOP_WORDS_SET = new Set(STOP_WORDS)
const STACK_LIMIT = 5

enum TokenType {
  Plain = "plain",
  Highlight = "highlight",
}

type Token = {
  content: string
  type: TokenType
}

const getTotalContentLength = (tokens: Token[]) => {
  return tokens.reduce((acc: number, token: Token) => {
    return acc + token.content.length
  }, 0)
}

const applyTruncateRules = (parsedTokens: Token[], charCount: number) => {
  // Apply truncation to a token array that is assumed to be of all the same
  // type
  const truncate = (tokens: Token[], type: TokenType): Token[] => {
    const concatenated = tokens.reduce(
      (acc: string, token: Token) => acc + token.content,
      ""
    )

    const truncated =
      concatenated.length + 3 > charCount
        ? concatenated.substring(0, charCount - 3) + "..."
        : concatenated

    return [{ content: truncated, type: type }]
  }

  const recursiveTruncate = (tokens: Token[], recursions: number): Token[] => {
    // Guard clause to prevent infinite recursion. Render whatever content we
    // can
    if (recursions > STACK_LIMIT) return tokens

    const totalLength = getTotalContentLength(tokens)
    const delta = totalLength - charCount

    // Base case. The total length is less than the target charCount
    if (totalLength <= charCount) return tokens

    // If the whole token array is made up of highlights then highlighted
    // content needs to be truncated
    if (tokens.every((token: Token) => token.type === TokenType.Highlight)) {
      return truncate(tokens, TokenType.Highlight)
    }

    // If the whole token array is made up of plain text then content needs to
    // be truncated
    if (tokens.every((token: Token) => token.type === TokenType.Plain)) {
      return truncate(tokens, TokenType.Plain)
    }

    const firstHighlightIndex = tokens.findIndex(
      (token: Token) => token.type === TokenType.Highlight
    )
    const lastHighlightIndex = tokens
      .map((token: Token) => token.type)
      .lastIndexOf(TokenType.Highlight)

    if (firstHighlightIndex !== 0 && !tokens[0].content.startsWith("...")) {
      // The first highlight is not the first position, and the first position
      // has not being truncated
      if (delta > tokens[0].content.length) {
        tokens[0].content = "..."
      } else {
        tokens[0].content = "..." + tokens[0].content.substring(delta + 3)
      }
      return recursiveTruncate(tokens, recursions + 1)
    }

    const lastToken = tokens[tokens.length - 1]
    if (
      lastHighlightIndex !== tokens.length - 1 &&
      !lastToken.content.endsWith("...")
    ) {
      // The last highlight is not the last position, and the last position
      // has not being truncated
      if (delta > lastToken.content.length) {
        lastToken.content = "..."
      } else {
        lastToken.content =
          lastToken.content.substring(0, lastToken.content.length - delta - 3) +
          "..."
      }

      return recursiveTruncate(tokens, recursions + 1)
    }

    // Trimming from the start and end didn't get under the limit. Truncate from
    // the largest section between matches
    const largestPlainToken = tokens.reduce(
      (largestToken: Token | null, token: Token) => {
        if (token.type === TokenType.Plain && token.content !== "...") {
          if (
            !largestToken ||
            token.content.length > largestToken.content.length
          ) {
            return token
          }
        }
        return largestToken
      },
      null
    )

    if (largestPlainToken) {
      const delta = totalLength - charCount
      if (delta >= largestPlainToken.content.length - 3) {
        // Subtract 3 to account for '...'
        largestPlainToken.content = "..."
      } else {
        // Truncate the content from the middle
        const start = Math.floor(
          (largestPlainToken.content.length - delta - 3) / 2
        )
        const end = start + delta
        largestPlainToken.content =
          largestPlainToken.content.substring(0, start) +
          "..." +
          largestPlainToken.content.substring(end)
      }

      recursiveTruncate(tokens, recursions + 1)
    }

    // Shouldn't get here
    return tokens
  }

  return recursiveTruncate(parsedTokens.slice(), 0)
}

const addToken = (
  stack: Token[],
  content: string,
  type: TokenType,
  concat: boolean = false
) => {
  if (stack.length === 0) {
    // First token
    stack.push({
      content: content,
      type: type,
    })
  } else if (stack[stack.length - 1].type === type) {
    // Last element is of same type
    const last = stack[stack.length - 1]
    last.content = last.content + " " + content
  } else {
    // Previous token was a different type.
    const last = stack[stack.length - 1]

    if (last.type === TokenType.Plain) {
      if (!concat) {
        // Don't concat previous tokens. I.e separate with a space
        last.content = last.content + " "
      }

      stack.push({
        content: content,
        type: type,
      })
    } else {
      if (!concat) {
        stack.push({
          content: " " + content,
          type: type,
        })
      } else {
        stack.push({
          content: content,
          type: type,
        })
      }
    }
  }
}

interface HighlightContentProps extends React.HTMLAttributes<HTMLDivElement> {
  content: string
  query?: string
  truncationLimit: number
  lineLimit: number
}

export const HighlightContent = React.forwardRef<
  HTMLDivElement,
  HighlightContentProps
>(({ content = "", query, truncationLimit, lineLimit }, ref) => {
  if (!query) {
    return (
      <div className={cn(`clamp-${lineLimit}`)} ref={ref}>
        {content}
      </div>
    )
  }

  const queryTokens: string[] = query
    .split(" ")
    .filter((token: string) => !STOP_WORDS_SET.has(token.toLowerCase()))
    .map((token: string) => token.replace(/\W+/g, "").toLowerCase())

  const targetWords = content.split(" ")
  let highlightedText: Token[] = []

  // For all target words attempt to find a match from the provided query.
  //
  // A match means the starting characters, ignoring non alpha characters, are
  // the same.
  //
  // The output is a tokenized stack with the format:
  //
  // [ { content: 'content string', type: <Plain|Highlight> }]
  targetWords.forEach((word: string, index: number) => {
    const cleanedWord = word.replace(/\W+/g, "").toLowerCase()
    const matchedToken = queryTokens.find((token) =>
      cleanedWord.startsWith(token)
    )

    if (matchedToken) {
      const matchIndexStart = word.toLowerCase().indexOf(matchedToken)
      const matchIndexEnd = matchIndexStart + matchedToken.length

      const [matchedSubstring, remainingSubstring] = [
        word.slice(matchIndexStart, matchIndexEnd),
        word.slice(matchIndexEnd),
      ]

      // Characters prior to the match were found.
      if (matchIndexStart !== 0) {
        addToken(
          highlightedText,
          word.slice(0, matchIndexStart),
          TokenType.Plain
        )

        // push the matched substring onto the stack, concat is set to true as
        // the previous token is part of the same word
        addToken(highlightedText, matchedSubstring, TokenType.Highlight, true)
      } else {
        // Push the matched substring onto the stack
        addToken(highlightedText, matchedSubstring, TokenType.Highlight)
      }

      if (remainingSubstring !== "") {
        // Push the remaining characters onto the stack, concat is set to true
        // as the previous token is part of the same word
        addToken(highlightedText, remainingSubstring, TokenType.Plain, true)
      }
    } else {
      // No match found, push the word onto the stack as plain
      addToken(highlightedText, word, TokenType.Plain)
    }
  })

  // At least one highlight is found. Apply truncation rules to try and display
  // the highlighted content.
  if (highlightedText.some(({ type }) => type === TokenType.Highlight)) {
    const truncatedHighlightedText = applyTruncateRules(
      highlightedText,
      truncationLimit
    )

    return (
      <div className={cn(`clamp-${lineLimit}`)} ref={ref}>
        {truncatedHighlightedText.map(
          ({ content, type }: Token, index: number) =>
            type === TokenType.Highlight ? (
              <span key={index} className="bg-[#FFD1DD] rounded-sm">
                {content}
              </span>
            ) : (
              <Fragment key={index}>{content}</Fragment>
            )
        )}
      </div>
    )
  } else {
    return (
      <div className={cn(`clamp-${lineLimit}`)} ref={ref}>
        {content}
      </div>
    )
  }
})
