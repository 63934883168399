import * as React from "react"
import clsx from "clsx"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { ChevronsUpDown } from "lucide-react"

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/ui/dropdown-menu"
import { useCommunityClassname } from "~/community/useCommunity"
import { CommunitySlug } from "~/__generated__/graphql"

export type DropdownOptionType = {
  label: string | React.ReactNode
  value: string
}

interface DropdownMenuCheckboxesProps {
  title: string
  optionTitle?: string
  options: DropdownOptionType[]
  selected: DropdownOptionType[]
  onChange: (value: any[]) => void
  exclusive?: boolean
}

export function DropdownMenuCheckboxes({
  title,
  optionTitle,
  options,
  selected,
  onChange,
  exclusive = false,
}: DropdownMenuCheckboxesProps) {
  const ccls = useCommunityClassname()
  const isChecked = (value: string) => {
    const checked = selected.find((option) => option.value === value)

    return !!checked
  }

  const onCheckedChange = (option: DropdownOptionType) => {
    return (state: boolean) => {
      if (exclusive) {
        if (state) {
          onChange([option])
        } else {
          onChange([])
        }
      } else {
        if (state) {
          onChange([...selected, option])
        } else {
          onChange(selected.filter((s) => s.value !== option.value))
        }
      }
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div
          className={clsx(
            "border border-mercury h-10 rounded-md bg-white flex items-center justify-between py-2 px-3 text-xs",
            selected.length > 0 &&
              ccls({
                [CommunitySlug.Marketingland]: "text-library-dropdown-badges",
                default: "text-primary",
              })
          )}
          role="button"
        >
          {title}
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-72 p-4 rounded-lg" align="end">
        {optionTitle && (
          <>
            <div className="text-3xs text-placeholder tracking-wide mb-2">
              {optionTitle}
            </div>
            <DropdownMenuSeparator />
          </>
        )}
        <ScrollArea
          className={clsx(
            "m-h-72 ScrollArea overflow-y-auto overflow-x-hidden",
            { "h-72": options.length > 9 }
          )}
        >
          {options.map((option, i) => {
            const checked = isChecked(option.value)
            const key =
              typeof option.value === "string" ||
              typeof option.value === "number"
                ? option.value
                : i

            return (
              <div key={key}>
                <DropdownMenuCheckboxItem
                  checked={checked}
                  className="DropdownMenuCheckboxItem cursor-pointer"
                  onCheckedChange={onCheckedChange(option)}
                >
                  <div role="button" className="flex flex-row items-center">
                    <div>{option.label}</div>
                  </div>
                </DropdownMenuCheckboxItem>
                {i !== options.length - 1 && <DropdownMenuSeparator />}
              </div>
            )
          })}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
