import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { ExternalShareLayout } from "./ExternalShareLayout"
import { AuthenticatedLayout } from "./AuthenticatedLayout"

export const AuthenticatedOrExternalLayout = () => {
  const { currentUser, result } = useCurrentUserMaybe()

  if (result.loading) {
    return null
  } else if (currentUser) {
    return <AuthenticatedLayout />
  } else {
    return <ExternalShareLayout />
  }
}
