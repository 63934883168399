import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  StripeSubscriptionWithoutStatusHistoryFragment,
  TierIntervalEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ManageSubscriptionWizard } from "./ManageSubscriptionWizard"
import { WizardHandle } from "~/ui/Wizard"
import {
  stripeCheckoutSessionFreePath,
  stripeCheckoutSessionPlusPath,
  stripeCheckoutSessionPlusQuarterlyPath,
  stripeCheckoutSessionProPath,
} from "~/common/paths"
import { ApolloError } from "@apollo/client"
import { useSearchParams } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { useTiers } from "~/tiers/TiersProvider"
import { displayErrors } from "~/common/validations"

interface SubscriptionContextType {
  acceptOffer: (couponId: string) => Promise<boolean>
  isLoading: boolean
  migrateToTier: (
    level: TierLevelEnum,
    interval: TierIntervalEnum,
    offerId?: string
  ) => Promise<{
    data: StripeSubscriptionWithoutStatusHistoryFragment | null | undefined
    errors: ApolloError | undefined
    needsPaymentMethod: boolean
  }>
  redirectToStripe: (
    level: TierLevelEnum,
    interval: TierIntervalEnum,
    offerId?: string,
    meta?: { [key: string]: any }
  ) => void
  subscription?: StripeSubscriptionWithoutStatusHistoryFragment | null
  sendMakeTheMostEmail: () => Promise<void>
  setIsLoading: (loading: boolean) => void
  setSubscription: (
    subscription:
      | StripeSubscriptionWithoutStatusHistoryFragment
      | undefined
      | null
  ) => void
  openSubscriptionWizard: (
    step?: string | number,
    meta?: { [key: string]: any }
  ) => void
}

const SubscriptionContext = createContext<SubscriptionContextType | null>(null)

export const useSubscription = () => {
  const context = useContext(SubscriptionContext)
  invariant(context)
  return context
}

interface SubscriptionProviderProps {
  children: React.ReactNode
  subscription?: StripeSubscriptionWithoutStatusHistoryFragment
}

export const SubscriptionProvider = ({
  children,
  subscription: specifiedSubscription,
}: SubscriptionProviderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [runAcceptOffer] = useSafeMutation(ACCEPT_OFFER_MUTATION)
  const [runUserChangeTier] = useSafeMutation(USER_CHANGE_TIER_MUTATION)
  const [runSendMakeTheMostEmail] = useSafeMutation(
    SEND_MAKE_THE_MOST_EMAIL_MUTATION
  )
  const [subscription, setSubscription] = useState<
    StripeSubscriptionWithoutStatusHistoryFragment | undefined | null
  >(specifiedSubscription)
  const [subscriptionWizardStep, setSubscriptionWizardStep] = useState<
    string | number | undefined
  >(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const { currentUser } = useCurrentUserMaybe()
  const { tiers } = useTiers()

  useEffect(() => {
    if (specifiedSubscription) {
      setSubscription(specifiedSubscription)
    }
  }, [specifiedSubscription])

  const isUpgrading = useCallback(
    (level: TierLevelEnum) => {
      const tier = tiers.find((tier) => tier.level === level)
      if (!tier || !currentUser?.activeStripeSubscription) {
        return false
      }
      return (currentUser.tier?.position || 0) < tier.position
    },
    [currentUser, tiers]
  )

  const redirectToStripe = useCallback(
    (
      level: TierLevelEnum,
      interval: TierIntervalEnum,
      offerId?: string,
      meta?: { [key: string]: any }
    ) => {
      let billingPortalUrl = ""
      if (level === TierLevelEnum.Free) {
        billingPortalUrl = stripeCheckoutSessionFreePath.pattern
      } else if (level === TierLevelEnum.Plus) {
        billingPortalUrl =
          interval === TierIntervalEnum.Year
            ? stripeCheckoutSessionPlusPath.pattern
            : stripeCheckoutSessionPlusQuarterlyPath.pattern
      } else if (level === TierLevelEnum.Pro) {
        billingPortalUrl = stripeCheckoutSessionProPath.pattern
      } else {
        throw new Error("Unknown tier level")
      }

      const returnUrl = new URL(window.location.href)
      if (isUpgrading(level)) {
        returnUrl.searchParams.set("showPostUpgradeStep", "1")
        returnUrl.searchParams.set("offerId", offerId || "")
        if (meta) {
          for (const key in meta) {
            returnUrl.searchParams.set(key, meta[key])
          }
        }
      }
      window.location.href = `${billingPortalUrl}?offer_id=${
        offerId || ""
      }&return_url=${encodeURIComponent(returnUrl.href)}`
    },
    [isUpgrading]
  )

  const migrateToTier = useCallback(
    async (
      level: TierLevelEnum,
      interval: TierIntervalEnum,
      offerId?: string
    ) => {
      const { data, errors } = await runUserChangeTier({
        variables: {
          input: {
            tierLevel: level,
            interval,
            offerId,
          },
        },
      })

      const needsPaymentMethod = !!(
        errors &&
        (errors as any as ApolloError).graphQLErrors.some(
          (error) => error.extensions?.code === "NO_DEFAULT_PAYMENT_METHOD"
        )
      )

      if (errors && !needsPaymentMethod) {
        displayErrors(errors)
      }

      return {
        data: data?.userChangeTier.user.activeStripeSubscription,
        errors: errors as ApolloError | undefined,
        needsPaymentMethod,
      }
    },
    [runUserChangeTier]
  )

  const acceptOffer = useCallback(
    async (offerId: string) => {
      const { data, errors } = await runAcceptOffer({
        variables: {
          input: {
            offerId,
          },
        },
      })

      if (errors) {
        toast.error(
          "There was an error applying an offer to your membership. Please try again later."
        )
        return false
      }

      setSubscription(data?.offerAccept.stripeSubscription)

      return true
    },
    [runAcceptOffer]
  )

  const sendMakeTheMostEmail = useCallback(async () => {
    await runSendMakeTheMostEmail({ variables: { input: {} } })
  }, [runSendMakeTheMostEmail])

  const subscriptionWizardRef = useRef<WizardHandle | null>(null)

  const openSubscriptionWizard = useCallback(
    (step?: string | number, meta?: { [key: string]: any }) => {
      if (meta) {
        Object.entries(meta).forEach(([key, value]) => {
          subscriptionWizardRef.current?.addToMeta(key, value)
        })
      }
      if (step) {
        setSubscriptionWizardStep(step)
        subscriptionWizardRef.current?.goToStep(step)
      }
      subscriptionWizardRef.current?.setOpen(true)
    },
    [subscriptionWizardRef]
  )

  const [autoloadSubscriptionWizard, setAutoloadSubscriptionWizard] =
    useState(false)

  useEffect(() => {
    if (searchParams.has("showPostUpgradeStep")) {
      setTimeout(() => {
        setAutoloadSubscriptionWizard(true)
      }, 500)
    }
  }, [searchParams])

  useEffect(() => {
    if (autoloadSubscriptionWizard) {
      searchParams.delete("showPostUpgradeStep")
      const meta: { [key: string]: any } = {}
      searchParams.forEach((value, key) => {
        meta[key] = value
      })
      setSearchParams(new URLSearchParams())

      openSubscriptionWizard("PostUpgradeStep", meta)
      setAutoloadSubscriptionWizard(false)
    }
  }, [
    autoloadSubscriptionWizard,
    openSubscriptionWizard,
    searchParams,
    setSearchParams,
  ])

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        setSubscription,
        migrateToTier,
        redirectToStripe,
        isLoading,
        setIsLoading,
        acceptOffer,
        sendMakeTheMostEmail,
        openSubscriptionWizard,
      }}
    >
      <ManageSubscriptionWizard
        step={subscriptionWizardStep}
        ref={subscriptionWizardRef}
      />
      {children}
    </SubscriptionContext.Provider>
  )
}

gql(`
  fragment StripeSubscriptionWithoutStatusHistory on CachedStripeSubscription {
    id
    status
    currentPeriodStart
    currentPeriodEnd
    startDate
    endedAt
    cancelAt
    canceledAt
    cancelAtPeriodEnd
    discounts
    plan {
      id
      amount
      interval
      intervalCount
      product
    }

    latestInvoice {
      id
      attemptCount
      created
      nextPaymentAttempt
      paid
      amountPaid
      lines {
        periodStart
        periodEnd
      }
    }

    upcomingInvoice {
      amountDue
      amountPaid
      amountRemaining
      nextPaymentAttempt
      subtotal
      totalDiscountAmounts
    }

    subscriptionSchedule {
      id
      status
      currentPhase {
        startDate
        endDate
      }
      phases {
        startDate
        endDate
        items {
          price
        }
      }
    }
  }
`)

const ACCEPT_OFFER_MUTATION = gql(`
  mutation OfferAccept($input: OfferAcceptInput!) {
    offerAccept(input: $input) {
      user {
        id

        tier {
          id
          level
          name
          position
          waitPeriodHours
        }

        tierInterval
        upcomingTier {
          id
          level
          name
          position
          quarterlyStripePriceId
          yearlyStripePriceId
        }

        upcomingTierInterval
      }

      stripeSubscription {
        ...StripeSubscriptionWithoutStatusHistory
      }
    }
  }
`)

const SEND_MAKE_THE_MOST_EMAIL_MUTATION = gql(`
  mutation SendMakeTheMostEmail($input: UserSendMakeTheMostEmailInput!) {
    userSendMakeTheMostEmail(input: $input) {
      user {
        id
      }
    }
  }
`)

export const USER_CHANGE_TIER_MUTATION = gql(`
  mutation UserChangeTier($input: UserChangeTierInput!) {
    userChangeTier(input: $input) {
      user {
        id

        tier {
          id
          level
          name
          position
        }

        tierInterval
        upcomingTier {
          id
          level
          name
          position
        }
        upcomingTierInterval

        activeStripeSubscription {
          ...StripeSubscriptionWithoutStatusHistory
        }
      }
    }
  }
`)

export const USER_INACTIVE_STRIPE_SUBSCRIPTIONS_QUERY_DOCUMENT = gql(`
  query UserInactiveStripeSubscriptions($userId: ID!) {
    user(userId: $userId) {
      inactiveStripeSubscriptions {
        ...StripeSubscriptionWithoutStatusHistory

        statusHistory {
          id
          status
          createdAt
        }
      }
    }
  }
`)
