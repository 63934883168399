import { parseISO, isAfter, sub, format } from "date-fns"
import { humanizedTimeSpan } from "~/common/humanizedTimeSpan"

export const postDateDisplay = (dateIso: string) => {
  const date = parseISO(dateIso)

  if (isAfter(date, sub(new Date(), { seconds: 5 }))) {
    return "Just now"
  } else if (isAfter(date, sub(new Date(), { hours: 24 }))) {
    return humanizedTimeSpan(date)
  } else {
    return format(date, "M/d/yyyy")
  }
}
