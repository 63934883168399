import { subscriberAgreementPath } from "~/common/paths"
import { useCommunity } from "~/community/useCommunity"

export const AgreementsSentence = ({ preface }: { preface: string }) => {
  const community = useCommunity()
  const AGREEMENTS = [
    ["Terms of Service", community.termsOfUseUrl],
    ["Privacy Policy", community.privacyPolicyUrl],
    ["Subscriber Agreement", subscriberAgreementPath.pattern],
  ].filter((a) => a[1])

  return (
    <div className="sign-in-disclaimer">
      {preface}{" "}
      {AGREEMENTS.map((entry, i) => (
        <span key={i}>
          <a href={entry[1]!} target="_blank" rel="noreferrer">
            {entry[0]}
          </a>
          {i === AGREEMENTS.length - 1
            ? null
            : i === AGREEMENTS.length - 2
            ? ", and "
            : ", "}
        </span>
      ))}
      .
    </div>
  )
}
