import { ComposerHandle } from "~/post-composer/PostComposer"
import { REPLIES_QUERY_DOCUMENT } from "./RepliesFeed"
import { useQuery } from "@apollo/client"
import { useCallback, useRef } from "react"
import { useCreateReply } from "~/post-composer/useCreateReply"

export const useArticleReplies = ({
  articleId,
  skip = false,
}: {
  articleId: string
  skip?: boolean
}) => {
  const composerRef = useRef<ComposerHandle>(null)

  const repliesResult = useQuery(REPLIES_QUERY_DOCUMENT, {
    variables: { articleId },
    notifyOnNetworkStatusChange: true,
    skip,
  })

  const afterCreateReply = useCallback(() => {
    repliesResult.refetch()
  }, [repliesResult])

  const { createReply, replayIsSaving } = useCreateReply({
    articleId,
    onSuccess: afterCreateReply,
  })

  return { composerRef, createReply, replayIsSaving, repliesResult }
}
