import { Course_LiveContentFragment } from "~/__generated__/graphql"
import { Button } from "~/ui/button"
import { CourseIndex } from "./CourseIndex"
import { useNavigate } from "react-router-dom"
import { articlePath } from "~/common/paths"
import { CompletionPercentageText } from "~/layouts/CourseLessonLayout"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import invariant from "tiny-invariant"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"

export const CourseArticleContent = ({
  course,
}: {
  course: Course_LiveContentFragment | undefined | null
}) => {
  const firstLessonArticle = course?.sections[0]?.lessons?.[0].article
  const navigate = useNavigate()

  const navigateToFirstLesson = () => {
    firstLessonArticle &&
      navigate(articlePath({ articleId: firstLessonArticle.id }))
  }

  const [runCourseRestart, courseRestartResult] = useSafeMutation(
    COURSE_RESTART_MUTATION
  )

  const restartCourse = async () => {
    invariant(course)

    const { errors } = await runCourseRestart({
      variables: { input: { courseId: course.id } },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      toast.success("Restarted course.")
      navigateToFirstLesson()
    }
  }

  if (!course) return null

  return (
    <div className="tracking-wide">
      {course.currentUserCompleted && (
        <div className="mb-8 bg-goblin rounded-lg text-white p-6">
          <span className="text-xl mr-2">🎉</span>
          <strong>Congratulations!</strong> You have completed this course.
        </div>
      )}
      <div className="flex justify-between items-center mb-6">
        <Button
          onClick={
            course.currentUserCompleted ? restartCourse : navigateToFirstLesson
          }
          isLoading={courseRestartResult.loading}
        >
          {course.currentUserCompleted ? "Restart Course" : "Start Course"}
        </Button>
        <div className="ml-4 font-semibold">
          <CompletionPercentageText
            percentage={course.currentUserCompletionPercentage}
          />
        </div>
      </div>

      <CourseIndex course={course} />
    </div>
  )
}

const COURSE_RESTART_MUTATION = gql(`
  mutation CourseRestart($input: CourseRestartInput!) {
    courseRestart(input: $input) {
      course {
        id
      }
    }
  }
`)
