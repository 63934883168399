import { CompanySize } from "~/__generated__/graphql"
import { useManualHrisDataContext } from "~/merge/manualData"
import { COMPANY_SIZES } from "~/merge/company_sizes"
import { ChevronDown } from "lucide-react"

import { Base } from "./Base"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"

const SelectIcon = () => {
  return <ChevronDown className="h-4 w-4" />
}

export const ManualCompanySize = ({
  editContext = false,
  closeDialog,
}: {
  editContext?: boolean
  closeDialog?: () => void
}) => {
  const { next, prev, companySize, setCompanySize, onSuccessCallback } =
    useManualHrisDataContext()

  return (
    <Base
      header="Company Size"
      title="What is your company size?"
      prev={prev}
      next={next}
      disabled={companySize === undefined}
      editContext={editContext}
      submit={() => {
        onSuccessCallback()
        closeDialog && closeDialog()
      }}
    >
      <div className="text-xs font-medium tracking-wide text-textLight mb-1">
        Company size<span className="text-red-400">*</span>
      </div>
      <div className="mb-6">
        <Select
          onValueChange={(value: CompanySize) => setCompanySize(value)}
          defaultValue={companySize}
        >
          <SelectTrigger Icon={SelectIcon} className="focus:ring-offset-0">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {COMPANY_SIZES.map(({ value, label }) => {
                return (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                )
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </Base>
  )
}
