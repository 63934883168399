import { useQuery } from "@apollo/client"
import { addDays, setHours, setMinutes, setSeconds } from "date-fns"
import { HeartHandshake } from "lucide-react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { AdminHeader } from "~/admin/AdminHeader"
import { adminIntroductionsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import {
  INTRODUCTION_CYCLES_ADMIN_COUNT_QUERY_DOCUMENT,
  INTRODUCTION_CYCLES_ADMIN_CREATE_MUTATION,
} from "~/introduction-cycles/api"
import {
  IntroductionCycleForm,
  IntroductionCycleFormValues,
  parseIntroductionsDateField,
  utcDateToIntroductionsDateField,
} from "~/introduction-cycles/IntroductionCycleForm"
import { Card, CardContent } from "~/ui/card"
import { Error } from "~/ui/Error"
import { LoadingIndicator } from "~/ui/LoadingIndicator"

export const AdminNewIntroductionCycleScreen = () => {
  const navigate = useNavigate()
  const { data, loading } = useQuery(
    INTRODUCTION_CYCLES_ADMIN_COUNT_QUERY_DOCUMENT
  )
  const [runIntroductionCycleCreate, introductionCycleCreateResult] =
    useSafeMutation(INTRODUCTION_CYCLES_ADMIN_CREATE_MUTATION)

  const onSubmit = async (values: IntroductionCycleFormValues) => {
    const { errors } = await runIntroductionCycleCreate({
      variables: {
        input: {
          name: values.name,
          sendAt: parseIntroductionsDateField(values.sendAt).toISOString(),
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
    } else {
      toast.success("Created introduction cycle")
      navigate(adminIntroductionsPath.pattern)
    }
  }

  return (
    <>
      <AdminHeader
        title="Introductions"
        subtitle="New Introduction Cycle"
        Icon={HeartHandshake}
      />
      {loading ? (
        <LoadingIndicator />
      ) : data?.introductionCycles ? (
        <Card>
          <CardContent className="pt-4">
            <IntroductionCycleForm
              defaultValues={{
                name: `Cycle ${data.introductionCycles.totalCount + 1}`,
                sendAt: utcDateToIntroductionsDateField(
                  setSeconds(
                    setMinutes(setHours(addDays(new Date(), 7), 9), 0),
                    0
                  )
                ),
              }}
              onSubmit={onSubmit}
              loading={introductionCycleCreateResult.loading}
            />
          </CardContent>
        </Card>
      ) : (
        <Error message="Error loading data." />
      )}
    </>
  )
}
