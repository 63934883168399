import { ReactNode } from "react"
import { cn } from "~/lib/utils"
import { Button, ButtonVariants } from "~/ui/button"

export type ButtonToggleGroupProps = {
  leftButtonCopy: ReactNode
  leftButtonOnClick: () => void
  rightButtonCopy: ReactNode
  rightButtonOnClick: () => void
  activeButton: "left" | "right"
  leftButtonClassName?: string
  rightButtonClassName?: string
  leftButtonVariant?: ButtonVariants["variant"]
  rightButtonVariant?: ButtonVariants["variant"]
}

export const ButtonGroupToggle = ({
  leftButtonCopy,
  leftButtonOnClick,
  leftButtonClassName,
  rightButtonCopy,
  rightButtonOnClick,
  rightButtonClassName,
  activeButton,
  leftButtonVariant = "default",
  rightButtonVariant = "default",
}: ButtonToggleGroupProps) => {
  return (
    <div className="flex items-center border border-mercury p-1 rounded-full bg-white">
      <Button
        onClick={activeButton === "right" ? leftButtonOnClick : undefined}
        variant={activeButton === "left" ? leftButtonVariant : "link"}
        className={cn("text-xs", leftButtonClassName)}
        size="sm"
      >
        {leftButtonCopy}
      </Button>

      <Button
        onClick={activeButton === "left" ? rightButtonOnClick : undefined}
        variant={activeButton === "right" ? rightButtonVariant : "link"}
        className={cn("text-xs", rightButtonClassName)}
        size="sm"
      >
        {rightButtonCopy}
      </Button>
    </div>
  )
}
