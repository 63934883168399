import { cva, VariantProps } from "class-variance-authority"
import { forwardRef } from "react"
import { cn } from "~/lib/utils"

const descriptionListVariants = cva(
  "grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-2 items-center",
  {
    variants: {
      variant: {
        inline: "grid grid-cols-1 gap-x-4 gap-y-0",
      },
    },
  }
)

interface DescriptionListProps
  extends React.HTMLAttributes<HTMLDListElement>,
    VariantProps<typeof descriptionListVariants> {}

export const DescriptionList = forwardRef<
  HTMLDListElement,
  DescriptionListProps
>(({ variant, className, ...props }, ref) => {
  return (
    <dl
      ref={ref}
      className={cn(descriptionListVariants({ variant }), className)}
      {...props}
    />
  )
})

const descriptionTermVariants = cva("font-semibold", {
  variants: {
    variant: {
      inline: "font-normal",
    },
  },
})

interface DescriptionTermProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof descriptionTermVariants> {}

export const DescriptionTerm = forwardRef<HTMLElement, DescriptionTermProps>(
  ({ variant, className, ...props }, ref) => {
    return (
      <dt
        ref={ref}
        className={cn(descriptionTermVariants({ variant }), className)}
        {...props}
      />
    )
  }
)

const descriptionDetailsVariants = cva("col-span-3", {
  variants: {
    variant: {
      inline: "text-sm",
    },
  },
})

interface DescriptionDetailsProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof descriptionDetailsVariants> {}

export const DescriptionDetails = forwardRef<
  HTMLElement,
  DescriptionDetailsProps
>(({ variant, className, ...props }, ref) => {
  return (
    <dd
      ref={ref}
      className={cn(descriptionDetailsVariants({ variant }), className)}
      {...props}
    />
  )
})
