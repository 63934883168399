import { useLazyQuery } from "@apollo/client"
import { AlertCircleIcon, Lock } from "lucide-react"
import * as React from "react"
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { Poll, PollOption } from "~/__generated__/graphql"
import { cn } from "~/lib/utils"
import { Button } from "~/ui/button"
import BarChartIcon from "~/images/icons/bar-chart.svg?react"
import { SimpleTooltip } from "~/ui/SimpleTooltip"
import { PollEditorModal } from "./PollEditorModal"
import { PollTool } from "~/editor/editorTools/PollTool"

export type PollEditorFragment = Pick<Poll, "id" | "title" | "editable"> & {
  options: Pick<PollOption, "id" | "title">[]
}

export type PollEditorHandle = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type PollEditorProps = React.HTMLAttributes<HTMLDivElement> & {
  hideIfEmpty?: boolean
  poll?: PollEditorFragment | string | null
  onPollChange: (poll: PollEditorFragment) => void
  openIfEmpty?: boolean
  tool?: PollTool
}

export const PollEditor = React.forwardRef<PollEditorHandle, PollEditorProps>(
  (
    {
      poll: pollFromProps,
      onPollChange,
      tool,
      className,
      hideIfEmpty,
      openIfEmpty,
      ...props
    },
    ref
  ) => {
    const [wasModalOpened, setWasModalOpened] = useState(false)
    const [poll, setPoll] = useState<PollEditorFragment | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [runPollQuery] = useLazyQuery(POLL_EDITOR_QUERY, {
      onCompleted: (data) => {
        setPoll(data.poll)
      },
    })

    useEffect(() => {
      if (pollFromProps) {
        if (typeof pollFromProps === "string") {
          console.log("loading from props", pollFromProps)
          runPollQuery({ variables: { id: pollFromProps } })
        } else {
          setPoll(pollFromProps)
        }
      } else {
        setPoll(null)

        if (openIfEmpty) {
          setIsModalOpen(true)
        }
      }
    }, [pollFromProps, runPollQuery, openIfEmpty])

    useEffect(() => {
      if (isModalOpen) {
        setWasModalOpened(true)
      }
    }, [isModalOpen])

    const onPollSave = useCallback(
      (poll: PollEditorFragment) => {
        toast.success("Poll saved!")
        setPoll(poll)
        onPollChange(poll)
      },
      [onPollChange]
    )

    useEffect(() => {
      if (wasModalOpened && !isModalOpen && !poll && tool) {
        tool.remove()
      }
    }, [isModalOpen, poll, tool, wasModalOpened])

    const isError = useMemo(() => !poll && !isModalOpen, [poll, isModalOpen])
    const isModalDisabled = useMemo(() => poll && !poll.editable, [poll])

    useImperativeHandle(ref, () => ({
      setIsModalOpen,
    }))

    return (
      <>
        <PollEditorModal
          onSave={onPollSave}
          open={isModalOpen}
          poll={poll}
          setIsOpen={setIsModalOpen}
        />
        {hideIfEmpty && !poll && !isModalOpen ? null : (
          <div
            {...props}
            className={cn(
              "flex flex-col gap-4 rounded-lg border border-mercury p-4",
              isError && "border-dashed border-error",
              className
            )}
          >
            <div className="flex gap-2 items-center">
              <div className="rounded-md border border-mercury p-1">
                {isError ? (
                  <AlertCircleIcon className="fill-error text-white w-4 h-4" />
                ) : (
                  <BarChartIcon className="w-4 h-4" />
                )}
              </div>
              <div className="font-bold text-sm">Poll</div>
              {isModalDisabled && (
                <SimpleTooltip content="Poll cannot be editted after being included in published content">
                  <Lock className="w-3 h-3 text-slate-500 cursor-help" />
                </SimpleTooltip>
              )}
            </div>
            <div className="flex flex-col gap-2">
              {poll && <div className="text-sm font-bold">{poll.title}</div>}
              <div className="flex gap-2 items-center">
                <SimpleTooltip
                  content={
                    isModalDisabled
                      ? "Poll cannot be editted after being included in published content"
                      : ""
                  }
                >
                  <Button
                    variant="link"
                    size="inline"
                    onClick={() => !isModalDisabled && setIsModalOpen(true)}
                    className="w-min text-xs text-link font-normal"
                    pseudo={isModalDisabled ? "disabled" : undefined}
                    type="button"
                  >
                    {poll ? <>Edit Poll</> : <>Add Poll</>}
                  </Button>
                </SimpleTooltip>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
)

export const POLL_EDITOR_QUERY = gql(`
  query Poll($id: ID!) {
    poll(id: $id) {
      id
      title
      editable
      options {
        id
        title
      }
    }
  }
`)
