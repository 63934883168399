interface TokenProps {
  children: React.ReactNode
}

export const Token = ({ children }: TokenProps) => {
  return (
    <div className="rounded-full w-10 h-10 flex justify-center items-center bg-highlight text-white font-medium text-lg">
      {children}
    </div>
  )
}
