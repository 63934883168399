import { useQuery } from "@apollo/client"
import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsUpDown,
  Copy,
  X,
} from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { useSearchParams } from "react-router-dom"
import { useCopyToClipboard } from "usehooks-ts"
import { gql } from "~/__generated__"
import { Event_AdminFragment } from "~/__generated__/graphql"
import { formatDate } from "~/common/formatDate"
import { cn } from "~/lib/utils"
import { UserName } from "~/directory/UserName"
import { Button } from "~/ui/button"
import { Card, CardContent } from "~/ui/card"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/ui/collapsible"
import { Command, CommandGroup, CommandInput, CommandItem } from "~/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "~/ui/popover"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"

const DEFAULT_PAGE_SIZE = 100

const NAME_FILTERS = [
  "Page Viewed",
  "HRIS Connection Initiated",
  "HRIS Connection Completed",
  "Headshot Addded",
  "Skip For Now Clicked",
  "Onboarding Completed",
  "Manual Data Submission Initiated",
  "Manual Data Submission Completed",
  "Benchmark Filter Applied",
  "Benchmark Filter Cleared",
  "Benchmark CTA Clicked",
  "Post Submitted",
  "Post Replied To",
  "Post Reacted To",
  "Content Shared",
  "Topic Filter Applied",
  "Topic Filter Cleared",
  "Content Searched",
  "Content Search Cleared",
  "Content Upload Initiated",
  "Content Submitted",
  "Content Submission Approved",
  "Collaboration Invite Sent",
  "Profile Viewed",
  "Activity Interval",
  "Complete Profile CTA Clicked",
  "Direct Message Initiated",
  "Interests Changed",
  "Expertise Changed",
  "Company Changed",
  "Manage Subscription Clicked",
  "Direct Message Sent",
  "Referral Code Copied",
  "Referral Code Redeemed",
  "Location Filter Applied",
  "Filters Cleared",
  "Profile Update Toast Clicked",
  "User Synced to Sailthru",
]

export const Events = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [after, setAfter] = useState<string | undefined>(
    searchParams.get("after") || undefined
  )
  const [before, setBefore] = useState<string | undefined>(
    searchParams.get("before") || undefined
  )

  const first = useMemo(() => {
    if (!before) return DEFAULT_PAGE_SIZE
    return undefined
  }, [before])
  const last = useMemo(() => {
    if (before) return DEFAULT_PAGE_SIZE
    return undefined
  }, [before])

  const [nameFilter, setNameFilter] = useState<string | undefined>(
    searchParams.get("name") || undefined
  )

  const { data, loading } = useQuery(EVENTS_QUERY_DOCUMENT, {
    variables: {
      first,
      last,
      after,
      before,
      name: nameFilter,
    },
  })

  const events = data?.events.nodes
  const hasNextPage = data?.events.pageInfo.hasNextPage
  const hasPreviousPage = data?.events.pageInfo.hasPreviousPage

  const memoizedSearchParams = useMemo(() => {
    const params = new URLSearchParams(searchParams.toString())
    if (after) {
      params.set("after", after)
    } else {
      params.delete("after")
    }
    if (before) {
      params.set("before", before)
    } else {
      params.delete("before")
    }
    if (nameFilter) {
      params.set("name", nameFilter)
    } else {
      params.delete("name")
    }
    return params
  }, [after, before, nameFilter, searchParams])

  useEffect(() => {
    setSearchParams(memoizedSearchParams)
  }, [memoizedSearchParams, setSearchParams])

  useEffect(() => {
    if (nameFilter) {
      setAfter(undefined)
      setBefore(undefined)
    }
  }, [nameFilter])

  const goToNextPage = () => {
    if (!data?.events.pageInfo.endCursor) return

    setBefore(undefined)
    setAfter(data?.events.pageInfo.endCursor)
  }

  const goToPreviousPage = () => {
    if (!data?.events.pageInfo.startCursor) return

    setAfter(undefined)
    setBefore(data?.events.pageInfo.startCursor)
  }

  const clearNameFilter = (
    e: Pick<Event, "stopPropagation" | "preventDefault">
  ) => {
    e.stopPropagation()
    e.preventDefault()
    setNameFilter(undefined)
  }

  return (
    <>
      {loading ? (
        <LoadingIndicatorCentered />
      ) : (
        <div className="flex flex-col gap-4 mb-8">
          <div className="grid grid-cols-4 gap-2">
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="input" role="combobox">
                  <div className="flex-grow text-left">
                    {nameFilter || "Name"}
                  </div>
                  {nameFilter && (
                    <X
                      className="w-5 h-5 pointer ml-2 shrink-0"
                      onClick={clearNameFilter}
                    />
                  )}
                  <ChevronsUpDown className="ml-2 h-5 w-5 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Command>
                  <CommandInput placeholder="Select a name" />
                  <CommandGroup>
                    {NAME_FILTERS.map((name) => (
                      <CommandItem
                        key={name}
                        onSelect={() => setNameFilter(name)}
                      >
                        <Check
                          className={cn(
                            "w-5 h-5 mr-2",
                            nameFilter === name ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          {events?.map((event) => <EventCard key={event.id} event={event} />)}
          <div className="flex justify-between">
            <Button
              variant="outline"
              size="sm"
              onClick={goToPreviousPage}
              disabled={!hasPreviousPage}
            >
              <div className="flex items-center gap-2">
                <ChevronLeft className="w-5 h-5" />
                <span>Previous</span>
              </div>
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={goToNextPage}
              disabled={!hasNextPage}
            >
              <div className="flex items-center gap-2">
                <span>Next</span>
                <ChevronRight className="w-5 h-5" />
              </div>
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

type EventCardProps = {
  event: Event_AdminFragment
}

const EventCard = ({ event }: EventCardProps) => {
  const [open, setOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copy] = useCopyToClipboard()

  const properties = useMemo(
    () => JSON.stringify(event.properties, null, 2),
    [event.properties]
  )

  const copyToClipboard = () => {
    copy(properties)
    toast.success("Copied to clipboard")
  }

  return (
    <Card>
      <Collapsible open={open} onOpenChange={setOpen}>
        <CardContent className="pt-4">
          <div className="flex gap-2 items-center">
            <div className="shrink-0">
              <CollapsibleTrigger asChild>
                <Button
                  variant="subtle"
                  size="icon"
                  onClick={() => setOpen(!open)}
                >
                  <>
                    {open ? (
                      <ChevronDown className="w-5 h-5" />
                    ) : (
                      <ChevronRight className="w-5 h-5" />
                    )}
                  </>
                  <span className="sr-only">Toggle</span>
                </Button>
              </CollapsibleTrigger>
            </div>
            <div className="shrink-0 text-2xs pr-2">
              {formatDate(event.time, "M/d/yyyy HH:mm:ss")}
            </div>
            <div className="flex-1 font-bold">{event.name}</div>
            <div className="flex-1 text-2xs">
              {event.user && <UserName user={event.user} />}
            </div>
            <div className="flex-1 text-2xs">{event.user?.email}</div>
          </div>
          <CollapsibleContent>
            <div className="relative">
              <pre className="m-4 p-4 border border-mercury">{properties}</pre>
              <div className="absolute top-4 right-8">
                <Button
                  variant="subtle"
                  size="icon"
                  onClick={copyToClipboard}
                  className="opacity-50 hover:opacity-100"
                >
                  <Copy className="w-5 h-5" />
                </Button>
              </div>
            </div>
          </CollapsibleContent>
        </CardContent>
      </Collapsible>
    </Card>
  )
}

gql(`
  fragment Event_Admin on Event {
    id
    name
    time
    properties

    user {
      id
      name
      email
    }
  }
`)

const EVENTS_QUERY_DOCUMENT = gql(`
  query Events($first: Int, $last: Int, $after: String, $before: String, $name: String, $userId: ID) {
    events(first: $first, last: $last, after: $after, before: $before, name: $name, userId: $userId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        ...Event_Admin
      }
    }
  }
`)
