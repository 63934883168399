import { ReactNode, useEffect } from "react"
import { Course_LiveContentFragment } from "~/__generated__/graphql"
import { CourseIndex } from "~/content/CourseIndex"
import { useAuthenticatedLayout } from "./AuthenticatedLayout"

export const CourseLessonLayout = ({
  course,
  children,
  articleId,
}: {
  course: Course_LiveContentFragment
  children: ReactNode
  articleId: string
}) => {
  const { setLeftSidebar, resetLeftSidebar } = useAuthenticatedLayout()

  useEffect(() => {
    setLeftSidebar(null)
    return () => resetLeftSidebar()
  }, [setLeftSidebar, resetLeftSidebar])

  return (
    <div className="container mx-auto flex flex-col flex-1 px-4 sm:px-0">
      <div className="flex-1 flex">
        <div className="flex-1">{children}</div>
        <div className="ml-5 hidden lg:block w-[300px]">
          <div className="border border-borderColor rounded-lg bg-porcelain p-6 mb-4">
            <div className="tracking-wide font-semibold text-center mb-3 flex justify-between">
              <div>Course Completion</div>
              <div>{course.currentUserCompletionPercentage}%</div>
            </div>

            <div className="w-full bg-mercury h-[9px] rounded">
              <div
                className="h-full bg-mantis rounded"
                style={{ width: `${course.currentUserCompletionPercentage}%` }}
              ></div>
            </div>
          </div>
          <CourseIndex
            course={course}
            compactView
            currentArticleId={articleId}
          />
        </div>
      </div>
    </div>
  )
}

export const CompletionPercentageText = ({
  percentage,
}: {
  percentage: number
}) => {
  return (
    <>
      Course Completion {percentage}%
      {percentage === 100 && <span>&nbsp;&nbsp;🎉</span>}
    </>
  )
}
