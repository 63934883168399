import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ComposerOnSave } from "./PostComposer"
import { displayErrors } from "~/common/validations"
import invariant from "tiny-invariant"
import toast from "react-hot-toast"
import { useLogEvent } from "~/analytics/EventsContext"
import { AhoyEventTypeEnum } from "~/__generated__/graphql"

export const useCreateReply = ({
  parentPostId,
  parentReplyId,
  articleId,
  onSuccess,
}: {
  parentPostId?: string
  parentReplyId?: string
  articleId?: string
  onSuccess: () => void
}) => {
  const [runPostCreate, postCreateResult] = useSafeMutation(
    REPLY_CREATE_MUTATION
  )

  const { logEvent } = useLogEvent()

  const createReply: ComposerOnSave = async ({
    content,
    channelId,
    tagId,
    attachmentIds,
    videoUrl,
  }) => {
    const { data, errors } = await runPostCreate({
      variables: {
        input: {
          content,
          channelId,
          tagId,
          attachmentIds,
          parentPostId,
          parentReplyId,
          articleId,
          videoUrl,
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.log(errors)
      return false
    } else {
      toast.success("Created reply")
      invariant(data?.postCreate.post.id)
      logEvent(AhoyEventTypeEnum.PostRepliedTo, {
        post_id: data.postCreate.post.id,
        parent_post_id: parentPostId,
      })
      onSuccess()
      return true
    }
  }

  return { createReply, replayIsSaving: postCreateResult.loading }
}

const REPLY_CREATE_MUTATION = gql(`
  mutation ReplyCreate($input: PostCreateInput!) {
    postCreate(input: $input) {
      post {
        id
        parentPost {
          id
          ...Post_Card
        }
        parentReply {
          id
          ...Post_Card
        }
      }
    }
  }
`)
