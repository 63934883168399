import { Outlet } from "react-router-dom"
import { NavItems } from "~/components/NavItems"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunity, useCommunityClassname } from "~/community/useCommunity"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"
import { cn } from "~/lib/utils"
import { Link } from "~/ui/Link"
import safeSpaceLogoUrl from "../images/safe-space-logo.svg"
import boardroomLogoUrl from "../images/boardroom-logo.svg"
import marketinglandLogoUrl from "../images/marketingland-logo.svg"
import safeSpaceSquareLogoUrl from "../images/safe-space-square-logo.png"
import boardroomSquareLogo from "../images/boardroom-square-logo.png"
import marketinglandSquareLogo from "../images/marketingland-square-logo.png"
import { signInPath } from "~/common/paths"

const logoMap: Record<CommunitySlug, string> = {
  SAFESPACE: safeSpaceLogoUrl,
  BOARDROOM: boardroomLogoUrl,
  MARKETINGLAND: marketinglandLogoUrl,
}

const mobileLogoMap: Record<CommunitySlug, string> = {
  SAFESPACE: safeSpaceSquareLogoUrl,
  BOARDROOM: boardroomSquareLogo,
  MARKETINGLAND: marketinglandSquareLogo,
}

export const ExternalShareLayout = () => {
  const ccls = useCommunityClassname()
  const community = useCommunity()

  return (
    <UserDialogContextProvider>
      <div className={`flex-1 flex flex-col`}>
        <nav role="navigation" aria-label="Main menu" aria-controls="main-menu">
          <div
            className={cn(
              "p-4 border-b h-[75px]",
              ccls({
                [CommunitySlug.Marketingland]: "border-primary",
                default: "border-mercury",
              })
            )}
          >
            <div className="mx-auto container flex items-center">
              <Link
                to={signInPath.pattern}
                className="block flex-grow"
                aria-label="Homepage"
              >
                <img
                  src={logoMap[community.slug]}
                  alt=""
                  className="hidden md:block"
                />
                <img
                  src={mobileLogoMap[community.slug]}
                  alt=""
                  className="md:hidden ml-3 w-[35px] h-[35px] rounded-md"
                />
              </Link>
            </div>
          </div>
        </nav>

        <div className="flex flex-1 flex-col">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 container mx-auto my-5">
            <div className="hidden lg:block blur pointer-events-none">
              <NavItems />
            </div>
            <div className={cn("md:col-span-3")}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </UserDialogContextProvider>
  )
}
