import { RenderFn } from "editorjs-blocks-react-renderer"
import { Viewer } from "@react-pdf-viewer/core"
import {
  ToolbarProps,
  ToolbarSlot,
  defaultLayoutPlugin,
} from "@react-pdf-viewer/default-layout"
import type { TransformToolbarSlot } from "@react-pdf-viewer/toolbar"

interface AttachmentBlockFileData {
  contentType: string
  extension?: string
  name: string
  id: string
  size: number
  url: string
}

export interface AttachmentBlockData {
  file: AttachmentBlockFileData
  title: string
}

export const EmbeddedPdfRenderer: RenderFn<AttachmentBlockData> = ({
  data,
}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    ),
  })

  const { renderDefaultToolbar } =
    defaultLayoutPluginInstance.toolbarPluginInstance

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => {
    return Object.assign({}, slot, {
      Open: () => null,
    })
  }

  return (
    <div className="h-[45rem] mb-6">
      <Viewer fileUrl={data.file.url} plugins={[defaultLayoutPluginInstance]} />
    </div>
  )
}
