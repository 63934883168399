import { useMemo } from "react"
import {
  Offer,
  RulesEngineEffect,
  StripeCoupon,
  Tier,
  TierIntervalEnum,
} from "~/__generated__/graphql"
import { formatCurrency } from "~/common/formatCurrency"
import { useTiers } from "~/tiers/TiersProvider"

interface UseDiscountedAmountOffer extends Pick<Offer, "tierInterval"> {
  effects: Pick<RulesEngineEffect, "params" | "type">[]
  stripeCoupon: Pick<StripeCoupon, "amountOff" | "percentOff">
  tier: Pick<Tier, "id">
}

export const useDiscountedAmount = (
  offer?: UseDiscountedAmountOffer | null
) => {
  const { tiers } = useTiers()

  const offerStripePrice = useMemo(() => {
    if (!offer) return null

    const tier = tiers.find((t) => t.id === offer.tier.id)
    if (!tier) return null

    return offer.tierInterval === TierIntervalEnum.Quarter
      ? tier.quarterlyStripePrice
      : tier.yearlyStripePrice
  }, [offer, tiers])

  const amountAfterDiscount = useMemo(() => {
    if (!offer || !offerStripePrice) return null

    if (offer.stripeCoupon.amountOff) {
      return Math.max(
        0.0,
        offerStripePrice.unitAmount - offer.stripeCoupon.amountOff
      )
    }

    if (offer.stripeCoupon.percentOff) {
      return Math.max(
        0.0,
        offerStripePrice.unitAmount -
          offerStripePrice.unitAmount * (offer.stripeCoupon.percentOff / 100)
      )
    }

    return null
  }, [offer, offerStripePrice])

  const discount = useMemo(() => {
    if (offer?.stripeCoupon.percentOff) {
      return `${offer.stripeCoupon.percentOff}%`
    } else if (offer?.stripeCoupon.amountOff) {
      return formatCurrency(offer.stripeCoupon.amountOff)
    }

    return null
  }, [offer])

  return {
    discount,
    amountAfterDiscount,
    amountBeforeDiscount: offerStripePrice?.unitAmount,
  }
}
