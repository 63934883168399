import { AlertCircleIcon, Clipboard } from "lucide-react"
import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { cn } from "~/lib/utils"
import { Button } from "~/ui/button"
import { AirTableFormEmbedTool } from "./editorTools/AirTableFormEmbedTool"
import { AirTableFormEmbed } from "./AirTableFormEmbed"
import { AirTableFormEmbedEditorModal } from "./AirTableFormEmbedModal"

type AirTableFormEmbedEditorProps = React.HTMLAttributes<HTMLDivElement> & {
  url?: string
  onAirTableFormEmbedChange: (url: string) => void
  tool: AirTableFormEmbedTool
}

export const AirTableFormEmbedEditor = React.forwardRef<
  HTMLDivElement,
  AirTableFormEmbedEditorProps
>(({ url, onAirTableFormEmbedChange, tool, className, ...props }, ref) => {
  const [wasModalOpened, setWasModalOpened] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(!url)
  const [airTableFormUrl, setAirTableFormUrl] = React.useState<string>(
    url || ""
  )

  useEffect(() => {
    if (url) {
      setAirTableFormUrl(url)
    }
  }, [url])

  useEffect(() => {
    if (isModalOpen) {
      setWasModalOpened(true)
    }
  }, [isModalOpen])

  const isError = useMemo(
    () => !isModalOpen && wasModalOpened && !airTableFormUrl,
    [airTableFormUrl, wasModalOpened, isModalOpen]
  )
  useEffect(() => {
    if (!isModalOpen && wasModalOpened && !airTableFormUrl) {
      tool.remove()
    }
  }, [airTableFormUrl, tool, wasModalOpened, isModalOpen])

  useEffect(() => {
    if (!wasModalOpened && !airTableFormUrl) {
      setIsModalOpen(true)
    }
  }, [airTableFormUrl, wasModalOpened])

  const onAirTableFormEmbedSave = useCallback(
    (url: string) => {
      setAirTableFormUrl(url)
      onAirTableFormEmbedChange(url)
    },
    [onAirTableFormEmbedChange]
  )

  return (
    <>
      <AirTableFormEmbedEditorModal
        open={isModalOpen}
        url={airTableFormUrl}
        setIsOpen={setIsModalOpen}
        onSave={onAirTableFormEmbedSave}
      />
      <div
        {...props}
        className={cn(
          "flex flex-col gap-4 rounded-lg border border-mercury p-4",
          isError && "border-dashed border-error",
          className
        )}
        ref={ref}
      >
        <div className="flex gap-2 items-center">
          <div className="rounded-md border border-mercury p-1">
            {isError ? (
              <AlertCircleIcon className="fill-error text-white w-4 h-4" />
            ) : (
              <Clipboard className="w-4 h-4" />
            )}
          </div>
          <div className="font-bold text-sm">AirTable Form</div>
        </div>
        <div className="flex flex-col gap-2">
          {airTableFormUrl && (
            <>
              <div className="text-sm font-bold">{airTableFormUrl}</div>
              <AirTableFormEmbed url={airTableFormUrl} />
            </>
          )}
          <div className="flex gap-2 items-center">
            <Button
              variant="link"
              size="inline"
              onClick={() => setIsModalOpen(true)}
              className="w-min text-xs text-link font-normal"
            >
              {airTableFormUrl ? <>Edit Form URL</> : <>Add Form URL</>}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
})
