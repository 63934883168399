import { cn } from "~/lib/utils"

export const Indicator = ({
  value,
  size = "default",
}: {
  value: number
  size?: "sm" | "default"
}) => {
  if (value === 0) return null

  return (
    <div
      className={cn(
        "bg-highlight rounded-full text-2xs flex items-center justify-center text-white px-1",
        size === "default" && "min-w-[24px] h-[24px] text-2xs",
        size === "sm" && "min-w-[16px] h-[16px] text-4xs"
      )}
    >
      {value}
    </div>
  )
}
