import { Dialog, DialogContent } from "~/ui/dialog"
import { Button } from "~/ui/button"
import {
  Course_LiveContentFragment,
  Section_LiveContentFragment,
} from "~/__generated__/graphql"
import { useEffect, useRef } from "react"
import { addConfetti } from "~/common/addConfetti"

export const ModuleCompletedModal = ({
  isOpen,
  setIsOpen,
  section,
  course,
  onContinue,
}: {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  section: Section_LiveContentFragment
  course: Course_LiveContentFragment
  onContinue: () => void
}) => {
  const openWasRef = useRef(false)

  useEffect(() => {
    if (openWasRef.current === false && isOpen === true) {
      openWasRef.current = true
      addConfetti()
    }
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        setIsOpen(value)
      }}
    >
      <DialogContent className="w-2/3 max-w-xl gap-0 py-10">
        <div className="mb-2 font-serif text-3xl text-center">
          🎉 Module Complete!
        </div>
        <div className="mb-6 text-xs tracking-wide text-center lg:px-16">
          You have successfully finished{" "}
          <span className="font-semibold">{section.title}</span> in{" "}
          <span className="font-semibold">
            {course.article.approvedRevision?.title}
          </span>
          .
        </div>

        <div className="flex justify-center">
          <Button type="button" className="px-8 py-3" onClick={onContinue}>
            Continue Course
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
