import { ArticleContentTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import Course from "../images/icons/course.svg?react"
import Guide from "../images/icons/guide.svg?react"
import Replay from "../images/icons/replay.svg?react"
import Report from "../images/icons/report.svg?react"
import TemplatesAndTools from "../images/icons/templates-and-tools.svg?react"
import Exercise from "../images/icons/exercise.svg?react"
import ChartSquare from "../images/icons/chart-square.svg?react"
import Chemistry from "../images/icons/chemistry.svg?react"
import Notebook from "../images/icons/notebook.svg?react"
import BlueprintArchitecture from "../images/icons/blueprint-architecture.svg?react"
import SwipeFile from "../images/icons/swipe-file.svg?react"
import CaseStudy from "../images/icons/case-study.svg?react"
import Playbook from "../images/icons/playbook.svg?react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/ui/tooltip"
import { ARTICLE_CONTENT_TYPE_LABELS } from "~/common/enumTranslations"
import { cn } from "~/lib/utils"
import { useCommunityClassname } from "~/community/useCommunity"

export const TagBubble = ({
  tagName,
  colorClass,
  borderClass,
}: {
  tagName: string
  colorClass?: string
  borderClass?: string
}) => {
  const ccls = useCommunityClassname()

  if (!colorClass) {
    colorClass = ccls({
      [CommunitySlug.Safespace]: "text-frenchRose",
      [CommunitySlug.Marketingland]: "text-marketingland-blue",
      default: "text-highlight",
    })
  }
  if (!borderClass) {
    borderClass = ccls({
      [CommunitySlug.Safespace]: "border-frenchRose",
      [CommunitySlug.Marketingland]: "border-marketingland-blue",
      default: "border-highlight",
    })
  }
  return (
    <span
      className={cn(
        "border rounded-md text-[10px] uppercase",
        "tracking-[0.1em] px-[6px] py-[3px]",
        colorClass,
        borderClass
      )}
    >
      {tagName}
    </span>
  )
}

export const TagAndContentType = ({
  tagName,
  contentType,
  colorClass,
  borderClass,
}: {
  tagName: string
  contentType?: ArticleContentTypeEnum | null
  colorClass?: string
  borderClass?: string
}) => {
  const ccls = useCommunityClassname()

  colorClass = ccls({
    [CommunitySlug.Safespace]: "text-frenchRose",
    [CommunitySlug.Marketingland]: "text-marketingland-blue",
    default: "text-highlight",
  })
  borderClass = ccls({
    [CommunitySlug.Safespace]: "border-frenchRose",
    [CommunitySlug.Marketingland]: "border-marketingland-blue",
    default: "border-highlight",
  })

  return (
    <div className={cn(colorClass, "flex items-center gap-3")}>
      <TagBubble
        tagName={tagName}
        colorClass={colorClass}
        borderClass={borderClass}
      />
      {contentType && <ContentTypeIconWithToolTip contentType={contentType} />}
    </div>
  )
}

export const ContentTypeIconWithToolTip = ({
  contentType,
  colorClass,
}: {
  contentType: ArticleContentTypeEnum
  colorClass?: string
}) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={40}>
        <TooltipTrigger className={cn("z-30", colorClass, "cursor-default")}>
          {contentType && <ContentTypeIcon contentType={contentType} />}
        </TooltipTrigger>
        <TooltipContent>
          {ARTICLE_CONTENT_TYPE_LABELS[contentType]}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export const ContentTypeIcon = ({
  contentType,
  className,
}: {
  contentType: ArticleContentTypeEnum
  className?: string
}) => {
  switch (contentType.toUpperCase()) {
    case ArticleContentTypeEnum.Course:
      return <Course className={className} />
    case ArticleContentTypeEnum.Guide:
      return <Guide className={className} />
    case ArticleContentTypeEnum.Replay:
      return <Replay className={className} />
    case ArticleContentTypeEnum.Report:
      return <Report className={className} />
    case ArticleContentTypeEnum.Template:
      return <TemplatesAndTools className={className} />
    case ArticleContentTypeEnum.Exercise:
      return <Exercise className={className} />
    case ArticleContentTypeEnum.FinancialAnalysis:
      return <ChartSquare className={className} />
    case ArticleContentTypeEnum.Research:
      return <Chemistry className={className} />
    case ArticleContentTypeEnum.DeepDive:
      return <Notebook className={className} />
    case ArticleContentTypeEnum.Note:
      return <BlueprintArchitecture className={className} />
    case ArticleContentTypeEnum.Playbook:
      return <Playbook className={className} />
    case ArticleContentTypeEnum.SwipeFile:
      return <SwipeFile className={className} />
    case ArticleContentTypeEnum.CaseStudy:
      return <CaseStudy className={className} />

    default:
      return <></>
  }
}
