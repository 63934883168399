import { useMemo } from "react"
import { CommunitySlug } from "~/__generated__/graphql"
import BoardroomSignature from "~/images/boardroom-signature.svg?react"
import MarketinglandSignature from "~/images/marketingland-signature.svg?react"
import SafespaceSignature from "~/images/safespace-signature.svg?react"
import { useCommunity } from "./useCommunity"

export const CreatorSignature = () => {
  const community = useCommunity()

  return useMemo(() => {
    switch (community.slug) {
      case CommunitySlug.Safespace:
        return <SafespaceSignature className="max-w-half h-fit" />
      case CommunitySlug.Boardroom:
        return <BoardroomSignature />
      case CommunitySlug.Marketingland:
        return <MarketinglandSignature />
      default:
        return null
    }
  }, [community])
}
