import { DialogProps } from "@radix-ui/react-dialog"
import { useCallback, useEffect, useState } from "react"
import { Dialog, DialogContent } from "~/ui/dialog"
import { OnboardingTitle } from "~/ui/Onboarding"
import { Trans } from "react-i18next"
import {
  OnboardingExperienceQuestions,
  useMissingOnboardingExperienceFields,
} from "./OnboardingExperienceQuestions"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useLocation } from "react-router-dom"
import { feedPath } from "~/common/paths"

interface OnboardingExperienceModalProps
  extends Omit<DialogProps, "open" | "onOpenChange"> {}

export const OnboardingExperienceModal = ({
  ...dialogProps
}: OnboardingExperienceModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser } = useCurrentUser()
  const location = useLocation()

  const onSave = useCallback(() => {
    setIsOpen(false)
  }, [])

  const missingFields = useMissingOnboardingExperienceFields()
  useEffect(() => {
    setIsOpen(
      currentUser.onboarded &&
        missingFields.length > 0 &&
        location.pathname !== feedPath.pattern
    )
  }, [currentUser, missingFields, location])

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen} {...dialogProps}>
      <DialogContent
        className="w-full sm:w-2/3-screen sm:max-w-xl gap-0"
        noCloseButton
      >
        <OnboardingTitle className="mb-2">
          <Trans i18nKey="experienceScreen.title" ns="onboarding" />
        </OnboardingTitle>

        <div className="text-center mb-6 text-2xs font-medium">
          <Trans
            i18nKey="experienceScreen.oops"
            ns="onboarding"
            count={missingFields.length}
          />
        </div>

        <OnboardingExperienceQuestions
          onSave={onSave}
          withCard={false}
          fitProfileDefaults={currentUser.fitProfile}
          onlyMissingFields
        />
      </DialogContent>
    </Dialog>
  )
}
