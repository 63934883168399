import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { Input } from "~/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/ui/select"
import { Textarea } from "~/ui/textarea"
import { Button } from "~/ui/button"
import { useCommunity } from "~/community/useCommunity"
import { z } from "zod"
import { ArticleContentTypeEnum } from "~/__generated__/graphql"
import { useTags } from "~/common/queries"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { CONTENT_TYPE_LABELS } from "~/articles/ArticleDetailsModal"

const createContentModalFormSchema = z.object({
  contentType: z.nativeEnum(ArticleContentTypeEnum, {
    required_error: "Select a type",
  }),
  tagId: z.string({
    required_error: "Select a topic",
  }),
  title: z.string().min(1, {
    message: "Title is required",
  }),
  description: z.string().min(1, {
    message: "Description is required",
  }),
})

export type CreateContentModalFormValues = z.infer<
  typeof createContentModalFormSchema
>

type ContentFormProps = {
  loading: boolean
  open: boolean
  onSubmit: (values: CreateContentModalFormValues) => void
}

export const ContentForm = ({ loading, open, onSubmit }: ContentFormProps) => {
  const community = useCommunity()
  const tags = useTags()

  const form = useForm<z.infer<typeof createContentModalFormSchema>>({
    resolver: zodResolver(createContentModalFormSchema),
    defaultValues: {
      title: "",
      description: "",
      tagId: undefined,
      contentType: undefined,
    },
  })

  useEffect(() => {
    if (!open) {
      form.reset()
    }
  }, [open, form])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex mb-4 gap-4">
          <FormField
            control={form.control}
            name="tagId"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel required>Select Topic</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a topic..." />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {tags &&
                      tags.map((tag) => (
                        <SelectItem value={tag.id} key={tag.id}>
                          {tag.name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="contentType"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel required>Select Type</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a type..." />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {community.contentTypes.map((type) => (
                      <SelectItem value={type} key={type}>
                        {CONTENT_TYPE_LABELS[type]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel required>Title</FormLabel>
              <FormControl>
                <Input
                  className="bg-white"
                  placeholder="Content Title..."
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel required>Description</FormLabel>
              <FormControl>
                <Textarea
                  className="bg-white"
                  placeholder="Enter a description of the content"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="h-[1px] bg-gray-300 my-8" />

        <div className="flex justify-end">
          <Button
            type="submit"
            className="px-10"
            disabled={loading}
            isLoading={loading}
          >
            Continue
          </Button>
        </div>
      </form>
    </Form>
  )
}
