import { DialogProps } from "@radix-ui/react-dialog"
import { useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import {
  AhoyEventTypeEnum,
  ArticleContentTypeEnum,
  CreateArticleMutation,
  CreateCourseMutation,
} from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Dialog, DialogContent } from "~/ui/dialog"
import { ContentForm, CreateContentModalFormValues } from "./ContentForm"
import { FetchResult } from "@apollo/client"
import { CREATE_ARTICLE_MUTATION } from "~/articles/useArticleDetailsModal"

export type CreateContentModalProps = {
  onCreateArticle?: (
    article: CreateArticleMutation["articleCreate"]["article"]
  ) => void
  onCreateCourse?: (
    course: CreateCourseMutation["courseCreate"]["course"]
  ) => void
} & DialogProps

export const CreateContentModal = ({
  onCreateArticle,
  onCreateCourse,
  ...dialogProps
}: CreateContentModalProps) => {
  const { logEvent } = useLogEvent()

  const [runCreateArticle, createArticleResult] = useSafeMutation(
    CREATE_ARTICLE_MUTATION
  )
  const [runCreateCourse, createCourseResult] = useSafeMutation(
    CREATE_COURSE_MUTATION
  )

  const isLoading = createArticleResult.loading || createCourseResult.loading

  const onSubmitCourse = useCallback(
    ({
      data,
      errors,
    }: Pick<FetchResult<CreateCourseMutation>, "data" | "errors">) => {
      if (errors) {
        displayErrors(errors)
        return
      }

      toast.success("Created content")

      const courseId = data?.courseCreate.course.id
      const articleId = data?.courseCreate.course.article?.id

      invariant(courseId)
      invariant(articleId)
      logEvent(AhoyEventTypeEnum.ContentSubmitted, {
        content_id: articleId,
      })

      onCreateCourse && onCreateCourse(data?.courseCreate.course)
    },
    [logEvent, onCreateCourse]
  )

  const onSubmitArticle = useCallback(
    ({
      data,
      errors,
    }: Pick<FetchResult<CreateArticleMutation>, "data" | "errors">) => {
      if (errors) {
        displayErrors(errors)
        return
      }

      const articleId = data?.articleCreate.article.id
      invariant(articleId)
      logEvent(AhoyEventTypeEnum.ContentSubmitted, {
        content_id: articleId,
      })
      onCreateArticle && onCreateArticle(data?.articleCreate.article)
    },
    [logEvent, onCreateArticle]
  )

  const onSubmit = useCallback(
    async (values: CreateContentModalFormValues) => {
      if (values.contentType === ArticleContentTypeEnum.Course) {
        const result = await runCreateCourse({
          variables: {
            input: {
              title: values.title,
              tagId: values.tagId,
              description: values.description,
            },
          },
        })
        onSubmitCourse(result)
      } else {
        const result = await runCreateArticle({
          variables: {
            input: {
              ...values,
            },
          },
        })
        onSubmitArticle(result)
      }
    },
    [runCreateArticle, runCreateCourse, onSubmitArticle, onSubmitCourse]
  )

  useEffect(() => {
    if (dialogProps.open === true) {
      logEvent(AhoyEventTypeEnum.ContentUploadInitiated)
    }
  }, [dialogProps.open, logEvent])

  return (
    <Dialog {...dialogProps}>
      <DialogContent className="w-2/3 max-w-xl gap-0">
        <div className="text-center mb-2 font-serif text-3xl">
          Start Creating!
        </div>
        <div className="text-center mb-6 text-2xs">
          Get started by selecting a topic for your content.
        </div>

        <ContentForm
          loading={isLoading}
          open={!!dialogProps.open}
          onSubmit={onSubmit}
        />
      </DialogContent>
    </Dialog>
  )
}

const CREATE_COURSE_MUTATION = gql(`
  mutation CreateCourse($input: CourseCreateInput!) {
    courseCreate(input: $input) {
      course {
        id

        article {
          ...Article_EditorContent
        }
      }
    }
  }
`)
