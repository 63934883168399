import { CelebrationDestroyInput } from "~/__generated__/graphql"
import { CELEBRATION_DESTROY_MUTATION } from "~/celebrations"
import { useSafeMutation } from "~/common/useSafeMutation"

export const useDestroyCelebration = () => {
  const [destroyCelebration, { loading }] = useSafeMutation(
    CELEBRATION_DESTROY_MUTATION
  )

  const destroy = async (input: CelebrationDestroyInput) => {
    return await destroyCelebration({
      variables: {
        input,
      },
    })
  }
  return { destroy, loading }
}
