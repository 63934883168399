import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const CREATE_MANUAL_HRIS_DATA = gql(`
  mutation CreateManualHrisData($input: CreateManualHrisDataInput!) {
    createManualHrisData(input: $input) {
      manualHrisData {
        industry
        hrisBackend
        avgCostPerEmployeeDollars
        avgTenureMonths
        avgRetentionPercent
        avgTurnoverPercent
      }
    }
  }
`)
