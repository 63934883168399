import Warning from "@editorjs/warning"
import { IconWarning } from "@codexteam/icons"

export class WorkweekCalloutTool extends Warning {
  static get toolbox() {
    return {
      icon: IconWarning,
      title: "Callout",
    }
  }
}
