import humps from "humps"
import { DirectUpload, DirectUploadDelegate } from "@rails/activestorage"
import { getMetaVar } from "./getMetaVar"

const directUploadsUrl = getMetaVar("direct-uploads-url")

export const directImageUpload = async (
  image: FileList[number],
  progressDelegate?: DirectUploadDelegate
): Promise<any> => {
  const data = await uploadFile(image, progressDelegate)
  return humps.camelizeKeys(data)
}

const uploadFile = (file: File, progressDelegate?: DirectUploadDelegate) => {
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, directUploadsUrl, progressDelegate)

    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob)
      }
    })
  })
}
