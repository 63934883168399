import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { ChatMessagesQuery, RoomQueryQuery } from "~/__generated__/graphql"
import { Error } from "~/ui/Error"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { PostCard } from "~/feed/PostCard"
import { parseISO } from "date-fns"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { useMarkMessagesRead } from "./useMarkMessagesRead"
import { useMemo } from "react"

const PER_PAGE = 10

export const MessagesSectionInner = ({
  roomData,
}: {
  roomData: RoomQueryQuery
}) => {
  const { data, loading, error, fetchMore } = useQuery(
    MESSAGES_QUERY_DOCUMENT,
    {
      variables: {
        roomId: roomData.room.id,
        first: PER_PAGE,
      },
      notifyOnNetworkStatusChange: true,
    }
  )
  useMarkMessagesRead({
    roomId: roomData.room.id,
    hasUnreads: roomData.room.hasUnreadPosts,
  })

  const orderedPosts = useMemo(() => {
    if (!data) return []
    return data.posts.edges
      .map((e) => e.node)
      .slice()
      .filter((p, i, self) => self.findIndex((t) => t.id === p.id) === i)
      .sort((a, b) => {
        const aDate = parseISO(a.createdAt)
        const bDate = parseISO(b.createdAt)
        return bDate.getTime() - aDate.getTime()
      })
  }, [data])

  if (!data && loading)
    return (
      <div className="my-8 flex justify-center">
        <LoadingIndicator />
      </div>
    )
  if (error || !data) return <Error message="Error loading messages." />

  const mapPostToCard = (
    post: ChatMessagesQuery["posts"]["edges"][0]["node"]
  ) => (
    <PostCard
      key={post.id}
      post={post}
      hidePostActions
      hidePostMenu
      variant="ghost"
    />
  )

  return (
    <>
      {orderedPosts.map(mapPostToCard)}
      <div className="px-4 py-10">
        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: { postsCursor: data.posts.pageInfo.endCursor },
            })
          }
          canLoadMore={!loading && data.posts.pageInfo.hasNextPage}
          loadingText="Loading posts..."
          loading={loading && data.posts.edges.length > 0}
        />
      </div>
    </>
  )
}

export const MESSAGES_QUERY_DOCUMENT = gql(`
  query ChatMessages($roomId: ID, $first: Int, $postsCursor: String) {
    posts(first: $first, after: $postsCursor, roomId: $roomId) {
      edges {
        node {
          ...Post_Card
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)
