import {
  AhoyEventTypeEnum,
  Offer,
  TierIntervalEnum,
  TierLevelEnum,
} from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { LoadingStep } from "./LoadingStep"
import { PricingTableTier } from "./PricingTableStep"
import toast from "react-hot-toast"
import { useUserOffers } from "~/offers/UserOffersProvider"
import { useLogEvent } from "~/analytics/EventsContext"

export const MigrateToTierStep = () => {
  const { currentUser } = useCurrentUser()
  const { goToStep, meta, addToMeta } = useWizard()
  const { setSubscription, migrateToTier, redirectToStripe } = useSubscription()
  const { getBestUserOffer } = useUserOffers()
  const { logEvent } = useLogEvent()

  const { offer, selectedTier, selectedInterval } = useMemo(() => {
    const selectedTier = meta.selectedTier as PricingTableTier
    const selectedInterval = meta.selectedInterval as TierIntervalEnum
    const offer = meta.offer
      ? (meta.offer as Offer)
      : getBestUserOffer(selectedTier.level, selectedInterval)?.offer

    return {
      offer,
      selectedTier,
      selectedInterval,
    }
  }, [meta.selectedTier, meta.selectedInterval, meta.offer, getBestUserOffer])

  const isDowngrading = useMemo(() => {
    if (!selectedTier) return false
    return selectedTier.position < (currentUser.tier?.position || 0)
  }, [currentUser, selectedTier])

  const isCrossgrading = useMemo(() => {
    if (!selectedTier) return false
    return selectedTier.level === currentUser.tier?.level
  }, [currentUser, selectedTier])

  const isUpgrading = useMemo(() => {
    if (!selectedTier) return false
    return selectedTier.position > (currentUser.tier?.position || 0)
  }, [currentUser, selectedTier])

  const hasLoggedOfferClaimAttemptedRef = useRef(false)
  useEffect(() => {
    if (!isUpgrading || !offer) return

    const properties: Record<string, any> = {
      source: meta.source,
      stripe_coupon_id: offer.stripeCoupon?.id,
    }

    if (meta.cancellationReason)
      properties.cancellation_reason = meta.cancellationReason
    if (meta.otherReason)
      properties.other_cancellation_reason = meta.otherReason

    logEvent(
      AhoyEventTypeEnum.UpgradeAttempted,
      properties,
      hasLoggedOfferClaimAttemptedRef
    )
  }, [
    isUpgrading,
    offer,
    logEvent,
    meta.source,
    meta.cancellationReason,
    meta.otherReason,
  ])

  const isCancellationConfirmed = useMemo(() => {
    return !!meta.isCancellationConfirmed
  }, [meta.isCancellationConfirmed])

  const initialized = useRef(false)

  const changeTier = useCallback(async () => {
    if (
      !(
        selectedTier &&
        selectedInterval &&
        (isDowngrading || isCrossgrading || isUpgrading)
      )
    ) {
      toast.error("An unexpected error has occurred. Please try again later.")
      return
    }

    if (isDowngrading && !isCancellationConfirmed) {
      if (selectedTier.level === TierLevelEnum.Free) {
        goToStep("CancellationReasonStep")
      } else {
        goToStep("LookupSaveBeforeLoseOffersStep", "forward", false)
      }
      return
    }

    const { data, errors, needsPaymentMethod } = await migrateToTier(
      selectedTier.level,
      selectedInterval,
      offer?.id
    )

    if (errors && !needsPaymentMethod) return

    if (data) {
      setSubscription(data)
    }

    if (needsPaymentMethod) {
      toast.success("Redirecting to Stripe to update payment method...")
      const redirectMeta: Record<string, any> = {}
      if (offer) {
        redirectMeta.offerId = offer.id
        redirectMeta.offerCategory = offer.category
        redirectMeta.stripeCouponId = offer.stripeCoupon?.id
      }
      if (meta.contentId) {
        redirectMeta.contentId = meta.contentId
      }
      if (meta.source) {
        redirectMeta.source = meta.source
      }
      redirectToStripe(
        selectedTier.level,
        selectedInterval,
        offer?.id,
        redirectMeta
      )
      return
    }

    if (isCrossgrading) {
      goToStep("SameTierSuccessStep")
    } else if (isDowngrading) {
      goToStep("CancelledStep")
    } else {
      addToMeta("latestTier", selectedTier)

      if (data?.latestInvoice) {
        addToMeta("latestInvoice", data.latestInvoice)
      }

      if (data?.upcomingInvoice) {
        addToMeta("upcomingInvoice", data.upcomingInvoice)
      }

      goToStep("PostUpgradeStep")
    }
  }, [
    selectedTier,
    selectedInterval,
    isDowngrading,
    isCrossgrading,
    isUpgrading,
    migrateToTier,
    setSubscription,
    goToStep,
    addToMeta,
    isCancellationConfirmed,
    redirectToStripe,
    offer,
    meta.contentId,
    meta.source,
  ])

  const isReady = useMemo(() => {
    return (
      selectedTier &&
      selectedInterval &&
      (isDowngrading || isCrossgrading || isUpgrading)
    )
  }, [
    selectedTier,
    selectedInterval,
    isDowngrading,
    isCrossgrading,
    isUpgrading,
  ])

  useEffect(() => {
    if (isReady && !initialized.current) {
      initialized.current = true
      changeTier()
    }
  }, [changeTier, isReady])

  return <LoadingStep />
}

MigrateToTierStep.displayName = "MigrateToTierStep"
