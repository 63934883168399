import { ReactNode } from "react"
import { cn } from "~/lib/utils"

export const MessageSectionHeader = ({
  className,
  children,
}: {
  className?: string
  children: ReactNode
}) => (
  <div
    className={cn(
      "flex items-center px-4 h-[75px] border-b border-mercury bg-white tracking-wide",
      className
    )}
  >
    {children}
  </div>
)
