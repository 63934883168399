import { useQuery } from "@apollo/client"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { feedPath, subscriberAgreementPath } from "~/common/paths"
import ReactMarkdown from "react-markdown"
import { useCommunity } from "~/community/useCommunity"
import { Link } from "~/ui/Link"

type LegalPolicy = "subscriberAgreement" | "privacyPolicy"

export const LegalPolicyScreen = ({ policy }: { policy: LegalPolicy }) => {
  const { data, loading } = useQuery(LEGAL_POLICIES_QUERY_DOCUMENT)
  const navigate = useNavigate()
  const community = useCommunity()

  if (loading) return null
  if (!data?.legalPolicies[policy]) {
    toast.error("Could not find requested legal policy")
    navigate(feedPath.pattern)
    return
  }

  return (
    <div className="min-h-screen text-foreground">
      <div className="container mx-auto my-24 text-sm">
        <ReactMarkdown
          components={{
            a: ({ node, children, ...other }) => (
              <a className="text-blue-500 hover:underline" {...other}>
                {children}
              </a>
            ),
            h1: ({ node, children, ...other }) => (
              <h1 className="text-5xl my-4 text-center font-serif" {...other}>
                {children}
              </h1>
            ),
            h2: ({ node, children, ...other }) => (
              <h2 className="text-2xl font-bold my-3" {...other}>
                {children}
              </h2>
            ),
            h3: ({ node, children, ...other }) => (
              <h3 className="text-xl font-bold my-2" {...other}>
                {children}
              </h3>
            ),
            h4: ({ node, children, ...other }) => (
              <h4 className="text-lg font-bold my-2" {...other}>
                {children}
              </h4>
            ),
            h5: ({ node, children, ...other }) => (
              <h5 className="text-base font-bold mt-2" {...other}>
                {children}
              </h5>
            ),
            h6: ({ node, children, ...other }) => (
              <h6 className="text-sm my-2 text-center" {...other}>
                {children}
              </h6>
            ),
            li: ({ node, ...other }) => <li className="py-2" {...other} />,
            ol: ({ node, ...other }) => (
              <ol
                className="list-decimal list-outside pl-4 pb-2 first:pt-0 last:pb-0"
                {...other}
              />
            ),
            p: ({ node, ...other }) => (
              <p className="py-2 first:pt-0 last:pb-0" {...other} />
            ),
            ul: ({ node, ...other }) => (
              <ul
                className="list-disc list-outside pl-4 pb-2 first:pt-0 last:pb-0"
                {...other}
              />
            ),
          }}
        >
          {data.legalPolicies[policy]}
        </ReactMarkdown>
      </div>
      <div className="border-t">
        <div className="mx-auto flex gap-4 h-16 items-center text-2xs w-fit">
          <div>
            &copy; {new Date().getFullYear()} {community.brand}
          </div>
          <Link to={subscriberAgreementPath.pattern}>Subscriber Agreement</Link>
          {/* <Link to={privacyPolicyPath.pattern}>
            Privacy Policy
          </Link> */}
        </div>
      </div>
    </div>
  )
}

const LEGAL_POLICIES_QUERY_DOCUMENT = gql(`
  query LegalPolicies {
    legalPolicies {
      subscriberAgreement
      privacyPolicy
    }
  }
`)
