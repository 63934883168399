import { Post_CardFragment } from "~/__generated__/graphql"
import { ChevronDown } from "lucide-react"
import {
  formatSize,
  getAttachmentColor,
} from "~/editor/renderers/AttachmentRenderer"
import mime from "mime"

export const FileAttachments = ({
  attachments,
}: {
  attachments: Post_CardFragment["attachments"]
}) => {
  if (attachments.length === 0) return null

  return (
    <>
      {attachments.map((attachment) => {
        const extension = mime.getExtension(attachment.contentType)
        const { formattedSize, sizePrefix } = formatSize(attachment.byteSize)

        return (
          <a
            href={attachment.editorUrl}
            className="flex items-center border border-mercury rounded-2xl px-4 py-2 card-clickable nostyle"
            key={attachment.id}
          >
            {extension && (
              <div
                className="text-white text-3xs uppercase font-bold tracking-wider p-2 rounded mr-4 min-w-[40px] text-center"
                style={{
                  backgroundColor: getAttachmentColor(extension),
                }}
              >
                {extension}
              </div>
            )}
            <div className="flex-1">
              <div className="text-sm">{attachment.filename}</div>
              <div className="text-2xs text-slate-500">
                {formattedSize} {sizePrefix}
              </div>
            </div>
            <div className="rounded bg-slate-100 p-2 aspect-square flex items-center justify-center">
              <ChevronDown size={16} />
            </div>
          </a>
        )
      })}
    </>
  )
}
